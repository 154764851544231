import { CreateUpdateContactDto } from "@app/redux/features/v2/org-service/models/organisation-portal-dto";
import { Form, InputGroup } from "react-bootstrap";
import deleteIcon from "@assets/icons/delete_icon.svg";

const ContactBlock = ({
  isError,
  contact,
  country_data,
  index,
  update,
  remove,
}: {
  isError: boolean;
  contact: CreateUpdateContactDto;
  country_data: any;
  index: number;
  update: (index: number, updatedContact: CreateUpdateContactDto) => void;
  remove: (index: number) => void;
}) => {
  return (
    <div key={index} className={`col-12 flex-column row mb-3 gap-3 ${index !== 0 ? "border-grey-901 border-top" : ""}`}>
      {index !== 0 ? (
        <div className="d-flex justify-content-end align-items-end">
          <div
            className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
            style={{ width: "fit-content" }}
            onClick={() => remove(index)}
          >
            <img src={deleteIcon} alt="Delete" />
            Delete
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col">
        <div className="d-flex flex-row gap-3">
          <div className="col-3">
            <span className="font-10px text-dark">First name</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="text"
                placeholder="Enter first name"
                value={contact?.contact_first_name ?? ""}
                onChange={(e) => update(index, { ...contact, contact_first_name: e.target.value })}
              />
            </InputGroup>
            {isError && contact?.contact_first_name === "" ? (
              <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
            ) : null}
          </div>
          <div className="col-3">
            <span className="font-10px text-dark">Last name</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="text"
                placeholder="Enter last name"
                value={contact?.contact_last_name ?? ""}
                onChange={(e) => update(index, { ...contact, contact_last_name: e.target.value })}
              />
            </InputGroup>
            {isError && contact?.contact_last_name === "" ? (
              <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
            ) : null}
          </div>
          <div className="col">
            <span className="font-10px text-dark">Relationship</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="text"
                placeholder="Enter relationship"
                value={contact?.relationship ?? ""}
                onChange={(e) => update(index, { ...contact, relationship: e.target.value })}
              />
            </InputGroup>
            {isError && contact?.relationship === "" ? (
              <Form.Control.Feedback type="invalid">Please enter relationship</Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex flex-row gap-3">
          <div className="col-6">
            <span className="font-10px text-dark">Email</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="text"
                placeholder="Enter email address"
                value={contact?.contact_email ?? ""}
                onChange={(e) => update(index, { ...contact, contact_email: e.target.value })}
              />
            </InputGroup>
            {isError && contact?.contact_email === "" ? (
              <Form.Control.Feedback type="invalid">Please enter email address</Form.Control.Feedback>
            ) : null}
          </div>
          <div className="col-2">
            <span className="font-10px text-dark">Mobile prefix</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Select
                className="border-0 bg-transparent font-12px py-2"
                value={contact?.contact_mobile_prefix ?? ""}
                onChange={(e) => update(index, { ...contact, contact_mobile_prefix: e.target.value })}
              >
                <option value="" hidden>
                  Select prefix
                </option>
                {country_data.map((country: any) => (
                  <option key={country.sid} value={country.sid}>
                    {country.phone_prefix}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            {isError && contact?.contact_mobile_prefix === "" ? (
              <Form.Control.Feedback type="invalid">Please select your mobile prefix</Form.Control.Feedback>
            ) : null}
          </div>
          <div className="col">
            <span className="font-10px text-dark">Phone number</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="text"
                placeholder="Enter mobile number"
                value={contact?.contact_phone_number ?? ""}
                onChange={(e) => update(index, { ...contact, contact_phone_number: e.target.value })}
              />
            </InputGroup>
            {isError && contact?.contact_phone_number === "" ? (
              <Form.Control.Feedback type="invalid">Please enter mobile number</Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col d-flex align-items-center gap-3">
        <Form.Check
          type="switch"
          checked={contact.is_sos}
          onChange={(e) => update(index, { ...contact, is_sos: e.target.checked })}
          className="custom-switch"
        />
        <span className="font-12px fw-normal">SOS Contact</span>
      </div>
    </div>
  );
};

export default ContactBlock;

import React, { ReactNode } from "react";

export interface PieDataItem {
  name: string;
  value: number;
  status: string;
  color: string;
}
interface PieChartProps {
  children?: ReactNode;
  radius?: number;
  data: PieDataItem[];
}

const calculateTotalData = (data: PieDataItem[]) =>
  data.reduce((total, segment) => total + segment.value, 0);

const PieChart = ({ children, data, radius = 80 }: PieChartProps) => {
  const totalTime = calculateTotalData(data);
  const strokeWidth = 6;
  const gapSize = 10;
  const circumference = 2 * Math.PI * radius;

  // Calculate offsets for each segment
  const segmentData = data.map((segment) => {
    const percentage = segment.value / totalTime;
    const segmentLength = percentage * circumference - gapSize; 
    return { ...segment, segmentLength };
  });

  let cumulativeOffset = 0;

  return (
    <div style={{ position: "relative", width: radius * 2, height: radius * 2 }}>
      <svg width={radius * 2} height={radius * 2}>
        <g transform={`rotate(-90 ${radius} ${radius})`}>
          {/* Render each segment */}
          {segmentData.map((segment, index) => {
            const offset = cumulativeOffset + gapSize;
            cumulativeOffset += segment.segmentLength + gapSize; // Add gapSize to offset
            return (
              <circle
                key={index}
                cx={radius}
                cy={radius}
                r={radius - strokeWidth / 2}
                stroke={segment.color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${segment.segmentLength} ${circumference}`}
                strokeDashoffset={-offset}
                strokeLinecap="round" // Add rounded edges
                fill="none"
              />
            );
          })}
        </g>
      </svg>

      {/* Center Text */}
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none"
      }}>
        <div style={{
          width: "80%",
          height: "80%",
          backgroundColor: "#F7F7F7", 
          borderRadius: radius,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          {children && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {children}
              
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChart;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import ReportServiceApi from "@app/services/report-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";

export const getMonthlyReportsAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "reportService/getMonthlyReports",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ReportServiceApi.getMonthlyReports(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const getMonthlyReportsByVitalAsync = createAsyncThunk<
    { vital_type: string, result: string }, // Return type
    { user_sid: string, vital_type: string }, // Params type
    { rejectValue: ApiError | undefined }
>(
    "reportService/getMonthlyReportsByVital",
    async (params, { rejectWithValue }) => {
        try {
            const { user_sid, vital_type } = params;
            const response = await ReportServiceApi.getMonthlyReportsByVital(user_sid, vital_type);
            return { vital_type, result: response.data };
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

import ValueBreakDown from "./ValueBreakdown";
import { VitalType } from "@app/common/enums/VitalTypes";
import noteIcon from "@assets/icons/note_icon.svg";
import noteCloseIcon from "@assets/icons/note_close_icon.svg";
import {
  Button,
  ButtonGroup,
  OffcanvasBody,
  OffcanvasHeader,
} from "react-bootstrap";
import MonthlyReportAreaChart from "@app/components/MonthlyReportChart/MonthlyReportAreaChart";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import NoteContent from "./NoteContent";
import { GraphProps } from "@app/redux/features/v2/report-service/model/monthly-report";
import NodataCard from "@app/components/NoDataCard";

interface ValueBreakdown {
  veryLow?: number;
  low?: number;
  normal?: number;
  high?: number;
  veryHigh?: number;
}
interface ValueBreakDownItem {
  minimum: number;
  average: number;
  maximum: number;
}

interface BaseReportCardProps {
  title?: string;
  icon?: string;
  value?: ValueBreakDownItem;
  unit?: string;
  breakdownValues?: ValueBreakdown;
  monthlySummaryData?: GraphProps[];
  dailySummaryData?: GraphProps[];
  upperThreshold?: number;
  lowerThreshold?: number;
  yAxisDomain?: number[];
  yAxisTicks?: number[];
  xAxisTicks?: number[];
  pointPeriod?: number;
  xAxisType?: string;
}

interface BloodReportCardProps extends BaseReportCardProps {
  bloodType: "BPD" | "BPS";
  selectBloodType: (bloodType: "BPD" | "BPS") => void;
  type: VitalType.BLOOD_PRESSURE;
}

interface OtherReportCardProps extends BaseReportCardProps {
  bloodType?: "BPD" | "BPS";
  selectBloodType?: (bloodType: "BPD" | "BPS") => void;
  type: Exclude<VitalType, VitalType.BLOOD_PRESSURE>;
}

// Union type for the props
type DetailReportCardProps = BloodReportCardProps | OtherReportCardProps;

const DetailReportCard: React.FC<DetailReportCardProps> = (
  props: DetailReportCardProps
) => {
  const [showNote, setShowNote] = useState(false);
  const handleShowNote = () => setShowNote(true);
  const handleCloseNote = () => setShowNote(false);

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-between align-items-center w-100 border-bottom border-grey-904 pb-2">
        <div className="col-4 d-flex flex-row justify-content-start align-items-center">
          <img
            width={18}
            height={18}
            src={VitalType.getIcons(props.type)}
            alt="Identity Icon"
          />
          <span className="font-14px fw-medium d-flex flex-grow-1 ms-2">
            {VitalType.getAnalysisName(props.type)}
          </span>
        </div>

        {/* Note button for Glucose */}
        {props.type === VitalType.GLUCOSE && (
          <div className="col-4 d-flex flex-row justify-content-end align-items-center">
            <div
              className="d-flex justify-content-start align-items-center cursor-pointer"
              onClick={handleShowNote}
            >
              <div className="py-2  d-flex align-items-center">
                <p className="my-0 font-12px progress-bar text-blue-902 fw-medium">
                  View Note
                </p>
                <img className="ms-1" src={noteIcon} alt="Note" />
              </div>
            </div>
          </div>
        )}
        {/* Note button for Blood Pressure */}
        {props.type === VitalType.BLOOD_PRESSURE && (
          <div className="col d-flex flex-row justify-content-end align-items-center">
            {" "}
            <div className="ms-2 tab-container analysis-tabs d-flex flex-row justify-content-end align-items-center border-end border-grey-904">
              <div className="select-container me-2">
                <ButtonGroup>
                  <Button
                    className="text-nowrap border-blue-902"
                    style={{ margin: "1 rem", textTransform: "none" }}
                    onClick={() => props.selectBloodType("BPS")}
                    variant={
                      props.bloodType === "BPD" ? "primary" : "secondary"
                    }
                  >
                    Systolic Blood Pressure
                    {/* {item.key} */}
                  </Button>
                  <Button
                    className="ms-2 text-nowrap"
                    style={{ margin: "1 rem", textTransform: "none" }}
                    onClick={() => props.selectBloodType("BPD")}
                    variant={
                      props.bloodType === "BPS" ? "primary" : "secondary"
                    }
                  >
                    Diastolic Blood Pressure
                    {/* {item.key} */}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div
              className="d-flex justify-content-start align-items-center cursor-pointer"
              onClick={handleShowNote}
            >
              <div className="py-2 ps-2  d-flex align-items-center">
                <p className="my-0 font-12px progress-bar text-blue-902 fw-medium">
                  View Note
                </p>
                <img className="ms-1" src={noteIcon} alt="Note" />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Note Offcanvas */}
      <Offcanvas show={showNote} onHide={handleCloseNote} placement="end">
        <OffcanvasHeader>
          <img
            className="ms-2 me-3 cursor-pointer"
            onClick={handleCloseNote}
            src={noteCloseIcon}
            alt="Note"
          />
          <p className="my-0 font-14px fw-medium">Note</p>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0 mx-3">
          <NoteContent />
        </OffcanvasBody>
      </Offcanvas>

      {/* Content */}
      <div className="d-flex flex-column flex-lg-row col-12 gap-3">
        <div className="col">
          <div className="mb-1 mt-3 pe-3">
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              <span className="font-12px fw-medium d-flex flex-grow-1 mb-3">
                Daily values summary
              </span>
            </div>
            {props.monthlySummaryData && props.monthlySummaryData.length > 0 ? (
              <MonthlyReportAreaChart
                summaryData={props.monthlySummaryData}
                upperThreshold={props.upperThreshold}
                lowerThreshold={props.lowerThreshold}
                yAxisDomain={props.yAxisDomain}
                yAxisTicks={props.yAxisTicks}
                xAxisTicks={props.xAxisTicks}
                xAxisType={props.xAxisType || "day"}
                pointPeriod={props.pointPeriod || 4}
              />
            ) : (
              <NodataCard />
            )}
          </div>
        </div>
        <div className="col">
          <div className="border-start mb-1 mt-3 ps-4">
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              <span className="font-12px fw-medium d-flex flex-grow-1 mb-3">
                Hourly values summary
              </span>
            </div>
            {props.dailySummaryData && props.dailySummaryData.length > 0 ? (
              <MonthlyReportAreaChart
                summaryData={props.dailySummaryData}
                upperThreshold={props.upperThreshold}
                lowerThreshold={props.lowerThreshold}
                yAxisDomain={props.yAxisDomain}
                yAxisTicks={props.yAxisTicks}
                xAxisTicks={props.xAxisTicks ?? [0, 4, 8, 12, 16, 20, 24]}
                xAxisType={props.xAxisType ?? "time"}
                pointPeriod={props.pointPeriod}
              />
            ) : (
              <NodataCard />
            )}
          </div>
        </div>
        <div className="col">
          <ValueBreakDown
            type={props.type}
            breakdownValues={props.breakdownValues}
            value={props.value}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailReportCard;

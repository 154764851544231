import { NavLink, Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import logo from "@assets/images/logo_2.png";
import userIcon from "@assets/images/unknown-user.png";
import { logout } from "@app/redux/features/v2/security-service/securityServiceSlice";
import React, { useState } from "react";
import menuBarIcon from "@assets/icons/menu_bar_icon.svg";
import {
  Dropdown,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "react-bootstrap";
import closeIcon from "@assets/icons/close_icon.svg";
import SubNavMobile from "./SubnavMobile";

const subNavLinks = [
  { label: "Heart Rate", path: "/consumer/health-insights/heart-rate" },
  { label: "Blood Pressure", path: "/consumer/health-insights/blood-pressure" },
  {
    label: "Oxygen Saturation",
    path: "/consumer/health-insights/oxygen-saturation",
  },
  { label: "Temperature", path: "/consumer/health-insights/temperature" },
  { label: "Glucose", path: "/consumer/health-insights/glucose" },
  {
    label: "Respiratory Rate",
    path: "/consumer/health-insights/respiratory-rate",
  },
  { label: "Stress", path: "/consumer/health-insights/stress" },
  { label: "Sleep", path: "/consumer/health-insights/sleep" },
  { label: "Steps", path: "/consumer/health-insights/steps" },
  { label: "ECG", path: "/consumer/health-insights/ecg" },
];

interface NavProps {
  navLinks: {
    label: string;
    path: string;
  }[];
}
const Navbar = ({ navLinks }: NavProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => setShowNavbar(true);
  const handleCloseNavbar = () => setShowNavbar(false);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark text-white p-0">
        <div className="container-xl justify-content-center h-100">
          <div className="row w-100 justify-content-center align-items-center h-100">
            <div className="col-5 col-md-8 col-lg-2 ">
              <div className="d-flex flex-grow-1 justify-content-start">
                <Link className="navbar-brand" to="*">
                  <img height={20} width={100} alt="..." src={logo}></img>
                </Link>
              </div>
            </div>

            <div className="d-none d-lg-block col-8 h-100">
              <div
                className="collapse navbar-collapse h-100"
                id="navbarNavAltMarkup"
              >
                <div className="navbar-nav w-100 h-100">
                  <div className="d-flex flex-grow-1 justify-content-center h-100">
                    {navLinks.map((link) => (
                      <NavLink
                        className={({ isActive }) =>
                          `px-2 py-1 nav-link ${
                            isActive ? "text-white fw-medium" : ""
                          }`
                        }
                        aria-current="page"
                        to={link.path}
                        key={link.path}
                      >
                        <span className="d-flex align-items-center justify-content-center h-100">
                          <p className="font-14px mb-0">{link.label}</p>
                        </span>
                        <div className="triangle"></div>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-7 col-md-4 col-lg-2 d-flex align-items-center justify-content-end h-100">
              <Dropdown className="h-100 me-2">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="bg-transparent border-0 p-0 d-flex align-items-center text-white h-100"
                >
                  <div className="me-1">
                    <img
                      height={20}
                      width={20}
                      className="d-flex align-items-center justify-content-center"
                      alt="..."
                      src={userIcon}
                    ></img>
                  </div>
                  {currentUser?.email}
                </Dropdown.Toggle>

                <Dropdown.Menu className="login-dropdown">
                  <Dropdown.Item
                    href="#-1"
                    onClick={() => navigate("/consumer/profile")}
                  >
                    <span className="ms-1 font-18px fw-medium">Profile</span>
                  </Dropdown.Item>

                  <Dropdown.Item href="#-1" onClick={() => dispatch(logout())}>
                    <span className="ms-1 font-18px fw-medium">Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div
                onClick={handleShowNavbar}
                className="h-100 d-flex d-lg-none align-items-center text-white"
              >
                <img width={18} height={18} src={menuBarIcon} alt="Menu Icon" />
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Navbar Offcanvas */}
      <Offcanvas show={showNavbar} onHide={handleCloseNavbar} placement="end">
        <OffcanvasHeader
          id="offcanvasRight"
          className="d-flex align-items-center justify-content-between"
        >
          <p style={{ marginBottom: 0, fontSize: "20px", fontWeight: 600 }}>
            Menu
          </p>
          <img
            width={20}
            height={20}
            className="ms-2 me-3 cursor-pointer"
            onClick={handleCloseNavbar}
            src={closeIcon}
            alt="Close icon"
          />
        </OffcanvasHeader>
        <OffcanvasBody className="p-0 mx-3">
          <div className="navbar-nav w-100 h-100">
            <div className="d-flex flex-column flex-grow-1 h-100">
              {navLinks.map((link) => (
                <React.Fragment key={link.label}>
                  <NavLink
                    className={({ isActive }) =>
                      `px-2 py-1 nav-link ${
                        isActive ? "navbar_drawer_text fw-medium" : ""
                      }`
                    }
                    aria-current="page"
                    to={link.path}
                  >
                    <span className="d-flex align-items-center h-100">
                      <p className="font-14px mb-0">{link.label}</p>
                    </span>
                    <div className="triangle"></div>
                  </NavLink>
                  {link.label === "Health Insights" && (
                    <NavLink className="text-decoration-none" to={link.path}>
                      {({ isActive }) =>
                        isActive && <SubNavMobile navLinks={subNavLinks} />
                      }
                    </NavLink>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default Navbar;

import { FluctuationTypes } from "@app/common/enums/FluctuationTypes";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { VitalType } from "@app/common/enums/VitalTypes";
import React from "react";
import { CardContainer } from "./CardContainer";
import moment from "moment";

interface CardContainerProps {
  vitalType: VitalType;
  currentData: string;
  unit: string;
  date: string;
  dataHistory: SleepDataHistory[];
}

export interface SleepDataHistory {
  type: TimePeriod;
  value: string;
  increase: FluctuationTypes;
}

export const SleepCardContainer = ({
  vitalType,
  currentData,
  unit,
  date,
  dataHistory,
}: CardContainerProps) => {
  const customBuildHistoryData = () => {
    return (
      <div className="row flex-fill">
        {dataHistory.map((e: SleepDataHistory, index: number) =>
          customBuildElementType({
            item: e,
            index: index,
          })
        )}
      </div>
    );
  };

  const customBuildElementType = ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }) => {
    return (
      <div
        className={`col-4 d-flex flex-column justify-content-end ${
          index === 1
            ? "border-grey-905 border border-top-0 border-bottom-0"
            : ""
        }`}
      >
        <div className="d-flex flex-column">
          <span className="font-12px text-grey-901 whitespace-nowrap overflow-hidden text-truncate">
            {TimePeriodHelper.getTimePeriodName(item.type)}
          </span>
          <div className="d-flex flex-row align-items-center">
            <span className="font-18px fw-medium me-2 text-black whitespace-nowrap overflow-hidden text-truncate">
              {item.value}
            </span>
            <img
              src={FluctuationTypes.getIcon(item.increase)}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const customBuildContent = () => {
    return (
      <>
        <div className="d-flex flex-row align-items-end">
          <span className="font-28px fw-medium text-black">{currentData}</span>

          <span className="font-12px text-grey-901 pb-2">{unit}</span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <span className="font-12px text-grey-901">{getTextTime()}</span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            hi
          </span>
        </div>
        <div
          className="d-flex flex-row align-items-center justify-content-between w-100"
          style={{ marginTop: "-10px", marginBottom: "10px" }}
        >
          <span
            className="font-12px text-grey-901"
            style={{ color: "transparent" }}
          >
            space
          </span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            space
          </span>
        </div>
      </>
    );
  };

  const getTextTime = (): string => {
    if (date === "") {
      return "N/A";
    }
    const dateMoment = moment(date);

    return dateMoment.format("DD MMM YYYY - HH:mm:ss");
  };

  return (
    <CardContainer
      vitalType={vitalType}
      currentData={undefined}
      unit={unit}
      dataHistory={[]}
      date={date}
      customBuildElementType={customBuildHistoryData}
      customBuildContent={customBuildContent}
    />
  );
};

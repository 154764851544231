import { useEffect } from "react";
import moment from "moment";

import BasicCard from "./widgets/Home.BasicCard";
import HighRiskCard from "./widgets/Home.HighRisk";
import AlertClientCard from "./widgets/Home.Alert";
import { Tab, Tabs } from "react-bootstrap";

import clientsIcon from "@assets/icons/clients_icon.svg";
import sosIcon from "@assets/icons/sos_client_icon.svg";
import highRiskIcon from "@assets/icons/portal/high_risk_icon.svg";

import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import { RootState } from "@app/redux/store";

import {
  getClientsAlertCardDataAsync,
  getClientsCardDataAsync,
  getHighRiskClientsCardDataAsync,
} from "@app/redux/features/v2/portal-service/home/portalHomeThunk";

const Home = () => {
  const { clientsCardData, clientsAlertCardData, highRiskClientsCardData } =
    useAppSelector((state: RootState) => state.portalHomeV2);
  const dispatch = useAppDispatch();

  const org_id = getOrgFromJwt();

  const fetchCardsData = (orgId: string) => {
    dispatch(getClientsCardDataAsync(orgId));
    dispatch(getClientsAlertCardDataAsync(orgId));
    dispatch(getHighRiskClientsCardDataAsync(orgId));
  };

  const getCardDateValue = (date?: Date) =>
    moment(date ?? undefined).format("DD MMM YYYY");

  useEffect(() => {
    if (!org_id) return;
    fetchCardsData(org_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id]);

  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column">
          <div className="mt-5">
            <p className="font-20px mb-0 fw-bold">Home</p>
          </div>
          <div className="d-flex flex-wrap flex-md-nowrap flex-row gap-2 mt-3">
            <BasicCard
              title="Clients"
              icon={highRiskIcon}
              value={clientsCardData?.count ?? 0}
              period={getCardDateValue(clientsCardData?.date as Date)}
              unit="clients"
            />
            <BasicCard
              title="Clients Alert"
              icon={clientsIcon}
              value={clientsAlertCardData?.count ?? 0}
              period={getCardDateValue(clientsAlertCardData?.date as Date)}
              unit="clients"
            />
            <BasicCard
              title="High Risk Clients"
              icon={sosIcon}
              value={highRiskClientsCardData?.count ?? 0}
              period={getCardDateValue(highRiskClientsCardData?.date as Date)}
              unit="clients"
            />
          </div>
          <div className="tab-container history-tabs mt-3">
            <Tabs className="">
              <Tab
                eventKey="medicalHistory"
                title="High Risk Clients"
                className="text-black"
              >
                <HighRiskCard />
              </Tab>
              <Tab
                eventKey="medication"
                title="Clients Alert"
                className="text-black"
              >
                <AlertClientCard />
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;

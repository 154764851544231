import { VitalType } from "@app/common/enums/VitalTypes";
import PieChart, { PieDataItem } from "./PieChart";

interface ValueBreakdown {
  veryLow?: number;
  low?: number;
  normal?: number;
  high?: number;
  veryHigh?: number;
}
interface ValueBreakDownItem {
  minimum: string;
  average: string;
  maximum: string;
}
interface SummaryDetailProps {
  title?: string;
  icon?: string;
  unit?: string;
  type: VitalType;
  breakdownValues?: ValueBreakdown;
  wearingMymonX?: number;
  value?: ValueBreakDownItem;
}

const SummaryDetail: React.FC<SummaryDetailProps> = (
  props: SummaryDetailProps
) => {
  const typeRanges = {
    [VitalType.SLEEP]: [
      { color: "very-low", label: "Light Sleep", type: "light" },
      { color: "low", label: "Deep Sleep", type: "deep" },
    ],
  };
  const ranges = typeRanges[props.type as keyof typeof typeRanges] || [];
  const InfoBlock = ({
    range,
  }: {
    range: { color: string; label: string; type: string };
  }) => (
    <div className="d-flex flex-row align-items-center">
      <div
        className={`bg-${range.color} rounded-circle ms-2 me-2`}
        style={{
          width: "8px",
          height: "8px",
          display: "inline-block",
        }}
      ></div>
      <p className="m-0 text-grey-901 font-12px">
        {range.label}

        <span className="font-12px fw-medium ms-1 text-dark">
          ({props.breakdownValues?.[range.type as keyof ValueBreakdown] || 0}%)
        </span>
      </p>
    </div>
  );

  const convertTimeToHoursAndMinutes = (minutes: string) => {
    if (!minutes || minutes === undefined) {
      return "N/A";
    }
    const mins = parseInt(minutes, 10);
    const hours = Math.floor(mins / 60);
    const remainingMinutes = mins % 60;

    return `${hours + " hours " + remainingMinutes + " mins"}`;
  };

  const dailySleepData: PieDataItem[] = [
    { name: "Light", value: 54, status: "very-low", color: "#A281E4" },
    { name: "Deep", value: 40, status: "low", color: "#3F72F3" },
  ];
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Value Breakdown */}
      <div className="w-100 d-flex flex-row flex-wrap mt-2">
        <PieChart data={dailySleepData} />

        <div className="d-flex flex-column flex-wrap mt-2 ms-4">
          <div className="d-flex flex-column flex-wrap mt-2">
            <p className="m-0 text-dark font-14px fw-medium ms-2">
              {convertTimeToHoursAndMinutes(props.wearingMymonX?.toString() || "")}
            </p>
            <p className="mb-2 ms-2 text-grey-901 font-12px fw-medium">
              While wearing MyMonX this month
            </p>
          </div>
          <div className="d-flex flex-row flex-wrap mt-2">
            {ranges.map((range, index) => (
              <InfoBlock range={range} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* Value */}
      <div className="w-100 d-flex flex-row px-2 border-top border-grey-904 mt-2">
        <div className="col">
          <div className="d-flex flex-column mt-2">
            <p className="m-0 text-grey-901 font-12px fw-medium">Minimum</p>
            <p className="m-0 text-dark font-14px fw-medium">
              {convertTimeToHoursAndMinutes(props.value?.minimum || "")}{" "}
            </p>
          </div>
        </div>
        <div className="col border-start border-grey-904">
          <div className="d-flex flex-column mt-2 ms-2">
            <p className="m-0 text-grey-901 font-12px fw-medium">Average</p>
            <p className="m-0 text-dark font-14px fw-medium">
              {convertTimeToHoursAndMinutes(props.value?.average || "")}{" "}
            </p>
          </div>
        </div>
        <div className="col border-start border-grey-904">
          <div className="d-flex flex-column mt-2 ms-2">
            <p className="m-0 text-grey-901 font-12px fw-medium">Maximum</p>
            <p className="m-0 text-dark font-14px fw-medium">
              {convertTimeToHoursAndMinutes(props.value?.maximum || "")}{" "}
            </p>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="w-100 d-flex flex-row flex-wrap mt-2">
        <p className="mb-2 ms-2 text-grey-901 font-12px fw-medium">
          The <span className="text-normal fw-medium"> recommended </span>
          amount of sleep for an adult is 8 hours.
        </p>
      </div>
    </div>
  );
};

export default SummaryDetail;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import { GpsResultDto } from "./models/gps-result-dto";
import { SosRequest, SosResultDto } from "./models/sos-result-dto";
import {
  acknowledgeSosResultAsync,
  closeSosResultAsync,
  getGpsResultAsync,
  getSosResultAsync,
  getVitalsResultAsync,
  saveNotesResultAsync,
} from "./sosServiceThunk";
import { VitalsResultDto } from "./models/vitals-result-dto";
import { UserSosModelDto } from "./models/user-sos-model-dto";
import { appConstants } from "@app/redux/common/constants/app-constant";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";

// Define the interface for SosServiceState
export interface SosServiceState extends BaseState {
  sosRequest: SosRequest;
  sosResultPaginate?: PaginatedResult<SosResultDto>;
  gpsResult: GpsResultDto[];
  notesResult: string | undefined;
  closeResult: string | undefined;
  acknowledgeResult: string | undefined;
  vitalsResult: VitalsResultDto[];
  sosModels: UserSosModelDto[];
}

// // Initial client request object with org_id

const initialClientRequest = {
  page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  page_size: appConstants.GRID_DEFAULT_PAGE_SIZE,
  page: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  per_page: appConstants.GRID_DEFAULT_PAGE_SIZE,
  filter_by: undefined,
  users: {} as SosResultDto,
  org_id: getOrgFromJwt(),
};
// Initial state for SosService
const initialState: SosServiceState = {
  status: ApiStatus.IDLE,
  gpsResult: [], // Initialize as null or appropriate initial value
  vitalsResult: [],
  notesResult: "",
  closeResult: "",
  acknowledgeResult: "",
  sosModels: [],
  sosRequest: initialClientRequest,
  apiError: undefined,
};

// Create the slice
export const sosServiceSlice = createSlice({
  name: "sosServiceV2",
  initialState,
  reducers: {
    // Add reducers here if needed

    setGpsResult(state, action: PayloadAction<GpsResultDto[]>) {
      state.gpsResult = action.payload;
    },
    setVitalsResult(state, action: PayloadAction<VitalsResultDto[]>) {
      state.vitalsResult = action.payload;
    },
    setNotesResult(state, action: PayloadAction<string>) {
      state.notesResult = action.payload;
    },
    setCloseResult(state, action: PayloadAction<string>) {
      state.closeResult = action.payload;
    },
    setSosModels(state, action: PayloadAction<UserSosModelDto[]>) {
      state.sosModels = action.payload;
    },
    setAcknowledgeResult(state, action: PayloadAction<string>) {
      state.acknowledgeResult = action.payload;
    },
    setSosRequest(state, action: PayloadAction<SosRequest>) {
      state.sosRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending, fulfilled, and rejected cases for your async thunks
      .addCase(getSosResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getSosResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.sosResultPaginate = action.payload;
      })
      .addCase(getSosResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getGpsResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getGpsResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.gpsResult = action.payload;
      })
      .addCase(getGpsResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getVitalsResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getVitalsResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.vitalsResult = action.payload;
      })
      .addCase(getVitalsResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(saveNotesResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(saveNotesResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.notesResult = action.payload;
      })
      .addCase(saveNotesResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(closeSosResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(closeSosResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.closeResult = action.payload;
      })
      .addCase(closeSosResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(acknowledgeSosResultAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(acknowledgeSosResultAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.acknowledgeResult = action.payload;
      })
      .addCase(acknowledgeSosResultAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });
  },
});

// Export actions
export const {
  setSosRequest,
  setGpsResult,
  setVitalsResult,
  setNotesResult,
  setCloseResult,
  setSosModels,
  setAcknowledgeResult,
} = sosServiceSlice.actions;

// Export the reducer
export default sosServiceSlice.reducer;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { CreateMessageDto } from "./models/create-message-dto";
import {
    createTextDeviceResultAsync,
    createCurrentPositionResultAsync,
    createFindWatchResultAsync,
    createRestartResultAsync,
    createFactoryRestoreResultAsync,
    createShutdownResultAsync
} from "./messageServiceThunk";
import { ApiError } from "@app/redux/common/models/api-error";

// Define the interface for MessageServiceState
export interface MessageServiceState extends BaseState {
    createTextDeviceResult: string | undefined;
    createCurrentPositionResult: string | undefined;
    createFindWatchResult: string | undefined;
    createRestartResult: string | undefined;
    createFactoryRestoreResult: string | undefined;
    createShutdownResult: string | undefined;
}

// Initial state for MessageService
const initialState: MessageServiceState = {
    status: ApiStatus.IDLE,
    createTextDeviceResult: undefined,
    createCurrentPositionResult: undefined,
    createFindWatchResult: undefined,
    createRestartResult: undefined,
    createFactoryRestoreResult: undefined,
    createShutdownResult: undefined,
    apiError: undefined
}

// Create the slice
export const messageServiceSlice = createSlice({
    name: 'messageService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setCreateTextDeviceResult(state, action: PayloadAction<string>) {
            state.createTextDeviceResult = action.payload;
        },
        setCreateCurrentPositionResult(state, action: PayloadAction<string>) {
            state.createCurrentPositionResult = action.payload;
        },
        setCreateFindWatchResult(state, action: PayloadAction<string>) {
            state.createFindWatchResult = action.payload;
        },
        setCreateRestartResult(state, action: PayloadAction<string>) {
            state.createRestartResult = action.payload;
        },
        setCreateFactoryRestoreResult(state, action: PayloadAction<string>) {
            state.createFactoryRestoreResult = action.payload;
        },
        setCreateShutdownResult(state, action: PayloadAction<string>) {
            state.createShutdownResult = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(createTextDeviceResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createTextDeviceResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createTextDeviceResult = action.payload;
            })
            .addCase(createTextDeviceResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            })

            .addCase(createCurrentPositionResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createCurrentPositionResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createCurrentPositionResult = action.payload;
            })
            .addCase(createCurrentPositionResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            })

            .addCase(createFindWatchResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createFindWatchResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createFindWatchResult = action.payload;
            })
            .addCase(createFindWatchResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            })

            .addCase(createRestartResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createRestartResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createRestartResult = action.payload;
            })
            .addCase(createRestartResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            })

            .addCase(createFactoryRestoreResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createFactoryRestoreResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createFactoryRestoreResult = action.payload;
            })
            .addCase(createFactoryRestoreResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            })

            .addCase(createShutdownResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createShutdownResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createShutdownResult = action.payload;
            })
            .addCase(createShutdownResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            });
    }
});

// Export actions
export const {
    setCreateTextDeviceResult,
    setCreateCurrentPositionResult,
    setCreateFindWatchResult,
    setCreateRestartResult,
    setCreateFactoryRestoreResult,
    setCreateShutdownResult
} = messageServiceSlice.actions;

// Export the reducer
export default messageServiceSlice.reducer;

import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getHighRiskClientsTableDatasAsync } from "@app/redux/features/v2/portal-service/home/portalHomeThunk";
import { setHighRiskClientsRequest } from "@app/redux/features/v2/portal-service/home/portalHomeSlice";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";

import { PaginateDefault } from "@app/common/enums/PaginateDefault";
import { HighRiskClientsTableDataResult } from "@app/redux/features/v2/portal-service/home/models/request-result-dto";

import searchIcon from "@assets/icons/search_icon.svg";
import closeIcon from "@assets/icons/close_icon.svg";
import plusIcon from "@assets/icons/plus_icon.svg";
import highRiskIcon from "@assets/icons/portal/high_risk_icon.svg";

import HighRiskTable from "./Table";
import HighRiskModal from "./HighRiskModal";
import DeleteModal from "../../../../../components/Modal/DeleteModal";

interface HistoryRecordCardProps {}

const HighRiskCard: React.FC<HistoryRecordCardProps> = () => {
  const org_id = getOrgFromJwt();
  const dispatch = useAppDispatch();
  const { highRiskClientsTableDataResultPaginate, highRiskClientsRequest } =
    useAppSelector((state: RootState) => state.portalHomeV2);

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [dataSelected, setDataSelected] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const clearSearch = () => {
    setSearchTerm("");
    dispatch(
      setHighRiskClientsRequest({
        ...highRiskClientsRequest,
        search_query: "",
      })
    );
    dispatch(
      getHighRiskClientsTableDatasAsync({
        ...highRiskClientsRequest,
        search_query: "",
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      setHighRiskClientsRequest({
        ...highRiskClientsRequest,
        search_query: searchTerm,
      })
    );
    dispatch(
      getHighRiskClientsTableDatasAsync({
        ...highRiskClientsRequest,
        search_query: searchTerm,
      })
    );
  };

  const onShowModifyModal = (data?: HighRiskClientsTableDataResult | null) => {
    setShowModifyDialog(true);
    setDataSelected(data);
  };

  const onCloseModifyModal = () => {
    setShowModifyDialog(false);
    setDataSelected(null);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteDialog(false);
    setDataSelected(null);
  };

  useEffect(() => {
    if (!org_id) return;
    dispatch(
      setHighRiskClientsRequest({
        ...highRiskClientsRequest,
        organisation_sid: org_id,
      })
    );
    dispatch(
      getHighRiskClientsTableDatasAsync({
        ...highRiskClientsRequest,
        organisation_sid: org_id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id]);

  return (
    <div>
      <div className="d-flex flex-column mt-3" style={{ gap: "18px" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex rounded-3 col-6 title-chart gap-2 align-items-center">
            <div className="rounded-5 icon-chart-custom">
              <img height={32} width={32} src={highRiskIcon} alt="Icon" />
            </div>
            <div className="text-dark">
              <span className="font-14px fw-bold text-dark">
                High Risk Clients
              </span>
              <p className="font-10px text-grey-901 m-0">
                Lorem ipsum dolor sit amet, in quo illum vocibus.
              </p>
            </div>
          </div>
          <div className="col-4 d-flex align-items-center gap-2">
            <InputGroup className="rounded-3 bg-white">
              <div className="search-icon-history">
                <img
                  className="h-100"
                  width={16}
                  src={searchIcon}
                  alt="Search"
                />
              </div>
              <Form.Control
                className="border-0 bg-transparent ms-4 font-12px py-2"
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <div className="close-icon-history">
                <img
                  src={closeIcon}
                  alt="Close"
                  onClick={clearSearch}
                  className="cursor-pointer"
                />
              </div>
            </InputGroup>
            <Button
              onClick={() => onShowModifyModal()}
              className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center"
            >
              <img src={plusIcon} alt="Plus" />
              <p className="ms-1 my-0 font-12px progress-bar">Add</p>
            </Button>
          </div>
        </div>

        <HighRiskTable
          data={highRiskClientsTableDataResultPaginate ?? PaginateDefault}
        />
      </div>

      <HighRiskModal
        type={dataSelected ? "update" : "add"}
        show={showModifyDialog}
        onHide={onCloseModifyModal}
        dataSelected={dataSelected}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteDialog}
        onHide={onCloseDeleteModal}
        onSubmit={onCloseDeleteModal}
        title="Alert & Health Incident"
        icon={highRiskIcon}
        content="Are you sure you want to remove this record?"
      />
    </div>
  );
};

export default HighRiskCard;

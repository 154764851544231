import { Check } from "@material-ui/icons";
import React from "react";
// Replace with a web-compatible icon library like 'react-icons'

interface StepDailyChartProps {
  min: number;
  max: number;
  radius?: number;
}

const StepDailyChart = ({ min, max, radius = 90 }: StepDailyChartProps) => {
  return (
    <>
      {min >= max ? (
        <ReachedChart min={min} max={max} radius={radius} />
      ) : (
        <ProgressChart min={min} max={max} radius={radius} />
      )}
    </>
  );
};

const ProgressChart = ({ min, max, radius = 100 }: StepDailyChartProps) => {
  const strokeWidth = 6;
  const gapSize = 0;
  const circumference = 2 * Math.PI * radius;
  const percentage = min / max;
  const progressLength = percentage * circumference - gapSize;
  const blueAngle = percentage * 2 * Math.PI + (Math.PI / 20) * percentage;
  const blueDotX = radius + (radius - strokeWidth / 2) * Math.cos(blueAngle);
  const blueDotY = radius + (radius - strokeWidth / 2) * Math.sin(blueAngle);

  return (
    <div
      style={{ position: "relative", width: radius * 2, height: radius * 2 }}
    >
      <svg width={radius * 2} height={radius * 2}>
        <g transform={`rotate(-90 ${radius} ${radius})`}>
          <circle
            key={"full-arc-bg"}
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            stroke={"#D6DCE7"}
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference - 30} ${circumference}`}
            strokeDashoffset={0}
            strokeLinecap="round"
            fill="none"
          />
          <circle
            key={"progress-arc"}
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            stroke={"#3F72F3"}
            strokeWidth={strokeWidth}
            strokeDasharray={`${progressLength} ${circumference}`}
            strokeDashoffset={0}
            strokeLinecap="round"
            fill="none"
          />
          <circle
            cx={blueDotX}
            cy={blueDotY}
            r={strokeWidth / 1.1}
            stroke={"#FFFFFF"}
            strokeWidth={2}
            fill={"#3F72F3"}
          />
        </g>
      </svg>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: "50%",
        }}
      >
        <div
          className="text-center bg-grey-902 rounded-circle px-3 py-2 d-flex align-items-center justify-content-center"
          style={{ width: "100px", height: "100px" }}
        >
          <div className="d-flex flex-column align-items-center">
            <span className="font-28px fs-bold">{min}</span>
            <span
              style={{
                color: "#888",
                display: "block",
                transform: "translateY(-8px)",
              }}
            >{`/${max}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReachedChart = ({ min, max, radius = 100 }: StepDailyChartProps) => {
  const greenColor = "#00BD91";
  const strokeWidth = 6;

  return (
    <div
      style={{ position: "relative", width: radius * 2, height: radius * 2 }}
    >
      <svg width={radius * 2} height={radius * 2}>
        <g transform={`rotate(-90 ${radius} ${radius})`}>
          <circle
            key={"full-circle-bg"}
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            stroke={greenColor}
            strokeWidth={strokeWidth}
            fill="none"
          />
        </g>
      </svg>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "greenColor",
          borderRadius: "50%",
        }}
      >
        <div
          className="text-center rounded-circle px-3 py-2 d-flex align-items-center justify-content-center"
          style={{ width: "80%", height: "80%", backgroundColor: "#F7F7F7" }}
        >
          <div className="d-flex flex-column align-items-center">
            <span className="font-28px fs-bold">{min}</span>
            <span
              style={{
                color: "#888",
                display: "block",
                transform: "translateY(-8px)",
              }}
            >{`/${max}`}</span>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "-2px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: greenColor,
          border: "1px solid white",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-13px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          backgroundColor: greenColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "3px solid white",
        }}
      >
        <Check className="text-white h-75" />
      </div>
    </div>
  );
};

export default StepDailyChart;

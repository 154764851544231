import { ComplianceRecordDto, CreateComplianceDocumentDto, CreateComplianceRecordDto, GetComplianceDto } from "@redux/features/compliance-service/models/create-compliance-record-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const createComplianceRecord = (compliance: ComplianceRecordDto) => {
    return organisationPortalApi.post<string>("/api/v0/compliance/records", compliance);
}

const createComplianceDocument = (compliance: CreateComplianceDocumentDto) => {
    return organisationPortalApi.put<string>("/api/v0/compliance/document", compliance);
}
const getActiveCompliance = (compliance: GetComplianceDto) => {
    let url = `/api/v2/compliance/documents`;
    if (compliance.jurisdiction) {
        url += `?jurisdiction=${compliance.jurisdiction}`;
    }
    return organisationPortalApi.get<CreateComplianceDocumentDto[]>(url);
}

const ComplianceServiceApi = {
    createComplianceDocument,
    createComplianceRecord,
    getActiveCompliance
}

export default ComplianceServiceApi;
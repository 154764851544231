import { VitalType } from "@app/common/enums/VitalTypes";
import { defaultData } from "../HistoryRecordCard/data";
import Table from "../Table";
import StatusBadge from "./StatusBadge";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";

interface TableProps {
  period: string;
  data?: any[];
}

const VitalTypeSwitcher = (type: VitalType) => {
  switch (type) {
    case VitalType.HEART_RATE:
    case VitalType.OXYGEN:
    case VitalType.TEMPERATURE:
    case VitalType.RESPIRATORY_RATE:
    case VitalType.GLUCOSE:
      return {
        name: "Status",
        selector: (row: any) => row.average,
        sortable: true,
        cell: (row: any) => {
          return (
            <div>
              <StatusBadge
                status={VitalType.getStatusAllType(type, row.average)}
              />
            </div>
          );
        },
      };

    default:
      return {
        name: "Status",
        selector: (row: any) => row.status,
        sortable: true,
        cell: (row: any) => {
          let color;
          switch (row.status) {
            case "Very high":
              color = "very-high";
              break;
            case "Low":
              color = "low";
              break;
            case "Very low":
              color = "very-low";
              break;
            case "High":
              color = "high";
              break;
            case "Normal":
              color = "normal";
              break;
            default:
              color = "normal";
          }
          return (
            <span
              style={{ display: "flex", alignItems: "center" }}
              className="fw-medium"
            >
              <span className={`fw-medium fs-3 me-2 text-${color}`}>
                &#x2022;
              </span>
              <span className={`fw-medium text-${color}`}>{row.status}</span>
            </span>
          );
        },
      };
  }
};

const BloodTable: React.FC<TableProps> = ({ period, data }) => {
  const columns = [
    {
      name: "Health vitals",
      selector: (row: any) => row.healthVitals,
    },
    {
      name: "Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: any) => row.time,
      sortable: true,
    },
    {
      name: "Minimum",
      selector: (row: any) => row.low,
      sortable: true,
      cell: (row: any) => {
        const value1 = parseFloat(row.value1);
        const value2 = parseFloat(row.value2);

        return (
          <span>
            <span
              className={`fw-medium text-${VitalType.getDataColorStatus(
                VitalType.BLOOD_PRESSURE,
                Math.floor(value1),
                Math.floor(value2)
              )}`}
            >
              {value1}/{value2}
            </span>{" "}
            <span>{VitalType.getUnit(VitalType.BLOOD_PRESSURE)}</span>
          </span>
        );
      },
    },

    {
      name: "Average",
      selector: (row: any) => row.average,
      sortable: true,
      cell: (row: any) => {
        const value1 = parseFloat(row.value1);
        const value2 = parseFloat(row.value2);
        return (
          <span>
            <span
              className={`fw-medium text-${VitalType.getDataColorStatus(
                VitalType.BLOOD_PRESSURE,
                Math.floor(value1),
                Math.floor(value2)
              )}`}
            >
              {value1}/{value2}
            </span>{" "}
            <span>{VitalType.getUnit(VitalType.BLOOD_PRESSURE)}</span>
          </span>
        );
      },
    },
    {
      name: "Maximum",
      selector: (row: any) => row.high,
      sortable: true,
      cell: (row: any) => {
        const value1 = parseFloat(row.value1);
        const value2 = parseFloat(row.value2);
        return (
          <span>
            <span
              className={`fw-medium text-${VitalType.getDataColorStatus(
                VitalType.BLOOD_PRESSURE,
                Math.floor(value1),
                Math.floor(value2)
              )}`}
            >
              {value1}/{value2}
            </span>{" "}
            <span>{VitalType.getUnit(VitalType.BLOOD_PRESSURE)}</span>
          </span>
        );
      },
    },
    VitalTypeSwitcher(VitalType.BLOOD_PRESSURE),
  ];

  const dailyColumns = [
    {
      name: "Health vitals",
      selector: (row: any) => row.healthVitals,
    },
    {
      name: "Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: any) => row.time,
      sortable: true,
    },

    {
      name: VitalType.getAnalysisName(VitalType.BLOOD_PRESSURE),
      selector: (row: any) => row.average,
      sortable: true,
      cell: (row: any) => {
        const value1 = parseFloat(row.value1);
        const value2 = parseFloat(row.value2);
        return (
          <span>
            <span
              className={`fw-medium text-${VitalType.getDataColorStatus(
                VitalType.BLOOD_PRESSURE,
                Math.floor(value1),
                Math.floor(value2)
              )}`}
            >
              {value1}/{value2}
            </span>{" "}
            <span>{VitalType.getUnit(VitalType.BLOOD_PRESSURE)}</span>
          </span>
        );
      },
    },
    VitalTypeSwitcher(VitalType.BLOOD_PRESSURE),
  ];

  console.log(data);
  return (
    <div className="row mt-3">
      <Table
        columns={
          period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
            ? dailyColumns
            : columns
        }
        data={data ?? defaultData}
      />
    </div>
  );
};

export default BloodTable;

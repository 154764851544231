import SubNav from "@app/components/Nav/HealthInsightSubnav";
import { Outlet } from "react-router-dom";
import ClientDetailBreadcrumb from "@app/components/Breadcrumb/ClientDetailBreadcrumb";

const navLinks = [
  {
    label: "Heart Rate",
    path: "/portal-new/clients/client-detail/:id/health-insights/heart-rate",
  },
  {
    label: "Blood Pressure",
    path: "/portal-new/clients/client-detail/:id/health-insights/blood-pressure",
  },
  {
    label: "Oxygen Saturation",
    path: "/portal-new/clients/client-detail/:id/health-insights/oxygen-saturation",
  },
  {
    label: "Temperature",
    path: "/portal-new/clients/client-detail/:id/health-insights/temperature",
  },
  {
    label: "Glucose",
    path: "/portal-new/clients/client-detail/:id/health-insights/glucose",
  },
  {
    label: "Respiratory Rate",
    path: "/portal-new/clients/client-detail/:id/health-insights/respiratory-rate",
  },
  {
    label: "Stress",
    path: "/portal-new/clients/client-detail/:id/health-insights/stress",
  },
  {
    label: "Sleep",
    path: "/portal-new/clients/client-detail/:id/health-insights/sleep",
  },
  {
    label: "Steps",
    path: "/portal-new/clients/client-detail/:id/health-insights/steps",
  },
  {
    label: "ECG",
    path: "/portal-new/clients/client-detail/:id/health-insights/ecg",
  },
];

const HealthInsights = () => {
  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column">
          <div className="container health_insight row">
            <div className="d-flex flex-column">
              <ClientDetailBreadcrumb viewName="Health insight" />
              <SubNav navLinks={navLinks} />
            </div>
          </div>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default HealthInsights;

import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useEffect, useState } from "react";
import AnalysisCard from "@app/components/AnalysisCard";
import HistoryRecordCard from "@components/HistoryRecordCard";
import SummaryCard from "@app/components/SummaryCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import { HeartRateStatus } from "@app/common/enums/AnalysisType";
import BreadcrumbNavigation from "@app/components/Breadcrumb/BreadcrumbNavigation";
import { useVitalsGraph } from "@app/hooks/useVitalsGraph";
import { RootState } from "@app/redux/store";
import { useAppSelector } from "@app/redux/store/useStore";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { VitalsGraphDto } from "@app/redux/features/v2/health-service/models/bp-graph-dto";

const HeartRate = () => {
  const [tableData, setTableData] = useState<
  {
    id: number;
    healthVitals: string;
    date: string;
    time: string;
    low: string | number;
    average: string | number;
    high: string | number;
    status: string;
  }[]>([]);

  const [selectedPeriod, setSelectedPeriod] = useState(TimePeriodHelper.getTimePeriodJson(TimePeriod.day));
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const { user_profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);  
  const { fetchVitalsGraph, fetchCustomRange } = useVitalsGraph(user_profile?.sid || '', VitalType.HEART_RATE);
  const vitalsGraphDto = useAppSelector((state: RootState) => state.healthServiceV2.vitalsGraphDto[VitalType.HEART_RATE]);

  const convertHeartRateData = (vitalsGraphDto: VitalsGraphDto) => {
    const { vital_datetime, vital_data } = vitalsGraphDto;
  
    return vital_datetime.map((datetime: Date, index: number) => {
      const dateObj = new Date(datetime);
      const date = `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj.getDate().toString().padStart(2, "0")}/${dateObj.getFullYear()}`;
      const time = dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
  
      return {
        id: index + 1,
        healthVitals: "Heart Rate",
        date,
        time,
        low: vital_data.VITAL_MIN?.[index] ?? "",
        average: vital_data.VITAL?.[index] ?? "",
        high: vital_data.VITAL_MAX?.[index] ?? "",
        status: vital_data.LABEL?.[index] ?? ""
      };
    });
  };

  useEffect(() => {
    fetchVitalsGraph(selectedPeriod);
  }, [selectedPeriod, fetchVitalsGraph]);

  useEffect(() => {
    if (vitalsGraphDto) {
      const defaultData = convertHeartRateData(vitalsGraphDto);
      setTableData(defaultData);  
    }
  }, [vitalsGraphDto, selectedPeriod]);

  return (
    <div className="container health_insight row">
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <BreadcrumbNavigation
            analysisName={VitalType.getAnalysisName(VitalType.HEART_RATE)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              analysisType={VitalType.HEART_RATE}
              analysisStatus={HeartRateStatus.HIGH}
              date={selectedPeriod}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              analysisType={VitalType.HEART_RATE}
              period={selectedPeriod}
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard 
              period={selectedPeriod} 
              type={VitalType.HEART_RATE} 
              data={tableData}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <div className="health-column">
          <div className="title-health-column">Health Column</div>
          {[1, 2, 3, 4, 5].map((index) => (
            <HealthyColumn key={index.toString()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeartRate;

import React from "react";
import analysisIcon from "@assets/icons/analysis-icon.svg";
import personRunIcon from "@assets/icons/person_run.svg";
import footIcon from "@assets/icons/foot.svg";
import SummaryChart from "./Steps.SummaryChart";
import { getTimeRange } from "@app/utils/time";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";

export interface SummaryCardProd {
  period: string;
  overviewData?: {
    totalSteps: string | number;
    averageDailySteps: string | number;
    averageStepsPerMonth: string | number;
  };
  summaryData: { date: string; value: number | null }[];
  unit: string;
}

export interface SummaryCardProps extends SummaryCardProd {}

const SummaryCard: React.FC<SummaryCardProps> = ({
  period,
  overviewData,
  summaryData,
  unit,
}) => {
  const renderComponent = () => (
    <div className="d-flex mt-0 h-75">
      <div className="col-12 d-flex flex-column justify-content-center">
        <div className="d-flex flex-row align-items-end ">
          <div className="text-grey-901 font-12px mb-0 d-flex flex-row align-items-end flex-responsive">
            <div className="text-black fw-bold me-1">1/7 days</div>
            daily
            <div className="text-black fw-bold mx-1">10,000 steps</div>
            goals achieved
          </div>
        </div>

        <div
          className="w-100 mt-2"
          style={{ height: "1px", backgroundColor: "#F7F7F7" }}
        ></div>

        <div className="d-flex flex-row align-items-center gap-3 my-2">
          <div className="d-flex flex-column">
            <p className="text-grey-901 mb-0 font-8px">Total steps</p>
            <div className="d-flex flex-row align-items-center ">
              <span
                className="mb-0 fw-bold font-16px "
                style={{ display: "inline-block", width: "fit-content" }}
              >
                {overviewData?.totalSteps ?? 0}
              </span>
              <div className="h-100">
                <img
                  src={personRunIcon}
                  alt="Trend Down"
                  className="h-100 w-75 ms-2"
                />
              </div>
            </div>
          </div>
          <div
            className="h-100"
            style={{
              width: "1px",
              backgroundColor: "#ECECEC",
            }}
          />

          <div className="d-flex flex-column">
            <p className="text-grey-901 mb-0 font-8px">Average daily steps</p>
            <div className="d-flex flex-row align-items-center ">
              <div className="mb-0 fw-bold font-16px h-fit-content">{overviewData?.averageDailySteps ?? 0}</div>
              <div className="h-100">
                <img
                  src={footIcon}
                  alt="Trend Down"
                  className="h-100 w-75 ms-2"
                />
              </div>
            </div>
          </div>
          {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.year) ? (
            <>
              <div
                className="h-100"
                style={{
                  width: "1px",
                  backgroundColor: "#ECECEC",
                }}
              />
              <div className="d-flex flex-column">
                <p className="text-grey-901 mb-0 font-8px">
                  Average steps per month
                </p>
                <div className="d-flex flex-row align-items-center ">
                  <div className="mb-0 fw-bold font-16px h-fit-content">
                    {overviewData?.averageStepsPerMonth ?? 0}
                  </div>
                  <div className="h-100">
                    <img
                      src={personRunIcon}
                      alt="Trend Down"
                      className="h-100 w-75 ms-2"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-3 h-100 rounded-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div >
            <img className="img-fluid" src={analysisIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Summary</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 h-100">
        {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day) ? (
          <SummaryChart summaryData={summaryData} unit={unit} period={period} />
        ) : (
          renderComponent()
        )}
      </div>
    </div>
  );
};

export default SummaryCard;

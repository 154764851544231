import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "assets/scss/index.scss";
import AppRoutes from "@app/routers/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import MymonXLoader from "@components/mymonx-loader";
import { useState } from "react";

const CloseIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4605 13.6648C14.5127 13.7171 14.5542 13.7792 14.5825 13.8474C14.6108 13.9157 14.6253 13.9889 14.6253 14.0628C14.6253 14.1367 14.6108 14.2099 14.5825 14.2782C14.5542 14.3465 14.5127 14.4085 14.4605 14.4608C14.4082 14.513 14.3462 14.5545 14.2779 14.5828C14.2096 14.6111 14.1364 14.6256 14.0625 14.6256C13.9886 14.6256 13.9154 14.6111 13.8471 14.5828C13.7788 14.5545 13.7168 14.513 13.6645 14.4608L9.00001 9.79555L4.33548 14.4608C4.22993 14.5663 4.08677 14.6256 3.93751 14.6256C3.78824 14.6256 3.64509 14.5663 3.53954 14.4608C3.43399 14.3552 3.37469 14.2121 3.37469 14.0628C3.37469 13.9135 3.43399 13.7704 3.53954 13.6648L8.20477 9.00031L3.53954 4.33578C3.43399 4.23023 3.37469 4.08708 3.37469 3.93781C3.37469 3.78855 3.43399 3.64539 3.53954 3.53984C3.64509 3.4343 3.78824 3.375 3.93751 3.375C4.08677 3.375 4.22993 3.4343 4.33548 3.53984L9.00001 8.20508L13.6645 3.53984C13.7701 3.4343 13.9132 3.375 14.0625 3.375C14.2118 3.375 14.3549 3.4343 14.4605 3.53984C14.566 3.64539 14.6253 3.78855 14.6253 3.93781C14.6253 4.08708 14.566 4.23023 14.4605 4.33578L9.79524 9.00031L14.4605 13.6648Z"
        fill="white"
      />
    </svg>
  );
};

function App() {
  const { isLoading } = useAppSelector((state: RootState) => state.loading);
  const [showModal, setShowModal] = useState(true);

  const CustomCloseButton = ({ closeToast }: any) => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        onClick={() => {
          closeToast(true);
        }}
      >
        <CloseIcon />
      </div>
    );
  };

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        newestOnTop={true}
        pauseOnHover
        closeOnClick
        theme="colored"
        closeButton={CustomCloseButton}
        hideProgressBar={true}
      />
      {isLoading && <MymonXLoader isLoading={isLoading} />}
      <AppRoutes />
    </>
  );
}

export default App;

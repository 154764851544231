import { CreateUpdateMedicationDto } from "@app/redux/features/v2/medical-service/models/medication-result-dto";
import { Form, InputGroup } from "react-bootstrap";
import deleteIcon from "@assets/icons/delete_icon.svg";
import moment from "moment";

const MedicationBlock = ({
  isError,
  medication,
  medicationList,
  index,
  update,
  remove,
}: {
  isError: boolean;
  medication: CreateUpdateMedicationDto;
  medicationList: string[];
  index: number;
  update: (index: number, updatedContact: CreateUpdateMedicationDto) => void;
  remove: (index: number) => void;
}) => {
  return (
    <div key={index} className={`col-12 row mb-3 gap-3 ${index !== 0 ? "border-grey-901 border-top" : ""}`}>
      {index !== 0 ? (
        <div className="d-flex justify-content-end align-items-end">
          <div
            className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
            style={{ width: "fit-content" }}
            onClick={() => remove(index)}
          >
            <img src={deleteIcon} alt="Delete" />
            Delete
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col-12 row gap-3">
        <div className="col">
          <span className="font-10px text-dark">Medication</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Select
              className="border-0 font-12px py-2"
              value={medication?.medication ?? ""}
              onChange={(e) => {
                update(index, {
                  ...medication,
                  medication: e.target.value,
                });
              }}
            >
              <option value="" hidden>Select Medication</option>
              {medicationList.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </InputGroup>

          {isError && medication?.medication === "" ? (
            <Form.Control.Feedback type="invalid">
              Please select medication
            </Form.Control.Feedback>
          ) : null}
        </div>
        <div className="col">
          <span className="font-10px text-dark">Dosage</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 font-12px py-2"
              type="text"
              placeholder="Dosage"
              value={medication?.dosage ?? ""}
              onChange={(e) => {
                update(index, {
                  ...medication,
                  dosage: e.target.value,
                });
              }}
            />
          </InputGroup>

          {isError && medication?.dosage === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter dosage
            </Form.Control.Feedback>
          ) : null}
        </div>
      </div>
      <div className="col-12 row gap-3">
        <div className="col">
          <span className="font-10px text-dark">Date of prescription</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 font-12px py-2"
              type="date"
              placeholder="dd/mm/yyyy"
              value={moment(medication?.start_date).format("YYYY-MM-DD") ?? ""}
              onChange={(e) => {
                update(index, {
                  ...medication,
                  start_date: e.target.value,
                });
              }}
            />
          </InputGroup>

          {isError && medication?.start_date === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter Date of prescription
            </Form.Control.Feedback>
          ) : null}
        </div>
        <div className="col">
          <span className="font-10px text-dark">Date of prescription end</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 font-12px py-2"
              type="date"
              placeholder="dd/mm/yyyy"
              value={moment(medication?.end_date).format("YYYY-MM-DD") ?? ""}
              onChange={(e) => {
                update(index, {
                  ...medication,
                  end_date: e.target.value,
                });
              }}
            />
          </InputGroup>

          {isError && medication?.end_date === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter Date of prescription end
            </Form.Control.Feedback>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MedicationBlock;

import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import eyeIcon from "@assets/icons/login/eye_icon.svg";
import eyeCloseIcon from "@assets/icons/login/eye_close_icon.svg";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useEffect, useState } from "react";
import backIcon from "@assets/icons/login/back_icon.svg";
import { activateUserAsync, resendVerifyOtpAsync, verifyOtpAsync } from "@app/redux/features/v2/security-service/securityServiceThunk";
import LocalStorageService from "@app/services/v2/local-storage-service";
import { ResendVerifyOtpDto } from "@app/redux/features/v2/security-service/models/resend-verify-otp-dto";
import { toast } from "react-toastify";
import { VerifyOtpDto } from "@app/redux/features/v2/security-service/models/verify-otp";

const SignupOtpVerification = () => {
  // Redux state and dispatch
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Local state
  const [isMobile, setIsMobile] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const [otpValues, setOtpValues] = useState(Array(6).fill(""));

  const usernameVerify = LocalStorageService.getUsernameVerified() ?? '';

  useEffect(() => {
    setValue("otp", otpValues.join(""));
    setIsFormValid(otpValues.every((value) => value.trim() !== "") && timeLeft !== 0);
  }, [otpValues]);

  const handleOtpChange = (index: number, value: string) => {
    if (/^[0-9]*$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && otpValues[index] === "" && index > 0) {
      (document.getElementById(`otp-${index - 1}`) as HTMLInputElement)?.focus();
    } else if (index < 5 && otpValues[index] !== "") {
      (document.getElementById(`otp-${index + 1}`) as HTMLInputElement)?.focus();
    }
  };

  // Form handling
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<{ username: string; otp: string }>({
    defaultValues: {
      username: usernameVerify,
      otp: ''
    },
  });

  // Start countdown on component mount
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // TODO: API not responding
  const handleResendOtp = (resendOtpDto: ResendVerifyOtpDto) => {
    dispatch(resendVerifyOtpAsync(resendOtpDto)).then((action) => {
      if(action.meta.requestStatus === "fulfilled"){
        setTimeLeft(60);
      }
    })
  }

  return (
    <div className="d-flex h-100 w-100 justify-content-end">
      {/* Right Panel */}
      <Row className="h-100 w-50 bg-white m-0">
        <Col className="col-12 w-100" style={{ marginTop: "150px" }}>
          <Row>
            <Col className="col-12 w-100">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column" style={{ width: "530px" }}>
                  <Link
                    to={"/auth/register"}
                    className="d-flex flex-row align-items-center font-12px fw-medium text-decoration-none text-black mb-3"
                  >
                    <img
                      height={14}
                      width={14}
                      className="ms-0 me-2 cursor-pointer"
                      src={backIcon}
                      alt="Note"
                    />
                    <p className="my-0 font-12px fw-medium">Back</p>
                  </Link>

                  <p className="font-24px fw-medium text-start my-0">
                    OTP Verification
                  </p>
                  <p className="font-12px text-grey-901 pb-0">
                    Enter the verification code we just sent to your email
                    address
                  </p>
                  <div className="w-100">
                    <Form
                      onSubmit={handleSubmit((data) => {
                        const verifyOtp = {
                          username: data.username,
                          otp: data.otp
                        }
                        dispatch(verifyOtpAsync(verifyOtp)).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            const result = action.payload as { status?: boolean };
                            if (!result.status) {
                              dispatch(activateUserAsync({ email: data.username })).then((action) => {
                                const activateResult = action.payload as { status?: boolean };
                                if(!activateResult.status){
                                  navigate('/auth/login');
                                  LocalStorageService.removeUsernameVerified();
                                }
                              })
                            }
                          }
                        });
                      })}
                      id="loginForm"
                    >
                      <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                        {otpValues.map((value, index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            className="m-1 text-center form-control rounded bg-grey-904 border-0"
                            type="text"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleOtpChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            style={{ width: "81px", height: "81px" }}
                          />
                        ))}
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <p className="font-12px text-black">
                          Did you receive the code?
                          <span onClick={() => {
                            const payload = {
                              username: usernameVerify
                            };
                            handleResendOtp(payload);
                          }} className="font-12px fw-medium text-blue-902 ms-1 cursor-pointer">
                            Resend
                          </span>
                        </p>
                        <p className="font-12px text-black me-1">
                          {formatTime(timeLeft)}
                        </p>
                      </div>

                      <Button
                        as="input"
                        type="submit"
                        value="Verify"
                        className={`font-12px fw-medium rounded-3 ${
                          isFormValid
                            ? "text-white bg-blue-902"
                            : "text-grey-901 bg-grey-904"
                        } border-0 px-3 d-flex align-items-center text-login no-hover`}
                        disabled={!isFormValid}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

type FormInputProps = {
  control: any;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  togglePasswordVisibility?: () => void;
};

const FormInput: React.FC<FormInputProps> = ({
  control,
  name,
  label,
  type,
  placeholder,
  togglePasswordVisibility,
}) => (
  <Form.Group className="mb-3" controlId={`loginForm.${name}`}>
    <span className="font-10px text-dark">{label}</span>
    <InputGroup className="border rounded-3 border-0 bg-grey-904">
      <Controller
        name={name}
        control={control}
        rules={{ required: { value: true, message: `${name} is required` } }}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            className="border-0 bg-transparent font-12px py-2 ms-1"
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
        )}
      />
      {togglePasswordVisibility &&
        (type === "text" ? (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeCloseIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ))}
    </InputGroup>
  </Form.Group>
);
export default SignupOtpVerification;

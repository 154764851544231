import { useState, useEffect } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import moment from "moment";
import alertIcon from "@assets/icons/alert_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getMedicalAlertByWearerAsync, postMedicalAlertAsync, putMedicalAlertAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { CreateUpdateAlertWithDateTimeDto } from "@app/redux/features/v2/medical-service/models/medical-alert-result-dto";
import { hasMissingFields } from "@app/utils/helper";
import AlertBlock from "./AlertBlock";

const AlertsHealthIncidentsModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const dispatch = useAppDispatch();
  const { medicalAlertRequest } = useAppSelector((state: RootState) => state.medicalServiceV2);
  const { user_profile: profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);
  const DEFAULT_DATA: CreateUpdateAlertWithDateTimeDto[] = [
    { alert_description: "", alert_type: "", date: "", time: "", wearer_sid: profile?.sid ?? "", sid: "", org_sid: "" },
  ]

  const [alerts, setAlers] = useState(DEFAULT_DATA);

  const fieldsToCheck: (keyof CreateUpdateAlertWithDateTimeDto)[] = [
    "alert_type",
    "alert_description",
    "date",
    "time",
  ];

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (dataSelected && type === "update") {
      const isValidAlertTime = moment(dataSelected.alert_time, moment.ISO_8601, true).isValid();
      const date = isValidAlertTime ? moment(dataSelected.alert_time).format("YYYY-MM-DD") : "";
      const time = isValidAlertTime ? moment(dataSelected.alert_time).format("HH:mm") : "";

      setAlers([
        {
          alert_description: dataSelected.alert_description ?? "",
          alert_type: dataSelected.alert_type ?? "",
          date: date || "",
          time: time || "",
          wearer_sid: profile?.sid ?? "",
          sid: dataSelected.sid ?? "",
          org_sid: dataSelected.org_sid ?? "",
        },
      ]);
    }
    else {
      setAlers(DEFAULT_DATA);
    }
  }, [dataSelected, type, profile?.sid]);

  const onAddAnother = () => {
    setAlers([
      ...alerts,
      DEFAULT_DATA[0],
    ]);
  };

  const onDelete = (index: number) => {
    setAlers(alerts.filter((_, i) => i !== index));
  };

  const onUpdate = (index: number, updatedAlert: CreateUpdateAlertWithDateTimeDto) => {
    const updatedAlerts = [...alerts];
    updatedAlerts[index] = updatedAlert;
    setAlers(updatedAlerts);
  };

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setAlers(DEFAULT_DATA);
  }

  const onSubmit = async () => {
    try {
      if (type === "add") {
        const emptyContacts = alerts.filter((item) =>
          hasMissingFields(item, fieldsToCheck)
        );

        if (emptyContacts.length > 0) {
          setIsError(true);
          return;
        }

        const requests = alerts.map((alert) =>
          dispatch(
            postMedicalAlertAsync({
              ...alert,
              alert_time: moment(`${alert.date}T${alert.time}`).toISOString(),
            })
          )
        );

        const responses = await Promise.all(requests);
        const success = responses.every((res) => res.payload);

        if (success) {
          onCloseModal();
          dispatch(getMedicalAlertByWearerAsync(medicalAlertRequest));
        }
      } else {
        const value = alerts[0];
        if (hasMissingFields(value, fieldsToCheck)) {
          setIsError(true);
          return;
        }

        const res = await dispatch(
          putMedicalAlertAsync({
            ...value,
            alert_time: moment(`${value.date}T${value.time}`).toISOString(),
          })
        );

        if (res) {
          onCloseModal();
          dispatch(getMedicalAlertByWearerAsync(medicalAlertRequest));
        }
      }
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Customize the contents of your incidents"
      show={show}
      onHide={onCloseModal}
      title="Alert & Health Incident"
      size="lg"
      icon={alertIcon}
      onSubmit={onSubmit}
    >
      {type === "add" ? (
        <div className="row">
          {alerts.map((_, index) => (
            <AlertBlock
              isError={isError}
              key={index}
              alert={alerts[index]}
              index={index}
              update={onUpdate}
              remove={onDelete}
            />
          ))}
          <AddAnotherButton onAddAnother={onAddAnother} />
        </div>
      ) : (
        <AlertBlock
          isError={isError}
          key={0}
          alert={alerts[0]}
          index={0}
          update={onUpdate}
          remove={onDelete}
        />
      )}
    </ActionModal>
  );
};

export default AlertsHealthIncidentsModal;

export interface GraphProps {
  timestamp: string;
  mean: number;
  "5th_percentile": number;
  "95th_percentile": number;
}

export interface MonthlyReportDetail {
  avg: number;
  max: number;
  min: number;
  low: number;
  high: number;
  normal: number;
  very_low: number;
  very_high: number;
  hourly: GraphProps[];
  monthly: GraphProps[];
}
export interface MonthlyReportDto {
  BLOOD_GLUCOSE: MonthlyReportDetail;
  BLOOD_OXYGEN: MonthlyReportDetail;
  BLOOD_PRESSURE_BPS: MonthlyReportDetail;
  BLOOD_PRESSURE_BPD: MonthlyReportDetail;
  BODY_TEMPERATURE: MonthlyReportDetail;
  HEART_RATE: MonthlyReportDetail;
  RESPIRATORY_RATE: MonthlyReportDetail;
  time_used: number;
  steps: number;
  distance: number;
  calories: number;
}

export function mergeMonthlyDataTo8HourIntervals(
  detail: MonthlyReportDetail
): MonthlyReportDetail {
  if (
    detail?.hourly?.length === 0 ||
    detail?.monthly?.length === 0
  )
    return detail;
  const mergeData = (
    data: GraphProps[],
    interval: number,
    isMonthly: boolean
  ) => {
    const groupedData: { [key: string]: GraphProps[] } = {};

    data.forEach((item) => {
      const date = isMonthly ? new Date(item.timestamp) : null;
      const hours = isMonthly
        ? date!.getUTCHours()
        : parseInt(item.timestamp.split(":")[0], 10);
      const groupHour = Math.floor(hours / interval) * interval;
      const groupKey = isMonthly
        ? `${date!.getUTCFullYear()}-${
            date!.getUTCMonth() + 1
          }-${date!.getUTCDate()}T${groupHour}:00:00+00:00`
        : `${groupHour.toString().padStart(2, "0")}:00:00`;

      if (!groupedData[groupKey]) groupedData[groupKey] = [];
      groupedData[groupKey].push(item);
    });

    return Object.entries(groupedData).map(([timestamp, group]) => ({
      timestamp,
      mean: Math.floor(
        group.reduce((sum, item) => sum + item.mean, 0) / group.length
      ),
      "5th_percentile": Math.floor(
        group.reduce((sum, item) => sum + item["5th_percentile"], 0) /
          group.length
      ),
      "95th_percentile": Math.floor(
        group.reduce((sum, item) => sum + item["95th_percentile"], 0) /
          group.length
      ),
    }));
  };

  return {
    ...detail,
    max: Math.floor(detail.max),
    min: Math.floor(detail.min),
    avg: Math.floor(detail.avg),
    monthly: mergeData(detail.monthly, 6, true),
    hourly: mergeData(detail.hourly, 1, false),
  };
}

import React from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import PieChart from "./Sleep.DailyChart";
import { PieDataItem } from "./Sleep.DailyChart";
import { getTimeRange } from "@app/utils/time";
import AnalysisChart from "./Sleep.AnalysisChart";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import { formatDuration } from "@app/utils/timeUtils";

interface AnalysisCardProps {
  period: string;
  summaryData: {
    date: string | number;
    startTime: number | null;
    stages: { name: string; value: number }[];
  }[];
  // unit: string;
}

const getStatusForStage = (name: string): string => {
  switch (name) {
    case "Light":
      return "very-low";
    case "Deep":
      return "low";
    case "REM":
      return "normal";
    case "Awake":
      return "high";
    default:
      return "normal";
  }
};

const getColorForStage = (name: string): string => {
  switch (name) {
    case "Light":
      return "#A281E4";
    case "Deep":
      return "#3F72F3";
    case "REM":
      return "#00BD91";
    case "Awake":
      return "#FFAA00";
    default:
      return "#F9FAFB";
  }
};

const AnalysisCard: React.FC<AnalysisCardProps> = ({
  period,
  summaryData,
}) => {
  const aggregatedStages = summaryData.flatMap((data) =>
    data.stages.map((stage) => ({
      name: stage.name,
      value: stage.value,
    }))
  );

  const stageSums = aggregatedStages.reduce((acc, { name, value }) => {
    if (acc[name]) {
      acc[name] += value;
    } else {
      acc[name] = value;
    }
    return acc;
  }, {} as Record<string, number>);

  const dailySleepData: PieDataItem[] = Object.entries(stageSums).map(([name, value]) => ({
    name,
    value,
    status: getStatusForStage(name),
    color: getColorForStage(name),
    date: summaryData[0].date, 
  }));

  const totalDurationInMinutes = dailySleepData.reduce((total, data) => total + data.value, 0);

  const durationHours = Math.floor(totalDurationInMinutes / 60);
  const durationMinutes = totalDurationInMinutes % 60;

  const renderDailyComponent = () => {
    const defaultData: PieDataItem[] = [
      { name: "Light", value: 0, status: getStatusForStage("Light"), color: getColorForStage("Light") },
      { name: "Deep", value: 0, status: getStatusForStage("Deep"), color: getColorForStage("Deep") },
      { name: "REM", value: 0, status: getStatusForStage("REM"), color: getColorForStage("REM") },
      { name: "Awake", value: 0, status: getStatusForStage("Awake"), color: getColorForStage("Awake") },
    ];

  const dataToRender = dailySleepData.length > 0 ? dailySleepData : defaultData;


    return (
      <div className="d-flex flex-row justify-content-between align-items-center   my-4">
        <PieChart data={dailySleepData}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="text-black fw-bold">
              <span className="font-28px fw-bold">{durationHours}</span>h
              <span className="font-28px fw-bold">{durationMinutes}</span>m
            </div>
            <div className="font-14px text-grey-901">total hours</div>
          </div>
        </PieChart>

        <div className="flex-fill ms-3">
          <div className="d-flex flex-column justify-content-center h-100">
            {dataToRender.map((data, index) => (
              <InfoBlock key={index} data={data} />
            ))}
          </div>
        </div>
      </div>
    )
  };

  const renderComponent = () => {
    return (
      <AnalysisChart summaryData={summaryData} period={period} />
    )
  };

  const InfoBlock = ({ data }: { data: PieDataItem }) => {
    const formattedDuration = formatDuration(data.value);
  
    return (
      <div className="flex-fill w-100 my-1">
        <div
          className="rounded-2 bg-grey-903 px-2 d-flex justify-content-between align-items-center"
          style={{ height: "40px" }}
        >
          <div className={`text-${data.status} font-12px fw-bold`}>
            <div
              className={`bg-${data.status} rounded-circle ms-2 me-2`}
              style={{
                width: "8px",
                height: "8px",
                display: "inline-block",
              }}
            ></div>
            {data.name}
          </div>
          <div className="d-flex flex-row">
            <p className="ms-1 my-0 font-12px fw-bold text-black py-1">
              {formattedDuration}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white p-3 rounded-3 h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Analysis</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day) ? renderDailyComponent() : renderComponent()}
    </div>
  );
};

export default AnalysisCard;

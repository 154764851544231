import React, { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { range } from 'lodash';
import ActionModal from "@app/components/Modal/ActionModal";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import tickIcon from "@assets/icons/signup/tick_icon.svg";
import { GetComplianceDto } from "@app/redux/features/compliance-service/models/create-compliance-record-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { registerAsync } from "@app/redux/features/v2/security-service/securityServiceThunk";
import { RegisterCredentialsDto } from "@app/redux/features/v2/security-service/models/register-credentials-dto";
import LocalStorageService from "@app/services/v2/local-storage-service";
import { RegisterResponseDto } from "@app/redux/features/security-service/models/register-credentials-dto";
import { ComplianceRecordDto, CreateComplianceRecordDto } from "@app/redux/features/v2/compliance-service/models/create-compliance-record-dto";
import { createComplianceRecordResultAsync, getActiveComplianceResultAsync } from "@app/redux/features/v2/compliance-service/complianceServiceThunk";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface onSaveDocumentRecordType {
  user_sid: string,
  privacy_doc_id: string,
  terms_doc_id: string
}

const TermsPrivacyModal = ({
  show,
  onHide,
  countryCode,
  registerCredentials
}: {
  show: boolean;
  onHide: () => void;
  countryCode: string;
  registerCredentials: RegisterCredentialsDto;
}) => {
  const [currentStep, setCurrentStep] = useState<"privacy" | "terms">("privacy");
  const [isAgree, setIsAgree] = useState(false);
  const { complianceResult } = useAppSelector((state: RootState) => state.complianceService);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [numPages, setNumPages] = useState<number>(0);
  const [privacyDocId, setPrivacyDocId] = useState('');
  const [termDocId, setTermDocId] = useState('');

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  
  const onCloseModal = () => {
    onHide();
  };

  const base64ToDataUrlas = (base64: string) => {
    const binary = atob(base64);
    const array = [...binary].map(char => char.charCodeAt(0));
    const blob = new Blob([new Uint8Array(array)], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  };
  
  const onSaveDocumentRecord = (payload: onSaveDocumentRecordType) => {
    const complianceRecords: CreateComplianceRecordDto[] = [];

    const privacyRecord: CreateComplianceRecordDto = {
        sid: payload.user_sid,
        wearer_sid: payload.user_sid,
        acceptance_datetime: new Date(),
        revocation_datetime: new Date(),
        compliance_document_sid: payload.privacy_doc_id
    };

    complianceRecords.push(privacyRecord);

    const termsRecord: CreateComplianceRecordDto = {
        sid: payload.user_sid,
        wearer_sid: payload.user_sid,
        acceptance_datetime: new Date(),
        revocation_datetime: new Date(),
        compliance_document_sid: payload.terms_doc_id
    };

    complianceRecords.push(termsRecord);

    const complianceData: ComplianceRecordDto = {
        records: complianceRecords
    };

    dispatch(createComplianceRecordResultAsync(complianceData)).then((action) => {
      if(action.meta.requestStatus === "fulfilled"){
        const result = action.payload as { status: number };
        if(!result.status){
          LocalStorageService.setUsernameVerified(registerCredentials.username);
          navigate('/auth/signup-otp-verification');
        }
      }
    });
  }

  const onAgreeAndContinue = (registerCredentials: RegisterCredentialsDto) => {
    if (currentStep === "privacy") {
      setCurrentStep("terms");
      setIsAgree(false);
    } else if (currentStep === "terms") {
      onHide();

      const registerPayload = {
        username: registerCredentials.username,
        password: registerCredentials.password,
        role_id: registerCredentials.role_id,
        role: registerCredentials.role,
      }

      dispatch(registerAsync(registerPayload)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          const result = action.payload as RegisterResponseDto;
          if (result.user_sid) {
            onSaveDocumentRecord({
              user_sid: result.user_sid,
              privacy_doc_id: privacyDocId,
              terms_doc_id: termDocId,
            })
          }
        }
      });
    }
  };

  const privacyDocs = useMemo(() => {
    return complianceResult?.filter((result) => result.compliance_type === 'PDP') ?? [];
  }, [complianceResult]);

  const termsDocs = useMemo(() => {
    return complianceResult?.filter((result) => result.compliance_type === 'TAC') ?? [];
  }, [complianceResult]);

  const privacyDocsUrls = useMemo(() => {
    return privacyDocs.map((doc) => ({
      sid: doc.sid,
      url: base64ToDataUrlas(doc.document),
    }));
  }, [privacyDocs]);

  const termsDocsUrls = useMemo(() => {
    return termsDocs.map((doc) => ({
      sid: doc.sid,
      url: base64ToDataUrlas(doc.document),
    }));
  }, [termsDocs]);

  useEffect(() => {
    if (privacyDocs.length > 0) {
      setPrivacyDocId(privacyDocs[0].sid);
    }
  }, [privacyDocs]);

  useEffect(() => {
    if (termsDocs.length > 0) {
      setTermDocId(termsDocs[0].sid);
    }
  }, [termsDocs]);

  const renderDocuments = (docs: { sid: string; url: string }[]) => (
    docs.length > 0 ? (
      docs.map((doc) => (
        <div key={doc.sid}>
          <Document
            renderMode="canvas"
            file={doc.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {range(1, numPages + 1).map((pageIndex) => (
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page_${pageIndex}`}
                pageNumber={pageIndex}
              />
            ))}
          </Document>
        </div>
      ))
    ) : <div>Loading...</div>
  );

  useEffect(() => {
    if(show){
      const compliancePrivacyParams: GetComplianceDto = {
        jurisdiction: countryCode
      };
      dispatch(getActiveComplianceResultAsync(compliancePrivacyParams));
    }
  }, [countryCode, show, dispatch]);

  return (
    <ActionModal
      subTitle={currentStep === "privacy" ? "Privacy Policy" : "Terms and Conditions"}
      show={show}
      onHide={onCloseModal}
      title="Agreement"
      size="lg"
      onSubmit={() => onAgreeAndContinue(registerCredentials)}
      customButton={
        <Button
          disabled={!isAgree}
          onClick={() => onAgreeAndContinue(registerCredentials)}
          style={{
            backgroundColor: !isAgree ? "#E4E8F1" : "#3F72F3",
            color: !isAgree ? "#777E8F" : "white",
          }}
          className={`${
            !isAgree ? "border-0" : "border-blue-901"
          } rounded-3 d-flex align-items-center`}
        >
          <p className="ms-1 my-0 font-12px progress-bar fw-bold">
            {currentStep === "privacy" ? "Agree and Continue" : "Agree and Sign up"}
          </p>
        </Button>
      }
    >
      <div>
        <div style={{ gap: 20 }} className="d-flex align-items-center mb-3">
          <div style={{ gap: 10 }} className="d-flex align-items-center">
            <div
              className='terms-privacy-step-done-wrapper'
            >
              {currentStep === 'privacy' ? (
                1
              ): (
                <img
                  height={11}
                  width={11}
                  src={tickIcon}
                  alt="tick"
                />
              )}
            </div>
            <span className={`${currentStep === 'terms' && 'terms-privacy-text-color'} fw-medium font-12px`}>Privacy Policy</span>
          </div>
          <div style={{ gap: 10 }} className="d-flex align-items-center">
            <div
              className={`${
                currentStep === "terms" ? "terms-privacy-step-done-wrapper" : "terms-privacy-step-undone-wrapper"
              }`}
            >
              2
            </div>
            <span className="font-12px">Terms of Condition</span>
          </div>
        </div>

        <div style={{ maxHeight: "359px", overflowY: "auto" }}>
          {currentStep === "privacy"
            ? renderDocuments(privacyDocsUrls)
            : renderDocuments(termsDocsUrls)}

          <div className="pt-2 pb-2">
            <div style={{ gap: 10 }} className="d-flex align-items-center">
              <input
                type="checkbox"
                id="agree"
                name="agree"
                className="mr-2"
                onChange={() => setIsAgree(!isAgree)}
                checked={isAgree}
              />
              <span>
                I have read and agreed with the {currentStep === "privacy" ? "Privacy Policy" : "Terms and Conditions"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default TermsPrivacyModal;

import SubNav from "@app/components/Nav/Subnav";
import { Outlet } from "react-router-dom";

const navLinks = [
  { label: "Users", path: "/portal-new/admin/users" },
  { label: "Devices", path: "/portal-new/admin/devices" },
];

const Admin = () => {
  return (
    <div>
      <SubNav navLinks={navLinks} />
      <main className="container min-vh-100">
        <div className="d-flex flex-column gap-3">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Admin;

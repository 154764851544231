import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  Bar,
  BarChart,
  Cell,
} from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";

type Props = {
  summaryData: {
    date: string;
    value: number | string;
  }[];
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload?.length) {
    return (
      <div className="bg-black rounded-1 p-1">
        <p className="mb-0 font-14px text-white">{`${payload[0].value} %`}</p>
      </div>
    )
  }

  return null;
}

const getBarColor = (value: number | string) => {
  const data = typeof value === 'string' ? parseInt(value) : value;
  if (data <= 25) return "#3F72F3";
  else if (data >= 26 && data <= 50) return "#00BD91";
  else if (data >= 51 && data <= 75) return "#00BD91";
  else if (data <= 100) return "#FFAA00";
  return "#FF2D1A";
}

const NoDataMessage = () => (
  <div
    className="d-flex align-items-center position-absolute rounded-1 bg-blue-50 text-blue-901 p-2"
    style={{ top: "35%", left: "20%", zIndex: "1001" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#3F72F3" />
      <path
        d="M8 3.9375C7.19652 3.9375 6.41107 4.17576 5.743 4.62215C5.07492 5.06855 4.55422 5.70302 4.24674 6.44535C3.93926 7.18767 3.85881 8.00451 4.01556 8.79255C4.17231 9.5806 4.55923 10.3045 5.12738 10.8726C5.69553 11.4408 6.4194 11.8277 7.20745 11.9844C7.9955 12.1412 8.81233 12.0607 9.55465 11.7533C10.297 11.4458 10.9315 10.9251 11.3778 10.257C11.8242 9.58893 12.0625 8.80349 12.0625 8C12.0614 6.92291 11.633 5.89025 10.8714 5.12863C10.1097 4.36701 9.07709 3.93864 8 3.9375ZM7.6875 6.125C7.6875 6.04212 7.72043 5.96263 7.77903 5.90403C7.83764 5.84542 7.91712 5.8125 8 5.8125C8.08288 5.8125 8.16237 5.84542 8.22097 5.90403C8.27958 5.96263 8.3125 6.04212 8.3125 6.125V8.3125C8.3125 8.39538 8.27958 8.47487 8.22097 8.53347C8.16237 8.59208 8.08288 8.625 8 8.625C7.91712 8.625 7.83764 8.59208 7.77903 8.53347C7.72043 8.47487 7.6875 8.39538 7.6875 8.3125V6.125ZM8 10.1875C7.90729 10.1875 7.81666 10.16 7.73958 10.1085C7.66249 10.057 7.60241 9.98379 7.56693 9.89813C7.53145 9.81248 7.52217 9.71823 7.54026 9.6273C7.55835 9.53637 7.60299 9.45285 7.66855 9.38729C7.7341 9.32174 7.81762 9.27709 7.90855 9.25901C7.99948 9.24092 8.09373 9.2502 8.17938 9.28568C8.26504 9.32116 8.33825 9.38124 8.38975 9.45833C8.44126 9.53541 8.46875 9.62604 8.46875 9.71875C8.46875 9.84307 8.41937 9.9623 8.33146 10.0502C8.24355 10.1381 8.12432 10.1875 8 10.1875Z"
        fill="white"
      />
    </svg>
    <span className="ms-1">There is currently no data available to show</span>
  </div>
);

const SummaryDailyChart = ({ summaryData }: Props) => {
  return (
    <div className="container font-12px">
      <div className="chart-container position-relative">
        {summaryData.length === 0 && <NoDataMessage />}
        <ResponsiveContainer width="110%" height={200}>
          <BarChart data={summaryData} barSize={6} margin={{ left: 15 }}>
            <XAxis 
              axisLine={false} 
              tickLine={false} 
              padding={{ left: 10 }} 
              dataKey="date" 
              ticks={["00:00", "06:00", "12:00", "18:00", "23:00"]}
            />
            <YAxis 
              axisLine={false} 
              tickLine={false} 
              type="number" 
              domain={[0, 100]} 
              ticks={[0, 25, 50, 75, 100]} 
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />} />
            <Bar type="monotone" dataKey="value" radius={[10, 10, 10, 10]} >
              {summaryData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getBarColor(entry.value)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SummaryDailyChart;

export const router = {
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    registerConsumer: "/auth/register-consumer",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    registerSuccess: "/auth/register-success",
    emailConfirmation: "/auth/email-confirmation",
    completeRegistration: "/auth/complete-registration",
    otpVerification: "/auth/otp-verification",
    signUpOtpVerification: "auth/signup-otp-verification",
  },
  portal: {
    client: "/portal/Client",
    dashboard: "/portal/dashboard",
    clients: "/portal-new/clients",
    clientDetail: {
      root: "/portal-new/clients/client-detail/:id",
      healthSummary: "/portal-new/clients/client-detail/:id/health-summary",
      alertsHealthIncidents:
        "/portal-new/clients/client-detail/:id/alerts-health-incidents",
      monthlyReports: "/portal-new/clients/client-detail/:id/monthly-reports",
      healthSettings: "/portal-new/clients/client-detail/:id/health-settings",
      medical: "/portal-new/clients/client-detail/:id/medical",
      healthInsights: {
        root: "/portal-new/clients/client-detail/:id/health-insights",
        glucose:
          "/portal-new/clients/client-detail/:id/health-insights/glucose",
        oxygen:
          "/portal-new/clients/client-detail/:id/health-insights/oxygen-saturation",
        ecg: "/portal-new/clients/client-detail/:id/health-insights/ecg",
        bloodPressure:
          "/portal-new/clients/client-detail/:id/health-insights/blood-pressure",
        heartRate:
          "/portal-new/clients/client-detail/:id/health-insights/heart-rate",
        respirationRate:
          "/portal-new/clients/client-detail/:id/health-insights/respiratory-rate",
        sleep: "/portal-new/clients/client-detail/:id/health-insights/sleep",
        steps: "/portal-new/clients/client-detail/:id/health-insights/steps",
        temperature:
          "/portal-new/clients/client-detail/:id/health-insights/temperature",
        stress: "/portal-new/clients/client-detail/:id/health-insights/stress",
      },
    },
    admin: {
      root: "/portal-new/admin",
      users: "/portal-new/admin/users",
      devices: "/portal-new/admin/devices",
    },
    devices: "/portal/Devices",
    home: "/portal-new/home",
    users: "/portal/users",
    userProfile: "/portal/user-profile",
    sos: "/portal-new/sos",
    completeOrganiztionProfile: "/portal-new/complete-organization-profile",
    sosDetail: "/portal-new/sos/:id",
  },
  consumer: {
    dashboard: "/consumer/dashboard",
    healthInsights: {
      root: "/consumer/health-insights",
      glucose: "/consumer/health-insights/glucose",
      oxygen: "/consumer/health-insights/oxygen-saturation",
      bloodPressure: "/consumer/health-insights/blood-pressure",
      heartRate: "/consumer/health-insights/heart-rate",
      respirationRate: "/consumer/health-insights/respiratory-rate",
      sleep: "/consumer/health-insights/sleep",
      steps: "/consumer/health-insights/steps",
      temperature: "/consumer/health-insights/temperature",
      stress: "/consumer/health-insights/stress",
      ecg: "/consumer/health-insights/ecg",
    },
    healthSummary: "/consumer/health-summary",
    medical: "/consumer/medical",
    alertsHealthIncidents: "/consumer/alerts-health-incidents",
    monthlyReports: "/consumer/monthly-reports",
    profile: "/consumer/profile",
    healthSettings: "/consumer/health-settings",
    completeIndividualProfile: "/consumer/complete-individual-profile",
  },
};

import { NavLink } from "react-router-dom";
import "./Navbar.css";
interface SubNavProps {
  navLinks: {
    label: string;
    path: string;
  }[];
}
const SubNav = ({ navLinks }: SubNavProps) => {
  return (
    <div style={{ width: "fit-content" }}>
      <nav className="sub-nav-client d-none d-lg-flex navbar navbar-expand-lg navbar-light text-dark p-0">
        <div className="row align-items-center">
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-client-nav">
              <div className="d-flex flex-grow-1 justify-content-center">
                {navLinks.map((link) => (
                  <NavLink
                    className={({ isActive }) =>
                      ` nav-link px-2 py-0 mx-1 ${isActive ? "active" : ""}`
                    }
                    aria-current="page"
                    to={link.path}
                    key={link.path}
                  >
                    <p className="font-12px mx-0 my-2">{link.label}</p>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
};

export default SubNav;

import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { VitalType } from "@app/common/enums/VitalTypes";
import { GraphFilterDto } from "@app/redux/features/health-service/models/graph-filter-dto";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

interface PeriodSelectProps {
  analysisName: string;
  handleFilterClick?: (value: string) => void;
  selectedPeriod?: string;
}

const PeriodSelect: React.FC<PeriodSelectProps> = ({
  analysisName,
  handleFilterClick = () => {},
  selectedPeriod = "",
}) => {
  const graphFilterDto = TimePeriodHelper.timePeriodList.map((item) => ({
    key: TimePeriodHelper.getTimePeriodName(item),
    value: TimePeriodHelper.getTimePeriodJson(item),
  }));

  return (
    <div className="d-flex flex-column">
      {analysisName !== VitalType.ECG && (
        <div
          className="row d-flex justify-content-center"
          style={{ width: "fit-content" }}
        >
          <div className="filter-container">
            <div className="form-floating font-12px px-2 py-1 rounded-3 bg-grey-904">
              <span>View:</span>
              <select
                className="border-0 bg-grey-904 py-1"
                onChange={(e) => handleFilterClick(e.target.value)}
                value={selectedPeriod}
              >
                {graphFilterDto.map((item: GraphFilterDto, index: number) => {
                  return <option value={item.value}> {item.key}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PeriodSelect;


import heartBeat from "assets/icons/heart_beat.svg";
import syringeIcon from "@app/assets/icons/syringe_icon.svg";
import heartBeatIcon from "@app/assets/icons/heart_beat.svg";
export interface ECGDetail {
  type: ECGType;
  value?: number;
}

export enum ECGType {
  HEART_RATE_VARIABILITY = "HEART_RATE_VARIABILITY",
  HEART_RATE = "HEART_RATE",
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
}

export namespace ECGDetail {
  export function getIcon(ecgType: ECGType) {
    switch (ecgType) {
      case ECGType.HEART_RATE_VARIABILITY:
        return heartBeat;
      case ECGType.HEART_RATE:
        return heartBeatIcon;
      case ECGType.BLOOD_PRESSURE:
        return syringeIcon;
      default:
        return heartBeat;
    }
  }
  export function getName(ecgType: ECGType) {
    switch (ecgType) {
      case ECGType.HEART_RATE_VARIABILITY:
        return "Heart Rate Variability";
      default:
        return "Heart Rate Variability";
    }
  }

  export function getUnit(ecgType: ECGType) {
    switch (ecgType) {
      case ECGType.HEART_RATE_VARIABILITY:
        return "ms";
      case ECGType.HEART_RATE:
        return "BPM";
      case ECGType.BLOOD_PRESSURE:
        return "mmHg";

      default:
        return "ms";
    }
  }
}

import DevicesCard from "./widgets/DevicesCard";

const Devices = () => {
    return (
      <div>
        <main className="container min-vh-100">
          <div className="d-flex flex-column">
            <div className="mt-5">
              <p className="font-20px mb-0 fw-bold">Devices</p>
            </div>
            <div className="row d-flex ">
              <div className="tab-container history-tabs">
                <DevicesCard />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  };
  
  export default Devices;
  
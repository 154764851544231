import { VitalType } from "@app/common/enums/VitalTypes";

interface ValueBreakdown {
  veryLow?: number;
  low?: number;
  normal?: number;
  high?: number;
  veryHigh?: number;
}
interface ValueBreakDownItem {
  minimum: number;
  average: number;
  maximum: number;
}
interface ValueBreakDownProps {
  title?: string;
  icon?: string;
  unit?: string;
  type: VitalType;
  breakdownValues?: ValueBreakdown;
  value?: ValueBreakDownItem;
}

const ValueBreakDown: React.FC<ValueBreakDownProps> = (
  props: ValueBreakDownProps
) => {
  const typeRanges = {
    [VitalType.GLUCOSE]: [
      { color: "very-low", label: "Very Low", type: "veryLow" },
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
      { color: "very-high", label: "Very High", type: "veryHigh" },
    ],
    [VitalType.BLOOD_PRESSURE]: [
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
    ],
    [VitalType.HEART_RATE]: [
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
    ],
    [VitalType.OXYGEN]: [
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
    ],
    [VitalType.RESPIRATORY_RATE]: [
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
    ],
    [VitalType.TEMPERATURE]: [
      { color: "low", label: "Low", type: "low" },
      { color: "normal", label: "Normal", type: "normal" },
      { color: "high", label: "High", type: "high" },
    ],
  };
  const ranges = typeRanges[props.type as keyof typeof typeRanges] || [];
  const InfoBlock = ({
    range,
  }: {
    range: { color: string; label: string; type: string };
  }) => (
    <div className="d-flex flex-row align-items-center">
      <div
        className={`bg-${range.color} rounded-circle ms-2 me-2`}
        style={{
          width: "8px",
          height: "8px",
          display: "inline-block",
        }}
      ></div>
      <p className="m-0 text-grey-901 font-12px">
        {range.label}

        <span className="font-12px fw-medium ms-1 text-dark">
          ({props.breakdownValues?.[range.type as keyof ValueBreakdown] || 0}%)
        </span>
      </p>
    </div>
  );

  const NoteBlock = ({ type }: { type: VitalType }) => {
    switch (type) {
      case VitalType.GLUCOSE:
        return (
          <>
            <p className="mb-2 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-very-high fw-medium"> very high </span>{" "}
              glucose value is considered to be above 11 mmol/L.
            </p>
            <p className="mb-2 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-normal fw-medium">normal</span> glucose
              value is considered to be between 4 and 8 mmol/L. (Target range)
            </p>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-very-low fw-medium">very low</span> glucose
              value is considered to be below 3 mmol/L.
            </p>
          </>
        );
      case VitalType.BLOOD_PRESSURE:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              In a non-clinical setting, a{" "}
              <span className="text-normal fw-medium">normal</span>{" "}
              <span className="text-black fw-medium">
                systolic blood pressure{" "}
              </span>
              is considered to be between 110 and 135 mmHg. (Target range)
            </p>
          </>
        );
      case VitalType.HEART_RATE:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-normal fw-medium">normal</span> heart rate
              is considered to be between 51 and 90 bpm. (Target range)
            </p>
          </>
        );
      case VitalType.OXYGEN:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-normal fw-medium">normal</span> oxygen is
              considered to be above 94%. (Target range)
            </p>
          </>
        );

      case VitalType.RESPIRATORY_RATE:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-normal fw-medium">normal</span> respiratory
              rate is considered to be between 12 and 20 rpm. (Target range)
            </p>
          </>
        );
      case VitalType.TEMPERATURE:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A <span className="text-normal fw-medium">normal</span> body
              temperature is considered to be between 36 and 38°C. (Target
              range)
            </p>
          </>
        );
      default:
        return (
          <>
            <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
              A normal body temperature is considered to be between 36 and 38°C.
              (Target range)
            </p>
          </>
        );
    }
  };
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 ps-0 w-100 h-100 ">
      <div className="flex-grow-1 border-start border-grey-904 ps-3">
        {/* Header */}
        <div className="d-flex flex-row justify-content-start align-items-center w-100">
          <span className="font-12px fw-medium d-flex flex-grow-1 ms-2">
            Value Breakdown
          </span>
        </div>
        {/* Value Breakdown */}
        <div className="w-100 d-flex flex-row flex-wrap mt-2">
          {ranges.map((range, index) => (
            <InfoBlock range={range} key={index} />
          ))}
          <p className="m-0 ms-2 text-grey-901 font-12px fw-medium">
            - Average value
          </p>
        </div>
        {/* Value */}
        <div className="w-100 d-flex flex-row px-2 border-top border-grey-904 mt-2">
          <div className="col">
            <div className="d-flex flex-column mt-2">
              <p className="m-0 text-grey-901 font-12px fw-medium">Minimum</p>
              <p className="m-0 text-dark font-14px fw-medium">
                {props.value?.minimum}{" "}
                <span className="text-grey-901 font-12px fw-medium">
                  {VitalType.getUnit(props.type)}
                </span>
              </p>
            </div>
          </div>
          <div className="col border-start border-grey-904">
            <div className="d-flex flex-column mt-2 ms-2">
              <p className="m-0 text-grey-901 font-12px fw-medium">Average</p>
              <p className="m-0 text-dark font-14px fw-medium">
                {props.value?.average}{" "}
                <span className="text-grey-901 font-12px fw-medium">
                  {VitalType.getUnit(props.type)}
                </span>
              </p>
            </div>
          </div>
          <div className="col border-start border-grey-904">
            <div className="d-flex flex-column mt-2 ms-2">
              <p className="m-0 text-grey-901 font-12px fw-medium">Maximum</p>
              <p className="m-0 text-dark font-14px fw-medium">
                {props.value?.maximum}{" "}
                <span className="text-grey-901 font-12px fw-medium">
                  {VitalType.getUnit(props.type)}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="w-100 d-flex flex-row flex-wrap mt-2">
          <NoteBlock type={props.type} />
        </div>
      </div>
    </div>
  );
};

export default ValueBreakDown;

import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import ActionModal from "@app/components/Modal/ActionModal";
import alertIcon from "@assets/icons/alert_icon.svg";
import moment from "moment";
import { AssignDeviceDto } from "@app/redux/features/v2/org-service/models/organisation-portal-dto";
import { hasMissingFields } from "@app/utils/helper";

const DEFAULT_DATA: AssignDeviceDto = { device: "mac", mac_id: "", imei: "", client: "", date: "" };

const AssignModal = ({ show, onHide }: { show: boolean; onHide: () => void }) => {
  const [deviceType, setDeviceType] = useState<"mac" | "imei">("mac");
  const [device, setDevice] = useState(DEFAULT_DATA);
  const [isError, setIsError] = useState(false);

  const fieldsToCheck: (keyof AssignDeviceDto)[] = [deviceType === "mac" ? "mac_id" : "imei", "client", "date"];

  const onCloseModal = () => {
    onHide();
    setDevice(DEFAULT_DATA);
  };

  const onSubmit = async () => {
    try {
      if (hasMissingFields(device, fieldsToCheck)) {
        setIsError(true);
        return;
      }

      onCloseModal();
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Assign device to client"
      show={show}
      onHide={onCloseModal}
      title="Map Device"
      size="lg"
      icon={alertIcon}
      customButton={
        <Button className="rounded-3 bg-blue-901 border-blue-901 d-flex align-items-center" onClick={onSubmit}>
          <p className="ms-1 my-0 font-12px progress-bar fw-bold">Assign</p>
        </Button>
      }
    >
      <div className="row">
        <div className="col-12">
          <span className="font-10px text-dark">Choose device</span>
          <InputGroup className="border border-0 d-flex align-items-center gap-3">
            <Form.Check
              type="radio"
              value="mac"
              label="MAC ID"
              checked={deviceType === "mac"}
              onChange={() => setDeviceType("mac")}
              className="d-flex align-items-center gap-3"
            />
            <Form.Check
              type="radio"
              value="imei"
              label="IMEI Number"
              checked={deviceType === "imei"}
              onChange={() => setDeviceType("imei")}
              className="d-flex align-items-center gap-3"
            />
          </InputGroup>
        </div>

        {deviceType === "mac" && (
          <div className="col">
            <span className="font-10px text-dark">MAC ID</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="device-input-color border-0 font-12px py-2"
                type="text"
                name="mac_id"
                placeholder="Enter MAC ID"
                value={device.mac_id}
                onChange={(e) => setDevice({ ...device, mac_id: e.target.value })}
              />
            </InputGroup>
            {isError && device.mac_id === "" && (
              <Form.Control.Feedback type="invalid">Please enter MAC ID</Form.Control.Feedback>
            )}
          </div>
        )}

        {deviceType === "imei" && (
          <div className="col">
            <span className="font-10px text-dark">IMEI Number</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="device-input-color border-0 font-12px py-2"
                type="text"
                name="imei"
                placeholder="Enter IMEI number"
                value={device.imei}
                onChange={(e) => setDevice({ ...device, imei: e.target.value })}
              />
            </InputGroup>
            {isError && device.imei === "" && (
              <Form.Control.Feedback type="invalid">Please enter IMEI number</Form.Control.Feedback>
            )}
          </div>
        )}

        <div className="d-flex flex-row gap-3">
          <div className="col">
            <Form.Group className="dropdown-selector text-capitalize">
              <span className="font-10px text-dark">Client</span>
              <Form.Select
                className="border-0 font-12px py-2"
                value={device.client}
                onChange={(e) => setDevice({ ...device, client: e.target.value })}
              >
                <option value="" hidden>
                  Select client
                </option>
                <option value="Carter">Carter</option>
                <option value="Gavin">Gavin</option>
              </Form.Select>
            </Form.Group>
            {isError && device.client === "" && (
              <Form.Control.Feedback type="invalid">Please select client</Form.Control.Feedback>
            )}
          </div>
          <div className="col">
            <span className="font-10px text-dark">Set up date</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="border-0 font-12px py-2"
                type="date"
                placeholder="dd/mm/yyyy"
                value={moment(device.date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDevice({ ...device, date: e.target.value });
                }}
              />
            </InputGroup>
            {isError && device.date === "" && (
              <Form.Control.Feedback type="invalid">Please select set up date</Form.Control.Feedback>
            )}
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default AssignModal;

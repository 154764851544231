import React, { useState } from "react";
import { SkinColorItem } from "./SkinColorItem";

interface SkinColorSectionProps {
  initValue?: string;
  onSkinColorChange: (newSkinColor: string) => void;
}

class SkinItem {
  key: string;
  color: string;
  constructor(key: string, color: string) {
    this.key = key;
    this.color = color;
  }
}

export const SkinColorSection = ({
  initValue,
  onSkinColorChange,
}: SkinColorSectionProps) => {
  const theme = {
    colors: {
      skins: {
        dairyCream: "#F8D2B3",
        goldSand: "#EBB68E",
        tan: "#CFA07C",
        santaFe: "#BD7852",
        ironstone: "#90493C",
        eclipse: "#3C1F1B",
      },
    },
  };

  const skinList: SkinItem[] = [
    { key: "Dairy Cream", color: theme.colors.skins.dairyCream },
    { key: "Gold Sand", color: theme.colors.skins.goldSand },
    { key: "Tan", color: theme.colors.skins.tan },
    { key: "Santa Fe", color: theme.colors.skins.santaFe },
    { key: "Ironstone", color: theme.colors.skins.ironstone },
    { key: "Eclipse", color: theme.colors.skins.eclipse },
  ];

  const [selectedSkinColor, setSelectedSkinColor] = useState(
    initValue ?? skinList[0].key
  );

  const handleSkinColorSelect = (key: string) => {
    setSelectedSkinColor(key);
    onSkinColorChange(key);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-row font-10px text-dark">
        <span>Skin</span>
      </div>
      <div className="d-flex flex-row align-items-center ms-2">
        {skinList.map((e, i) => (
          <React.Fragment key={e.key}>
            <SkinColorItem
              isSelected={selectedSkinColor === e.key}
              colorKey={e.key}
              onPress={() => handleSkinColorSelect(e.key)}
            />
            {i !== skinList.length - 1 && <div style={{ width: "4px" }} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};




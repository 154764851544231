import { ComplianceDocsResponseDto, ComplianceRecordDto, CreateComplianceDocumentDto, GetComplianceDto } from "@redux/features/v2/compliance-service/models/create-compliance-record-dto";

import { createOrganisationPortalApi } from "../organisation-portal-api"; 
import { config } from "../config";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const createComplianceRecord = (compliance: ComplianceRecordDto) => {
    return organisationPortalApi.post<string>("/api/v2/compliance/records", compliance);
}

const createComplianceDocument = (compliance: CreateComplianceDocumentDto) => {
    return organisationPortalApi.put<string>("/api/v2/compliance/document", compliance);
}

const getActiveCompliance = (compliance: GetComplianceDto) => {
    let url = `/api/v2/compliance/documents`;
    if (compliance.jurisdiction) {
        url += `?jurisdiction=${compliance.jurisdiction}`;
    }
    return organisationPortalApi.get<ComplianceDocsResponseDto[]>(url);
}

const ComplianceServiceApi = {
    createComplianceDocument,
    createComplianceRecord,
    getActiveCompliance
}

export default ComplianceServiceApi;
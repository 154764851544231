import { ECGType } from "@app/common/enums/ECGType";
import { FluctuationTypes } from "@app/common/enums/FluctuationTypes";
import { StepDetailType } from "@app/common/enums/StepType";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { VitalType } from "@app/common/enums/VitalTypes";
import {
  SleepCardContainer,
  StepsCardContainer,
  ECGCardContainer,
  CardContainer,
} from "@app/components/HealthSumaryCard";
import React, { useEffect } from "react";
import moment from "moment";
import {
  getStepsSummaryAsync,
  getVitalsSummaryAsync,
} from "@app/redux/features/v2/health-service/healthServiceThunk";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { VitalsSummaryDto } from "@app/redux/features/v2/health-service/models/bp-graph-dto";
import ClientDetailBreadcrumb from "@app/components/Breadcrumb/ClientDetailBreadcrumb";

const HealthSummary = () => {
  const dispatch = useAppDispatch();
  const { user_profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const { vitalsSummaryDto } = useAppSelector(
    (state: RootState) => state.healthServiceV2
  );

  const { stepsSummaryDto } = useAppSelector(
    (state: RootState) => state.healthServiceV2
  );

  useEffect(() => {
    if (user_profile?.sid) {
      dispatch(getVitalsSummaryAsync(user_profile.sid));
      dispatch(getStepsSummaryAsync(user_profile.sid));
    }
  }, [dispatch, user_profile?.sid]);

  const convertTimeToHoursAndMinutes = (minutes?: string) => {
    if (!minutes) return "N/A";
    const mins = parseInt(minutes, 10);
    const hours = Math.floor(mins / 60);
    const remainingMinutes = mins % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const getLatestVital = (type: string) => {
    return vitalsSummaryDto?.find(
      (item) => item.type === type && item.vital_period === "LATEST"
    );
  };

  const getVitalHistory = (type: string) => {
    const periods = [TimePeriod.day, TimePeriod.week, TimePeriod.month];
    const determineFluctuation = (vital?: VitalsSummaryDto) => {
      if (vital?.vital_trend === 0 || !vital?.vital_trend)
        return FluctuationTypes.unChange;

      if (vital?.vital_trend === -1) return FluctuationTypes.decrease;

      if (vital?.vital_trend === 1) return FluctuationTypes.increase;
      return FluctuationTypes.increase;
    };

    return periods.map((period) => {
      const vital = vitalsSummaryDto?.find(
        (item: VitalsSummaryDto) =>
          item.type === type &&
          item.vital_period === TimePeriodHelper.getTimePeriod(period)
      );
      const value =
        type === "BLOOD_PRESSURE"
          ? vital?.vital_average_sys
          : vital?.vital_average;
      return {
        type: period,
        value: value,
        secondValue: vital?.vital_average_dia,
        increase: determineFluctuation(vital),
      };
    });
  };
  const getStepsDataHistory = () => {
    const periods = [TimePeriod.day, TimePeriod.week, TimePeriod.month];
    const determineFluctuation = (vital_trend?: number) => {
      if (vital_trend === 0 || !vital_trend) return FluctuationTypes.unChange;

      if (vital_trend === -1) return FluctuationTypes.decrease;

      if (vital_trend === 1) return FluctuationTypes.increase;
      return FluctuationTypes.increase;
    };
    return periods.map((period) => {
      const periodData =
        stepsSummaryDto?.step_summary?.[TimePeriodHelper.getTimePeriod(period)]
          ?.data?.[0];
      const value = periodData?.total_steps;
      return {
        type: period,
        value,
        increase: determineFluctuation(periodData?.vital_trend),
      };
    });
  };

  const getSleepDataHistory = () => {
    const periods = [TimePeriod.day, TimePeriod.week, TimePeriod.month];
    const determineFluctuation = (vital_trend?: number) => {
      if (vital_trend === 0 || !vital_trend) return FluctuationTypes.unChange;

      if (vital_trend === -1) return FluctuationTypes.decrease;

      if (vital_trend === 1) return FluctuationTypes.increase;
      return FluctuationTypes.increase;
    };

    return periods.map((period, index) => {
      const sleepData = vitalsSummaryDto?.find(
        (item) =>
          item.type === "SLEEP" && TimePeriodHelper.getTimePeriod(period)
      );
      return {
        type: period,
        value: convertTimeToHoursAndMinutes(
          sleepData?.sleep_duration?.toString() ?? ""
        ),
        increase: determineFluctuation(sleepData?.vital_trend),
      };
    });
  };

  const getECGDataHistory = () => {
    const periods = [TimePeriod.day, TimePeriod.week, TimePeriod.month];
    const determineFluctuation = (vital_trend?: number) => {
      if (vital_trend === 0 || !vital_trend) return FluctuationTypes.unChange;

      if (vital_trend === -1) return FluctuationTypes.decrease;

      if (vital_trend === 1) return FluctuationTypes.increase;
      return FluctuationTypes.increase;
    };

    return periods.map((period) => {
      const ecgData = vitalsSummaryDto?.find(
        (item) => item.type === "ECG" && TimePeriodHelper.getTimePeriod(period)
      );
      return {
        type: period,
        heartRate: {
          value: ecgData?.vital_value_BP_max_mean,
          increase: determineFluctuation(ecgData?.vital_trend_BP),
        },
        secondValue: {
          value: ecgData?.vital_value_hrv_mean,
          increase: determineFluctuation(ecgData?.vital_trend_hrv),
        },
      };
    });
  };

  const renderCard = (type: VitalType) => {
    const latest = getLatestVital(type);
    const commonProps = {
      key: type,
      vitalType: type,
      unit: VitalType.getUnit(type),
      date: latest?.vital_datetime
        ? moment(latest.vital_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "",
    };

    switch (type) {
      case VitalType.GLUCOSE:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.GLUCOSE)}
          />
        );
      case VitalType.STEPS:
        return (
          <StepsCardContainer
            {...commonProps}
            currentData={
              stepsSummaryDto?.step_summary?.DAY?.data[0]?.total_calories
            }
            dataDetail={[
              {
                type: StepDetailType.ENERGY_BURNED,
                value:
                  stepsSummaryDto?.step_summary?.DAY?.data[0]?.total_calories ??
                  0,
              },
              {
                type: StepDetailType.DISTANCE,
                value:
                  stepsSummaryDto?.step_summary?.DAY.data[0]?.total_distance ??
                  0,
              },
            ]}
            dataHistory={getStepsDataHistory()}
          />
        );
      case VitalType.SLEEP:
        return (
          <SleepCardContainer
            {...commonProps}
            currentData={convertTimeToHoursAndMinutes(
              latest?.sleep_duration?.toString()
            )}
            dataHistory={getSleepDataHistory()}
          />
        );
      case VitalType.BLOOD_PRESSURE:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value_sys}
            currentDataSecondValue={latest?.vital_value_dia}
            dataHistory={getVitalHistory(VitalType.BLOOD_PRESSURE)}
          />
        );
      case VitalType.HEART_RATE:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.HEART_RATE)}
          />
        );
      case VitalType.ECG:
        return (
          <ECGCardContainer
            {...commonProps}
            currentData={latest?.vital_value_BP_min}
            currentDataSecondValue={latest?.vital_value_BP_max}
            dataHistory={getECGDataHistory()}
            dataDetail={[
              {
                type: ECGType.HEART_RATE_VARIABILITY,
                value: latest?.vital_value_hrv,
              },
            ]}
          />
        );
      case VitalType.STRESS:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.STRESS)}
          />
        );
      case VitalType.RESPIRATORY_RATE:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.RESPIRATORY_RATE)}
          />
        );
      case VitalType.TEMPERATURE:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.TEMPERATURE)}
          />
        );
      case VitalType.OXYGEN:
        return (
          <CardContainer
            {...commonProps}
            currentData={latest?.vital_value}
            dataHistory={getVitalHistory(VitalType.OXYGEN)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column gap-3">
          <ClientDetailBreadcrumb viewName="Health summary" />
          <div className="mt-3">
            <p className="font-14px mb-0">
              Hello, {user_profile?.first_name} {user_profile?.last_name}
            </p>
            <div className="d-flex row">
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-20px mb-0 fw-bold">Welcome to MyMonX</p>
              </div>
            </div>
          </div>
          <div className="row d-flex">
            {VitalType.values.map((type) => (
              <div
                key={type}
                className="col-xxl-3 col-xl-3 col-lg-4 col-12 my-2 px-2"
              >
                <div className="h-100">{renderCard(type)}</div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default HealthSummary;

import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useCallback, useEffect, useRef, useState } from "react";
import AnalysisCard from "./widgets/Stress.AnalysisCard";
import HistoryRecordCard from "@components/HistoryRecordCard";
import SummaryCard from "@app/components/SummaryCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import { GlucoseStatus } from "@app/common/enums/AnalysisType";
import PeriodSelect from "@app/components/PeriodSelect";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getStressVitalsGraphAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";
import moment from "moment";
import { typeRanges } from "@app/components/SummaryCard/ProgressContainer";
import { formatDuration } from "@app/utils/timeUtils";
import { PieDataItem } from "./widgets/Stress.AnalysisDaily";
import {
  StressDataDto,
  StressDetailDto,
} from "@app/redux/features/v2/health-service/models/stress-data-dto";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

type Stage = {
  stage: string;
  value: number;
  duration: number;
};

type StressChartType = {
  date: string | number;
  startTime: number | undefined | null;
  stages: Stage[];
};

const Stress = () => {
  const dispatch = useAppDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(
    TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
  );
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const [tableData, setTableData] = useState<
    {
      id: number;
      healthVitals: string;
      date: string;
      time: string | number;
      duration: string | number;
      stressLevel: string;
    }[]
  >([]);
  const { user_profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const { stressDataDto } = useAppSelector(
    (state: RootState) => state.healthServiceV2
  );

  const calculateDateRange = useCallback((period: string): DateRange => {
    const current_moment = moment().endOf("day");
    let start: Date;
    let end: Date;

    switch (period) {
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        start = moment().startOf("day").toDate();
        end = current_moment.clone().toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
        start = current_moment
          .clone()
          .startOf("isoWeek")
          .startOf("day")
          .toDate();
        end = current_moment.clone().endOf("isoWeek").startOf("day").toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
        start = current_moment.clone().startOf("month").startOf("day").toDate();
        end = current_moment.clone().endOf("month").endOf("day").toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
        start = current_moment
          .clone()
          .startOf("month")
          .subtract(5, "months")
          .startOf("day")
          .toDate();
        end = current_moment.clone().endOf("month").endOf("day").toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
        start = current_moment.clone().startOf("year").startOf("day").toDate();
        end = current_moment.clone().endOf("year").endOf("day").toDate();
        break;
      default:
        start = current_moment.clone().startOf("day").toDate();
        end = current_moment.toDate();
    }

    return { startDate: start, endDate: end };
  }, []);

  const convertSummaryDailyData = (responseData: StressDataDto) => {
    if (!responseData || !responseData.data || !responseData.categories)
      return [];

    const convertTo24HourFormat = (time: string | undefined) => {
      if (!time || typeof time !== "string") {
        return "00:00";
      }

      const parts = time.split(" ");
      if (parts.length !== 2) {
        return "00:00";
      }

      const [timePart, modifier] = parts;
      const timeValues = timePart.split(":").map(Number);

      if (timeValues.length !== 2) {
        return "00:00";
      }

      let [hours, minutes] = timeValues;

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    };

    const hourlyData: { [key: string]: number[] } = {};

    responseData.data.forEach((item: StressDetailDto, index: number) => {
      const timeLabel = responseData.categories[index];
      const timeIn24HourFormat = convertTo24HourFormat(timeLabel);
      const hour = timeIn24HourFormat.split(":")[0];

      if (!hourlyData[hour]) {
        hourlyData[hour] = [];
      }
      hourlyData[hour].push(item.stress);
    });

    const result = Array.from({ length: 24 }).map((_, i) => {
      const hour = i.toString().padStart(2, "0");
      const stressValues = hourlyData[hour] || [];

      const avgStress =
        stressValues.length > 0
          ? stressValues.reduce((a, b) => a + b, 0) / stressValues.length
          : 0;

      return {
        date: `${hour}:00`,
        value: avgStress.toFixed(2),
      };
    });

    return result;
  };

  useEffect(() => {
    if (user_profile && selectedPeriod) {
      const dateRange = calculateDateRange(selectedPeriod);
      const defaultRequest = {
        userId: user_profile?.sid ?? "",
        vital_type: "STRESS",
        graphType: selectedPeriod,
        startDate: moment(dateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss"),
      };
      dispatch(getStressVitalsGraphAsync(defaultRequest));
    }
  }, [dispatch, user_profile, calculateDateRange, selectedPeriod]);

  const convertHistoryRecord = (responseData: StressDataDto) => {
    if (!responseData?.categories) return [];

    return responseData.data.map((record: StressDetailDto, index: number) => {
      const dateTime = moment(record.key);
      const stress = record.stress ?? 0;
      const stressLevel =
        typeRanges[VitalType.STRESS].find(
          (range: any) => stress >= range.range[0] && stress <= range.range[1]
        )?.label || "Relaxed";

      return {
        id: index + 1,
        healthVitals: "Stress",
        date: dateTime.format("YYYY-MM-DD") ?? "",
        time: dateTime.format("hh:mm A") ?? "",
        duration: formatDuration(record.duration ?? 0),
        stressLevel,
      };
    });
  };

  const convertDailyStressData = (responseData: StressDataDto) => {
    if (!responseData?.data) return [];

    const stressLevels: Record<
      string,
      { values: number[]; durations: number[] }
    > = {
      Relaxed: { values: [], durations: [] },
      Normal: { values: [], durations: [] },
      Medium: { values: [], durations: [] },
      High: { values: [], durations: [] },
    };

    responseData.data.forEach((record: StressDetailDto) => {
      const stress = record.stress ?? 0;

      let stressLevel =
        typeRanges[VitalType.STRESS].find((range) => {
          if (stress >= range.range[0] && stress <= range.range[1]) {
            return range.label === "Very High"
              ? { ...range, label: "High" }
              : range.label === "High"
              ? { ...range, label: "Medium" }
              : range;
          }
        })?.label || "Relaxed";

      if (stressLevels[stressLevel]) {
        stressLevels[stressLevel].values.push(stress);

        if (typeof record.duration === "number" && !isNaN(record.duration)) {
          stressLevels[stressLevel].durations.push(record.duration);
        }
      }
    });

    const averageData: PieDataItem[] = Object.keys(stressLevels).map(
      (level) => {
        const { values, durations } = stressLevels[level];

        const totalStress = values.reduce((sum, value) => sum + value, 0);
        const averageStress =
          values.length > 0 ? totalStress / values.length : 0;

        const totalDuration =
          durations.length > 0
            ? durations.reduce((sum, duration) => sum + duration, 0)
            : 0;

        let status = level.toLowerCase();
        if (level === "Relaxed") status = "very-low";
        if (level === "Normal") status = "low";
        if (level === "Medium") status = "normal";

        return {
          name: level,
          value: parseFloat(averageStress.toFixed(2)),
          status: status,
          color: getColorForLevel(level),
          percentage: parseFloat(averageStress.toFixed(2)),
          averageDuration: totalDuration,
        };
      }
    );

    return averageData;
  };

  const getColorForLevel = (level: string): string => {
    switch (level) {
      case "Relaxed":
        return "#A281E4";
      case "Normal":
        return "#3F72F3";
      case "Medium":
        return "#00BD91";
      case "High":
        return "#FFAA00";
      default:
        return "#000000";
    }
  };

  const STRESS_CATEGORIES = [
    { label: "Relaxed", color: "low", range: [0, 25] },
    { label: "Normal", color: "normal", range: [26, 50] },
    { label: "Medium", color: "high", range: [51, 75] },
    { label: "High", color: "very-high", range: [76, 100] },
  ];

  const categorizeStress = (value: number) => {
    const roundedValue = Math.round(value);
    const category = STRESS_CATEGORIES.find(
      (c) => roundedValue >= c.range[0] && roundedValue <= c.range[1]
    );
    return category ? category.label : "Unknown";
  };

  const convertStressDataToChartFormat = (
    stressData: StressDataDto,
    period: string
  ) => {
    const result: StressChartType[] = [];
    const currentDate = new Date();

    const getAllLabelsForPeriod = () => {
      switch (period) {
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
          return Array.from(
            { length: 25 },
            (_, i) => `${i.toString().padStart(2, "0")}:00`
          );
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
          return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
          return Array.from({ length: 31 }, (_, i) =>
            (i + 1).toString().padStart(2, "0")
          );
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
          return Array.from({ length: 6 }, (_, i) => {
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i);
            return date.toLocaleString("en-US", { month: "short" });
          }).reverse();
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
          return [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
        default:
          return [];
      }
    };

    const getLabelForPeriod = (timestamp: string) => {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return;
      }
      switch (period) {
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
          return `${date.getHours().toString().padStart(2, "0")}:00`;
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
          return date.toLocaleString("en-US", { weekday: "short" });
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
          return date.getDate().toString().padStart(2, "0");
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
          return date.toLocaleString("en-US", { month: "short" });
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
          return date.toLocaleString("en-US", { month: "short" });
        default:
          return "";
      }
    };

    const convertStartTimeToNumber = (timestamp: string) => {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return;
      }
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const totalMinutesFromMidnight = hours * 60 + minutes;
      let adjustedMinutes = totalMinutesFromMidnight - 1320;
      if (adjustedMinutes < 0) {
        adjustedMinutes += 1440;
      }
      return adjustedMinutes > 720 ? 720 : adjustedMinutes;
    };

    const allLabels = getAllLabelsForPeriod();
    allLabels.forEach((label) => {
      result.push({
        date: label,
        startTime: null,
        stages: [],
      });
    });

    stressData.data.forEach((item: StressDetailDto, index: number) => {
      const label = getLabelForPeriod(item.key);
      if (!label) return;

      let entry = result.find((r) => r.date === label);

      if (!entry) {
        entry = { date: label, startTime: null, stages: [] };
        result.push(entry);
      }

      const startTime = convertStartTimeToNumber(item.key);
      if (startTime !== null) {
        entry.startTime = startTime;
        entry.stages.push({
          stage: categorizeStress(item.stress),
          value: item.stress,
          duration: item.duration / 60 || 0,
        });
      }
    });

    return result;
  };

  const [summaryDailyData, setSummaryDailyData] = useState<
    { date: string; value: string | number }[]
  >([]);
  const [summaryData, setSummaryData] = useState<StressChartType[]>([]);
  const [dailyStressData, setDailyStressData] = useState<
    {
      color: string;
      name: string;
      value: number;
      status: string;
      percentage: number;
      averageDuration: number;
    }[]
  >([]);

  useEffect(() => {
    if (stressDataDto && Array.isArray(stressDataDto?.data)) {
      const historyRecord = convertHistoryRecord(stressDataDto);
      setTableData(historyRecord);
      if (
        selectedPeriod === TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
      ) {
        const dailyData = convertSummaryDailyData(stressDataDto);
        setSummaryDailyData(dailyData);
        const dailyStress = convertDailyStressData(stressDataDto);
        setDailyStressData(dailyStress);
      } else {
        const summary = convertStressDataToChartFormat(
          stressDataDto,
          selectedPeriod
        );
        setSummaryData(summary);
      }
    }
  }, [stressDataDto, selectedPeriod]);

  return (
    <div className="container health_insight row">
      <div className="col-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <PeriodSelect
            analysisName={VitalType.getAnalysisName(VitalType.STRESS)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              analysisType={VitalType.STRESS}
              analysisStatus={GlucoseStatus.HIGH}
              date={selectedPeriod}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              period={selectedPeriod}
              summaryDailyData={summaryDailyData}
              summaryData={summaryData}
              dailyStressData={dailyStressData}
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard
              type={VitalType.STRESS}
              period={selectedPeriod}
              data={tableData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stress;

import { formatDuration } from "@app/utils/timeUtils";
import React, { ReactNode } from "react";

export interface PieDataItem {
  name: string;
  value: number;
  status: string;
  color: string;
  percentage: number;
  averageDuration: number;
}
interface PieChartProps {
  children?: ReactNode;
  radius?: number;
  data: PieDataItem[];
}

const calculateTotalData = (data: PieDataItem[]) =>
  data.reduce((total, segment) => total + segment.value, 0);

const PieChart = ({ children, data, radius = 90 }: PieChartProps) => {
  const totalTime = calculateTotalData(data);
  const strokeWidth = 6;
  const gapSize = 10;
  const circumference = 2 * Math.PI * radius;

  // Calculate offsets for each segment
  const segmentData = data.map((segment) => {
    const percentage = segment.value / totalTime;
    const segmentLength = percentage * circumference - gapSize;
    return { ...segment, segmentLength };
  });

  let cumulativeOffset = 0;

  return (
    <div
      style={{ position: "relative", width: radius * 2, height: radius * 2 }}
    >
      <svg width={radius * 2} height={radius * 2}>
        <g transform={`rotate(-90 ${radius} ${radius})`}>
          {/* Render each segment */}
          {segmentData.map((segment, index) => {
            const offset = cumulativeOffset + gapSize;
            cumulativeOffset += segment.segmentLength + gapSize; // Add gapSize to offset
            return (
              <circle
                key={index}
                cx={radius}
                cy={radius}
                r={radius - strokeWidth / 2}
                stroke={segment.color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${segment.segmentLength} ${circumference}`}
                strokeDashoffset={-offset}
                strokeLinecap="round" // Add rounded edges
                fill="none"
              />
            );
          })}
        </g>
      </svg>

      {/* Center Text */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            width: "80%",
            height: "80%",
            backgroundColor: "#F7F7F7",
            borderRadius: radius,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StressAnalysisDaily = ({
  dailyStressData,
}: {
  dailyStressData: PieDataItem[];
}) => {
  const InfoBlock = ({ data }: { data: PieDataItem }) => (
    <div className="flex-fill w-100">
      <div
        className="rounded-2 bg-grey-903 px-2 d-flex justify-content-between align-items-center"
        style={{ height: "40px" }}
      >
        <div className={`text-${data.status} font-12px fw-bold`} style={{ width: "80px" }}>
          <div
            className={`bg-${data.status} rounded-circle ms-2 me-2`}
            style={{
              width: "8px",
              height: "8px",
              display: "inline-block",
            }}
          ></div>
          {data.name}
        </div>
        <div className="d-flex flex-row">
          <p className="ms-1 my-0 font-12px fw-bold text-black py-1">
            <span className="text-grey-901 fw-normal">{formatDuration(data.averageDuration)}</span>
          </p>
        </div>

        <div className="d-flex flex-row">
          <p className="ms-1 my-0 font-12px fw-bold text-black py-1">
            {data.percentage}%
          </p>
        </div>
      </div>
    </div>
  );

  const totalDuration = dailyStressData.reduce((sum, item) => sum + item.averageDuration, 0);

  return (
    <div className="d-flex flex-row justify-content-between my-4 mb-3">
      <PieChart data={dailyStressData}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="text-black fw-bold">
            <span className="font-28px fw-bold">{formatDuration(totalDuration, true)}</span>
          </div>
          <div className="font-14px text-grey-901">total hours</div>
        </div>
      </PieChart>

      <div className="flex-fill ms-3">
        <div className="d-flex flex-column justify-content-center h-100">
          {dailyStressData.map((item) => (
            <InfoBlock data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StressAnalysisDaily;

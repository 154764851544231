import React, { useState } from "react";
import Table from "./Table";
import { Button, Form, FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import searchIcon from "@assets/icons/search_icon.svg";
import closeIcon from "@assets/icons/close_icon.svg";
import plusIcon from "@assets/icons/plus_icon.svg";
import deviceIcon from "@assets/icons/portal/device_icon.svg";
import DeviceModal from "./DeviceModal";
import DeleteModal from "@app/components/Modal/DeleteModal";
import AssignModal from "./AssignModal";
import ConfigureModal from "./ConfigureModal";

interface HistoryRecordCardProps {}

const DeviceCard: React.FC<HistoryRecordCardProps> = () => {
  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [dataSelected, setDataSelected] = useState<any>(null);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [showUnassignDialog, setShowUnassignDialog] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const clearSearch = () => {
    setSearchTerm("");
  };

  const onShowModifyModal = (data?: null) => {
    setShowModifyDialog(true);
    setDataSelected(data);
  }

  const onCloseModifyModal = () => {
    setShowModifyDialog(false);
    setDataSelected(null);
  }

  const onShowDeleteModal = (data: any) => {
    setShowDeleteDialog(true);
    setDataSelected(data);
  }

  const onCloseDeleteModal = () => {
    setShowDeleteDialog(false);
    setDataSelected(null);
  }

  const onShowAssignModal = (data?: null) => {
    setShowAssignDialog(true);
    setDataSelected(data);
  }

  const onCloseAssignModal = () => {
    setShowAssignDialog(false);
    setDataSelected(null);
  }

  const onShowUnassignModal = (data: any) => {
    setShowUnassignDialog(true);
    setDataSelected(data);
  }

  const onCloseUnassignModal = () => {
    setShowUnassignDialog(false);
    setDataSelected(null);
  }

  const onShowConfigureModal = (data?: null) => {
    setShowConfigureModal(true);
    setDataSelected(data);
  }

  const onCloseConfigureModal = () => {
    setShowConfigureModal(false);
    setDataSelected(null);
  }

  return (
    <div>
      <div className="d-flex flex-column mt-3" style={{ gap: "18px" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex rounded-3 col-6 title-chart gap-2 align-items-center">
            <div className="rounded-5 icon-chart-custom">
              <img height={32} width={32} src={deviceIcon} alt="Icon" />
            </div>
            <div className="text-dark">
              <span className="font-14px fw-bold text-dark">
                Devices
              </span>
              <p className="font-10px text-grey-901 m-0">
                Lorem ipsum dolor sit amet, in quo illum vocibus.
              </p>
            </div>
          </div>
          <div className="col-4 d-flex align-items-center gap-2">
            <InputGroup className="rounded-3 bg-white">
              <div className="search-icon-history">
                <img className="h-100" width={16} src={searchIcon} alt="Search" />
              </div>
              <Form.Control
                className="border-0 bg-transparent ms-4 font-12px py-2"
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="close-icon-history">
                <img src={closeIcon} alt="Close" onClick={clearSearch} className="cursor-pointer" />
              </div>
            </InputGroup>
            <Button
              onClick={() => onShowModifyModal()}
              className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center"
            >
              <img src={plusIcon} alt="Plus" />
              <p className="ms-1 my-0 font-12px progress-bar">Add</p>
            </Button>
          </div>
        </div>
        <Table onEdit={onShowModifyModal} onDelete={onShowDeleteModal} onAssign={onShowAssignModal} onUnassign={onShowUnassignModal} onConfigure={onShowConfigureModal} />
      </div>

      <DeviceModal
        type={dataSelected ? "update" : "add"}
        show={showModifyDialog}
        onHide={onCloseModifyModal} 
        dataSelected={dataSelected}
      />

      <AssignModal
        show={showAssignDialog}
        onHide={onCloseAssignModal}
      />

      <ConfigureModal
        show={showConfigureModal}
        onHide={onCloseConfigureModal}
        type={dataSelected?.type ?? "watch"}
      />

      <DeleteModal
        show={showUnassignDialog}
        onHide={onCloseUnassignModal}
        onSubmit={onCloseUnassignModal}
        title="Unassign device"
        content="Selected device will be unassigned. You can always change this action by assign device to user."
        deleteText="Unassign"
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteDialog}
        onHide={onCloseDeleteModal}
        onSubmit={onCloseDeleteModal}
        title="Disable device"
        icon={deviceIcon}
        content="Selected device will be disabled. This actions can't be undo"
        deleteText="Disable"
      />
    </div>
  );
};

export default DeviceCard;

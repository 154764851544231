
import arrowUpIcon from '@assets/icons/trend_up.svg';
import arrowDownIcon from '@assets/icons/trend_down.svg';
import unChangeIcon from '@assets/icons/un_change.svg';

export enum FluctuationTypes {
  increase,
  decrease,
  unChange,
  }

export namespace FluctuationTypes{
    export function getIcon(status: FluctuationTypes): string {
      switch (status) {
        case FluctuationTypes.increase:
          return arrowUpIcon;
        case FluctuationTypes.decrease:
          return arrowDownIcon;
        default:
          return unChangeIcon;
      }
    }
  }

import { paginationDTO } from "@redux/features/staticData-service/models/country-data-dto";
import { MedicalDataDto } from "@redux/features/staticData-service/models/medical-data-dto";
import { createOrganisationPortalApi } from "@services/organisation-portal-api";
import { config } from "@services/config";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getCointries = (paging: paginationDTO) => {
  return organisationPortalApi.get<any>("/api/v2/data/country");
};

const getMedicalData = (client_id: string) => {
  return organisationPortalApi.get<MedicalDataDto>(
    `/api/v0/medical-data/${client_id}`
  );
};

const StaticDataServiceApi = {
  getCointries,
  getMedicalData,
};

export default StaticDataServiceApi;

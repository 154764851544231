import SubNav from "@app/components/Nav/Subnav";
import { Outlet } from "react-router-dom";

const navLinks = [
  { label: "Heart Rate", path: "/consumer/health-insights/heart-rate" },
  { label: "Blood Pressure", path: "/consumer/health-insights/blood-pressure" },
  {
    label: "Oxygen Saturation",
    path: "/consumer/health-insights/oxygen-saturation",
  },
  { label: "Temperature", path: "/consumer/health-insights/temperature" },
  { label: "Glucose", path: "/consumer/health-insights/glucose" },
  {
    label: "Respiratory Rate",
    path: "/consumer/health-insights/respiratory-rate",
  },
  { label: "Stress", path: "/consumer/health-insights/stress" },
  { label: "Sleep", path: "/consumer/health-insights/sleep" },
  { label: "Steps", path: "/consumer/health-insights/steps" },
  { label: "ECG", path: "/consumer/health-insights/ecg" },

];

const HealthInsights = () => {


  return (
    <div>
      <SubNav navLinks={navLinks} />
      <main className="container min-vh-100">
        <div className="d-flex flex-column gap-3">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default HealthInsights;

import { createAsyncThunk } from "@reduxjs/toolkit";

import PortalHomeApi from "@app/services/v2/portal-service";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";

import {
  CardDataResultDto,
  ClientsAlertTableDataResult,
  HighRiskClientsTableDataResult,
} from "./models/request-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import {
  ClientsAlertTableDataRequest,
  HighRiskClientsTableDataRequest,
} from "./models/request-dto";

export const getClientsCardDataAsync = createAsyncThunk<
  CardDataResultDto,
  string,
  { rejectValue: ApiError | undefined }
>("portalHomeV2/GetClientsCardData", async (request, { rejectWithValue }) => {
  try {
    const response = await PortalHomeApi.getClientsCardData(request);
    return response.data;
  } catch (error) {
    const apiError = getErrorPayload(error);
    return rejectWithValue(apiError);
  }
});

export const getClientsAlertCardDataAsync = createAsyncThunk<
  CardDataResultDto,
  string,
  { rejectValue: ApiError | undefined }
>(
  "portalHomeV2/GetClientsAlertCardData",
  async (request, { rejectWithValue }) => {
    try {
      const response = await PortalHomeApi.getClientsAlertCardData(request);
      return response.data;
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getHighRiskClientsCardDataAsync = createAsyncThunk<
  CardDataResultDto,
  string,
  { rejectValue: ApiError | undefined }
>(
  "portalHomeV2/GetHighRiskClientsCardData",
  async (request, { rejectWithValue }) => {
    try {
      const response = await PortalHomeApi.getHighRiskClientsCardData(request);
      return response.data;
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getHighRiskClientsTableDatasAsync = createAsyncThunk<
  PaginatedResult<HighRiskClientsTableDataResult>,
  HighRiskClientsTableDataRequest,
  { rejectValue: ApiError | undefined }
>(
  "portalHomeV2/GetHighRiskClientsTableData",
  async (request, { rejectWithValue }) => {
    try {
      const response = await PortalHomeApi.getHighRiskClientsTableData(request);
      return response.data;
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getClientsAlertTableDataAsync = createAsyncThunk<
  PaginatedResult<ClientsAlertTableDataResult>,
  ClientsAlertTableDataRequest,
  { rejectValue: ApiError | undefined }
>(
  "portalHomeV2/GetClientsAlertTableData",
  async (request, { rejectWithValue }) => {
    try {
      const response = await PortalHomeApi.getClientsAlertTableData(request);
      return response.data;
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

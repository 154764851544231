import { OnlineStatusType } from "@app/common/enums/OnlineStatus";

interface StatusBadgeType {
  status: string;
}

export default function StatusBadge({ status }: StatusBadgeType) {
  return (
    <div>
      {status === OnlineStatusType.OFFLINE ? (
        <div
          className="text-capitalize ms-2 px-1 bg-red-150 rounded-2 font-12px text-very-high fw-medium d-flex flex-row align-items-center gap-1"
          style={{ height: "17px" }}
        >
          <div style={{ width: 4, height: 4, backgroundColor: "#FF2D1A", borderRadius: "1000px" }} />
          {status}
        </div>
      ) : null}
      {status === OnlineStatusType.ONLINE ? (
        <div
          className="text-capitalize ms-2 px-1 bg-green-150 rounded-2 font-12px text-normal fw-medium d-flex flex-row align-items-center gap-1"
          style={{ height: "17px" }}
        >
          <div style={{ width: 4, height: 4, backgroundColor: "#00BD91", borderRadius: "1000px" }} />
          {status}
        </div>
      ) : null}
    </div>
  );
}

import { GpsRequestDto, NotesRequestDto } from "@redux/features/sos-service/models/gps-request-dto";
import { GpsResultDto } from "@redux/features/sos-service/models/gps-result-dto";
import { SosResultDto } from "@redux/features/sos-service/models/sos-result-dto";
import { VitalsResultDto } from "@redux/features/sos-service/models/vitals-result-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getSos = (userId: string) => {
    return organisationPortalApi.get<SosResultDto[]>(`/api/v0/sos/org/${userId}`);
}
const getGps = (request: GpsRequestDto) => {
    return organisationPortalApi.get<GpsResultDto[]>(`/api/v0/sos/gps?sos_sid=${request.sos_sid}`);//&from_date=${request.from_date}&page_number=${request.page_number}&page_size=${request.page_size}`);
}
const getVitals = (request: GpsRequestDto) => {
    return organisationPortalApi.get<VitalsResultDto[]>(`/api/v0/sos/vitals?in_sos_sid=${request.sos_sid}`);//&from_date=${request.from_date}&page_number=${request.page_number}&page_size=${request.page_size}`);
}
const saveNotes = (request: NotesRequestDto) => {
    return organisationPortalApi.put<string>(`/api/v0/sos/notes?in_sos_sid=${request.sos_sid}&in_sos_notes=${request.sos_notes}&in_user_sid=${request.user_sid}`);
}
const closeSos = (sos_id: string, user_sid: string) => {
    return organisationPortalApi.put<string>(`/api/v0/sos/close?sos_sid=${sos_id}&in_user_sid=${user_sid}`);
}
const acknowledgeSos = (sos_id: string, user_sid: string) => {
    return organisationPortalApi.patch<string>(`/api/v0/sos/acknowledge?sos_sid=${sos_id}&user_sid=${user_sid}`);
}
const SosServiceApi = {
    getSos,
    getGps,
    getVitals,
    saveNotes,
    closeSos,
    acknowledgeSos
}

export default SosServiceApi;
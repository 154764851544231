import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import SosServiceApi from "@app/services/v2/sos-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import { SosRequest, SosResultDto } from "./models/sos-result-dto";
import { GpsResultDto } from "./models/gps-result-dto";
import {
  CloseRequestDto,
  GpsRequestDto,
  NotesRequestDto,
} from "./models/gps-request-dto";
import { VitalsResultDto } from "./models/vitals-result-dto";
import { SOSAckRequestDto } from "./models/sos-ack-request-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";

export const getSosResultAsync = createAsyncThunk<
  PaginatedResult<SosResultDto>,
  SosRequest,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/getSosResult",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.getSos(request);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getGpsResultAsync = createAsyncThunk<
  GpsResultDto[],
  GpsRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/getGpsResult",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.getGps(request);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);
export const getVitalsResultAsync = createAsyncThunk<
  VitalsResultDto[],
  GpsRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/getVitals",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.getVitals(request);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);
export const saveNotesResultAsync = createAsyncThunk<
  string,
  NotesRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/saveNotes",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.saveNotes(request);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);
export const closeSosResultAsync = createAsyncThunk<
  string,
  CloseRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/closeSos",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.closeSos(
        request.sos_sid ?? "",
        request.user_sid
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const acknowledgeSosResultAsync = createAsyncThunk<
  string,
  SOSAckRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "sosServiceV2/acknowledgeSos",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await SosServiceApi.acknowledgeSos(
        request.sos_sid,
        request.user_sid
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

interface RoleBadgeType {
    role: string;
}

export default function RoleBadge({
    role
}: RoleBadgeType){
    return (
        <div
            style={{ backgroundColor: '#E4EAFB', color: '#3F72F3', height: "17px" }}
            className="ms-2 px-1 rounded-2 font-12px fw-medium d-flex flex-row align-items-center gap-1"
        >
            {role}
        </div>
    )
}
import {
  mergeMonthlyDataTo8HourIntervals,
  MonthlyReportDto,
} from "@app/redux/features/v2/report-service/model/monthly-report";
import { config } from "../config";
import { createOrganisationPortalApi } from "../organisation-portal-api";
import { VitalType } from "@app/common/enums/VitalTypes";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getMonthlyReports = (
  user_profile_sid: string
): Promise<MonthlyReportDto> => {
  return organisationPortalApi
    .get<MonthlyReportDto>(
      `/api/v2/reports/monthly-report-data/${user_profile_sid}`
    )
    .then((response) => {
      const data = response.data;
      const processedData: MonthlyReportDto = {
        ...data,
        BLOOD_GLUCOSE: mergeMonthlyDataTo8HourIntervals(data?.BLOOD_GLUCOSE),
        BLOOD_OXYGEN: mergeMonthlyDataTo8HourIntervals(data?.BLOOD_OXYGEN),
        BLOOD_PRESSURE_BPS: mergeMonthlyDataTo8HourIntervals(
          data?.BLOOD_PRESSURE_BPS
        ),
        BLOOD_PRESSURE_BPD: mergeMonthlyDataTo8HourIntervals(
          data?.BLOOD_PRESSURE_BPD
        ),
        BODY_TEMPERATURE: mergeMonthlyDataTo8HourIntervals(
          data?.BODY_TEMPERATURE
        ),
        HEART_RATE: mergeMonthlyDataTo8HourIntervals(data?.HEART_RATE),
        RESPIRATORY_RATE: mergeMonthlyDataTo8HourIntervals(
          data?.RESPIRATORY_RATE
        ),
      };

      return processedData;
    })
    .catch((error) => {
      console.error("Failed to fetch monthly reports:", error);
      throw error;
    });
};

const getMonthlyReportsByVital = (
  user_profile_sid: string,
  vital_type: string
) => {
  return organisationPortalApi.get<string>(
    `/api/v0/reports/vitals-dashboards?user_profile_sid=${user_profile_sid}&vital=${vital_type}`
  );
};
const ReportServiceApi = {
  getMonthlyReports,
  getMonthlyReportsByVital,
};

export default ReportServiceApi;

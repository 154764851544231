import watchIcon from "@assets/icons/watch_icon.svg";
import Button from "@material-ui/core/Button";
import DeviceActionModal from "./ContactActionModal";
import { useState } from "react";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import moment from "moment";

const DeviceCard = () => {
  const { device } = useAppSelector((state: RootState) => state.deviceServiceV2);
  const [show, setShow] = useState(false);
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
        <img width={32} height={32} src={watchIcon} alt="icon" />
        <div className="ps-1 text-dark">
          <div className="d-flex flex-column">
            <div className="font-12px text-dark">mymonX</div>
            <div className="my-0 font-14px text-dark fw-bold">
              <div className="my-0">
                {device?.imei ?? "No IMEI"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="border-grey-901 ">
        <div className="mt-2 mb-1">
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p className="font-12px text-grey-901">Set-up Date</p>
            <p className="font-12px fw-bold">{
              device && device.setup_date && <span>
                {moment(device.setup_date).format('DD/MM/YYYY')}
              </span>
            }</p>
          </div>
          <Button
            className="rounded-3 w-100 text-white border-green-900 px-3 d-flex align-items-center no-hover bg-green-900"
            onClick={() => setShow(true)}
          >
            <p
              className="ms-1 my-0 font-12px progress-bar"
              style={{ textTransform: "none" }}
            >
              Connect
            </p>
          </Button>
        </div>
      </div>
      <DeviceActionModal
        icon={watchIcon}
        title="Connect Device"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      />
    </div>
  );
};

export default DeviceCard;

import DataTable from "react-data-table-component";
import CustomPagination from "./Pagination";
import { useMemo, useState } from "react";
import ITEMS_IN_PAGE from "./data";

interface TableProps {
  columns: any[];
  data: any[];
  totalCount?: number;
  currentPage?: number;
  rowsPerPage?: number;
  onChangePage?: (page: number) => void; 
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onRowClick?: (row: any) => void;
  isSelectableRows?: boolean;
  onHandleSelectableRows?: (row: any) => void;
}

const Table: React.FC<TableProps> = ({ columns, data, totalCount, currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage, onRowClick, isSelectableRows = false, onHandleSelectableRows }) => {
  const customSort = (rows: any, selector: any, direction: any) => {
    return rows.sort((a: any, b: any) => {
      const aField = selector(a);
      const bField = selector(b);
  
      let comparison = 0;
  
      if (typeof aField === "string" && typeof bField === "string") {
        comparison = aField.localeCompare(bField);
      } 
      else if (typeof aField === "number" && typeof bField === "number") {
        comparison = aField - bField;
      }
  
      if (aField == null || bField == null) {
        comparison = aField == null ? -1 : 1;
      }
  
      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const customStyles = {
    table: {
      style: {
        backgroundColor: "transparent",
      },
    },
    rows: {
      style: {
        backgroundColor: "transparent",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#ECECEC",
        borderRadius: "7px",
        border: "1px solid #cccccc",
      },
    },

    headCells: {
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
    },

    pagination: {
      style: {
        color: "#212121",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
      },
      pageButtonsStyle: {
        // backgroundColor: "black",x`
      },
    },
  };

  const [currentPageDefault, setCurrentPageDefault] = useState(1);
  const [rowsPerPageDefault, setRowsPerPageDefault] = useState(ITEMS_IN_PAGE[0].value);

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPageDefault(newRowsPerPage);
    setCurrentPageDefault(1);
  };

  const paginatedData = useMemo(() => {
    return [...data].slice(
      (currentPageDefault - 1) * rowsPerPageDefault,
      currentPageDefault * rowsPerPageDefault
    );
  }, [data, currentPageDefault, rowsPerPageDefault]);

  const conditionalRowStyles = [
    {
      when: (row: any) => typeof onRowClick === "function",
      style: {
        cursor: "pointer",
      },
    },
  ];

  return (
    <div className="row mx-auto">
      <DataTable
        columns={columns}
        data={(totalCount && currentPage && rowsPerPage) ? data : paginatedData}
        customStyles={customStyles}
        pagination
        paginationServer
        selectableRows={isSelectableRows}
        onSelectedRowsChange={onHandleSelectableRows}
        sortFunction={customSort}
        onRowClicked={onRowClick}
        conditionalRowStyles={conditionalRowStyles}
        paginationPerPage={rowsPerPage ?? rowsPerPageDefault}
        paginationTotalRows={totalCount ?? data.length}
        paginationComponent={() => (
          <CustomPagination
            rowCount={totalCount ?? data.length}
            rowsPerPage={rowsPerPage ?? rowsPerPageDefault}
            currentPage={currentPage ?? currentPageDefault}
            onChangePage={(page) => {
              if (onChangePage) {
                onChangePage(page);
              } else {
                setCurrentPageDefault(page);
              }
            }}
            onChangeRowsPerPage={(newRowsPerPage) => {
              if (onChangeRowsPerPage) {
                onChangeRowsPerPage(newRowsPerPage);
              } else {
                handleRowsPerPageChange(newRowsPerPage);
              }
            }}
          />
        )}
      />
    </div>
  );
};

export default Table;

const AUTH_TOKEN = 'AUTH_TOKEN';
const USER_ROLE = 'USER_ROLE';
const ORG_REGISTER = 'ORG_REGISTER';

const setAccessToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
}
const setUserRole = (role: string) => {
    localStorage.setItem(USER_ROLE, role);
}
const setOrg = (org_id: string) => {
    localStorage.setItem(ORG_REGISTER, org_id);
}

const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}
const getUserRole = () => {
    return localStorage.getItem(USER_ROLE);
}
const getOrg = () => {
    return localStorage.getItem(ORG_REGISTER)
}

const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

const removeOrg = () => {
    localStorage.removeItem(ORG_REGISTER);
}

const LocalStorageService = {
    setAccessToken,
    setOrg,
    getAccessToken,
    getOrg,
    removeAccessToken,
    removeOrg,
    setUserRole,
    getUserRole
}

export default LocalStorageService;
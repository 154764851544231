import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import ActionModal from "@app/components/Modal/ActionModal";
import infoIcon from "@assets/icons/info_icon.svg";
import { SkinColorSection } from "./SkinColorSection";
import avatarSVG from "@assets/icons/complete-profile/default_profile_image.svg";
import uploadIcon from "@assets/icons/upload_icon.svg";
import removeIcon from "@assets/icons/remove_icon.svg";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import moment from "moment";
import {
  getProfileByEmailAsync,
  getUserProfileImageAsync,
  updateProfileClientAsync,
  uploadProfileImageAsync,
} from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";
import {
  UserProfileDto,
  UserProfileImageDto,
} from "@app/redux/features/v2/user-profile-service/models/user-profile-dto";
import Image from "react-bootstrap/Image";
import { hasMissingFields } from "@app/utils/helper";
import { FormControl, MenuItem, Select } from "@mui/material";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      left: 0,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 125,
    },
  },
};
const GeneralActionModal = ({
  show,
  onHide,
  nationality,
}: {
  show: boolean;
  onHide: () => void;
  nationality: any;
}) => {
  const dispatch = useAppDispatch();
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const { userGetProfileImage: avatarImage } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const [avatar, setAvatar] = useState("");

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isError, setIsError] = useState(true);
  const [dataForm, setDataForm] = useState<UserProfileDto>({
    time_zone: clientTimezone,
    ...profile,
  });
  const fieldsToCheck: (keyof UserProfileDto)[] = [
    "first_name",
    "date_of_birth",
    "email",
    "last_name",
    "gender",
    "nationality",
    "height_cm",
    "weight_kg",
    "skin_tone",
  ];
  useEffect(() => {
    setDataForm({ time_zone: clientTimezone, ...profile });
  }, [profile]);

  useEffect(() => {
    setAvatar(avatarImage?.avatar ?? "");
  }, [avatarImage]);

  const handleChange = (field: string, value: any) => {
    setDataForm((prevState: UserProfileDto) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onSave = () => {
    if (
      hasMissingFields(dataForm, fieldsToCheck) ||
      !/^\S+@\S+\.\S+$/.test(dataForm?.email ?? "")
    ) {
      setIsError(false);
      return;
    }
    const userProfileImageDto: UserProfileImageDto = {
      wearer_sid: profile?.sid ?? "",
      avatar,
    };

    const promises = [];
    promises.push(dispatch(updateProfileClientAsync(dataForm)));
    if (avatar !== avatarImage?.avatar) {
      promises.push(dispatch(uploadProfileImageAsync(userProfileImageDto)));
    }
    Promise.all(promises).then(() => {
      onHide();
      dispatch(getProfileByEmailAsync(profile?.email ?? ""));
      // Optionally, refresh the avatar image if it was updated
      if (avatar !== avatarImage?.avatar) {
        dispatch(getUserProfileImageAsync(profile?.sid ?? ""));
      }
    });
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string | null;
        if (result) {
          const base64Image = result.split(",")[1];
          setAvatar(base64Image ?? "");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };

  const handleRemovePhoto = () => {
    setAvatar("");
  };

  const handleSkinColorChange = (newSkinColor: string) => {
    setDataForm((prevState: any) => ({
      ...prevState,
      skin_tone: newSkinColor,
    }));
  };

  return (
    <ActionModal
      show={show}
      onHide={onHide}
      title="General Information"
      subTitle="Edit your personal information"
      size="lg"
      icon={infoIcon}
      onSubmit={onSave}
    >
      <Form>
        <div className="row">
          <div className="font-14px fw-bold mt-2">Profile image</div>

          <div className="col-12 border-grey-901 border-bottom pb-3 mb-2">
            {/* header */}
            <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
              <div className="icon-chart-custom py-1 d-flex align-items-center justify-content-center">
                <Image
                  height={78}
                  width={78}
                  src={avatar ? `data:image/png;base64,${avatar}` : avatarSVG}
                  className="rounded-circle"
                  alt="Profile"
                  style={{ objectFit: "cover" }}
                />
              </div>

              <div>
                <Button
                  className="ms-2 rounded-3 text-blue-901 border-green-900 px-3 d-flex align-items-center no-hover bg-blue-903"
                  onClick={handleButtonClick}
                >
                  <img src={uploadIcon} alt="upload icon" />
                  <p
                    className="ms-1 my-0 font-12px progress-bar"
                    style={{ textTransform: "none" }}
                  >
                    Upload photo
                  </p>
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <Button
                className="rounded-3  text-very-high border-green-900 px-3 d-flex align-items-center no-hover bg-transparent"
                onClick={handleRemovePhoto}
              >
                <img src={removeIcon} alt="remove" />
                <p
                  className="ms-1 my-0 font-12px progress-bar"
                  style={{ textTransform: "none" }}
                >
                  {" "}
                  Remove photo
                </p>
              </Button>
            </div>
          </div>

          <div className="font-14px fw-bold mt-2">Personal Details</div>

          <div className="col-12 row gap-3">
            <div className="col">
              <div className="row gap-3">
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">First Name</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 bg-transparent font-12px py-2"
                      type="text"
                      placeholder="First Name"
                      value={dataForm?.first_name ?? ""}
                      onChange={(e) =>
                        handleChange("first_name", e.target.value)
                      }
                    />
                  </InputGroup>

                  {isError && dataForm?.first_name === "" ? (
                    <Form.Control.Feedback type="invalid">
                      Please enter first name
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Last Name</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 bg-transparent font-12px py-2"
                      type="text"
                      placeholder="Last Name"
                      value={dataForm?.last_name ?? ""}
                      onChange={(e) =>
                        handleChange("last_name", e.target.value)
                      }
                    />
                  </InputGroup>

                  {isError && dataForm?.last_name === "" ? (
                    <Form.Control.Feedback type="invalid">
                      Please enter last name
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col">
              <span className="font-10px text-dark">Email</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="email"
                  placeholder="Email"
                  value={dataForm?.email ?? ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </InputGroup>
              {isError && dataForm?.email === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter email
                </Form.Control.Feedback>
              ) : isError &&
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataForm?.email ?? "") ? (
                <Form.Control.Feedback type="invalid">
                  Please enter valid email
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>

          <div className="col-12 row gap-3">
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Nationality</span>
              <Form.Group
                id="registerCountry"
                className="text-capitalize position-relative mb-0"
                controlId="registerForm.location"
              >

                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <Select
                    className="custom-select d-flex flex-column"
                    MenuProps={MenuProps}
                    value={dataForm?.nationality}
                    onChange={(e) =>
                      handleChange("nationality", e.target.value)
                    }
                    labelId="country-select-label"
                    id="country-select"
                    label="Country"
                    displayEmpty={true}
                  >
                    {nationality.map((item: any, index: number) => (
                      <MenuItem className="d-flex justify-content-start ms-2" key={index} value={item.iso_name}>
                        {item.iso_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Form.Group>

              {isError && dataForm?.nationality === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter nationality
                </Form.Control.Feedback>
              ) : null}
            </div>

            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Date of birth</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2 "
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={
                    moment(dataForm?.date_of_birth).format("YYYY-MM-DD") ?? ""
                  }
                  onChange={(e) =>
                    handleChange("date_of_birth", e.target.value)
                  }
                />
              </InputGroup>
              {isError && dataForm?.date_of_birth === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter date of birth
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>

          <div className="col-12 row gap-3">
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Gender</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Select
                  className="border-0 bg-transparent font-12px py-2"
                  value={dataForm?.gender ?? ""}
                  onChange={(e) => handleChange("gender", e.target.value)}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
              </InputGroup>
              {isError && dataForm?.gender === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter your gender
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="col">
              <div className="row gap-3">
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Height</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904 d-flex flex-row align-items-center">
                    <Form.Control
                      className="border-0 bg-transparent font-12px py-2"
                      type="text"
                      placeholder=""
                      value={dataForm?.height_cm ?? 0}
                      onChange={(e) =>
                        handleChange(
                          "height_cm",
                          e.target.value.replace(/[^0-9]/g, "")
                        )
                      }
                    />
                    <span className="font-12px text-grey-901 pe-2">cm</span>
                  </InputGroup>
                  {isError && dataForm?.height_cm === 0 ? (
                    <Form.Control.Feedback type="invalid">
                      Please enter height
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Weight</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904 d-flex flex-row align-items-center">
                    <Form.Control
                      className="border-0 bg-transparent font-12px py-2 text-black"
                      type="text"
                      placeholder=""
                      value={dataForm?.weight_kg ?? 0}
                      onChange={(e) =>
                        handleChange(
                          "weight_kg",
                          e.target.value.replace(/[^0-9]/g, "")
                        )
                      }
                    />
                    <span className="font-12px text-grey-901 pe-2">kg</span>
                  </InputGroup>
                  {isError && dataForm?.weight_kg === 0 ? (
                    <Form.Control.Feedback type="invalid">
                      Please enter weight
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <SkinColorSection
              initValue={dataForm?.skin_tone ?? ""}
              onSkinColorChange={handleSkinColorChange}
            />
          </div>
        </div>
      </Form>
    </ActionModal>
  );
};

export default GeneralActionModal;

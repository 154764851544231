import { IconButton, MenuItem } from "@material-ui/core";
import { makeStyles, Select } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { useState, ChangeEvent } from "react";

import arrow from "assets/icons/arrow_icon.svg";
import doubleArrow from "assets/icons/double_arrow_icon.svg";

import './styles.scss';
import ITEMS_IN_PAGE from "./data";

interface ICustomPaginationProps {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
}

const CustomPagination = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
  onChangeRowsPerPage
}: ICustomPaginationProps) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    select: {
      padding: 0,
      fontSize: "12px",
    },
    menuItem: {
      fontSize: "12px",
    },
  }));
  const classes = useStyles();

  const handleFirstPage = () => onChangePage(1);
  const handleLastPage = () => onChangePage(totalPages);
  const handlePreviousPage = () => onChangePage(currentPage - 1);
  const handleNextPage = () => onChangePage(currentPage + 1);

  return (
    <div className="d-flex justify-content-between align-items-center pagination" style={{ margin: '18px 0', }}>
      <div className="d-flex">
        <IconButton aria-label="first page" className="direct-page-btn first-page" size="small" onClick={handleFirstPage} disabled={currentPage === 1}>
          <img
            width={11}
            height={11}
            src={doubleArrow}
            alt="icon"
          />
        </IconButton>
        <IconButton aria-label="previous page" className="direct-page-btn previous-page" size="small" onClick={handlePreviousPage} disabled={currentPage === 1}>
          <img
            width={11}
            height={11}
            src={arrow}
            alt="icon"
          />
        </IconButton>
      </div>
      <div className="pagination-item-wrapper d-flex flex-column flex-md-row align-items-center justify-content-center font-12px">
        <div className="d-flex align-items-center justify-content-center ">
          <span>Page</span>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="page-selection"
              value={currentPage}
              onChange={(e: ChangeEvent<{ value: unknown }>) => onChangePage(e.target.value as number)}
              MenuProps={MenuProps}
              style={{ marginLeft: "0 !important" }}
              className={classes.select}
            >
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                <MenuItem key={page} value={page} className={classes.menuItem}>
                  {page}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span> Of {totalPages} {" "}</span>
        </div>

        <div
          className="d-none d-md-flex px-[12px]"
          style={{
            width: "1px",
            height: '30px',
            backgroundColor: "#ECECEC",
          }}
        />

        <div className="d-flex align-items-center justify-content-center gap-3">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={rowsPerPage}
              onChange={(e: ChangeEvent<{ value: unknown }>) => {
                onChangeRowsPerPage(e.target.value as number)
              }}
              MenuProps={MenuProps}
              className={classes.select}
            >
              {ITEMS_IN_PAGE.map((size) => (
                <MenuItem key={size.value} value={size.value} className={classes.menuItem}>
                  {size.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span>Out of {rowCount}</span>
        </div>

      </div>
      <div className="d-flex">
        <IconButton aria-label="next page" className="direct-page-btn next-page" size="small" onClick={handleNextPage} disabled={currentPage === totalPages}>
          <img
            width={11}
            height={11}
            src={arrow}
            alt="icon"
            style={{ transform: "rotate(180deg)" }}
          />
        </IconButton>
        <IconButton aria-label="last page" className="direct-page-btn last-page" size="small" onClick={handleLastPage} disabled={currentPage === totalPages}>
          <img
            width={11}
            height={11}
            src={doubleArrow}
            alt="icon"
            style={{ transform: "rotate(180deg)" }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomPagination;

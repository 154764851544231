import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import ComplianceServiceApi from "@app/services/v2/compliance-service";
import { ComplianceDocsResponseDto, ComplianceRecordDto, CreateComplianceDocumentDto, GetComplianceDto } from "./models/create-compliance-record-dto";
import { AxiosError } from "axios";
import { addErrorNotification } from "../../common/notificationSlice";



export const createComplianceDocumentResultAsync = createAsyncThunk<string, CreateComplianceDocumentDto,
    { rejectValue: ApiError | undefined }
>(
    "complianceService/createComplianceDocument",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.createComplianceDocument(request);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
);

export const createComplianceRecordResultAsync = createAsyncThunk<string, ComplianceRecordDto,
    { rejectValue: ApiError | undefined }
>(
    "complianceService/createComplianceRecord",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.createComplianceRecord(request);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
);
export const getActiveComplianceResultAsync = createAsyncThunk<ComplianceDocsResponseDto[], GetComplianceDto,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/getActiveCompliance",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.getActiveCompliance(request);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
);


import { Modal, Col, Button, Nav, CloseButton, Form, InputGroup } from "react-bootstrap";
import { useState } from "react";
import watchConfigure from "@app/assets/icons/portal/device_watch_configure.svg";
import ringConfigure from "@app/assets/icons/portal/device_ring_configure.svg";
import watch from "@app/assets/icons/portal/watch.svg";
import ring from "@app/assets/icons/portal/ring.svg";
import setting from "@app/assets/icons/portal/setting.svg";
import watchImage from "@app/assets/images/watch.png";
import ringImage from "@app/assets/images/ring.png";
import caretRight from "@app/assets/icons/portal/caret_right_icon.svg";
import findWatch from "@app/assets/icons/portal/device_icon.svg";
import findingWatch from "@app/assets/icons/portal/finding_watch_icon.svg";
import polarBlue from "@app/assets/icons/portal/polar_blue_icon.svg";
import polarWhite from "@app/assets/icons/portal/polar_white_icon.svg";
import BatteryIndicator from "./BatteryIndicator";
import SignalIndicator from "./SignalIndicator";
import StatusBadge from "./StatusBadge";
import DeleteModal from "@app/components/Modal/DeleteModal";
import "./index.scss";

interface IConfigureModalProps {
  show: boolean;
  onHide: () => void;
  type: "watch" | "ring";
}

const ConfigureModal = ({ show, onHide, type }: IConfigureModalProps) => {
  const [activeTab, setActiveTab] = useState<"general" | "setting">("general");
  const [activeSetting, setActiveSetting] = useState<"default" | "find" | "calibration" | "restore">("default");
  const [isReject, setIsReject] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    deleteText: "",
  });

  const activeSettingLabel =
    activeSetting === "calibration"
      ? "Blood Oxygen Calibration"
      : activeSetting === "find"
      ? "Find Watch"
      : "Restore factory setting";

  const handleSelectTab = (tab: "general" | "setting") => {
    if (tab === "general") setActiveSetting("default");
    setActiveTab(tab);
  };

  const handleClose = () => {
    onHide();
    setActiveTab("general");
    setActiveSetting("default");
  };

  const handleOpenModal = (title: string, content: string, deleteText: string) => {
    setModalContent({ title, content, deleteText });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered className="width-720px">
      <Modal.Body className="d-flex p-0 rounded-3" style={{ height: "650px" }}>
        {/* Sidebar */}
        <Col md={3} className="p-3 d-flex flex-column gap-3 bg-grey-904 rounded-3">
          <div className="d-flex align-items-center gap-2">
            <img src={type === "watch" ? watchConfigure : ringConfigure} alt="device-icon" />
            <div className="d-flex flex-column justify-content-start gap-1">
              <div className="font-14px fw-normal">Configure Device</div>
              <span className="font-10px fw-normal text-grey-901">Device configuration</span>
            </div>
          </div>
          <Nav defaultActiveKey="general" className="flex-column">
            <Nav.Item>
              <Nav.Link
                eventKey="general"
                onClick={() => handleSelectTab("general")}
                className={`${activeTab === "general" ? "bg-grey-908" : ""} rounded-3`}
              >
                <div className="d-flex align-items-center gap-1">
                  <img src={type === "watch" ? watch : ring} alt="device" />
                  <div className="font-12px fw-normal text-dark">General</div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="setting"
                onClick={() => handleSelectTab("setting")}
                className={`${activeTab === "setting" ? "bg-grey-908" : ""} rounded-3`}
              >
                <div className="d-flex align-items-center gap-1">
                  <img src={setting} alt="setting" />
                  <div className="font-12px fw-normal text-dark">Setting</div>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <CloseButton style={{ position: "absolute", top: "16px", right: "16px" }} onClick={handleClose} />
        </Col>

        {/* Body */}
        <Col md={9} className="p-3 rounded-3">
          {activeTab === "general" ? (
            <div className="d-flex flex-column align-items-start justify-content-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <img src={type === "watch" ? watch : ring} alt="device-head" />
                <div className="font-12px fw-normal text-dark">General</div>
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <div className="d-flex align-items-center gap-3">
                  <img src={type === "watch" ? watchImage : ringImage} alt="watch-image" height={70} width={70} />
                  <div className="d-flex flex-column">
                    <div className="font-14px fw-bold text-dark">{`${type === "watch" ? "Watch" : "Ring"}`} MyMonx</div>
                    <span className="font-10px fw-normal text-grey-901">
                      {`${type === "watch" ? "Watch" : "Ring"}`} Model 1.2.34XII
                    </span>
                  </div>
                </div>
              </div>
              <div className="line" />
              <div className="d-flex flex-column align-items-start w-100 gap-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Device type</div>
                  <div className="font-12px fw-bold text-dark">MyMonX {`${type === "watch" ? "Watch" : "Ring"}`}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Battery</div>
                  <BatteryIndicator level={20} />
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Signal</div>
                  <SignalIndicator strength={5} />
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">IMEI Number</div>
                  <div className="font-12px fw-bold text-dark">1227VD9380890_970vbuc</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Serial No.</div>
                  <div className="font-12px fw-bold text-dark">1227VD9380890_970vbuc</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">MNC</div>
                  <div className="font-12px fw-bold text-dark">1227VD9380890_970vbuc</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">MCC</div>
                  <div className="font-12px fw-bold text-dark">1227VD9380890_970vbuc</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">ICCID</div>
                  <div className="font-12px fw-bold text-dark">1227VD9380890_970vbuc</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Device model</div>
                  <div className="font-12px fw-bold text-dark">12.3.4 vnbg</div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="font-12px fw-normal text-grey-901">Status</div>
                  <StatusBadge status="Online" />
                </div>
              </div>
              <div className="line" />
              <div className="d-flex justify-content-end align-items-center w-100 gap-3">
                <Button
                  className="border-very-high py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                  variant="outline"
                  onClick={() =>
                    handleOpenModal(
                      "Unassign device",
                      "Selected device will be unassigned. You can always change this action by assign device to user.",
                      "Unassign"
                    )
                  }
                >
                  <p className="font-12px fw-normal my-0 text-very-high">Un-assign</p>
                </Button>
                <Button
                  className="bg-very-high border-very-high py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                  onClick={() =>
                    handleOpenModal(
                      "Disable device",
                      "Selected device will be disabled. This actions can't be undo",
                      "Disable"
                    )
                  }
                >
                  <p className="font-12px fw-normal my-0 text-white">Disable</p>
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-start justify-content-center gap-3">
              <div className="d-flex flex-column justify-content-center gap-1">
                <div className="d-flex align-items-center gap-1">
                  <img src={setting} alt="device-head" />
                  <div className="font-12px fw-normal text-dark">Setting</div>
                </div>
                {activeSetting !== "default" && (
                  <div className="d-flex align-items-center gap-1">
                    <span
                      onClick={() => setActiveSetting("default")}
                      className="font-12px fw-normal text-grey-901 cursor-pointer"
                    >
                      Setting /{" "}
                    </span>
                    <div className="font-12px fw-normal text-dark">{activeSettingLabel}</div>
                  </div>
                )}
              </div>
              {activeSetting === "default" && (
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-3">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="font-12px fw-normal text-dark">Reject unidentified caller</div>
                    <Form.Check type="switch" checked={isReject} onChange={() => setIsReject(!isReject)} />
                  </div>
                  <div className="line" />
                  <div
                    className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                    onClick={() => setActiveSetting("calibration")}
                  >
                    <div className="font-12px fw-normal text-dark">Blood Oxygen Calibration</div>
                    <img src={caretRight} alt="caret-right" />
                  </div>
                  <div className="line" />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="font-12px fw-normal text-dark">Current position</div>
                    <div className="font-12px fw-bold text-blue-902">Request</div>
                  </div>
                  <div className="line" />
                  <div
                    className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                    onClick={() => setActiveSetting("find")}
                  >
                    <div className="font-12px fw-normal text-dark">Find Watch</div>
                    <img src={caretRight} alt="caret-right" />
                  </div>
                  <div className="line" />
                  <div
                    className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                    onClick={() => setActiveSetting("restore")}
                  >
                    <div className="font-12px fw-normal text-dark">Restore factory setting</div>
                    <img src={caretRight} alt="caret-right" />
                  </div>
                  <div className="line" />
                  <div className="d-flex justify-content-end align-items-center w-100 gap-3">
                    <Button
                      className="border-blue-902 py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                      variant="outline"
                      onClick={() => setActiveSetting("default")}
                    >
                      <p className="font-12px fw-normal my-0 text-blue-902">Restart</p>
                    </Button>
                    <Button
                      className="bg-very-high border-very-high py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                      onClick={() =>
                        handleOpenModal("Shut down", "Are you sure you want to shut down your device now?", "Shut down")
                      }
                    >
                      <p className="font-12px fw-normal my-0 text-white">Shutdown</p>
                    </Button>
                  </div>
                </div>
              )}
              {activeSetting === "calibration" && (
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-3">
                  <div className="d-flex flex-column gap-2 w-100">
                    <span className="font-12px text-dark">Blood Oxygen Calibration</span>
                    <div className="d-flex flex-column">
                      <span className="font-10px text-dark">Calibration</span>
                      <InputGroup className="border rounded-3 border-0 bg-grey-904 align-items-center">
                        <Form.Control
                          className="user-input-color border-0 font-12px py-2"
                          type="text"
                          name="calibration"
                          placeholder="Enter calibration"
                          value=""
                        />
                        <span className="font-12px text-grey-901 pe-2">mmol/L</span>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center w-100 gap-3">
                    <Button
                      className="border-blue-902 py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                      variant="outline"
                      onClick={() => setActiveSetting("default")}
                    >
                      <p className="font-12px fw-normal my-0 text-dark">Cancel</p>
                    </Button>
                    <Button
                      className="bg-blue-902 border-blue-902 py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                      onClick={() => setActiveSetting("default")}
                    >
                      <p className="font-12px fw-normal my-0 text-white">Save</p>
                    </Button>
                  </div>
                </div>
              )}
              {activeSetting === "find" && (
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-3">
                  <div className="d-flex align-items-start justify-content-between w-100">
                    <div className="d-flex align-items-center gap-2">
                      <img src={!isCalling ? findWatch : findingWatch} alt="find-watch" />
                      <div className="d-flex flex-column justify-content-start gap-1">
                        <div className="d-flex align-items-center gap-2">
                          <div className="font-12px fw-normal text-dark">727102A21</div>
                          <div style={{ width: 6, height: 6, backgroundColor: "#00BD91", borderRadius: "1000px" }} />
                        </div>
                        <span className="font-12px fw-normal text-grey-901">603 West Street, Norwich, NR63 3PH</span>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center bg-grey-909 px-2 py-1"
                      style={{ borderRadius: "1000px" }}
                    >
                      <span className="font-12px fw-normal text-grey-901">1,2km away</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center w-100 gap-3">
                    <Button
                      className={`${
                        isCalling ? "bg-blue-902" : "bg-white"
                      } border-blue-902 py-2 px-3 rounded-3 d-flex justify-content-center align-items-center gap-3`}
                      onClick={() => setIsCalling(!isCalling)}
                    >
                      <img src={isCalling ? polarWhite : polarBlue} alt="polar-icon" />
                      <p className={`font-12px fw-normal my-0 ${isCalling ? "text-white" : "text-blue-902"}`}>{`${
                        isCalling ? "Stop calling" : "Call my watch"
                      }`}</p>
                    </Button>
                  </div>
                </div>
              )}
              {activeSetting === "restore" && (
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-3">
                  <div className="d-flex flex-column justify-content-start gap-1">
                    <div className="font-12px fw-normal text-dark">Restore factory setting</div>
                    <span className="font-12px fw-normal text-grey-901">
                      Erasing your Mac removes all the information from it.
                    </span>
                  </div>
                  <div className="d-flex justify-content-end align-items-center w-100 gap-3">
                    <Button
                      className="border-very-high py-2 px-3 rounded-3 d-flex justify-content-center align-items-center"
                      variant="outline"
                      onClick={() =>
                        handleOpenModal(
                          "Restore factory setting",
                          "Restoring manufacturer’s setting will delete all your datas permanently. Are you sure you want to proceed?",
                          "Restore"
                        )
                      }
                    >
                      <p className="font-12px fw-normal my-0 text-very-high">Restore</p>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
      </Modal.Body>
      <DeleteModal
        show={showModal}
        onHide={handleCloseModal}
        onSubmit={handleCloseModal}
        title={modalContent.title}
        content={modalContent.content}
        deleteText={modalContent.deleteText}
        contentClassName="width-360px"
      />
    </Modal>
  );
};

export default ConfigureModal;

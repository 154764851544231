import medicalIcon from "@assets/icons/medical_info_icon.svg";
import conditionIcon from "@assets/icons/medical_icon.svg";
import currentIcon from "@assets/icons/medication_icon.svg";
import { useState } from "react";

import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";

export interface UserProfile {
  avatar: string;
  email: string;
  name: string;
  date_of_birth: string;
  gender: string;
  height: string;
  weight: string;
  skin_color: string;
  skin_color_name: string;
}

const MedicalInfoCard = () => {
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const [show, setShow] = useState(false);

  const infos = [
    {
      src: conditionIcon,
      alt: "Nation Icon",
      title: "Known Medical Conditions",
      value: profile?.nationality ?? "",
    },
    {
      src: currentIcon,
      alt: "Date Icon",
      title: "Current medication",
      value: String(profile?.date_of_birth ?? ""),
    },
  ];

  const { contry_data } = useAppSelector(
    (state: RootState) => state.staticDataService
  );

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100">
        <img width={18} height={18} src={medicalIcon} alt="Identity Icon" />
        <span
          className="font-14px fw-bold d-flex flex-grow-1 ms-2"
          style={{ lineHeight: "17.09px" }}
        >
          Medical Information
        </span>
      </div>
      {/* Content */}
      <div className="border-grey-901">
        <div className="mt-3 mb-1">
          <div className="col-12 d-flex flex-row w-100" style={{ gap: "16px" }}>
            {/* {infos.map((icon, index) => (
              <div
                key={index}
                className="d-flex align-items-center rounded-3 col-6 title-chart gap-2"
              >
                <div className="rounded-5 icon-chart-custom d-flex align-items-center justify-content-center">
                  <img height={32} width={32} src={icon.src} alt={icon.alt} />
                </div>
                <div className="text-dark">
                  <div className="d-flex flex-column">
                    <span className="font-12px text-grey-901 text-truncate">
                      {icon.title}
                    </span>
                    <p className="my-0 font-10px text-black whitespace-nowrap overflow-hidden text-truncate">
                      <p className="my-0">{icon.value}</p>
                    </p>
                  </div>
                </div>
              </div>
            ))} */}

            <div className="col">
              <div className="text-dark">
                <div className="d-flex flex-column">
                  <span className="font-12px fw-medium text-black text-truncate">
                    Known Medical Conditions
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate mb-1">
                    12/01/2024
                  </span>
                  <span className="font-12px fw-normal text-black text-truncate">
                    &#x2022; Steatotic liver disease
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate mb-1">
                    Type I
                  </span>
                  <span className="font-12px fw-normal text-black text-truncate">
                    &#x2022; Diabetes
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate">
                    Type II
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div className="text-dark">
                <div className="d-flex flex-column">
                  <span className="font-12px fw-medium text-black text-truncate">
                    Current medication
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate mb-1">
                    12/01/2024
                  </span>
                  <span className="font-12px fw-normal text-black text-truncate">
                    &#x2022; Hydrochloride
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate mb-1">
                    Twice daily
                  </span>
                  <span className="font-12px fw-normal text-black text-truncate">
                    &#x2022; Hydrochloride
                  </span>
                  <span className="font-12px text-grey-901 fw-normal text-truncate">
                    Twice daily
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalInfoCard;

import React from "react";
class SkinItem {
  key: string;
  color: string;
  constructor(key: string, color: string) {
    this.key = key;
    this.color = color;
  }
}
export const SkinColorItem = ({
  isSelected,
  colorKey,
  onPress,
}: {
  isSelected: boolean;
  colorKey: string;
  onPress: () => void;
}) => {
  const theme = {
    colors: {
      skins: {
        dairyCream: "#F8D2B3",
        goldSand: "#EBB68E",
        tan: "#CFA07C",
        santaFe: "#BD7852",
        ironstone: "#90493C",
        eclipse: "#3C1F1B",
      },
    },
  };

  const skinList: SkinItem[] = [
    { key: "Dairy Cream", color: theme.colors.skins.dairyCream },
    { key: "Gold Sand", color: theme.colors.skins.goldSand },
    { key: "Tan", color: theme.colors.skins.tan },
    { key: "Santa Fe", color: theme.colors.skins.santaFe },
    { key: "Ironstone", color: theme.colors.skins.ironstone },
    { key: "Eclipse", color: theme.colors.skins.eclipse },
  ];

  const selectedSkin = skinList.find(skin => skin.key === colorKey);
  const backgroundColor = selectedSkin ? selectedSkin.color : "transparent";

  return (
    <div
      onClick={onPress}
      style={{
        ...styles.container,
        borderColor: isSelected ? backgroundColor : "transparent",
      }}
    >
      <div style={{ ...styles.childrenContainer, backgroundColor }} />
    </div>
  );
};

const styles = {
  container: {
    width: "fit-content",
    padding: "2px",
    borderRadius: "50%",
    borderWidth: "1px",
    borderStyle: "solid",
    cursor: "pointer",
  },
  childrenContainer: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  },
};

import React, { useEffect, useState } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import callIcon from "@assets/icons/call_icon.svg";
import { EmergencyProps } from ".";
import ContactBlock from "./ContactBlock";
import AddContactButton from "./AddContactButton";
import { setContactList } from "@app/redux/features/v2/contact-service/contactServiceSlice";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";

export interface ActionModalProp {
  type: "add" | "update";
  show: boolean;
}

const ContactActionModal = ({
  show,
  onHide,
  type,
  contact,
}: ActionModalProp & { contact?: EmergencyProps; onHide: () => void }) => {
  const dispatch = useAppDispatch();
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const DEFAULT_CONTACT: EmergencyProps = {
    wearer_sid: profile?.sid ?? "",
    sid: profile?.sid ?? "",
    user_sid: profile?.sid ?? "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    mobile_prefix: "",
    is_sos: false,
    relationship: "",
    avatar: "",
    email_address: "",
  };
  const [contacts, setContacts] = useState<EmergencyProps[]>(
    contact ? [] : [DEFAULT_CONTACT]
  );
  const { contactList } = useAppSelector(
    (state: RootState) => state.contactServiceV2
  );

  useEffect(() => {
    if (contact) {
      setContacts([contact]);
    }
  }, [contact]);

  const addContact = () => setContacts([...contacts, DEFAULT_CONTACT]);
  const removeContact = (index: number) =>
    setContacts(contacts.filter((_, i) => i !== index));

  const updateContact = (index: number, updatedContact: EmergencyProps) => {
    const updatedContacts = [...contacts];
    updatedContacts[index] = updatedContact;
    setContacts(updatedContacts);
  };

  const onCloseModal = () => {
    onHide();
    setContacts([contact || DEFAULT_CONTACT]);
  };

  const onSubmit = () => {
    let updatedContactList = [...contactList];
    if (type === "add") {
      updatedContactList = [...contactList, ...contacts];
    } else if (type === "update" && contacts.length > 0) {
      const contactIndex = contactList.findIndex(c => c.first_name === contacts[0].first_name);
      if (contactIndex !== -1) {
        updatedContactList[contactIndex] = contacts[0];
      }
    }
    onCloseModal();
    dispatch(setContactList(updatedContactList));
  };

  return (
    <ActionModal
      show={show}
      onHide={onCloseModal}
      subTitle="Edit your emergency contact"
      title="Emergency Contact"
      size="lg"
      icon={callIcon}
      onSubmit={onSubmit}
    >
      {type === "add" ? (
        <div className="row">
          {contacts.map((contact, index) => (
            <ContactBlock
              key={index}
              type={type}
              contact={contact}
              index={index}
              updateContact={updateContact}
              removeContact={removeContact}
            />
          ))}
          <AddContactButton addContact={addContact} />
        </div>
      ) : (
        <ContactBlock
          key={0}
          type={type}
          contact={contacts[0]}
          index={0}
          updateContact={updateContact}
          removeContact={removeContact}
        />
      )}
    </ActionModal>
  );
};

export default ContactActionModal;

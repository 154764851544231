import { useState, useEffect } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import moment from "moment";
import medicationIcon from "@assets/icons/medication_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import { getMedicalAlertByWearerAsync, getMedicationListAsync, postMedicationAsync, putMedicationAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { CreateUpdateMedicationDto } from "@app/redux/features/v2/medical-service/models/medication-result-dto";
import { hasMissingFields } from "@app/utils/helper";
import MedicationBlock from "./MedicationBlock";

const MedicationModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const dispatch = useAppDispatch();
  const { medicationRequest, medicationList } = useAppSelector((state: RootState) => state.medicalServiceV2);
  const { user_profile: profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);

  const DEFAULT_DATA: CreateUpdateMedicationDto[] = [
    { medication: "", dosage: "", start_date: "", end_date: "", wearer_sid: profile?.sid ?? "", sid: "" },
  ]

  useEffect(() => {
    dispatch(getMedicationListAsync())
  }, [dispatch])

  const [medications, setMedications] = useState(DEFAULT_DATA);

  const fieldsToCheck: (keyof CreateUpdateMedicationDto)[] = [
    "medication",
    "dosage",
    "start_date",
    "end_date",
  ];
  
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (dataSelected && type === "update") {
      setMedications([
        {
          medication: dataSelected.medication ?? "",
          dosage: dataSelected.dosage ?? "",
          start_date: dataSelected.start_date ?? "",
          end_date: dataSelected.end_date ?? "",
          wearer_sid: profile?.sid ?? "",
          sid: dataSelected.sid ?? ""
        },
      ]);
    }
    else {
      setMedications(DEFAULT_DATA);
    }
  }, [dataSelected, type, profile?.sid]);

  const onAddAnother = () => {
    setMedications([
      ...medications,
      DEFAULT_DATA[0],
    ]);
  };

  const onDelete = (index: number) => {
    setMedications(medications.filter((_, i) => i !== index));
  };

  const onUpdate = (index: number, updatedMedications: CreateUpdateMedicationDto) => {
    const updatedMedication = [...medications];
    updatedMedication[index] = updatedMedications;
    setMedications(updatedMedication);
  };

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setMedications(DEFAULT_DATA);
  }

  const onSubmit = async () => {
    try {
      if (type === "add") {
        const emptyContacts = medications.filter((item) =>
          hasMissingFields(item, fieldsToCheck)
        );

        if (emptyContacts.length > 0) {
          setIsError(true);
          return;
        }

        const requests = medications.map((medication) =>
          dispatch(
            postMedicationAsync({
              ...medication,
              start_date: moment(medication.start_date).format("YYYY-MM-DD"),
              end_date: moment(medication.end_date).format("YYYY-MM-DD"),
            })
          )
        );

        const responses = await Promise.all(requests);
        const success = responses.every((res) => res.payload);

        if (success) {
          onCloseModal();
          dispatch(getMedicalAlertByWearerAsync(medicationRequest));
        }
      } else {
        const value = medications[0];

        if (hasMissingFields(value, fieldsToCheck)) {
          setIsError(true);
          return;
        }

        const res = await dispatch(
          putMedicationAsync({
            ...value,
            start_date: moment(value.start_date).format("YYYY-MM-DD"),
            end_date: moment(value.end_date).format("YYYY-MM-DD"),
          })
        );

        if (res) {
          onCloseModal();
          dispatch(getMedicalAlertByWearerAsync(medicationRequest));
        }
      }
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Customize the contents of your medication"
      show={show}
      onHide={onCloseModal}
      title="Add Medication"
      size="lg"
      icon={medicationIcon}
      onSubmit={onSubmit}
    >
      {type === "add" ? (
        <div className="row">
          {medications.map((_, index) => (
            <MedicationBlock
              isError={isError}
              key={index}
              medication={medications[index]}
              medicationList={medicationList}
              index={index}
              update={onUpdate}
              remove={onDelete}
            />
          ))}
          <AddAnotherButton onAddAnother={onAddAnother} />
        </div>
      ) : (
        <MedicationBlock
          isError={isError}
          key={0}
          medication={medications[0]}
          medicationList={medicationList}
          index={0}
          update={onUpdate}
          remove={onDelete}
        />
      )}
    </ActionModal>
  );
};

export default MedicationModal;

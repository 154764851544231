import { HealthStatus } from "@app/common/enums/HealthStatus";
import warningIcon from "@assets/icons/warning_icon.svg";
import lowIcon from "@assets/icons/low_icon.svg";

interface StatusBadgeType {
    healthStatus: string;
}

export default function StatusBadge({
    healthStatus
}: StatusBadgeType){
    return (
        <div>
            {healthStatus.toLowerCase() === HealthStatus.HIGH ? (
                <div
                    className="ms-2 px-1 bg-red-150 rounded-2 font-12px text-very-high fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                >
                    <img width={10} height={10} src={warningIcon} alt="icon" />
                    {healthStatus}
                </div>
            ) : null}
            {healthStatus.toLowerCase() === HealthStatus.LOW ? (
                <div
                    className="ms-2 px-1 bg-green-150 rounded-2 font-12px text-normal fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                >
                    <img width={10} height={10} src={lowIcon} alt="icon" />
                    {healthStatus}
                </div>
            ) : null}
        </div>
    )
}
export const CountryCodes = [
  { value: "+1", name: "United States" },
  { value: "+93", name: "Afghanistan" },
  { value: "+355", name: "Albania" },
  { value: "+213", name: "Algeria" },
  { value: "+1684", name: "American Samoa" },
  { value: "+376", name: "Andorra" },
  { value: "+244", name: "Angola" },
  { value: "+1264", name: "Anguilla" },
  { value: "+672", name: "Antarctica" },
  { value: "+1268", name: "Antigua and Barbuda" },
  { value: "+54", name: "Argentina" },
  { value: "+374", name: "Armenia" },
  { value: "+297", name: "Aruba" },
  { value: "+61", name: "Australia" },
  { value: "+43", name: "Austria" },
  { value: "+994", name: "Azerbaijan" },
  { value: "+1242", name: "Bahamas" },
  { value: "+973", name: "Bahrain" },
  { value: "+880", name: "Bangladesh" },
  { value: "+1246", name: "Barbados" },
  { value: "+375", name: "Belarus" },
  { value: "+32", name: "Belgium" },
  { value: "+501", name: "Belize" },
  { value: "+229", name: "Benin" },
  { value: "+1441", name: "Bermuda" },
  { value: "+975", name: "Bhutan" },
  { value: "+591", name: "Bolivia" },
  { value: "+387", name: "Bosnia and Herzegovina" },
  { value: "+267", name: "Botswana" },
  { value: "+55", name: "Brazil" },
  { value: "+246", name: "British Indian Ocean Territory" },
  { value: "+1284", name: "British Virgin Islands" },
  { value: "+673", name: "Brunei" },
  { value: "+359", name: "Bulgaria" },
  { value: "+226", name: "Burkina Faso" },
  { value: "+257", name: "Burundi" },
  { value: "+855", name: "Cambodia" },
  { value: "+237", name: "Cameroon" },
  { value: "+1", name: "Canada" },
  { value: "+238", name: "Cape Verde" },
  { value: "+1345", name: "Cayman Islands" },
  { value: "+236", name: "Central African Republic" },
  { value: "+235", name: "Chad" },
  { value: "+56", name: "Chile" },
  { value: "+86", name: "China" },
  { value: "+61", name: "Christmas Island" },
  { value: "+61", name: "Cocos Islands" },
  { value: "+57", name: "Colombia" },
  { value: "+269", name: "Comoros" },
  { value: "+243", name: "Congo (DRC)" },
  { value: "+242", name: "Congo (Republic)" },
  { value: "+682", name: "Cook Islands" },
  { value: "+506", name: "Costa Rica" },
  { value: "+385", name: "Croatia" },
  { value: "+53", name: "Cuba" },
  { value: "+357", name: "Cyprus" },
  { value: "+420", name: "Czech Republic" },
  { value: "+45", name: "Denmark" },
  { value: "+253", name: "Djibouti" },
  { value: "+1767", name: "Dominica" },
  { value: "+1", name: "Dominican Republic" },
  { value: "+593", name: "Ecuador" },
  { value: "+20", name: "Egypt" },
  { value: "+503", name: "El Salvador" },
  { value: "+240", name: "Equatorial Guinea" },
  { value: "+291", name: "Eritrea" },
  { value: "+372", name: "Estonia" },
  { value: "+251", name: "Ethiopia" },
  { value: "+500", name: "Falkland Islands" },
  { value: "+298", name: "Faroe Islands" },
  { value: "+679", name: "Fiji" },
  { value: "+358", name: "Finland" },
  { value: "+33", name: "France" },
  { value: "+594", name: "French Guiana" },
  { value: "+689", name: "French Polynesia" },
  { value: "+241", name: "Gabon" },
  { value: "+220", name: "Gambia" },
  { value: "+995", name: "Georgia" },
  { value: "+49", name: "Germany" },
  { value: "+233", name: "Ghana" },
  { value: "+350", name: "Gibraltar" },
  { value: "+30", name: "Greece" },
  { value: "+299", name: "Greenland" },
  { value: "+1473", name: "Grenada" },
  { value: "+590", name: "Guadeloupe" },
  { value: "+1671", name: "Guam" },
  { value: "+502", name: "Guatemala" },
  { value: "+224", name: "Guinea" },
  { value: "+245", name: "Guinea-Bissau" },
  { value: "+592", name: "Guyana" },
  { value: "+509", name: "Haiti" },
  { value: "+504", name: "Honduras" },
  { value: "+852", name: "Hong Kong" },
  { value: "+36", name: "Hungary" },
  { value: "+354", name: "Iceland" },
  { value: "+91", name: "India" },
  { value: "+62", name: "Indonesia" },
  { value: "+98", name: "Iran" },
  { value: "+964", name: "Iraq" },
  { value: "+353", name: "Ireland" },
  { value: "+972", name: "Israel" },
  { value: "+39", name: "Italy" },
  { value: "+1876", name: "Jamaica" },
  { value: "+81", name: "Japan" },
  { value: "+962", name: "Jordan" },
  { value: "+7", name: "Kazakhstan" },
  { value: "+254", name: "Kenya" },
  { value: "+686", name: "Kiribati" },
  { value: "+383", name: "Kosovo" },
  { value: "+965", name: "Kuwait" },
  { value: "+996", name: "Kyrgyzstan" },
  { value: "+856", name: "Laos" },
  { value: "+371", name: "Latvia" },
  { value: "+961", name: "Lebanon" },
  { value: "+266", name: "Lesotho" },
  { value: "+231", name: "Liberia" },
  { value: "+218", name: "Libya" },
  { value: "+423", name: "Liechtenstein" },
  { value: "+370", name: "Lithuania" },
  { value: "+352", name: "Luxembourg" },
  { value: "+853", name: "Macau" },
  { value: "+389", name: "Macedonia" },
  { value: "+261", name: "Madagascar" },
  { value: "+265", name: "Malawi" },
  { value: "+60", name: "Malaysia" },
  { value: "+960", name: "Maldives" },
  { value: "+223", name: "Mali" },
  { value: "+356", name: "Malta" },
  { value: "+692", name: "Marshall Islands" },
  { value: "+596", name: "Martinique" },
  { value: "+222", name: "Mauritania" },
  { value: "+230", name: "Mauritius" },
  { value: "+262", name: "Mayotte" },
  { value: "+52", name: "Mexico" },
  { value: "+691", name: "Micronesia" },
  { value: "+373", name: "Moldova" },
  { value: "+377", name: "Monaco" },
  { value: "+976", name: "Mongolia" },
  { value: "+382", name: "Montenegro" },
  { value: "+1664", name: "Montserrat" },
  { value: "+212", name: "Morocco" },
  { value: "+258", name: "Mozambique" },
  { value: "+95", name: "Myanmar" },
  { value: "+264", name: "Namibia" },
  { value: "+674", name: "Nauru" },
  { value: "+977", name: "Nepal" },
  { value: "+31", name: "Netherlands" },
  { value: "+599", name: "Netherlands Antilles" },
  { value: "+687", name: "New Caledonia" },
  { value: "+64", name: "New Zealand" },
  { value: "+505", name: "Nicaragua" },
  { value: "+227", name: "Niger" },
  { value: "+234", name: "Nigeria" },
  { value: "+683", name: "Niue" },
  { value: "+672", name: "Norfolk Island" },
  { value: "+850", name: "North Korea" },
  { value: "+1670", name: "Northern Mariana Islands" },
  { value: "+47", name: "Norway" },
  { value: "+968", name: "Oman" },
  { value: "+92", name: "Pakistan" },
  { value: "+680", name: "Palau" },
  { value: "+970", name: "Palestine" },
  { value: "+507", name: "Panama" },
  { value: "+675", name: "Papua New Guinea" },
  { value: "+595", name: "Paraguay" },
  { value: "+51", name: "Peru" },
  { value: "+63", name: "Philippines" },
  { value: "+64", name: "Pitcairn" },
  { value: "+48", name: "Poland" },
  { value: "+351", name: "Portugal" },
  { value: "+1", name: "Puerto Rico" },
  { value: "+974", name: "Qatar" },
  { value: "+262", name: "Réunion" },
  { value: "+40", name: "Romania" },
  { value: "+7", name: "Russia" },
  { value: "+250", name: "Rwanda" },
  { value: "+590", name: "Saint Barthélemy" },
  { value: "+290", name: "Saint Helena" },
  { value: "+1869", name: "Saint Kitts and Nevis" },
  { value: "+1758", name: "Saint Lucia" },
  { value: "+590", name: "Saint Martin" },
  { value: "+508", name: "Saint Pierre and Miquelon" },
  { value: "+1784", name: "Saint Vincent and the Grenadines" },
  { value: "+685", name: "Samoa" },
  { value: "+378", name: "San Marino" },
  { value: "+239", name: "São Tomé and Príncipe" },
  { value: "+966", name: "Saudi Arabia" },
  { value: "+221", name: "Senegal" },
  { value: "+381", name: "Serbia" },
  { value: "+248", name: "Seychelles" },
  { value: "+232", name: "Sierra Leone" },
  { value: "+65", name: "Singapore" },
  { value: "+421", name: "Slovakia" },
  { value: "+386", name: "Slovenia" },
  { value: "+677", name: "Solomon Islands" },
  { value: "+252", name: "Somalia" },
  { value: "+27", name: "South Africa" },
  { value: "+82", name: "South Korea" },
  { value: "+211", name: "South Sudan" },
  { value: "+34", name: "Spain" },
  { value: "+94", name: "Sri Lanka" },
  { value: "+249", name: "Sudan" },
  { value: "+597", name: "Suriname" },
  { value: "+47", name: "Svalbard and Jan Mayen" },
  { value: "+268", name: "Swaziland" },
  { value: "+46", name: "Sweden" },
  { value: "+41", name: "Switzerland" },
  { value: "+963", name: "Syria" },
  { value: "+886", name: "Taiwan" },
  { value: "+992", name: "Tajikistan" },
  { value: "+255", name: "Tanzania" },
  { value: "+66", name: "Thailand" },
  { value: "+228", name: "Togo" },
  { value: "+690", name: "Tokelau" },
  { value: "+676", name: "Tonga" },
  { value: "+1868", name: "Trinidad and Tobago" },
  { value: "+216", name: "Tunisia" },
  { value: "+90", name: "Turkey" },
  { value: "+993", name: "Turkmenistan" },
  { value: "+1649", name: "Turks and Caicos Islands" },
  { value: "+688", name: "Tuvalu" },
  { value: "+256", name: "Uganda" },
  { value: "+380", name: "Ukraine" },
  { value: "+971", name: "United Arab Emirates" },
  { value: "+44", name: "United Kingdom" },
  { value: "+1", name: "United States" },
  { value: "+598", name: "Uruguay" },
  { value: "+998", name: "Uzbekistan" },
  { value: "+678", name: "Vanuatu" },
  { value: "+379", name: "Vatican" },
  { value: "+58", name: "Venezuela" },
  { value: "+84", name: "Vietnam" },
  { value: "+681", name: "Wallis and Futuna" },
  { value: "+967", name: "Yemen" },
  { value: "+260", name: "Zambia" },
  { value: "+263", name: "Zimbabwe" },
];

export const UniqueCountryCodes = [
  { value: "+1" },
  { value: "+93" },
  { value: "+355" },
  { value: "+213" },
  { value: "+1684" },
  { value: "+376" },
  { value: "+244" },
  { value: "+1264" },
  { value: "+672" },
  { value: "+1268" },
  { value: "+54" },
  { value: "+374" },
  { value: "+297" },
  { value: "+61" },
  { value: "+43" },
  { value: "+994" },
  { value: "+1242" },
  { value: "+973" },
  { value: "+880" },
  { value: "+1246" },
  { value: "+375" },
  { value: "+32" },
  { value: "+501" },
  { value: "+229" },
  { value: "+1441" },
  { value: "+975" },
  { value: "+591" },
  { value: "+387" },
  { value: "+267" },
  { value: "+55" },
  { value: "+246" },
  { value: "+1284" },
  { value: "+673" },
  { value: "+359" },
  { value: "+226" },
  { value: "+257" },
  { value: "+855" },
  { value: "+237" },
  { value: "+238" },
  { value: "+1345" },
  { value: "+236" },
  { value: "+235" },
  { value: "+56" },
  { value: "+86" },
  { value: "+57" },
  { value: "+269" },
  { value: "+243" },
  { value: "+242" },
  { value: "+682" },
  { value: "+506" },
  { value: "+385" },
  { value: "+53" },
  { value: "+357" },
  { value: "+420" },
  { value: "+45" },
  { value: "+253" },
  { value: "+1767" },
  { value: "+593" },
  { value: "+20" },
  { value: "+503" },
  { value: "+240" },
  { value: "+291" },
  { value: "+372" },
  { value: "+251" },
  { value: "+500" },
  { value: "+298" },
  { value: "+679" },
  { value: "+358" },
  { value: "+33" },
  { value: "+594" },
  { value: "+689" },
  { value: "+241" },
  { value: "+220" },
  { value: "+995" },
  { value: "+49" },
  { value: "+233" },
  { value: "+350" },
  { value: "+30" },
  { value: "+299" },
  { value: "+1473" },
  { value: "+590" },
  { value: "+1671" },
  { value: "+502" },
  { value: "+224" },
  { value: "+245" },
  { value: "+592" },
  { value: "+509" },
  { value: "+504" },
  { value: "+852" },
  { value: "+36" },
  { value: "+354" },
  { value: "+91" },
  { value: "+62" },
  { value: "+98" },
  { value: "+964" },
  { value: "+353" },
  { value: "+972" },
  { value: "+39" },
  { value: "+1876" },
  { value: "+81" },
  { value: "+962" },
  { value: "+7" },
  { value: "+254" },
  { value: "+686" },
  { value: "+383" },
  { value: "+965" },
  { value: "+996" },
  { value: "+856" },
  { value: "+371" },
  { value: "+961" },
  { value: "+266" },
  { value: "+231" },
  { value: "+218" },
  { value: "+423" },
  { value: "+370" },
  { value: "+352" },
  { value: "+853" },
  { value: "+389" },
  { value: "+261" },
  { value: "+265" },
  { value: "+60" },
  { value: "+960" },
  { value: "+223" },
  { value: "+356" },
  { value: "+692" },
  { value: "+596" },
  { value: "+222" },
  { value: "+230" },
  { value: "+262" },
  { value: "+52" },
  { value: "+691" },
  { value: "+373" },
  { value: "+377" },
  { value: "+976" },
  { value: "+382" },
  { value: "+1664" },
  { value: "+212" },
  { value: "+258" },
  { value: "+95" },
  { value: "+264" },
  { value: "+674" },
  { value: "+977" },
  { value: "+31" },
  { value: "+599" },
  { value: "+687" },
  { value: "+64" },
  { value: "+505" },
  { value: "+227" },
  { value: "+234" },
  { value: "+683" },
  { value: "+850" },
  { value: "+1670" },
  { value: "+47" },
  { value: "+968" },
  { value: "+92" },
  { value: "+680" },
  { value: "+970" },
  { value: "+507" },
  { value: "+675" },
  { value: "+595" },
  { value: "+51" },
  { value: "+63" },
  { value: "+48" },
  { value: "+351" },
  { value: "+974" },
  { value: "+40" },
  { value: "+250" },
  { value: "+290" },
  { value: "+1869" },
  { value: "+1758" },
  { value: "+508" },
  { value: "+1784" },
  { value: "+685" },
  { value: "+378" },
  { value: "+239" },
  { value: "+966" },
  { value: "+221" },
  { value: "+381" },
  { value: "+248" },
  { value: "+232" },
  { value: "+65" },
  { value: "+421" },
  { value: "+386" },
  { value: "+677" },
  { value: "+252" },
  { value: "+27" },
  { value: "+82" },
  { value: "+211" },
  { value: "+34" },
  { value: "+94" },
  { value: "+249" },
  { value: "+597" },
  { value: "+268" },
  { value: "+46" },
  { value: "+41" },
  { value: "+963" },
  { value: "+886" },
  { value: "+992" },
  { value: "+255" },
  { value: "+66" },
  { value: "+228" },
  { value: "+690" },
  { value: "+676" },
  { value: "+1868" },
  { value: "+216" },
  { value: "+90" },
  { value: "+993" },
  { value: "+1649" },
  { value: "+688" },
  { value: "+256" },
  { value: "+380" },
  { value: "+971" },
  { value: "+44" },
  { value: "+598" },
  { value: "+998" },
  { value: "+678" },
  { value: "+379" },
  { value: "+58" },
  { value: "+84" },
  { value: "+681" },
  { value: "+967" },
  { value: "+260" },
  { value: "+263" },
];

import { MedicalAlertRequestDto } from "@app/redux/features/v2/medical-service/models/medical-alert-request-dto";
import { MedicalAlertDto, CreateUpdateAlertDto } from "@app/redux/features/v2/medical-service/models/medical-alert-result-dto";
import { ConditionResultDto, CreateUpdateConditionDto } from "@app/redux/features/v2/medical-service/models/condition-result-dto";
import { MedicationResultDto, CreateUpdateMedicationDto } from "@app/redux/features/v2/medical-service/models/medication-result-dto";
import { ConditionRequestDto } from "@app/redux/features/v2/medical-service/models/condition-request-dto";
import { MedicationRequestDto } from "@app/redux/features/v2/medical-service/models/medication-request-dto";
import { config } from "@services/config";
import { createOrganisationPortalApi } from "@services/organisation-portal-api";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";


const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);


const getMedication = (medicationId: string) => {
    return organisationPortalApi.get<MedicationResultDto>(`/api/v2/medical/medication?medication_sid=${medicationId}`);
}

const getMedicationByWearer = (request: MedicationRequestDto) => {
    const filter = request.filter_by ? `filter_by=${request.filter_by}` : '';
    const wearer_sid = `wearer_sid=${request.wearer_sid === undefined ? '' : request.wearer_sid}`
    return organisationPortalApi.get<PaginatedResult<MedicationResultDto>>(`/api/v2/medical/medication/by-wearer?${wearer_sid}&page_number=${request.page_number}&page_size=${request.page_size}&${filter}`);
}

const postMedication = (medication: CreateUpdateMedicationDto) => {
    return organisationPortalApi.post<string>(`/api/v2/medical/medication`, medication);
}

const putMedication = (medication: CreateUpdateMedicationDto) => {
    return organisationPortalApi.put<string>(`/api/v2/medical/medication`, medication);
}

const deleteMedication = (medication_sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v2/medical/medication?medication_sid=${medication_sid}`);
}

const getMedicationList = () => {
    return organisationPortalApi.get<string[]>(`/api/v2/medical/medication/medication-list`);
}

const getCondition = (conditionId: string) => {
    return organisationPortalApi.get<ConditionResultDto>(`/api/v2/medical/condition?condition_sid=${conditionId}`);
}

const getConditionByWearer = (request: ConditionRequestDto) => {
    const filter = request.filter_by ? `filter_by=${request.filter_by}` : '';
    const wearer_sid = `wearer_sid=${request.wearer_sid === undefined ? '' : request.wearer_sid}`
    return organisationPortalApi.get<PaginatedResult<ConditionResultDto>>(`/api/v2/medical/condition/by-wearer?${wearer_sid}&page_number=${request.page_number}&page_size=${request.page_size}&${filter}`);
}

const postCondition = (condition: CreateUpdateConditionDto) => {
    return organisationPortalApi.post<string>(`/api/v2/medical/condition`, condition);
}

const putCondition = (condition: CreateUpdateConditionDto) => {
    return organisationPortalApi.put<string>(`/api/v2/medical/condition`, condition);
}

const deleteCondition = (condition_sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v2/medical/condition?condition_id=${condition_sid}`);
}

const getAlert = (alert_sid: string) => {
    return organisationPortalApi.get<MedicalAlertDto>(`/api/v2/medical/alert/?alert_sid=${alert_sid}`);
}

const getAlertByWearer = (request: MedicalAlertRequestDto) => {
    const filter = request.filter_by ? `filter_by=${request.filter_by}` : '';
    const wearer_sid = `wearer_sid=${request.wearer_sid === undefined ? '' : request.wearer_sid}`
    return organisationPortalApi.get<PaginatedResult<MedicalAlertDto>>(`/api/v2/medical/alert/by-wearer?${wearer_sid}&page_number=${request.page_number}&page_size=${request.page_size}&${filter}`);
}

const postAlert = (payload: CreateUpdateAlertDto) => {
    return organisationPortalApi.post<string>(`/api/v2/medical/alerts`, payload);
}

const putAlert = (payload: CreateUpdateAlertDto) => {
    return organisationPortalApi.put<string>(`/api/v2/medical/alerts`, payload);
}

const deleteAlert = (alert_sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v2/medical/alert/${alert_sid}`);
}

const MedicalServiceApi = {
    getMedication,
    getMedicationByWearer,
    postMedication,
    putMedication,
    deleteMedication,
    getMedicationList,
    getCondition,
    getConditionByWearer,
    postCondition,
    putCondition,
    deleteCondition,
    getAlert,
    getAlertByWearer,
    postAlert,
    putAlert,
    deleteAlert
}

export default MedicalServiceApi;

import React from "react";
import analysisIcon from "@assets/icons/analysis-icon.svg";
import image from "@assets/images/stethoscope.png";
import { ECGType } from "@app/common/enums/ECGType";
import { ECGDetail } from "@app/common/enums/ECGType";
import moment from "moment";

interface SummaryCardProps {
  summaryData: {
    label: string;
    value: string;
    type: ECGType;
  }[];
  date?: Date;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ summaryData, date }) => {
  return (
    <div className="bg-white p-3 h-100 rounded-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-6 title-chart">
          <div className="">
            <img className="img-fluid" src={analysisIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Summary</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {date ? moment(date).format("DD MMM YYYY") : ""}</b>
            </p>
          </div>
        </div>
      </div>

      <div className="p-2">
        <div className="d-flex mt-0 h-100">
          <div className="col-10 d-flex flex-column justify-content-between">
            {summaryData.map((data, index) => (
              <div key={index} className="mt-2">
                <div className="d-flex flex-row align-items-end w-100">
                  <div className="col-5 font-12px text-grey-901 whitespace-nowrap overflow-hidden text-truncate mb-2">
                    {data.label}
                  </div>
                  <div className="d-flex flex-row align-items-end">
                    <span className="font-28px fw-bold text-black">{data.value}</span>
                    <div className="d-flex flex-column ms-1">
                      <img src={ECGDetail.getIcon(data.type)} width={14} height={14} alt="icon" />
                      <span className="font-12px text-grey-901 mb-2">{ECGDetail.getUnit(data.type)}</span>
                    </div>
                  </div>
                </div>
                {index !== summaryData.length - 1 && (
                  <div className="w-75 mt-2" style={{ height: "1px", backgroundColor: "#F7F7F7" }}></div>
                )}
              </div>
            ))}
          </div>
          <div className="col-2 position-relative">
            <div className="h-75 position-relative">
              <div className="position-absolute h-100 end-0 top-0">
                <img src={image} alt="" className="h-75" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;

import { PageAndFilterRequestDto } from "@redux/common/models/page-and-filter-request-dto";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";
import { ClientDto, ClientsRequest } from "@redux/features/v2/client-service/models/client-dto";
import { CreateClientDto } from "@redux/features/v2/client-service/models/create-client-dto";
import { CreateClientResultDto } from "@redux/features/v2/client-service/models/create-client-result-dto";
import { createOrganisationPortalApi } from "../organisation-portal-api";
import { config } from "../config";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getClients = (request: ClientsRequest) => {
  const filter = `&filter_by=${
    request.filter_by === undefined ? "" : request.filter_by
  }`;
  const org_id = `org_id=${request.org_id === undefined ? '' : request.org_id}`

  return organisationPortalApi.get<PaginatedResult<ClientDto>>(
    `/api/v2/auth/clients/by-organization?${org_id}&page=${request.page_number}&per_page=${request.page_size}${filter}`
  );
};

const getClientStats = () => {
  return organisationPortalApi.get<any>(`/api/v0/clients/stats`);
};

const getClient = (id: string) => {
  return organisationPortalApi.get<ClientDto>(`/api/v0/clients/${id}`);
};

const createClient = (client: CreateClientDto) => {
  return organisationPortalApi.post<CreateClientResultDto>(
    "/api/v0/clients",
    client
  );
};

const updateClient = (client: CreateClientDto) => {
  return organisationPortalApi.put<CreateClientResultDto>(
    "/api/v0/clients",
    client
  );
};

const deleteClient = (sid: string) => {
  return organisationPortalApi.delete<boolean>(`/api/v0/clients/${sid}`);
};

const ClientServiceApi = {
  getClients,
  getClientStats,
  getClient,
  createClient,
  updateClient,
  deleteClient,
};

export default ClientServiceApi;

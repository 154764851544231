import BasicReportCard from "./widgets/Monthly.BasicReportCard";
import CaloIcon from "@app/assets/icons/calo_icon.svg";
import DistanceIcon from "@app/assets/icons/distance_icon.svg";
import TakenIcon from "@assets/icons/taken_icon.svg";
import DetailReportCard from "./widgets/Monthly.DetailReportCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import DetailSleepReportCard from "./widgets/Monthly.DetailSleepReportCard";
import { Toast, ToastBody } from "react-bootstrap";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { getMonthlyReportsAsync } from "@app/redux/features/v2/report-service/reportServiceThunk";
import { RootState } from "@app/redux/store";

const generateRandomValue = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const sleepSummaryData = Array.from({ length: 31 }, (_, i) => ({
  date: i + 1,
  value1: generateRandomValue(1, 4),
  value2: generateRandomValue(1, 4),
}));

const MonthlyReports = () => {
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const [showNavbarToast, setShowNavbarToast] = useState(true);
  const dispatch = useAppDispatch();
  const { monthlyReportsResult } = useAppSelector(
    (state: RootState) => state.reportServiceV2
  );
  const [bloodType, setBloodType] = useState<"BPD" | "BPS">("BPS");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (profile?.sid) {
      dispatch(getMonthlyReportsAsync(profile.sid));
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (monthlyReportsResult) {
      setLoading(false);
    }
  }, [monthlyReportsResult]);

  return (
    <div>
      <Toast
        show={showNavbarToast}
        style={{
          boxShadow: "none",
          width: "100%",
          borderWidth: 0,
          backgroundColor: "#00BD91",
          borderRadius: 0,
        }}
      >
        <ToastBody className="container-xl d-flex justify-content-between text-white pe-0">
          <span className="font-14px ms-2 fw-medium">
            This report has been put together with the help of qualified medical
            doctors.
          </span>
          <IoClose
            type="button"
            size={24}
            onClick={() => setShowNavbarToast(false)}
          />
        </ToastBody>
      </Toast>
      <main className="monthly-reports-container container min-vh-100">
        <div className="d-flex flex-column gap-3">
          <div className="mt-5">
            <p className="font-20px mb-0 fw-bold">Monthly Reports</p>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column flex-lg-row col-12 ">
              <div className="col-12 col-lg-5">
                <div className="d-flex flex-column ">
                  <p className="font-14px text-grey-901 mb-1">
                    <span className="text-dark fw-bold">At mymonX</span>, the
                    well-being of our members is a top priority. We understand
                    that leading a healthy lifestyle can have a positive impact
                    on both physical and mental health. Therefore, we are
                    committed to promotinghealthy habits and behaviours among
                    our members.
                  </p>
                  <p className="font-14px text-grey-901 mb-1">
                    Keep <span className="text-dark fw-bold">working hard</span>{" "}
                    to improve these stats by the{" "}
                    <span className="text-dark fw-bold">next report!</span>
                  </p>
                  <p className="font-14px text-grey-901 ">
                    Let's take a look at your vitals during this month now!
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <p className="font-14px text-grey-901">
                  While using your{" "}
                  <span className="text-dark fw-bold">mymonX</span> you have...
                </p>
                <div className="d-flex flex-wrap flex-md-nowrap flex-row gap-2">
                  <BasicReportCard
                    value={monthlyReportsResult?.time_used ?? 0}
                    unit="hours"
                  />
                  <BasicReportCard
                    icon={CaloIcon}
                    title="Burned"
                    value={monthlyReportsResult?.calories ?? 0}
                    unit="kcal"
                  />
                  <BasicReportCard
                    icon={TakenIcon}
                    title="Taken"
                    value={monthlyReportsResult?.steps ?? 0}
                    unit="steps"
                  />
                  <BasicReportCard
                    icon={DistanceIcon}
                    title="and walked"
                    value={monthlyReportsResult?.distance ?? 0}
                    unit="kms"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.GLUCOSE}
                value={{
                  minimum: monthlyReportsResult?.BLOOD_GLUCOSE?.min ?? 0,
                  average: monthlyReportsResult?.BLOOD_GLUCOSE?.avg ?? 0,
                  maximum: monthlyReportsResult?.BLOOD_GLUCOSE?.max ?? 0,
                }}
                breakdownValues={{
                  high: monthlyReportsResult?.BLOOD_GLUCOSE?.high ?? 0,
                  veryHigh: monthlyReportsResult?.BLOOD_GLUCOSE?.very_high ?? 0,
                }}
                monthlySummaryData={
                  monthlyReportsResult?.BLOOD_GLUCOSE?.monthly
                }
                dailySummaryData={monthlyReportsResult?.BLOOD_GLUCOSE?.hourly}
                upperThreshold={11}
                lowerThreshold={6}
                yAxisDomain={[3, 11]}
                yAxisTicks={[2, 4, 6, 8, 10, 12]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.BLOOD_PRESSURE}
                bloodType={bloodType}
                selectBloodType={setBloodType}
                value={
                  bloodType === "BPD"
                    ? {
                        minimum:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPD?.min ?? 0,
                        average:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPD?.avg ?? 0,
                        maximum:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPD?.max ?? 0,
                      }
                    : {
                        minimum:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPS?.min ?? 0,
                        average:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPS?.avg ?? 0,
                        maximum:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPS?.max ?? 0,
                      }
                }
                breakdownValues={
                  bloodType === "BPD"
                    ? {
                        low: monthlyReportsResult?.BLOOD_PRESSURE_BPD?.low ?? 0,
                        normal:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPD?.normal ?? 0,
                        high:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPD?.high ?? 0,
                      }
                    : {
                        low: monthlyReportsResult?.BLOOD_PRESSURE_BPS?.low ?? 0,
                        normal:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPS?.normal ?? 0,
                        high:
                          monthlyReportsResult?.BLOOD_PRESSURE_BPS?.high ?? 0,
                      }
                }
                monthlySummaryData={
                  bloodType === "BPD"
                    ? monthlyReportsResult?.BLOOD_PRESSURE_BPD?.monthly
                    : monthlyReportsResult?.BLOOD_PRESSURE_BPS?.monthly
                }
                dailySummaryData={
                  bloodType === "BPD"
                    ? monthlyReportsResult?.BLOOD_PRESSURE_BPD?.hourly
                    : monthlyReportsResult?.BLOOD_PRESSURE_BPS?.hourly
                }
                upperThreshold={135}
                lowerThreshold={100}
                yAxisDomain={[95, 140]}
                yAxisTicks={[20, 40, 60, 80, 100, 120, 140]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.HEART_RATE}
                value={{
                  minimum: monthlyReportsResult?.HEART_RATE?.min ?? 0,
                  average: monthlyReportsResult?.HEART_RATE?.avg ?? 0,
                  maximum: monthlyReportsResult?.HEART_RATE?.max ?? 0,
                }}
                breakdownValues={{
                  low: monthlyReportsResult?.HEART_RATE?.low ?? 0,
                  normal: monthlyReportsResult?.HEART_RATE?.normal ?? 0,
                  high: monthlyReportsResult?.HEART_RATE?.high ?? 0,
                }}
                monthlySummaryData={monthlyReportsResult?.HEART_RATE?.monthly}
                dailySummaryData={monthlyReportsResult?.HEART_RATE?.hourly}
                upperThreshold={104}
                lowerThreshold={48}
                yAxisDomain={[46, 112]}
                yAxisTicks={[15, 30, 45, 60, 75, 90, 105, 120]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.OXYGEN}
                value={{
                  minimum: monthlyReportsResult?.BLOOD_OXYGEN?.min ?? 0,
                  average: monthlyReportsResult?.BLOOD_OXYGEN?.avg ?? 0,
                  maximum: monthlyReportsResult?.BLOOD_OXYGEN?.max ?? 0,
                }}
                breakdownValues={{
                  low: monthlyReportsResult?.BLOOD_OXYGEN?.low ?? 0,
                  normal: monthlyReportsResult?.BLOOD_OXYGEN?.normal ?? 0,
                  high: monthlyReportsResult?.BLOOD_OXYGEN?.high ?? 0,
                }}
                monthlySummaryData={monthlyReportsResult?.BLOOD_OXYGEN?.monthly}
                dailySummaryData={monthlyReportsResult?.BLOOD_OXYGEN?.hourly}
                lowerThreshold={94}
                yAxisDomain={[86, 100]}
                yAxisTicks={[20, 40, 60, 80, 100]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.RESPIRATORY_RATE}
                value={{
                  minimum: monthlyReportsResult?.RESPIRATORY_RATE?.min ?? 0,
                  average: monthlyReportsResult?.RESPIRATORY_RATE?.avg ?? 0,
                  maximum: monthlyReportsResult?.RESPIRATORY_RATE?.max ?? 0,
                }}
                breakdownValues={{
                  low: monthlyReportsResult?.RESPIRATORY_RATE?.low ?? 0,
                  normal: monthlyReportsResult?.RESPIRATORY_RATE?.normal ?? 0,
                  high: monthlyReportsResult?.RESPIRATORY_RATE?.high ?? 0,
                }}
                monthlySummaryData={
                  monthlyReportsResult?.RESPIRATORY_RATE?.monthly
                }
                dailySummaryData={
                  monthlyReportsResult?.RESPIRATORY_RATE?.hourly
                }
                lowerThreshold={16}
                yAxisDomain={[9, 22]}
                yAxisTicks={[5, 10, 15, 20, 25]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailReportCard
                type={VitalType.TEMPERATURE}
                value={{
                  minimum: monthlyReportsResult?.BODY_TEMPERATURE?.min ?? 0,
                  average: monthlyReportsResult?.BODY_TEMPERATURE?.avg ?? 0,
                  maximum: monthlyReportsResult?.BODY_TEMPERATURE?.max ?? 0,
                }}
                breakdownValues={{
                  low: monthlyReportsResult?.BODY_TEMPERATURE?.low ?? 0,
                  normal: monthlyReportsResult?.BODY_TEMPERATURE?.normal ?? 0,
                  high: monthlyReportsResult?.BODY_TEMPERATURE?.high ?? 0,
                }}
                monthlySummaryData={
                  monthlyReportsResult?.BODY_TEMPERATURE?.monthly
                }
                dailySummaryData={
                  monthlyReportsResult?.BODY_TEMPERATURE?.hourly
                }
                lowerThreshold={16}
                yAxisDomain={[9, 22]}
                yAxisTicks={[5, 10, 15, 20, 25, 30, 35, 40]}
              />
            </div>
            <div className="d-flex flex-row col-12 ">
              <DetailSleepReportCard
                type={VitalType.SLEEP}
                wearingMymonX={120}
                value={{
                  minimum: "90",
                  average: "95",
                  maximum: "100",
                }}
                breakdownValues={{
                  low: 20,
                  normal: 30,
                  high: 40,
                }}
                summaryData={sleepSummaryData}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MonthlyReports;


import { DeviceByUserIdDto, DeviceDto } from "@redux/features/device-service/models/DeviceDto";
import { DeviceApiResponse, DeviceLiveInfo, DeviceResultDto } from "@redux/features/device-service/models/DeviceResultDto";
import { MapDeviceDto } from "@redux/features/device-service/models/MapDeviceDto";
import { MapDeviceResultDto } from "@redux/features/device-service/models/MapDeviceResultDto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);



const AssignDevice = (map_device: MapDeviceDto) => {
    return organisationPortalApi.put<MapDeviceResultDto>("/api/v0/device/assign", map_device);
}
const UnAssignDevice = (imei: string) => {
    return organisationPortalApi.put<MapDeviceResultDto>(`/api/v0/device/unassign/${imei}`);
}
const DisableDevice = (imei: string) => {
    return organisationPortalApi.put<MapDeviceResultDto>(`/api/v0/device/disable/${imei}`);
}
const GetDevices = (map_device: DeviceDto) => {
    return organisationPortalApi.get<DeviceApiResponse>(`/api/v0/devices/${map_device.org_id}`);
}
const GetDeviceByUserId = (map_device: DeviceByUserIdDto) => {
    return organisationPortalApi.get<DeviceResultDto>(`/api/v0/device/user/${map_device.user_id}`);
}
const Getliveinfo = (imei: string) => {
    return organisationPortalApi.get<DeviceLiveInfo>(`/api/v0/device/live-info?device_imei=${imei}`);
}
const CreateDeviceOtp = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/device/otp/${imei}`);
}
const AssignDeviceOtp = (otp: string, data: MapDeviceDto) => {
    return organisationPortalApi.put<string>(`/api/v0/device/otp/${otp}`,data);
}
const DeviceServiceApi = {
    AssignDevice,
    UnAssignDevice,
    DisableDevice,
    GetDevices,
    GetDeviceByUserId,
    Getliveinfo,
    CreateDeviceOtp,
    AssignDeviceOtp
}

export default DeviceServiceApi;
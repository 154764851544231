import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

interface SubNavProps {
  navLinks: {
    label: string;
    path: string;
  }[];
}
const SubNav = ({ navLinks }: SubNavProps) => {
  return (
    <div>
      <nav className="sub-nav d-none d-lg-flex navbar navbar-expand-lg navbar-light bg-white text-dark p-0">
        <div className="container-fluid justify-content-center">
          <div className="row w-100 align-items-center">
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav w-100">
                <div className="d-flex flex-grow-1 justify-content-center">
                  {navLinks.map((link) => (
                    <NavLink
                      className={({ isActive }) =>
                        ` nav-link px-3 py-1 ${isActive ? "active" : ""}`
                      }
                      aria-current="page"
                      to={link.path}
                      key={link.path}
                    >
                      <p className="font-12px mb-0">
                        {link.label}
                      </p>
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SubNav;

import React from "react";
import plusBlueIcon from "@assets/icons/plus_blue.svg";

const AddAnotherButton = ({ onAddAnother }: { onAddAnother: () => void }) => {
  return (
    <div className="d-flex justify-content-start cursor-pointer">
      <div onClick={onAddAnother} className="py-2 d-flex align-items-center mt-1">
        <img src={plusBlueIcon} alt="Plus" />
        <p className="ms-1 my-0 font-12px progress-bar text-blue-902 fw-normal">
          Add another
        </p>
      </div>
    </div>
  );
};

export default AddAnotherButton; 
import { useEffect, useCallback } from "react";
import moment from "moment";
import { useAppDispatch } from "@app/redux/store/useStore";
import { setVitalsGraphRequest } from "@app/redux/features/v2/health-service/healthServiceSlice";
import { getVitalsGraphAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const useVitalsGraph = (userId: string, vitalType: string) => {
  const dispatch = useAppDispatch();

  const calculateDateRange = useCallback((period: string): DateRange => {
    const currentMoment = moment().endOf("day");
    let start: Date;
    let end: Date;
    switch (period) {
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        start = moment().startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
        start = currentMoment
          .clone()
          .subtract(6, "days")
          .startOf("day")
          .toDate();
        end = currentMoment.clone().startOf("day").toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
        start = moment().clone().subtract(1, "month").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
        start = moment().clone().subtract(5, "months").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
        start = moment().clone().subtract(1, "year").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      default:
        start = currentMoment.clone().startOf("day").toDate();
        end = currentMoment.toDate();
    }

    return { startDate: start, endDate: end };
  }, []);

  const fetchVitalsGraph = useCallback(
    (period: string, customDateRange?: DateRange) => {
      if (!userId) return;

      const dateRange = customDateRange || calculateDateRange(period);

      const request = {
        userId,
        vital_type: vitalType,
        graphType: period,
        startDate: moment(dateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss"),
      };

      dispatch(setVitalsGraphRequest({ vital_type: vitalType, request }));
      dispatch(getVitalsGraphAsync(request));
    },
    [userId, vitalType, calculateDateRange, dispatch]
  );

  const fetchCustomRange = useCallback(
    (startDate: Date, endDate: Date) => {
      fetchVitalsGraph("RANGE", { startDate, endDate });
    },
    [fetchVitalsGraph]
  );

  useEffect(() => {
    fetchVitalsGraph("DAY");
  }, [fetchVitalsGraph]);

  return {
    fetchVitalsGraph,
    fetchCustomRange,
    calculateDateRange,
  };
};

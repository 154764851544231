import phoneIcon from "@assets/icons/phone_call.svg";
import avatar from "@assets/icons/complete-profile/default_profile_image.svg";
import plusBlueIcon from "@assets/icons/plus_blue.svg";
import { Button, Dropdown } from "react-bootstrap";
import ContactActionModal, { ActionModalProp } from "./ContactActionModal";
import { useState } from "react";
import menuIcon from "@assets/icons/menu_icon.svg";
import editIcon from "@assets/icons/edit_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import DeleteModal from "@app/components/Modal/DeleteModal";
import callIcon from "@assets/icons/call_icon.svg";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setContactList } from "@app/redux/features/contact-service/contactServiceSlice";
import {
  deleteContactsAsync,
  getContactsAsync,
  updateContactsAsync,
} from "@app/redux/features/v2/contact-service/contactServiceThunk";
import { constant } from "lodash";

export interface EmergencyProps {
  sid: string;
  wearer_sid: string;
  user_sid: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  mobile_prefix: string;
  is_sos: boolean;
  // more
  relationship: string;
  avatar: string;
  email_address: string;
}

const ContactCard = () => {
  const dispatch = useAppDispatch();
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const DEFAULT_CONTACT: EmergencyProps = {
    wearer_sid: profile?.sid ?? "",
    sid: profile?.sid ?? "",
    user_sid: profile?.sid ?? "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    mobile_prefix: "",
    is_sos: false,
    // more
    relationship: "",
    avatar: "",
    email_address: "",
  };

  const [selectedContact, setSelectedContact] = useState<EmergencyProps>();
  const { contactList } = useAppSelector(
    (state: RootState) => state.contactServiceV2
  );

  const [showModifyDialog, setShowModifyDialog] = useState<ActionModalProp>({
    show: false,
    type: "add",
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onShowModifyModal = (
    contact = DEFAULT_CONTACT,
    type: "add" | "update"
  ) => {
    setSelectedContact(contact);
    setShowModifyDialog({
      show: true,
      type: type,
    });
  };

  const onCloseModifyModal = (type: "add" | "update") => {
    setShowModifyDialog({
      show: false,
      type: type,
    });
    setSelectedContact(DEFAULT_CONTACT);
  };

  const onShowDeleteModal = (contact: EmergencyProps) => {
    setSelectedContact(contact);
    setShowDeleteDialog(true);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteDialog(false);
    setSelectedContact(DEFAULT_CONTACT);
  };

  const onDelete = (sid: string) => {
    dispatch(deleteContactsAsync(sid)).then((res) => {
      if (res.payload) {
        dispatch(getContactsAsync(profile?.sid ?? ""));
        onCloseDeleteModal();
      }
    });
  };

  const onSaveData = () => {
    dispatch(updateContactsAsync(contactList)).then((res) => {
      if (res.payload) {
        dispatch(getContactsAsync(profile?.sid ?? ""));
      }
    });
  };

  const renderContact = (contact: EmergencyProps, index: number) => {
    return (
      <div key={index} className="d-flex justify-content-between title-chart">
        <div className="d-flex flex-row align-items-center">
          <div className="icon-chart-custom d-flex align-items-center justify-content-center">
            <img
              height={32}
              width={32}
              src={
                contact?.avatar
                  ? `data:image/png;base64,${contact.avatar}`
                  : avatar
              }
              alt="Icon"
              className="rounded-circle"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="ps-1 text-dark">
            <div className="d-flex flex-column">
              <span className="font-12px fw-bold text-dark">
                {contact.first_name} {contact.last_name}
                {contact.is_sos ? (
                  <span
                    className="ms-1 text-blue-901 bg-white-100 rounded-3"
                    style={{ padding: "2px 6px" }}
                  >
                    SOS
                  </span>
                ) : (
                  ""
                )}
              </span>
              <p className="my-0 font-10px text-grey-901 whitespace-nowrap overflow-hidden text-truncate">
                <p className="my-0">
                  {contact?.relationship ?? "No role data"}{" "}
                  <span className="ms-2">
                    {contact?.email_address ?? "no_email_data@gmail.com"}
                  </span>
                  <span className="ms-2">
                    {contact?.mobile_prefix} {contact?.mobile_number ?? ""}
                  </span>
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center cursor-pointer"></div>
        <div className="d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="bg-transparent border-0 p-0"
            >
              <img height={16} width={16} src={menuIcon} alt="icon" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-custom">
              <Dropdown.Item
                href="#-1"
                className="d-flex align-items-center"
                onClick={() => onShowModifyModal(contact, "update")}
              >
                <img height={16} width={16} src={editIcon} alt="icon" />
                <span className="ms-1 font-12px fw-bold">Edit</span>
              </Dropdown.Item>

              <Dropdown.Item
                href="#-1"
                className="d-flex align-items-center"
                onClick={() => onShowDeleteModal(contact)}
              >
                <img height={16} width={16} src={deleteIcon} alt="icon" />
                <span className="ms-1 font-12px fw-bold">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
        <img width={18} height={18} src={phoneIcon} alt="icon" />
        <span
          className="font-14px fw-bold d-flex flex-grow-1 ms-2"
          style={{ lineHeight: "17.09px" }}
        >
          Contacts
        </span>
        {/* Add button */}
        <div className="d-flex justify-content-start cursor-pointer">
          <div
            className="d-flex align-items-center"
            onClick={() => onShowModifyModal(DEFAULT_CONTACT, "add")}
          >
            <img src={plusBlueIcon} alt="Plus" />
            <p
              className="ms-1 my-0 font-12px progress-bar text-blue-902 fw-normal"
              style={{ lineHeight: "14.65px" }}
            >
              Add
            </p>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="mt-3 mb-3">
        <div className="border-grey-901 ">
          <div
            className="d-flex flex-column justify-content-between align-items-s w-100"
            style={{ gap: "16px" }}
          >
            {contactList.map((item: any, index) => renderContact(item, index))}
          </div>
        </div>
      </div>

      <Button
        className="rounded-3 w-100 text-white px-3 d-flex justify-content-center align-items-center no-hover bg-blue-901 border-blue-901"
        onClick={onSaveData}
      >
        <p className="ms-1 my-0 font-12px progress-bar">Save</p>
      </Button>

      <ContactActionModal
        show={showModifyDialog.show}
        type={showModifyDialog.type}
        onHide={() => onCloseModifyModal("add")}
        contact={selectedContact}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteDialog}
        onHide={onCloseDeleteModal}
        onSubmit={() => onDelete(selectedContact?.sid || "")}
        title="Contact"
        icon={callIcon}
        content="Are you sure you want to remove this record?"
      />
    </div>
  );
};

export default ContactCard;

import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useCallback, useEffect, useState } from "react";
import HistoryRecordCard from "@components/HistoryRecordCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import SummaryCard from "./widgets/Steps.SummaryCard";
import AnalysisCard from "./widgets/Steps.AnalysisCard";
import BreadcrumbNavigation from "@app/components/Breadcrumb/BreadcrumbNavigation";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import moment from "moment";
import { getStepsVitalsGraphAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";
import { StepsDataDto } from "@app/redux/features/v2/health-service/models/steps-data-dto";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

type SummaryChartData = {
  date: string;
  value: number;
};

type SummaryData = {
  totalSteps: number;
  avgDailySteps: number;
  itemReachGoal: number;
  averageStepsMonthly: number;
  summaryChartData: SummaryChartData[];
};

const Steps = () => {
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<
    {
      id: number;
      healthVitals: string;
      date: string;
      time: string;
      totalSteps: string | number;
      caloriesBurned: string | number;
      distance: string | number;
      isReachGoal: boolean;
    }[]
  >([]);

  const [selectedPeriod, setSelectedPeriod] = useState(
    TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear)
  );
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const { user_profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const { stepsDataDto } = useAppSelector(
    (state: RootState) => state.healthServiceV2
  );

  const calculateDateRange = useCallback((period: string): DateRange => {
    const currentMoment = moment().endOf("day");
    let start: Date;
    let end: Date;

    switch (period) {
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        start = moment().startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
        start = currentMoment
          .clone()
          .subtract(6, "days")
          .startOf("day")
          .toDate();
        end = currentMoment.clone().startOf("day").toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
        start = moment().clone().subtract(1, "month").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
        start = moment().clone().subtract(5, "months").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
        start = moment().clone().subtract(1, "year").startOf("day").toDate();
        end = currentMoment.toDate();
        break;
      default:
        start = currentMoment.clone().startOf("day").toDate();
        end = currentMoment.toDate();
    }

    return { startDate: start, endDate: end };
  }, []);

  useEffect(() => {
    if (user_profile && selectedPeriod) {
      const dateRange = calculateDateRange(selectedPeriod);
      const defaultRequest = {
        userId: user_profile?.sid ?? "",
        vital_type: "STEPS",
        graphType: "RANGE",
        startDate: moment(dateRange.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss"),
      };
      dispatch(getStepsVitalsGraphAsync(defaultRequest));
    }
  }, [dispatch, user_profile, calculateDateRange, selectedPeriod]);

  const getFormattedTime = (isoString: string): string => {
    const date = new Date(isoString);

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const convertStepsData = (stepsData: StepsDataDto) => {
    const { data } = stepsData;

    return data.map((step, index) => {
      const date = new Date(step.step_time).toLocaleDateString();
      const time = getFormattedTime(step.step_time);
      const isReachGoal = Math.abs(step.total_steps) > 10000;

      return {
        id: index + 1,
        healthVitals: "Steps",
        time,
        date,
        totalSteps: Math.abs(step.total_steps) ?? 0,
        caloriesBurned: Math.abs(step.total_calories) ?? 0,
        distance: Math.abs(step.total_distance) ?? 0,
        isReachGoal,
      };
    });
  };

  const convertSummaryData = (
    stepsData: StepsDataDto,
    period: string
  ): {
    totalSteps: number;
    avgDailySteps: number;
    itemReachGoal: number;
    averageStepsMonthly: number;
    summaryChartData: Array<{ date: string; value: number }>;
  } => {
    const { data } = stepsData;

    if (!data || data.length === 0) {
      return {
        totalSteps: 0,
        avgDailySteps: 0,
        itemReachGoal: 0,
        averageStepsMonthly: 0,
        summaryChartData: [],
      };
    }

    const cleanData = data.map((step) => ({
      ...step,
      totalSteps: Math.abs(step.total_steps ?? 0),
    }));

    const totalSteps = cleanData.reduce(
      (sum, step) => sum + (step.total_steps ?? 0),
      0
    );

    const uniqueDays = new Set(
      cleanData.map((step) => step.step_time.substring(0, 10))
    ).size;
    const avgDailySteps = totalSteps / (uniqueDays || 1);

    const uniqueMonths = new Set(
      cleanData.map((step) => step.step_time.substring(0, 7))
    ).size;
    const averageStepsMonthly = totalSteps / (uniqueMonths || 1);

    const itemReachGoal = cleanData.filter(
      (step) => step.total_steps > 10000
    ).length;

    let summaryChartData: Array<{ date: string; value: number }> = [];

    switch (period) {
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        summaryChartData = Array.from({ length: 24 }, (_, i) => {
          const hour = i.toString().padStart(2, "0");
          const value = cleanData
            .filter((step) =>
              step.step_time.startsWith(
                `${step.step_time.substring(0, 10)}T${hour}`
              )
            )
            .reduce((sum, step) => sum + (step.total_steps ?? 0), 0);
          return { date: `${hour}:00`, value };
        });
        break;

      case TimePeriodHelper.getTimePeriodJson(TimePeriod.week): {
        const shortDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        const today = (new Date().getDay() + 6) % 7; // Convert Sunday (0) -> Monday (0)

        // Reorder days of the week starting from the next day of today
        const reorderedDaysOfWeek = [
          ...shortDays.slice(today + 1), // Days from the next day of today to the end of the week
          ...shortDays.slice(0, today + 1), // Days from the start of the week to today
        ];

        // Generate chart data
        summaryChartData = reorderedDaysOfWeek.map((day, index) => {
          const actualDay = (today + index + 1) % 7; // Adjust day index
          const value = cleanData
            .filter((step) => {
              const stepDay = (new Date(step.step_time).getDay() + 6) % 7; // Adjust step_time to match our day indexing
              return stepDay === actualDay; // Compare the day
            })
            .reduce((sum, step) => sum + (step.total_steps ?? 0), 0);
          return { date: day, value };
        });
        break;
      }

      case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
        summaryChartData = Array.from({ length: 31 }, (_, i) => {
          const day = (i + 1).toString().padStart(2, "0");
          const value = cleanData
            .filter(
              (step) =>
                step.step_time.substring(0, 10) ===
                `${step.step_time.substring(0, 7)}-${day}`
            )
            .reduce((sum, step) => sum + (step.total_steps ?? 0), 0);
          return { date: day, value };
        });
        break;

      case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear): {
        const currentMonth = new Date().getMonth();

        // Reorder months starting from 6 months ago up to the current month
        const reorderedMonths = Array.from({ length: 6 }, (_, i) => {
          const monthIndex = (currentMonth - 5 + i + 12) % 12;
          return moment().month(monthIndex).format("MMM");
        });

        summaryChartData = reorderedMonths.map((month, index) => {
          const monthIndex = (currentMonth - 5 + index + 12) % 12;
          const value = cleanData
            .filter(
              (step) => new Date(step.step_time).getMonth() === monthIndex
            )
            .reduce((sum, step) => sum + (step.total_steps ?? 0), 0);
          return { date: month, value };
        });
        break;
      }

      case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
        const monthsYear = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        summaryChartData = monthsYear.map((month, index) => {
          const value = cleanData
            .filter((step) => new Date(step.step_time).getMonth() === index)
            .reduce((sum, step) => sum + (step.total_steps ?? 0), 0);
          return { date: month, value };
        });
        break;

      default:
        break;
    }

    return {
      totalSteps,
      avgDailySteps,
      itemReachGoal,
      averageStepsMonthly,
      summaryChartData,
    };
  };

  const [summaryData, setSummaryData] = useState<SummaryData>();

  useEffect(() => {
    if (stepsDataDto) {
      const defaultData = convertStepsData(stepsDataDto);
      setTableData(defaultData);

      const {
        totalSteps,
        avgDailySteps,
        itemReachGoal,
        summaryChartData,
        averageStepsMonthly,
      } = convertSummaryData(stepsDataDto, selectedPeriod);

      setSummaryData({
        totalSteps,
        avgDailySteps,
        itemReachGoal,
        summaryChartData,
        averageStepsMonthly,
      });
    }
  }, [stepsDataDto, selectedPeriod]);

  const formatNumber = (num: number): string => {
    return Math.round(num).toLocaleString();
  };

  return (
    <div className="container health_insight row">
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <BreadcrumbNavigation
            analysisName={VitalType.getAnalysisName(VitalType.STEPS)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              period={selectedPeriod}
              overviewData={{
                totalSteps: formatNumber(summaryData?.totalSteps ?? 0),
                averageDailySteps: formatNumber(
                  summaryData?.avgDailySteps ?? 0
                ),
                averageStepsPerMonth: formatNumber(
                  summaryData?.averageStepsMonthly ?? 0
                ),
              }}
              summaryData={summaryData?.summaryChartData ?? []}
              unit="steps"
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              period={selectedPeriod}
              summaryData={summaryData?.summaryChartData ?? []}
              unit="steps"
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard
              type={VitalType.STEPS}
              period={selectedPeriod}
              data={tableData}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <div className="health-column">
          <div className="title-health-column">Health Column</div>
          {[1, 2, 3, 4, 5].map((index) => (
            <HealthyColumn key={index.toString()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;

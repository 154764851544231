import Table from "../Table";

interface TableProps {
  period: string;
  data?: any[];
}

const StepsTable: React.FC<TableProps> = ({ period, data }) => {
  const commonColumns = [
    {
      name: "Health vitals",
      selector: (row: any) => row.healthVitals,
    },
    {
      name: "Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
  ];

  const dailyColumns = [
    {
      name: "Time",
      selector: (row: any) => row.time,
      sortable: true,
    },
    {
      name: "Steps",
      selector: (row: any) => row.totalSteps,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.totalSteps} steps`}</span>;
      },
    },
    {
      name: "Calories burned",
      selector: (row: any) => row.caloriesBurned,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.caloriesBurned} kcal`}</span>;
      },
    },
    {
      name: "Distance",
      selector: (row: any) => row.distance,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.distance} km`}</span>;
      },
    },
    // {
    //   name: "Daily reach goal",
    //   selector: (row: any) => row.isReachGoal,
    //   sortable: true,
    //   cell: (row: any) => {
    //     const isReachGoal = row.isReachGoal;
    //     const color = isReachGoal ? "normal" : "very-high"
    //     const context = isReachGoal ? "Reached goal" : "Not reached goal"

    //     return (
    //       <span
    //         style={{ display: "flex", alignItems: "center" }}
    //         className="fw-medium"
    //       >
    //         <span className={`fw-medium fs-3 me-2 text-${color}`}>
    //           &#x2022;
    //         </span>
    //         <span className={`fw-medium text-${color} whitespace-nowrap overflow-hidden text-truncate`}>{context}</span>
    //       </span>
    //     )
    //   }
    // }
  ];

  const weeklyMonthlyColumns = [
    {
      name: "Total steps",
      selector: (row: any) => row.totalSteps,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.totalSteps} steps`}</span>;
      },
    },
    {
      name: "Calories burned",
      selector: (row: any) => row.caloriesBurned,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.caloriesBurned} kcal`}</span>;
      },
    },
    {
      name: "Distance",
      selector: (row: any) => row.distance,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.distance} km`}</span>;
      },
    },
    {
      name: "Daily reach goal",
      selector: (row: any) => row.isReachGoal,
      sortable: true,
      cell: (row: any) => {
        const isReachGoal = row.isReachGoal;
        const color = isReachGoal ? "normal" : "very-high";
        const context = isReachGoal ? "Reached goal" : "Not reached goal";

        return (
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="fw-medium"
          >
            <span className={`fw-medium fs-3 me-2 text-${color}`}>
              &#x2022;
            </span>
            <span
              className={`fw-medium text-${color} whitespace-nowrap overflow-hidden text-truncate`}
            >
              {context}
            </span>
          </span>
        );
      },
    },
  ];

  const sixMonthsYearlyColumns = [
    {
      name: "Total steps",
      selector: (row: any) => row.totalSteps,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.totalSteps} steps`}</span>;
      },
    },
    {
      name: "Calories burned",
      selector: (row: any) => row.caloriesBurned,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.caloriesBurned} kcal`}</span>;
      },
    },
    {
      name: "Distance",
      selector: (row: any) => row.distance,
      sortable: true,
      cell: (row: any) => {
        return <span>{`${row.distance} km`}</span>;
      },
    },
    {
      name: "Daily reach goal",
      selector: (row: any) => row.isReachGoal,
      sortable: true,
      cell: (row: any) => {
        const isReachGoal = row.isReachGoal;
        const color = isReachGoal ? "normal" : "very-high";
        const context = isReachGoal ? "Reached goal" : "Not reached goal";

        return (
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="fw-medium"
          >
            <span className={`fw-medium fs-3 me-2 text-${color}`}>
              &#x2022;
            </span>
            <span
              className={`fw-medium text-${color} whitespace-nowrap overflow-hidden text-truncate`}
            >
              {context}
            </span>
          </span>
        );
      },
    },
  ];

  const columns = (() => {
    switch (period) {
      case "DAY":
        return commonColumns.concat(dailyColumns);
      case "WEEK":
      case "MONTH":
        return commonColumns.concat(weeklyMonthlyColumns);
      case "6MONTH":
      case "YEAR":
        return commonColumns.concat(sixMonthsYearlyColumns);
      default:
        return commonColumns;
    }
  })();

  return (
    <div className="row">
      <Table columns={columns} data={data ?? []} />
    </div>
  );
};

export default StepsTable;

import React from "react";

interface RangesType {
  range: number[];
  flex: number;
  color: string;
  label: string;
}

interface ProgressContainerProps {
  progress: number;
  ranges: RangesType[];
  name: string;
  colorName: string;
}

const ProgressContainer: React.FC<ProgressContainerProps> = ({
  progress,
  ranges,
  name,
  colorName,
}) => {
  const getMarkerPosition = () => {
    const totalFlex = ranges.reduce((sum, range) => sum + range.flex, 0);
    let cumulativeFlex = 0;

    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      const [min, max] = range.range;

      if (progress <= max) {
        const rangeFlex = range.flex;
        const rangeWidth = (rangeFlex / totalFlex) * 100;
        const rangeProgress = ((progress - min) / (max - min)) * rangeWidth;
        return (cumulativeFlex / totalFlex) * 100 + rangeProgress;
      }

      cumulativeFlex += range.flex;
    }

    return 100;
  };

  const labelPositions = ranges.reduce(
    (acc, range, index) => {
      const cumulativeFlex = acc.cumulativeFlex + range.flex;
      const position = (acc.cumulativeFlex / acc.totalFlex) * 100;
      acc.positions.push(position);
      acc.cumulativeFlex = cumulativeFlex;
      return acc;
    },
    {
      cumulativeFlex: 0,
      totalFlex: ranges.reduce((sum, r) => sum + r.flex, 0),
      positions: [] as number[],
    }
  ).positions;

  const markerPosition = getMarkerPosition();
  const shouldHideNow = labelPositions.some(
    (labelPosition) => Math.abs(markerPosition - labelPosition) < 10
  );

  return (
    <div className="position-relative">
      <div className="d-flex justify-content-between mb-4 position-relative">
        {
          ranges.reduce(
            (acc, range, index) => {
              const cumulativeFlex = acc.cumulativeFlex + range.flex;
              const position = (acc.cumulativeFlex / acc.totalFlex) * 100;
              const labelWidth = 30;
              const adjustedPosition = Math.min(
                Math.max(position, labelWidth / 2),
                100 - labelWidth / 2
              );

              acc.elements.push(
                <div
                  key={index}
                  className="fw-bold"
                  style={{
                    position: "absolute",
                    left: range.range[0] === 0 ? "0%" : `${adjustedPosition}%`,
                    fontSize: "12px",
                    transform: "translateX(-50%)",
                  }}
                >
                  {range.range[0]}
                </div>
              );
              acc.cumulativeFlex = cumulativeFlex;
              return acc;
            },
            {
              cumulativeFlex: 0,
              totalFlex: ranges.reduce((sum, r) => sum + r.flex, 0),
              elements: [] as React.ReactNode[],
            }
          ).elements
        }
      </div>

      <div
        style={{
          position: "absolute",
          left: `${Math.min(Math.max(markerPosition, 0), 100)}%`,
          top: "0px",
          marginBottom: "0",
          transform: "translateX(-50%)",
        }}
      >
        <div className="d-flex flex-column align-items-center">
          <div
            className={`font-12px fw-bold text-${colorName}`}

            style={{
              marginRight: "4px",
              visibility: shouldHideNow ? "hidden" : "visible",
            }}
          >
            {name}
          </div>

          <div
            style={{
              width: "9.6px",
              height: "5.2px",
              backgroundColor: "white",
              transform: "rotate(180deg)",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderBottom: "5px solid #777E8F",
            }}
          />
        </div>
      </div>

      <div
        className="d-flex align-items-center"
        style={{ height: "24px", position: "relative", marginBottom: "4px" }}
      >
        {ranges.map((range, index) => (
          <React.Fragment key={index}>
            {index === 0 && (
              <div
                style={{
                  width: "1px",
                  height: "24px",
                  backgroundColor: "#ECECEC",
                  position: "absolute",
                  left: "0%",
                }}
              />
            )}
            <div
              className={`bg-${range.color}`}
              style={{
                flex: range.flex,
                height: "14px",
                borderRadius:
                  index === 0
                    ? "14px 0 0 14px"
                    : index === ranges.length - 1
                    ? "0 14px 14px 0"
                    : "0",
              }}
            />
            <div
              style={{  
                width: "1px",
                height: "24px",
                backgroundColor: "#ECECEC",
              }}
            />
          </React.Fragment>
        ))}
      </div>

      <div className="d-flex justify-content-between mb-2">
        {ranges.map((range, index) => (
          <div key={index} style={{ flex: range.flex, textAlign: "center" }}>
            <span className="text-grey-901" style={{ fontSize: "12px" }}>
              {range.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressContainer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { StepDataResponse } from "./models/steps-graph-dto";
import { VitalsGraphRequestDto } from "../health-service/models/graph-request-dto";
import ActivityServiceApi from "@app/services/activity-service";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";



export const getActivityGraphAsync = createAsyncThunk<StepDataResponse, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
    "activityService/getActivityGraph",
    async (credentials, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ActivityServiceApi.getActivityGraph(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
interface BasicCardProps {
  title?: string;
  icon?: string;
  value?: number;
  unit?: string;
  period?: string;
}

const BasicReportCard: React.FC<BasicCardProps> = (props: BasicCardProps) => {
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Header */}
      <div className="d-flex rounded-3 col-6 title-chart gap-2 align-items-center">
        <div className="rounded-5 icon-chart-custom">
          <img height={32} width={32} src={props.icon} alt="Icon" />
        </div>
        <div className="text-dark">
          <span className="font-14px fw-bold text-dark">{props.title}</span>
          <p className="font-10px text-grey-901 m-0 text-nowrap">
            Display data on
            <b className="fw-normal text-dark"> {props.period}</b>
          </p>
        </div>
      </div>

      {/* Content */}
      <div className="border-grey-901">
        <div className="mt-2 mb-1">
          <div className="w-100">
            <p className="font-28px fw-medium mb-0">
              {props.value}
              <span className="ms-1 font-10px text-grey-901">{props.unit}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicReportCard;

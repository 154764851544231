import SubNav from "@app/components/Nav/Subnav";
import { Outlet } from "react-router-dom";

const navLinks = [
  {
    label: "Monthly Reports",
    path: "/portal-new/clients/client-detail/:id/monthly-reports",
  },
  {
    label: "Health Insights",
    path: "/portal-new/clients/client-detail/:id/health-insights",
  },
  {
    label: "Health Summary",
    path: "/portal-new/clients/client-detail/:id/health-summary",
  },
  {
    label: "Medical",
    path: "/portal-new/clients/client-detail/:id/medical",
  },
  {
    label: "Alerts & Health Incidents",
    path: "/portal-new/clients/client-detail/:id/alerts-health-incidents",
  },
];
const ClientDetail = () => {
  return (
    <>
      <SubNav navLinks={navLinks} />
      <Outlet />
    </>
  );
};
export default ClientDetail;

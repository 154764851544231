import { Popup } from "react-map-gl/maplibre";

export const LockerPopup = ({
  locker: { latitude, longitude, title, description },
  onClose,
}: {
  locker: {
    latitude: number;
    longitude: number;
    title: string;
    description: string;
  };
  onClose: () => void;
}) => {
  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      // offset={[0, -36]}
      closeButton
      closeOnClick
      onClose={onClose}
      anchor="bottom"
    >
      <>
        <h3>{title}</h3>
        <p>{description}</p>
      </>
    </Popup>
  );
};

import { BpGraphDto, EcgResponseDto, GlucoseGraphDto, HrGraphDto, OxygenGraphDto, RespiratoryRateGraphDto, TempGraphDto, VitalsGraphDto, VitalsSummaryDto } from "@redux/features/health-service/models/bp-graph-dto";
import { GlucoseStatsDto } from "@redux/features/health-service/models/glucose-stats-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api"

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);


const getGlucoseStats = (userId: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<GlucoseStatsDto>(`/api/v0/health/body_glucose/stats/${userId}`);
}


const getBpGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<BpGraphDto>(`/api/v0/health/blood_pressure_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getOxygenGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<OxygenGraphDto>(`/api/v0/health/oxygen_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getHrGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<HrGraphDto>(`/api/v0/health/heart_rates_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getTempGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<TempGraphDto>(`/api/v0/health/temp_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getGlucoseGraph = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<GlucoseGraphDto>(`/api/v0/health/glucose_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getRespiratoryRateGraph = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<RespiratoryRateGraphDto>(`/api/v0/health/respiratory_rate_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}
const getVitals = (user_profile_sid: string, vital_type: string, graphType: string, startDate: string, endDate: string, timezone: string) => {
    //added timezone
    return organisationPortalApi.get<VitalsGraphDto>(`/api/v0/health/vitals?user_profile_sid=${user_profile_sid}&vital_type=${vital_type}&period_type=${graphType}&from_date=${startDate}&to_date=${endDate}&timezone=${localStorage.getItem('timezone') || 'UTC'}`);
}
const getsleepVitals = (user_profile_sid: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<VitalsGraphDto>(`/api/v0/health/sleep/data?user_profile_sid=${user_profile_sid}&period_type=${graphType}
        &from_date=${startDate}&to_date=${endDate}&timezone=${localStorage.getItem('timezone') || 'UTC'}`);
}
const getsleepMobileVitals = (user_profile_sid: string, vital_type: string) => {

    return organisationPortalApi.get<string>(`/api/v0/mobile/vital_graph?user_profile_sid=${user_profile_sid}&vital=${vital_type}`);
}
const getEcgVitals = (user_profile_sid: string) => {

    return organisationPortalApi.get<EcgResponseDto>(`/api/v0/health/vitals/ecg?user_profile_sid=${user_profile_sid}`);
}

const getVitalsSummary = (userId: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<VitalsSummaryDto[]>(`/api/v0/health/vitals-summary?user_profile_sid=${userId}`);
}



const HealthServiceApi = {
    getGlucoseStats,
    getBpGraphStats,
    getHrGraphStats,
    getOxygenGraphStats,
    getTempGraphStats,
    getGlucoseGraph,
    getRespiratoryRateGraph,
    getVitals,
    getVitalsSummary,
    getsleepVitals,
    getEcgVitals,
    getsleepMobileVitals

}

export default HealthServiceApi;
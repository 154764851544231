import { useMemo } from "react";
import { v4 } from "uuid";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Area,
  Line,
} from "recharts";
import { GraphProps } from "@app/redux/features/v2/report-service/model/monthly-report";

type Props = {
  summaryData: GraphProps[];
  upperThreshold?: number;
  lowerThreshold?: number;
  yAxisDomain?: number[];
  yAxisTicks?: number[];
  xAxisTicks?: number[];
  pointPeriod?: number;
  xAxisType?: string;
};

const getLinearGradientOffset = (
  maxValue: number,
  minValue: number,
  threshold?: number,
  offset: number = 0
) => {
  if (!threshold) return 0;
  if (maxValue <= threshold - offset) return 0;
  if (minValue >= threshold) return 1;

  return (maxValue - threshold - offset) / (maxValue - minValue);
};

const getXAxisTicksByPeriod = (ticks: number[], period?: number) => {
  if (!period) return ticks;

  return ticks.filter((tick) => (tick + 1) % period === 0);
};

const xAxisTickFormatter = (tick: any, type?: string, period?: number) => {
  if (!type && !period) return tick;

  let formattedTick = tick;

  if (period) formattedTick = (formattedTick + 1) / period;

  switch (type) {
    case "day":
      return formattedTick.toString().padStart(2, "0");
    case "time":
      return `${(formattedTick % 24).toString().padStart(2, "0")}:00`;
    default:
      return formattedTick;
  }
};

const MonthlyReportAreaChart = ({
  summaryData,
  upperThreshold,
  lowerThreshold,
  yAxisDomain,
  yAxisTicks,
  xAxisTicks,
  xAxisType,
  pointPeriod,
}: Props) => {
  const adjustedData = summaryData.map((item, index) => ({
    ...item,
    date: index ?? 1,
    value: item.mean,
    lowerValue: item["5th_percentile"],
    upperValue: item["95th_percentile"],
  }));

  const maxLowerValue = Math.max(
    ...adjustedData.map((item) => item.lowerValue)
  );
  const minLowerValue = Math.min(
    ...adjustedData.map((item) => item.lowerValue)
  );
  const maxUpperValue = Math.max(
    ...adjustedData.map((item) => item.upperValue)
  );
  const minUpperValue = Math.min(
    ...adjustedData.map((item) => item.upperValue)
  );

  const xAxisDomain = [
    adjustedData[0].date,
    adjustedData[adjustedData.length - 1].date,
  ];


  xAxisTicks = xAxisTicks ? xAxisTicks : adjustedData.map((item) => +item.date);

  const expandWidth =
    (!xAxisType && xAxisTicks.length >= 30) ||
    (xAxisType && xAxisType === "day");

  const gradientId = useMemo(() => v4(), []);

  return (
    <div className="container font-12px p-0">
      <div className="chart-container position-relative">
        <div style={{ width: expandWidth ? "180%" : "100%" }}>
          <ResponsiveContainer
            width="110%"
            height={200}
            style={{ marginLeft: "-3rem" }}
          >
            <ComposedChart data={adjustedData} margin={{ left: 15, right: 7 }}>
              <defs>
                <linearGradient
                  id={`upperAreaGradient-${gradientId}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minLowerValue,
                      upperThreshold
                    )}
                    stopColor="#FFF4F3"
                  />
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minLowerValue,
                      upperThreshold
                    )}
                    stopColor="#F4FFFD"
                  />
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minLowerValue,
                      lowerThreshold
                    )}
                    stopColor="#F4FFFD"
                  />
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minLowerValue,
                      lowerThreshold
                    )}
                    stopColor="#EEF3FF"
                  />
                </linearGradient>

                <linearGradient
                  id={`upperLineGradient-${gradientId}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minUpperValue,
                      upperThreshold,
                      0.1
                    )}
                    stopColor="#FF2D1A"
                  />
                  <stop
                    offset={getLinearGradientOffset(
                      maxUpperValue,
                      minUpperValue,
                      upperThreshold,
                      0.1
                    )}
                    stopColor="#00BD91"
                  />
                </linearGradient>

                <linearGradient
                  id={`lowerLineGradient-${gradientId}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset={getLinearGradientOffset(
                      maxLowerValue,
                      minLowerValue,
                      lowerThreshold
                    )}
                    stopColor="#00BD91"
                  />
                  <stop
                    offset={getLinearGradientOffset(
                      maxLowerValue,
                      minLowerValue,
                      lowerThreshold
                    )}
                    stopColor="#3F72F3"
                  />
                </linearGradient>
              </defs>
              <XAxis
                interval={expandWidth ? 0 : undefined}
                axisLine={false}
                tickLine={false}
                type="number"
                dataKey="date"
                fontSize={12}
                domain={xAxisDomain}
                ticks={getXAxisTicksByPeriod(xAxisTicks, pointPeriod)}
                tickFormatter={(tick) =>
                  xAxisTickFormatter(tick, xAxisType, pointPeriod)
                }
              />
              <YAxis
                interval={0}
                axisLine={false}
                tickLine={false}
                padding={{ top: 10 }}
                type="number"
                domain={yAxisDomain}
                ticks={yAxisTicks}
              />
              <CartesianGrid stroke="#F7F7F7" strokeDasharray="1 1" />
              <ReferenceLine
                y={lowerThreshold}
                stroke="#D4D8DC"
                strokeDasharray="3 3"
              />
              <ReferenceLine
                y={upperThreshold}
                stroke="#D4D8DC"
                strokeDasharray="3 3"
              />
              <Area
                type="bump"
                dataKey="value"
                strokeWidth={0}
                fill={`url(#upperAreaGradient-${gradientId})`}
              />
              <Line
                type="bump"
                dataKey="lowerValue"
                stroke={`url(#lowerLineGradient-${gradientId})`}
                dot={false}
              />
              <Line
                type="bump"
                dataKey="upperValue"
                stroke={
                  upperThreshold
                    ? `url(#upperLineGradient-${gradientId})`
                    : "#00BD91"
                }
                dot={false}
              />
              <Line
                type="bump"
                dataKey="average"
                stroke="#777E8F"
                dot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MonthlyReportAreaChart;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import SosServiceApi from "@app/services/sos-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import { addSuccessNotification } from "../common/notificationSlice";
import { ConfigurationResultDto } from "./models/configuration-result-dto";
import ConfigurationServiceApi from "@app/services/configuration-service";
import { CreateAlarmClockConfigDto, DeviceMainConfiguration, GetConfigurationByUserDto } from "./models/create-configuration-dto";



export const createConfigurationResultAsync = createAsyncThunk<string, DeviceMainConfiguration,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/createConfiguration",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ConfigurationServiceApi.createConfiguration(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

export const updateConfigurationsResultAsync = createAsyncThunk<string, DeviceMainConfiguration,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/updateConfigurations",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ConfigurationServiceApi.updateConfigurations(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const getConfigurationByUserResultAsync = createAsyncThunk<DeviceMainConfiguration, GetConfigurationByUserDto,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/getConfigurationByUser",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ConfigurationServiceApi.getConfigurationByUser(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

export const createAlarmConfigurationsResultAsync = createAsyncThunk<string, CreateAlarmClockConfigDto,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/createAlarmConfigurations",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ConfigurationServiceApi.createAlarmConfigurations(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createMedicationConfigurationsResultAsync = createAsyncThunk<string, CreateAlarmClockConfigDto,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/createMedicationConfigurations",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ConfigurationServiceApi.createMedicationConfigurations(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
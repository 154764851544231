import CaloIcon from "@app/assets/icons/calo_icon.svg";
import DistanceIcon from "@app/assets/icons/distance_icon.svg";

export interface StepDetail {
  type: StepDetailType;
  value: number;
}

export enum StepDetailType {
  ENERGY_BURNED = 'ENERGY_BURNED',
  DISTANCE = 'DISTANCE',
}

export namespace StepDetailType {
  export function getIcon(stepDetailType: StepDetailType) {
    switch (stepDetailType) {
      case StepDetailType.ENERGY_BURNED:
        return CaloIcon;
      case StepDetailType.DISTANCE:
        return DistanceIcon;

      default:
        return CaloIcon;
    }
  }
  export function getName(stepDetailType: StepDetailType) {
    switch (stepDetailType) {
      case StepDetailType.ENERGY_BURNED:
        return "Energy Burned";
      case StepDetailType.DISTANCE:
        return "Distance";

      default:
        return "Energy Burned";
    }
  }

  export function getUnit(stepDetailType: StepDetailType) {
    switch (stepDetailType) {
      case StepDetailType.ENERGY_BURNED:
        return "kcal";
      case StepDetailType.DISTANCE:
        return "km";

      default:
        return "kcal";
    }
  }
}

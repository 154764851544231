import { ECGDetail } from "@app/common/enums/ECGType";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { VitalType } from "@app/common/enums/VitalTypes";
import moment from "moment";
import React from "react";
import { CardContainer } from "./CardContainer";
import { FluctuationTypes } from "@app/common/enums/FluctuationTypes";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import syringeIcon from "@app/assets/icons/syringe_icon.svg";
import heartBeatIcon from "@app/assets/icons/heart_beat.svg";
interface CardContainerProps {
  vitalType: VitalType;
  currentData: number | undefined;
  currentDataSecondValue?: number | undefined;
  unit: string;
  date: string;
  dataHistory: ECGDataHistory[];
  dataDetail: ECGDetail[];
}

export interface ECGDataHistory {
  type: TimePeriod;
  heartRate?: ECGDataDetailHistory;
  secondValue?: ECGDataDetailHistory;
}

export interface ECGDataDetailHistory {
  value?: number;
  increase?: FluctuationTypes;
}

export const ECGCardContainer = ({
  vitalType,
  currentData,
  unit,
  date,
  dataHistory,
  currentDataSecondValue,
  dataDetail,
}: CardContainerProps) => {
  const valueData = currentData ?? "N/A";
  const secondValueData = currentDataSecondValue ?? "N/A";
  const customBuildHistoryData = () => {
    return (
      <div className="row flex-fill">
        {dataHistory.map((e: ECGDataHistory, index: number) =>
          customBuildElementType({
            item: e,
            index: index,
          })
        )}
      </div>
    );
  };

  const customBuildElementType = ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }) => {
    return (
      <div
        className={`col-4 d-flex flex-column justify-content-end ${
          index === 1
            ? "border-grey-905 border border-top-0 border-bottom-0"
            : ""
        }`}
      >
        <div className="d-flex flex-column">
          <span className="font-12px text-grey-901">
            {TimePeriodHelper.getTimePeriodName(item.type)}
          </span>
          <div className="d-flex flex-row align-items-center">
            <img
              src={syringeIcon}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
            <span className="font-14px fw-medium mx-2 text-black">
              {item.secondValue.value ?? "N/A"}
            </span>
            <img
              src={FluctuationTypes.getIcon(item.secondValue.increase)}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
          </div>
          <div className="d-flex flex-row align-items-center">
            <img
              src={heartBeatIcon}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
            <span className="font-14px fw-medium mx-2 text-black">
              {item.heartRate.value ?? "N/A"}
            </span>
            <img
              src={FluctuationTypes.getIcon(item.heartRate.increase)}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const buildDetailECG = ({
    value,
    unit,
    icon,
  }: {
    value?: number;
    unit: string;
    icon: string;
  }) => {
    return (
      <div className="d-flex flex-row align-items-end justify-content-start">
        <span
          className="font-16px fw-medium text-black"
          style={{ marginBottom: "3px" }}
        >
          {value ?? "N/A"}
        </span>

        <img
          src={icon}
          width={14}
          height={14}
          alt="icon"
          style={{ marginBottom: "7px", marginLeft: "2px" }}
        />
        <span
          className="font-12px text-grey-901 "
          style={{ marginBottom: "5px", marginLeft: "2px" }}
        >
          {unit}
        </span>
      </div>
    );
  };

  const customBuildContent = () => {
    return (
      <>
        <div className="d-flex flex-row align-items-end">
          <span className="font-28px fw-medium text-black">
            {currentDataSecondValue !== undefined && currentData !== undefined
              ? `${valueData}/${secondValueData}`
              : "N/A"}
          </span>
          <div className="d-flex flex-column ms-1">
            <img
              src={syringeIcon} // Adjust the path as needed
              width={14}
              height={14}
              alt="icon"
            />
            <span className="font-12px text-grey-901 mb-2">
              {VitalType.getAnalysisName(vitalType)}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <span className="font-12px text-grey-901">{getTextTime()}</span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            space
          </span>
        </div>
        <div
          className="d-flex flex-row w-100"
          style={{ marginTop: "-10px", marginBottom: "10px" }}
        >
          <div className="d-flex flex-column col-8">
            {dataDetail.map((e) => (
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="font-12px text-grey-901 col-10 col-xxl-8">
                  {ECGDetail.getName(e.type)}
                </div>
                {buildDetailECG({
                  value: e.value,
                  unit: ECGDetail.getUnit(e.type),
                  icon: ECGDetail.getIcon(e.type),
                })}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const getTextTime = (): string => {
    if (date === "") {
      return "N/A";
    }
    const dateMoment = moment(date);

    return dateMoment.format("DD MMM YYYY - HH:mm:ss");
  };

  return (
    <CardContainer
      vitalType={vitalType}
      currentData={undefined}
      unit={unit}
      dataHistory={[]}
      date={date}
      customBuildElementType={customBuildHistoryData}
      customBuildContent={customBuildContent}
    />
  );
};

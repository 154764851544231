import React from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import StepDailyChart from "./Steps.DailyCircle";
import BurnIcon from "@app/assets/icons/burn_icon.svg";
import DistanceIcon from "@app/assets/icons/dis_icon.svg";
import CongratsIcon from "@app/assets/icons/congrats_icon.svg";
import RunIcon from "@app/assets/icons/run.svg";
import SummaryChart from "./Steps.AnalysisChart";
import { getTimeRange } from "@app/utils/time";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";
interface AnalysisCardProps {
  period: string;
  summaryData: { date: string; value: number }[];
  unit: string;
}

const AnalysisCard: React.FC<AnalysisCardProps> = ({
  period,
  summaryData,
  unit,
}) => {
  const renderDailyComponent = () => (
    <div className="d-flex flex-row justify-content-between my-4">
      <StepDailyChart min={10000} max={10000} />
      <div className="d-flex flex-column ms-3 justify-content-center">
        {statusInfo(10000, 10000)}
        <div
          className="w-100 mt-2"
          style={{ height: "1px", backgroundColor: "#F7F7F7" }}
        ></div>
        <div className="d-flex flex-row align-items-center gap-3 my-2">
          <InfoBlock
            label="Calories burned"
            value="560 kcal"
            icon={DistanceIcon}
          />
          <div
            className="h-100"
            style={{ width: "1px", backgroundColor: "#ECECEC" }}
          />
          <InfoBlock label="Distance" value="5,3 km" icon={BurnIcon} />
        </div>
      </div>
    </div>
  );

  const statusInfo = (min: number, max: number) => {
    return (
      <div className="d-flex flex-column">
        <span className="font-12px text-grey-901">
          You have{" "}
          <span className="text-black">
            reached {min >= max ? "" : `${(min / max) * 100}%`}
          </span>{" "}
          of your daily steps count goals
        </span>
        {min >= max ? (
          <div
            className="rounded-5 bg-linear-green px-3 me-2 d-flex align-items-center mt-2"
            style={{ width: "fit-content" }}
          >
            <img src={CongratsIcon} alt="Congrats" />
            <p className="ms-1 my-0 font-12px progress-bar text-white py-1">
              Congratulations!
            </p>
          </div>
        ) : (
          <div
            className="rounded-5 bg-linear-orange px-3 me-2 d-flex align-items-center mt-2"
            style={{ width: "fit-content" }}
          >
            <img src={RunIcon} alt="Congrats" />
            <p className="ms-1 my-0 font-12px progress-bar text-white py-1">
              Keep going!
            </p>
          </div>
        )}
      </div>
    );
  };

  const InfoBlock = ({
    label,
    value,
    icon,
  }: {
    label: string;
    value: string;
    icon: string;
  }) => (
    <div className="d-flex flex-column">
      <p className="text-grey-901 mb-0 font-10px">{label}</p>
      <div className="d-flex flex-row align-items-center">
        <span
          className="mb-0 fw-bold font-16px"
          style={{ display: "inline-block", width: "fit-content" }}
        >
          {value}
        </span>
        <div className="h-100">
          <img src={icon} alt="Icon" className="h-100 w-75 ms-2" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-3 rounded-3 h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div>
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Analysis</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on{" "}
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>
      {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day) ? (
        renderDailyComponent()
      ) : (
        <SummaryChart summaryData={summaryData} unit={unit} period={period} />
      )}
    </div>
  );
};

export default AnalysisCard;

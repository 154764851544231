import moment from "moment";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";

const getTimeRange = (period: string): string => {
  const currentMoment = moment().endOf("day");

  const data: Record<string, string> = {
    [TimePeriodHelper.getTimePeriodJson(TimePeriod.day)]: `${moment()
      .startOf("day")
      .format("D MMM YYYY")} - ${currentMoment.format("D MMM YYYY")}`,
    [TimePeriodHelper.getTimePeriodJson(TimePeriod.week)]: `${currentMoment
      .clone()
      .startOf("isoWeek")
      .startOf("day")
      .format("D MMM YYYY")} - ${currentMoment
      .clone()
      .endOf("isoWeek")
      .startOf("day")
      .format("D MMM YYYY")}`,
    [TimePeriodHelper.getTimePeriodJson(TimePeriod.month)]: `${moment()
      .clone()
      .subtract(1, "month")
      .startOf("day")
      .format("D MMM YYYY")} - ${currentMoment.format("D MMM YYYY")}`,
    [TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear)]: `${moment()
      .clone()
      .subtract(5, "months")
      .startOf("day")
      .format("D MMM YYYY")} - ${currentMoment.format("D MMM YYYY")}`,
    [TimePeriodHelper.getTimePeriodJson(TimePeriod.year)]: `${moment()
      .clone()
      .subtract(1, "year")
      .startOf("day")
      .format("D MMM YYYY")} - ${currentMoment.format("D MMM YYYY")}`,
  };

  return data[period] ?? "";
};

export { getTimeRange };

import React, { useEffect, useState } from "react";
import Table from "./Table";
import { Button, Form, FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import searchIcon from "@assets/icons/search_icon.svg";
import closeIcon from "@assets/icons/close_icon.svg";
import plusIcon from "@assets/icons/plus_icon.svg";
import sosIcon from "@assets/icons/sos_client_icon.svg";
import DeleteModal from "../../../../../components/Modal/DeleteModal";
import SOSModal from "./SOSModal";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import { getSosResultAsync } from "@app/redux/features/v2/sos-service/sosServiceThunk";
import { setSosRequest } from "@app/redux/features/v2/sos-service/sosServiceSlice";
import { PaginateDefault } from "@app/common/enums/PaginateDefault";
import { SosResultDto } from "@app/redux/features/v2/sos-service/models/sos-result-dto";

interface HistoryRecordCardProps {
  // handlePeriodChange: (value: string) => void;
  // period: string;
}
const AlertsHealthIncidentsCard: React.FC<HistoryRecordCardProps> = () => {
  const dispatch = useAppDispatch();

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [dataSelected, setDataSelected] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const { sosResultPaginate, sosRequest } = useAppSelector(
    (state: RootState) => state.sosServiceV2
  );

  const id = getOrgFromJwt();

  useEffect(() => {
    if (!id) return;
    dispatch(
      setSosRequest({
        ...sosRequest,
        org_id: id,
      })
    );
    dispatch(
      getSosResultAsync({
        ...sosRequest,
        org_id: id,
      })
    );
  }, [dispatch, id]);

  const clearSearch = () => {
    setSearchTerm("");
    dispatch(
      setSosRequest({
        ...sosRequest,
        page_number: 1,
        page_size: 10,
        filter_by: "",
      })
    );
    dispatch(
      getSosResultAsync({
        ...sosRequest,
        page_number: 1,
        page_size: 10,
        filter_by: "",
      })
    );
  };


  const onShowModifyModal = (data?: SosResultDto | null) => {
    setShowModifyDialog(true);
    setDataSelected(data);
  };

  const onCloseModifyModal = () => {
    setShowModifyDialog(false);
    setDataSelected(null);
  };

  const onShowDeleteModal = (data: any) => {
    setShowDeleteDialog(true);
    setDataSelected(data);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteDialog(false);
    setDataSelected(null);
  };

  return (
    <div>
      <div className="d-flex flex-column mt-3" style={{ gap: "18px" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex rounded-3 col-6 title-chart gap-2 align-items-center">
            <div className="rounded-5 icon-chart-custom">
              <img height={32} width={32} src={sosIcon} alt="Icon" />
            </div>
            <div className="text-dark">
              <span className="font-14px fw-bold text-dark">SOS client</span>
              <p className="font-10px text-grey-901 m-0">
                Lorem ipsum dolor sit amet, in quo illum vocibus.
              </p>
            </div>
          </div>
          <div className="col-4 d-flex align-items-center gap-2">
            <InputGroup className="rounded-3 bg-white">
              <div className="search-icon-history">
                <img
                  className="h-100"
                  width={16}
                  src={searchIcon}
                  alt="Search"
                />
              </div>
              <Form.Control
                className="border-0 bg-transparent ms-4 font-12px py-2"
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="close-icon-history">
                <img
                  src={closeIcon}
                  alt="Close"
                  onClick={clearSearch}
                  className="cursor-pointer"
                />
              </div>
            </InputGroup>
            <Button
              onClick={() => onShowModifyModal()}
              className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center"
            >
              <img src={plusIcon} alt="Plus" />
              <p className="ms-1 my-0 font-12px progress-bar">Add</p>
            </Button>
          </div>
        </div>

        <Table
          onEdit={onShowModifyModal}
          onDelete={onShowDeleteModal}
          data={sosResultPaginate ?? PaginateDefault}
        />
      </div>

      <SOSModal
        type={dataSelected ? "update" : "add"}
        show={showModifyDialog}
        onHide={onCloseModifyModal}
        dataSelected={dataSelected}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteDialog}
        onHide={onCloseDeleteModal}
        onSubmit={onCloseDeleteModal}
        title="Alert & Health Incident"
        icon={sosIcon}
        content="Are you sure you want to remove this record?"
      />
    </div>
  );
};

export default AlertsHealthIncidentsCard;

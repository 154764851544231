import React from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import SummaryDailyChart from "./Stress.AnalysisDailyChart";
import SummaryChart from "./Stress.AnalysisChart";
import { Tab } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import StressAnalysisDaily from "./Stress.AnalysisDaily";
import { PieDataItem } from "./Stress.AnalysisDaily";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { getTimeRange } from "@app/utils/time";

interface ChartCardProps {
  period: string;
  summaryDailyData: {
    date: string;
    value: string | number;
  }[];
  summaryData: {
    date: string | number;
    startTime: number | undefined | null;
    stages: { stage: string; value: number; duration: number }[];
  }[];
  dailyStressData: {
    color: string;
    name: string;
    value: number;
    status: string;
    percentage: number;
    averageDuration: number;
  }[];
}

const SummaryCard: React.FC<ChartCardProps> = ({
  period,
  summaryDailyData,
  summaryData,
  dailyStressData
}) => {
  const renderDailyComponent = () => (
    <>
      {" "}
      <div className="tab-container analysis-tabs ">
        <Tabs className="">
          <Tab
            eventKey="medicalHistory"
            title="Stress Level Analysis"
            className="text-black"
          >
            <SummaryDailyChart summaryData={summaryDailyData} />
          </Tab>
          <Tab
            eventKey="medication"
            title="Stress Level Breakdown"
            className=""
          >
            <StressAnalysisDaily dailyStressData={dailyStressData} />
          </Tab>
        </Tabs>
      </div>
    </>
  );

  return (
    <div className="bg-white p-3 rounded-3 h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-6 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Analysis</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day) ? (
        renderDailyComponent()
      ) : (
        <SummaryChart summaryData={summaryData} period={period} />
      )}
    </div>
  );
};

export default SummaryCard;

import { useMemo } from "react";

import Table from "@app/components/Table";

import { SOSDetailType } from "@app/common/enums/SOSType";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { ClientsAlertTableDataResult } from "@app/redux/features/v2/portal-service/home/models/request-result-dto";

import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";

import { setClientsAlertRequest } from "@app/redux/features/v2/portal-service/home/portalHomeSlice";
import { getClientsAlertTableDataAsync } from "@app/redux/features/v2/portal-service/home/portalHomeThunk";
import moment from "moment";

interface ITableProps {
  data: PaginatedResult<ClientsAlertTableDataResult>;
}

const ClientsAlertTable = ({ data }: ITableProps) => {
  const dispatch = useAppDispatch();
  const { clientsAlertRequest } = useAppSelector(
    (state: RootState) => state.portalHomeV2
  );
  const columns = useMemo(
    () => [
      {
        name: "No.",
        selector: (row: ClientsAlertTableDataResult, index: number) =>
          index + 1,
      },
      {
        name: "Name",
        selector: (row: ClientsAlertTableDataResult) => row.name,
      },
      {
        name: "Date of birth",
        selector: (row: ClientsAlertTableDataResult) => row.date_of_birth,
        sortable: true,
      },
      {
        name: "Time of alert",
        selector: (row: ClientsAlertTableDataResult) =>
          moment(row.alert_time).format("YYYY-MM-DD"),
        sortable: true,
      },
      {
        name: "SOS Type",
        selector: (row: ClientsAlertTableDataResult) => {
          return (
            <div
              className={`ms-2 px-1 bg-${SOSDetailType.getColor(
                row.alert_type
              )} rounded-1 font-12px text-white fw-medium d-flex flex-row align-items-center gap-1`}
              style={{ height: "17px" }}
            >
              {row.alert_type}
            </div>
          );
        },
        sortable: true,
      },

      {
        name: "Location",
        id: "action",
        style: {
          width: "fit-content",
          flex: "0 !important",
          padding: "12.5px 16px !important",
        },
        selector: (row: ClientsAlertTableDataResult) => row.location,
        sortable: true,
      },
    ],
    []
  );

  const handlePageChange = (page: number) => {
    dispatch(
      setClientsAlertRequest({
        ...clientsAlertRequest,
        page_number: page,
      })
    );
    dispatch(
      getClientsAlertTableDataAsync({
        ...clientsAlertRequest,
        page_number: page,
      })
    );
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(
      setClientsAlertRequest({
        ...clientsAlertRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
    dispatch(
      getClientsAlertTableDataAsync({
        ...clientsAlertRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
export default ClientsAlertTable;

// import Map, { NavigationControl } from "react-map-gl/maplibre";
import Map, { NavigationControl } from "react-map-gl/maplibre";
import lockerGeoJSON from "./lockers.json";
import { config } from "@app/services/config";
import { LockerMarkers } from "./LockerMarkers";
import "maplibre-gl/dist/maplibre-gl.css";

interface BasicReportCardProps {
  title?: string;
  icon?: string;
  value?: string;
  unit?: string;
}
const lockers = lockerGeoJSON.features.map(
  ({
    geometry: {
      coordinates: [longitude, latitude],
    },
    properties: { title, address: description },
  }) => ({
    latitude,
    longitude,
    title,
    description,
  })
);
const MapCard: React.FC<BasicReportCardProps> = (
  props: BasicReportCardProps
) => {
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 w-100">
      <Map
        initialViewState={{
          latitude: 49.2509,
          longitude: -123.1147,
          zoom: 11,
        }}
        style={{ height: "30vh", borderRadius: "16px" }}
        mapStyle={`https://maps.geo.${config.Region}.amazonaws.com/v2/styles/Standard/descriptor?key=${config.ApiKey}&color-scheme=Light`}
      >
        <NavigationControl position="bottom-right" />
        <LockerMarkers lockers={lockers} />
      </Map>
    </div>
  );
};

export default MapCard;

import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import phone from "@assets/icons/login/phones.svg";
import starIcon from "@assets/icons/login/star_icon.svg";
import pencilIcon from "@assets/icons/login/pencil_icon.svg";
import shieldIcon from "@assets/icons/login/shield_icon.svg";
import logo from "@assets/icons/login/logo.svg";
const AuthLayout = () => {
  return (
    <div className="login-main">
      {/* Left Panel */}
      <div
        className="position-absolute w-50 d-flex m-0"
        style={{ left: "0", top: "150px" }}
      >
        <Col className="col-12 d-flex justify-content-center mt-15">
          <Row>
            <Col className="d-flex flex-column" style={{ maxWidth: "450px" }}>
              <p className="font-18px fw-medium text-white mb-0">
                Start your health journey!
              </p>
              <p className="font-12px fw-normal text-white">
                Track your well-being with ease—let's set up your health goals
                and get started on a healthier you!
              </p>
              <div className="d-flex flex-row justify-content-between">
                <Feature icon={starIcon} text="Smart algorithm" />
                <Feature icon={pencilIcon} text="High accuracy" />
                <Feature icon={shieldIcon} text="Protected privacy" />
              </div>
            </Col>
          </Row>
        </Col>
      </div>

      <div
        className="position-absolute w-50 d-flex flex-row justify-content-center"
        style={{ bottom: "0px", left: "0" }}
      >
        <div style={{ width: "fit-content" }}>
          <img width={469} src={phone} alt="icon" />
        </div>
      </div>
      <div
        className="position-absolute w-50 d-flex flex-row justify-content-start"
        style={{ top: "0px", right: "0" }}
      >
        <div className="ms-5" style={{ width: "fit-content" ,marginTop:"50px"}}>
          <img height={34} width={149} src={logo} alt="Logo" />
        </div>
      </div>

      <Outlet></Outlet>
    </div>
  );
};

type FeatureProps = {
  icon: string;
  text: string;
};

const Feature: React.FC<FeatureProps> = ({ icon, text }) => (
  <div className="d-flex flex-row justify-content-start align-items-center">
    <img width={18} height={18} src={icon} alt="icon" />
    <span className="font-14px fw-medium d-flex text-white flex-grow-1 ms-1">
      {text}
    </span>
  </div>
);

export default AuthLayout;

import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "@redux/store";
import { useAppDispatch, useAppSelector } from "@redux/store/useStore";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { LoginCredentialsDto } from "@redux/features/security-service/models/login-credentials-dto";
import { loginAsync } from "@redux/features/security-service/securityServiceThunk";
import "../Auth.css";
import eyeIcon from "@assets/icons/login/eye_icon.svg";
import eyeCloseIcon from "@assets/icons/login/eye_close_icon.svg";
import { LoginResponseDto } from "@app/redux/features/v2/security-service/models/login-credentials-dto";
import { getCurrentUserFromJwt } from "@app/redux/common/helpers/jwt-helper";

const Login = () => {
  // Redux state and dispatch
  const { currentUser, apiError } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Local state
  const [isMobile, setIsMobile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Form handling
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<LoginCredentialsDto>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  // Watch form fields
  const username = watch("username");
  const password = watch("password");

  // Effects
  useEffect(() => {
    const storedValue = localStorage.getItem("isMobile");
    setIsMobile(storedValue === "true");
  }, []);

  useEffect(() => {
    if (currentUser !== undefined && currentUser.email !== "") {
      navigate(
        currentUser.roles[0].name !== "org_owner"
          ? "/consumer/"
          : "/portal-new/"
      );
    }
  }, [currentUser]);

  useEffect(() => {
    setIsFormValid(username !== "" && password !== "");
  }, [username, password]);

  // Handlers
  const onSubmit = (loginCredentials: LoginCredentialsDto) => {
    dispatch(loginAsync(loginCredentials)).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        const result = action.payload as LoginResponseDto;
        if (
          result.access_token &&
          getCurrentUserFromJwt(result.access_token)?.roles[0].name === "wearer"
        ) {
          navigate("/consumer/monthly-reports");
        }
        if (
          result.access_token &&
          getCurrentUserFromJwt(result.access_token)?.roles[0].name ===
            "org_owner"
        ) {
          navigate("/portal-new/");
        }
      }
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // JSX
  return (
    <div className="d-flex h-100 w-100 justify-content-end">
      {/* Right Panel */}
      <Row className="h-100 w-50 bg-white m-0">
        <Col className="col-12 w-100" style={{ marginTop: "200px" }}>
          <Row>
            <Col className="col-12 w-100">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column" style={{ width: "530px" }}>
                  <p className="font-24px fw-medium text-start my-0">Log In</p>
                  <p className="font-12px text-grey-901">
                    Sign up or login to manage your health better
                  </p>
                  <div className="w-100">
                    <Form onSubmit={handleSubmit(onSubmit)} id="loginForm">
                      <FormInput
                        control={control}
                        name="username"
                        label="Email address"
                        type="email"
                        placeholder="Enter your email address"
                      />
                      <FormInput
                        control={control}
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        togglePasswordVisibility={togglePasswordVisibility}
                      />
                      <Link
                        to="/auth/forgot-password"
                        className="text-start font-12px fw-medium text-decoration-none my-2 d-block mb-3"
                        style={{ color: "#3F72F3", width: "fit-content" }}
                      >
                        Forgot password?
                      </Link>
                      <Button
                        as="input"
                        type="submit"
                        value="Log in"
                        className={`font-12px fw-medium rounded-3 ${
                          isFormValid
                            ? "text-white bg-blue-902"
                            : "text-grey-901 bg-grey-904"
                        } border-0 px-3 d-flex align-items-center text-login no-hover`}
                      />

                      <p
                        style={{ color: "#212121" }}
                        className="mt-4 text-start font-12px fw-medium"
                      >
                        Don't have an account?{" "}
                        <Link
                          to="/auth/register"
                          className="text-start font-12px fw-medium text-decoration-none"
                          style={{ color: "#3F72F3", width: "fit-content" }}
                        >
                          Sign up
                        </Link>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

// Helper Components
type FormInputProps = {
  control: any;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  togglePasswordVisibility?: () => void;
};

const FormInput: React.FC<FormInputProps> = ({
  control,
  name,
  label,
  type,
  placeholder,
  togglePasswordVisibility,
}) => (
  <Form.Group className="mb-3" controlId={`loginForm.${name}`}>
    <span className="font-10px text-dark">{label}</span>
    <InputGroup className="border rounded-3 border-0 bg-grey-904">
      <Controller
        name={name}
        control={control}
        rules={{ required: { value: true, message: `${name} is required` } }}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
        )}
      />
      {togglePasswordVisibility &&
        (type === "text" ? (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeCloseIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ))}
    </InputGroup>
  </Form.Group>
);

export default Login;

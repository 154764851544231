import SOSCard from "./widgets/SOSCard";

const Sos = () => {
    return (
        <div>
          <main className="container min-vh-100">
            <div className="d-flex flex-column">
              <div className="mt-5">
                <p className="font-20px mb-0 fw-bold">SOS</p>
              </div>
              <div className="row d-flex ">
                <div className="tab-container history-tabs">
                  <SOSCard />
                </div>
              </div>
            </div>
          </main>
        </div>
      );
}

export default Sos;
import { Outlet } from "react-router-dom";
import Navbar from "@app/components/Nav/Navbar";

const navLinks = [
  {
    label: "Home",
    path: "/portal-new/home",
  },
  {
    label: "Clients",
    path: "/portal-new/clients",
  },
  {
    label: "SOS",
    path: "/portal-new/sos",
  },
  {
    label: "Admin",
    path: "/portal-new/admin",
  },

];
const PortalLayout = () => {
   
  return (
    <>
      <Navbar navLinks={navLinks} />
      <Outlet />
    </>
  );
};
export default PortalLayout;

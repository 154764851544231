import React, { useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import uploadIcon from "@assets/icons/upload_icon.svg";
import removeIcon from "@assets/icons/remove_icon.svg";
import avatar from "@assets/icons/complete-profile/default_profile_image.svg";
import { EmergencyProps } from ".";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { CountryCodes } from "./data";
import { uploadProfileImageAsync } from "@app/redux/features/user-profile-service/userProfileServiceThunk";
import { useAppDispatch } from "@app/redux/store/useStore";

const ContactBlock = ({
  contact,
  index,
  updateContact,
  removeContact,
  type,
}: {
  contact: EmergencyProps;
  type: "add" | "update";
  index: number;
  updateContact: (index: number, updatedContact: EmergencyProps) => void;
  removeContact: (index: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string | null;
        if (result) {
          const base64Image = result.split(",")[1];
          updateContact(index, { ...contact, avatar: base64Image });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };

  const handleRemovePhoto = () => {
    updateContact(index, { ...contact, avatar: "" });
  };

  return (
    <div
      className={`contact-block pb-3 mb-2 ${
        index !== 0 ? "border-grey-901 border-top" : ""
      }`}
    >
      <div className="font-14px fw-bold mt-2 d-flex justify-content-between align-items-center">
        {type === "add" ? <span>Person {index + 1} </span> : ""}
        {index !== 0 ? (
          <div className="d-flex justify-content-end align-items-end">
            <div
              className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
              style={{ width: "fit-content" }}
              onClick={() => removeContact(index)}
            >
              <img src={deleteIcon} alt="Delete" />
              Delete
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12">
        <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
          <div className="icon-chart-custom py-1 d-flex align-items-center justify-content-center">
            <img
              height={78}
              width={78}
              src={
                contact?.avatar
                  ? `data:image/png;base64,${contact.avatar}`
                  : avatar
              }
              alt="Icon"
              className="rounded-circle"
              style={{ objectFit: "cover" }}

            />
          </div>
          <div>
            <Button
              className="ms-2 rounded-3 text-blue-901 border-green-900 px-3 d-flex align-items-center no-hover bg-blue-903"
              onClick={handleButtonClick}
            >
              <img src={uploadIcon} alt="upload icon" />
              <p
                className="ms-1 my-0 font-12px progress-bar"
                style={{ textTransform: "none" }}
              >
                Upload photo
              </p>
            </Button>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <Button className="rounded-3 text-very-high border-green-900 px-3 d-flex align-items-center no-hover bg-transparent">
            <img src={removeIcon} alt="remove" />
            <p
              className="ms-1 my-0 font-12px progress-bar"
              style={{ textTransform: "none" }}
              onClick={handleRemovePhoto}
            >
              Remove photo
            </p>
          </Button>
        </div>
      </div>

      <div className="font-14px fw-bold mt-2">Contact information</div>
      <div className="d-flex justify-content-end">
        <div className="form-check form-switch ">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked-${index}`}
            checked={contact.is_sos ?? false}
            onChange={() =>
              updateContact(index, { ...contact, is_sos: !contact.is_sos })
            }
          />
          <label className="form-check-label font-12px fw-bold">
            SOS Contact
          </label>
        </div>
      </div>

      <div className="col-12 row gap-3">
        <div className="col">
          <div className="row gap-3">
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">First Name</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="First Name"
                  value={contact?.first_name ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      first_name: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </div>
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Last Name</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="Last Name"
                  value={contact?.last_name ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      last_name: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </div>
          </div>
        </div>

        <div className="col">
          {/* Label */}
          <span className="font-10px text-dark">Relationship</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="text"
              placeholder="Relationship"
              value={contact.relationship || ""}
              onChange={(e) =>
                updateContact(index, {
                  ...contact,
                  relationship: e.target.value,
                })
              }
            />
          </InputGroup>
        </div>
      </div>

      <div className="col-12 row gap-3">
        <div className="col">
          {/* Label */}
          <span className="font-10px text-dark">Email</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="email"
              placeholder="Email"
              value={contact?.email_address ?? ""}
              onChange={(e) =>
                updateContact(index, {
                  ...contact,
                  email_address: e.target.value,
                })
              }
            />
          </InputGroup>
        </div>
        <div className="col">
          <div className="row gap-3">
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Mobile Prefix</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Select
                  className="border-0 bg-transparent font-12px py-2"
                  value={contact?.mobile_prefix ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      mobile_prefix: e.target.value,
                    })
                  }
                >
                  {CountryCodes.map((item: { value: string }) => (
                    <option>{item.value}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            </div>
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Mobile Number</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="Mobile Number"
                  value={contact?.mobile_number ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      mobile_number: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;

import {
  Action,
  AnyAction,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import securityServiceReducer from "@redux/features/security-service/securityServiceSlice";
import staticDataServiceReducer from "@redux/features/staticData-service/sataticDataServiceSlice";
import clientServiceReducer from "@redux/features/client-service/clientServiceSlice";
import userProfileServiceReducer from "@redux/features/user-profile-service/userProfileServiceSlice";
import healthServiceReducer from "@redux/features/health-service/healthServiceSlice";
import usersServiceReducer from "@redux/features/users-service/usersServiceSlice";
import orgServiceReducer from "@redux/features/org-service/orgServiceSlice";
import roleServiceReducer from "@redux/features/role-service/roleServiceSlice";
import contactServiceReducer from "@redux/features/contact-service/contactServiceSlice";
import sosServiceReducer from "@redux/features/sos-service/sosServiceSlice";
import configurationServiceReducer from "@redux/features/configuration-service/configurationServiceSlice";
import messageServiceReducer from "@redux/features/message-service/messageServiceSlice";
import deviceServiceReducer from "@redux/features/device-service/deviceServiceSlice";
import medicalServiceReducer from "@redux/features/medical-service/medicalServiceSlice";
import complianceServiceReducer from "@redux/features/compliance-service/complianceServiceSlice";
import reportServiceReducer from "@redux/features/report-service/reportServiceSlice";
import activityServicerReducer from "@redux/features/activity-service/activityServiceSlice";
import loadingReducer from "@redux/features/common/loadingSlice";
import notificationReducer from "@redux/features/common/notificationSlice";
import toggleReducer from "@redux/features/common/toggleSlice";

//v2
import staticDataServiceV2Reducer from "@redux/features/v2/staticData-service/sataticDataServiceSlice";
import userProfileServiceV2Reducer from "@redux/features/v2/user-profile-service/userProfileServiceSlice";
import securityServiceV2Reducer from "@redux/features/v2/security-service/securityServiceSlice";
import medicalServiceV2Reducer from "@redux/features/v2/medical-service/medicalServiceSlice";
import contactServiceV2Reducer from "@redux/features/v2/contact-service/contactServiceSlice";
import deviceServiceV2Reducer from "@redux/features/v2/device-service/deviceServiceSlice";
import healthSettingsServiceReducer from "@redux/features/v2/health-settings-services/healthSettingsServiceSlice";
import healthServiceV2Reducer from "@redux/features/v2/health-service/healthServiceSlice";
import reportServiceV2Reducer from "@redux/features/v2/report-service/reportServiceSlice";
import orgServiceV2Reducer from "@redux/features/v2/org-service/orgServiceSlice";
import sosServiceV2Reducer from "@redux/features/v2/sos-service/sosServiceSlice";
import usersServiceV2Reducer from "@redux/features/v2/users-service/usersServiceSlice";
import portalHomeV2Ruducer from "@redux/features/v2/portal-service/home/portalHomeSlice";
import clientServiceV2Reducer from "@redux/features/v2/client-service/clientServiceSlice";

const combinedReducers = combineReducers({
  securityService: securityServiceReducer,
  staticDataService: staticDataServiceReducer,
  clientService: clientServiceReducer,
  userProfileService: userProfileServiceReducer,
  healthService: healthServiceReducer,
  usersService: usersServiceReducer,
  orgService: orgServiceReducer,
  roleService: roleServiceReducer,
  contactService: contactServiceReducer,
  loading: loadingReducer,
  notification: notificationReducer,
  toggle: toggleReducer,
  sosService: sosServiceReducer,
  configurationService: configurationServiceReducer,
  messageService: messageServiceReducer,
  deviceService: deviceServiceReducer,
  medicalService: medicalServiceReducer,
  complianceService: complianceServiceReducer,
  reportService: reportServiceReducer,
  activityService: activityServicerReducer,

  //v2
  staticDataServiceV2: staticDataServiceV2Reducer,
  userProfileServiceV2: userProfileServiceV2Reducer,
  securityServiceV2: securityServiceV2Reducer,
  medicalServiceV2: medicalServiceV2Reducer,
  contactServiceV2: contactServiceV2Reducer,
  deviceServiceV2: deviceServiceV2Reducer,
  healthSettingsService: healthSettingsServiceReducer,
  healthServiceV2: healthServiceV2Reducer,
  reportServiceV2: reportServiceV2Reducer,
  orgServiceV2: orgServiceV2Reducer,
  sosServiceV2: sosServiceV2Reducer,
  usersServiceV2: usersServiceV2Reducer,
  portalHomeV2: portalHomeV2Ruducer,
  clientServiceV2: clientServiceV2Reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout") {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { useEffect, useState } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import { Form, InputGroup } from "react-bootstrap";
import userPlusIcon from "@assets/icons/portal/user_plus_icon.svg";
import { useAppDispatch } from "@app/redux/store/useStore";
import { CreateUpdateUserDto } from "@app/redux/features/v2/org-service/models/organisation-portal-dto";
import { hasMissingFields } from "@app/utils/helper";
import "./index.scss";

const DEFAULT_DATA: CreateUpdateUserDto = { first_name: "", last_name: "", email: "", role: "", status: "" };

const UserModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState(DEFAULT_DATA);

  const fieldsToCheck: (keyof CreateUpdateUserDto)[] = ["first_name", "last_name", "email", "role", "status"];

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (dataSelected && type === "update") {
      setUser(
        {
          first_name: dataSelected.name ?? "",
          last_name: dataSelected.name ?? "",
          email: dataSelected.email ?? "",
          role: dataSelected.role ?? "",
          status: dataSelected.status ?? "",
        },
      );
    } else {
      setUser(DEFAULT_DATA);
    }
  }, [dataSelected, type]);

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setUser(DEFAULT_DATA);
  };

  const onSubmit = async () => {
    try {
      if (type === "add") {
        if (hasMissingFields(user, fieldsToCheck)) {
          setIsError(true);
          return;
        }

        onCloseModal();
      } else {
        if (hasMissingFields(user, fieldsToCheck)) {
          setIsError(true);
          return;
        }

        onCloseModal();
      }
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Input user’s information to add them to organization"
      show={show}
      onHide={onCloseModal}
      title="User"
      size="lg"
      icon={userPlusIcon}
      onSubmit={onSubmit}
    >
      <div className="col-12 row mb-3 gap-3">
        <div className="d-flex flex-row gap-3">
          <div className="col">
            <span className="font-10px text-dark">First Name</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="user-input-color border-0 font-12px py-2"
                type="text"
                name="first_name"
                placeholder="Enter first name"
                value={user?.first_name ?? ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    first_name: e.target.value,
                  });
                }}
              />
            </InputGroup>
            {isError && user.first_name === "" && <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>}
          </div>
          <div className="col">
            <span className="font-10px text-dark">Last Name</span>
            <InputGroup className="border rounded-3 border-0 bg-grey-904">
              <Form.Control
                className="user-input-color border-0 font-12px py-2"
                type="text"
                name="last_name"
                placeholder="Enter last name"
                value={user?.last_name ?? ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    last_name: e.target.value,
                  });
                }}
              />
            </InputGroup>
            {isError && user.last_name === "" && <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>}
          </div>
        </div>
        <div className="d-flex flex-row gap-3">
          <div className="col">
            <Form.Group className="dropdown-selector text-capitalize">
              <span className="font-10px text-dark">Select role</span>
              <Form.Select
                className="border-0 font-12px py-2"
                value={user?.role ?? ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    role: e.target.value,
                  });
                }}
              >
                <option value="" hidden>
                  Select Role
                </option>

                <option value="org_admin">Org Admin</option>
                <option value="User">User</option>
              </Form.Select>
              {isError && user.role === "" && <Form.Control.Feedback type="invalid">Please select role</Form.Control.Feedback>}
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group className="dropdown-selector text-capitalize">
              <span className="font-10px text-dark">Select status</span>
              <Form.Select
                className="border-0 font-12px py-2"
                value={user?.status ?? ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    status: e.target.value,
                  });
                }}
              >
                <option value="" hidden>
                  Select Status
                </option>

                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
              {isError && user.status === "" && <Form.Control.Feedback type="invalid">Please select status</Form.Control.Feedback>}
            </Form.Group>
          </div>
        </div>
        <div className="col-12">
          <span className="font-10px text-dark">Email</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="user-input-color border-0 font-12px py-2"
              type="email"
              name="last_name"
              placeholder="Enter email address"
              value={user?.email ?? ""}
              onChange={(e) => {
                setUser({
                  ...user,
                  email: e.target.value,
                });
              }}
            />
          </InputGroup>
          {isError && user.email === "" && <Form.Control.Feedback type="invalid">Please enter email</Form.Control.Feedback>}
        </div>
      </div>
    </ActionModal>
  );
};

export default UserModal;

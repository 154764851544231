import React, { useState } from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import { VitalType } from "@app/common/enums/VitalTypes";
import StepsTable from "./StepsTable";
import SleepTable from "./SleepsTable";
import StressTable from "./StressTable";
import Table from "./Table";
import BloodTable from "./BloodTable";

interface HistoryRecordCardProps {
  type: VitalType;
  period: string;
  data?: any;
}

const HistoryRecordCard: React.FC<HistoryRecordCardProps> = ({
  type,
  period,
  data,
}) => {
  const renderTable = () => {
    switch (type) {
      case VitalType.STEPS:
        return <StepsTable period={period} data={data} />;
      case VitalType.SLEEP:
        return <SleepTable period={period} data={data} />;
      case VitalType.STRESS:
        return <StressTable period={period} data={data} />;
      case VitalType.BLOOD_PRESSURE:
        return <BloodTable period={period} data={data} />;
      default:
        return <Table period={period} type={type} data={data} />;
    }
  };

  return (
    <div className="d-flex flex-column mt-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex py-2 rounded-3 col-6 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1 text-dark">
            <span className="font-14px fw-bold text-dark">History Record</span>
            <p className="font-10px text-grey-901">
              Lorem ipsum dolor sit amet, in quo illum vocibus.
            </p>
          </div>
        </div>
        {type !== VitalType.STRESS && (
          <div className="d-flex align-items-end">
            {/* <div className="form-floating font-12px bg-white px-2 py-1 rounded-2">
              <span>View:</span>
              <select
                className="border-0"
                onChange={(e) => setPeriod(e.target.value)}
                value={period}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="months">Months</option>
                <option value="6months">6 Months</option>
                <option value="year">Yearly</option>
              </select>
            </div> */}
          </div>
        )}
      </div>

      {renderTable()}
    </div>
  );
};

export default HistoryRecordCard;

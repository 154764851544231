import { createOrganisationPortalApi } from "@services/organisation-portal-api";
import { config } from "@services/config";
import { UserHealthCalibrationDto } from "@app/redux/features/v2/health-settings-services/models/user-health-calibration-dto";
import { GetUserHealthCalibrationDto } from "@app/redux/features/v2/health-settings-services/models/get-user-health-calibration-dto";
import { CreateUserHealthCalibrationDto } from "@app/redux/features/v2/health-settings-services/models/create-user-health-calibration-dto";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getUserHealthCalibration = (payload: GetUserHealthCalibrationDto) => {
    return organisationPortalApi.get<UserHealthCalibrationDto>(
        `/api/v2/calibration?wearer_sid=${payload.wearer_sid}&vital=${payload.vital}${
            payload.calibration_date ? `&calibration_date=${payload.calibration_date}` : ''
        }`
    );
};


const createUserHealthCalibration = (payload: CreateUserHealthCalibrationDto) => {
    return organisationPortalApi.post<null>(
        '/api/v2/calibration', payload
    );
}

const HealthSettingsService = {
    getUserHealthCalibration,
    createUserHealthCalibration,
};

export default HealthSettingsService;

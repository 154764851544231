import { VitalType } from "@app/common/enums/VitalTypes";
import mymonXIcon from "@assets/icons/mymonX_icon.svg";

interface BasicReportCardProps {
  type: VitalType;
  title?: string;
  icon?: string;
  value?: string;
  date?: string;
  unit?: string;
}

const BasicInfoCard: React.FC<BasicReportCardProps> = (
  props: BasicReportCardProps
) => {
  const buildDot = ({
    datValue,
    vitalType,
  }: {
    datValue: number;
    vitalType: VitalType;
  }) => {
    if (datValue === 0) {
      return null;
    }
    const statusText = VitalType.getDataTextStatus(vitalType, datValue);
    const statusColor = VitalType.getDataColorStatus(vitalType, datValue);

    return (
      <div className="d-flex flex-row align-items-center">
        <div
          className={`bg-${statusColor} rounded-circle me-2`}
          style={{
            width: "8px",
            height: "8px",
            display: "inline-block",
          }}
        ></div>
        <span
          className={`font-12px fw-medium text-${statusColor} whitespace-nowrap overflow-hidden text-truncate`}
        >
          {statusText}
        </span>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100">
        <img
          width={18}
          height={18}
          src={VitalType.getIcons(props.type)}
          alt="Identity Icon"
        />
        <span className="font-14px fw-medium d-flex flex-grow-1 ms-2">
          {VitalType.getAnalysisName(props.type)}
        </span>
      </div>
      {/* Content */}
      <div className="border-grey-901">
        <div className="mt-2 mb-1">
          <div className="w-100">
            <span className="font-12px text-grey-901">
              12 Jan 2024 - 12:00:43
            </span>
            <p className="font-28px fw-medium mb-0">
              {props.value}
              <span className="ms-1 font-10px text-grey-901">
                {VitalType.getUnit(props.type)}
              </span>
            </p>
            {buildDot({ datValue: Number(props.value), vitalType: props.type })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoCard;

import { CreateContactDto } from "@redux/features/contact-service/models/create-contact-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const AddContacts = (contacts: CreateContactDto[]) => {
    return organisationPortalApi.post<string[]>("/api/v0/contact", contacts);
}

const getContacts = (user_sid: string) => {
    return organisationPortalApi.get<CreateContactDto[]>(`/api/v0/contact/${user_sid}`);
}

const updateContacts = (contacts: CreateContactDto[]) => {
    return organisationPortalApi.put<string[]>("/api/v0/contact", contacts);
}
const deleteContacts = (contact_id:string) => {
    return organisationPortalApi.delete<string>(`/api/v0/contact?contact_id=${contact_id}`);
}
const ContactServiceApi = {
    AddContacts,
    getContacts,
    updateContacts,
    deleteContacts
}

export default ContactServiceApi;
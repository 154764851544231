import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import {
    createConfigurationResultAsync,
    createAlarmConfigurationsResultAsync,
    createMedicationConfigurationsResultAsync,
    updateConfigurationsResultAsync,
    getConfigurationByUserResultAsync
} from "./configurationServiceThunk";
import { ConfigurationResultDto } from "./models/configuration-result-dto";
import { AlarmsDto, DeviceMainConfiguration, DeviceSubConfigurationAlarm } from "./models/create-configuration-dto";

// Define the interface for ConfigurationServiceState
export interface ConfigurationServiceState extends BaseState {
    configurationResults: DeviceMainConfiguration | null;
    createConfigurationResult: string | undefined;
    createAlarmConfigurationResult: string | undefined;
    createMedicationConfigurationResult: string | undefined;
    updateConfigurationsResult: string | undefined;
    initialClientRequest: { org_id: string };
    alarmConfigurations: AlarmsDto[];
    configurationRequest?: DeviceMainConfiguration;
    alarms: DeviceSubConfigurationAlarm[]
}

// Initial client request object with org_id
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}

// Initial state for ConfigurationService
const initialState: ConfigurationServiceState = {
    status: ApiStatus.IDLE,
    configurationResults: null, // Initialize as null or appropriate initial value
    createConfigurationResult: '',
    createAlarmConfigurationResult: '',
    createMedicationConfigurationResult: '',
    updateConfigurationsResult: '',
    initialClientRequest: initialClientRequest,
    alarmConfigurations: [],
    alarms: [],
    apiError: undefined // Assuming BaseState includes apiError
}

// Create the slice
export const configurationServiceSlice = createSlice({
    name: 'configurationService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setConfigurationResult(state, action: PayloadAction<DeviceMainConfiguration>) {
            state.configurationResults = action.payload;
        },
        setCreateConfigurationResult(state, action: PayloadAction<string>) {
            state.createConfigurationResult = action.payload;
        },
        setCreateAlarmConfigurationResult(state, action: PayloadAction<string>) {
            state.createAlarmConfigurationResult = action.payload;
        },
        setCreateMedicationConfigurationResult(state, action: PayloadAction<string>) {
            state.createMedicationConfigurationResult = action.payload;
        },
        setUpdateConfigurationsResult(state, action: PayloadAction<string>) {
            state.updateConfigurationsResult = action.payload;
        },
        setConfigurationRequest(state, action: PayloadAction<DeviceMainConfiguration>) {
            state.configurationRequest = action.payload;
        },
        setAlarmsRequest(state, action: PayloadAction<DeviceSubConfigurationAlarm[]>) {
            state.alarms = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks


            .addCase(createConfigurationResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createConfigurationResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createConfigurationResult = action.payload;
            })
            .addCase(createConfigurationResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


            .addCase(createAlarmConfigurationsResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createAlarmConfigurationsResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createAlarmConfigurationResult = action.payload;
            })
            .addCase(createAlarmConfigurationsResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(createMedicationConfigurationsResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createMedicationConfigurationsResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.createMedicationConfigurationResult = action.payload;
            })
            .addCase(createMedicationConfigurationsResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(updateConfigurationsResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(updateConfigurationsResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.updateConfigurationsResult = action.payload;
            })
            .addCase(updateConfigurationsResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(getConfigurationByUserResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getConfigurationByUserResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.configurationResults = action.payload;
            })
            .addCase(getConfigurationByUserResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            });
    }
});

// Export actions
export const { setConfigurationResult, setCreateAlarmConfigurationResult, setCreateConfigurationResult
    , setCreateMedicationConfigurationResult, setConfigurationRequest
    , setUpdateConfigurationsResult, setAlarmsRequest } = configurationServiceSlice.actions;

// Export
// Export the reducer
export default configurationServiceSlice.reducer;

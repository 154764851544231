import { useState } from "react";
import editIcon from "@assets/icons/edit_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { useMemo } from "react";
import Table from "@app/components/Table";
import deviceMobileCamera from "@assets/icons/portal/device_mobile_camera.svg";
import watch from "@assets/icons/portal/watch_icon.svg";
import moreActionDots from "@assets/icons/portal/more_action_dots.svg";
import { Menu, MenuItem } from "@mui/material";
import StatusBadge from "./StatusBadge";
import { ClientDto } from "@app/redux/features/v2/client-service/models/client-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { setClientRequest } from "@app/redux/features/v2/client-service/clientServiceSlice";
import { getClientsAsync } from "@app/redux/features/v2/client-service/clientServiceThunk";
import { RootState } from "@app/redux/store";

interface ITableProps {
  onEdit: (data: any) => void;
  onDelete: (id: string | number) => void;
  onRowClick: (row: ClientDto) => void;
  data: PaginatedResult<ClientDto>;
}

const ClientsTable = ({ onEdit, onDelete, onRowClick, data }: ITableProps) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { clientRequest } = useAppSelector(
    (state: RootState) => state.clientServiceV2
  );
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row: ClientDto) => (
          <div style={{ gap: 8 }} className="d-flex align-items-center">
            <div>{row.name}</div>
            {/* {row.isMobileDevice && (
              <div>
                <img height={16} width={16} src={deviceMobileCamera} alt="device Mobile" />
              </div>
            )} */}
          </div>
        ),
      },
      {
        name: "Email",
        selector: (row: ClientDto) => row.email,
        sortable: true,
      },
      {
        name: "Date of birth",
        selector: (row: ClientDto) => {
          const calculateAge = (dateOfBirth: string): number => {
            const birthDate = new Date(dateOfBirth);
            const today = new Date();

            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (
              monthDifference < 0 ||
              (monthDifference === 0 && today.getDate() < birthDate.getDate())
            ) {
              age--;
            }

            return age;
          };
          return (
            <span>
              {row.date_of_birth} ({calculateAge(row.date_of_birth)})
            </span>
          );
        },
        sortable: true,
      },
      {
        name: "Status",
        selector: (row: ClientDto) => <StatusBadge healthStatus={row.status} />,
        sortable: true,
      },
      {
        name: "Location",
        selector: (row: ClientDto) => row.location,
        sortable: true,
      },
      {
        name: "Actions",
        id: "action",
        style: {
          width: "fit-content",
          flex: "0 !important",
          padding: "12.5px 16px !important",
        },

        cell: (row: ClientDto) => (
          <div className="action-cell">
            <img
              height={16}
              width={16}
              src={editIcon}
              alt="Edit"
              onClick={() => onEdit(row)}
              className="cursor-pointer"
            />
            <img
              height={16}
              width={16}
              src={deleteIcon}
              alt="Delete"
              className="ms-2 cursor-pointer"
              onClick={() => onDelete(row.sid)}
            />
            <img
              height={3}
              width={8}
              src={moreActionDots}
              alt="More Action"
              className="ms-2 cursor-pointer"
              onClick={(e) => handleMenuOpen(e)}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              className="p-0 rounded-lg"
            >
              <MenuItem
                onClick={() => {
                  alert("Watch clicked");
                  handleMenuClose();
                }}
                className="d-flex align-items-center gap-1 p-3"
              >
                <img
                  height={12}
                  width={12}
                  src={watch}
                  alt="watch"
                  className="mr-1"
                />
                <span
                  style={{ color: "#3F72F3" }}
                  className="font-12px fw-normal"
                >
                  Assign Device
                </span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  alert("Mobile clicked");
                  handleMenuClose();
                }}
                className="d-flex align-items-center gap-1 p-3"
              >
                <img
                  height={12}
                  width={12}
                  src={deviceMobileCamera}
                  alt="device Mobile"
                  className="mr-1"
                />
                <span
                  style={{ color: "#3F72F3" }}
                  className="font-12px fw-normal"
                >
                  Invite for mobile app
                </span>
              </MenuItem>
            </Menu>
          </div>
        ),
      },
    ],
    [onDelete, onEdit, anchorEl]
  );

  const handlePageChange = (page: number) => {
    dispatch(setClientRequest({ ...clientRequest, page_number: page }));
    dispatch(getClientsAsync({ ...clientRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(
      setClientRequest({
        ...clientRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
    dispatch(
      getClientsAsync({
        ...clientRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onRowClick={onRowClick}
      />
    </div>
  );
};
export default ClientsTable;

import { VitalType } from "@app/common/enums/VitalTypes";

const vitalDetails = {
  [VitalType.GLUCOSE]: {
    icon: VitalType.getIcons(VitalType.GLUCOSE),
    title: VitalType.getAnalysisName(VitalType.GLUCOSE),
    description: [
      <>
        The <span className="text-normal fw-medium">normal</span> glucose values range is for readings{" "}
        <span className="text-dark fw-medium">before meals</span> or{" "}
        <span className="text-dark fw-medium">2 hours after a meal</span>. After a meal, it is anticipated 
        that readings will be higher and, unless they are exceptionally high, there is no need for concern.
      </>,
      <>
        Kindly be informed that the ranges provided in this document are applicable solely to{" "}
        <span className="text-dark fw-medium">HEALTHY ADULTS</span>. The normal ranges for children{" "}
        <span className="text-dark fw-medium">differ</span> depending on their age.
      </>,
      <>
        The glucose values are presented in <span className="text-dark fw-medium">UTC time</span>, which 
        is one hour behind the UK time zone. Therefore, 11am UTC time corresponds to 12pm in the UK.
      </>,
    ],
  },
  [VitalType.BLOOD_PRESSURE]: {
    icon: VitalType.getIcons(VitalType.BLOOD_PRESSURE),
    title: VitalType.getAnalysisName(VitalType.BLOOD_PRESSURE),
    description: [
      <>
        A reading over <span className="text-dark fw-medium">140/90</span> (systolic/diastolic) would be 
        regarded as hypertension in a <span className="text-dark fw-medium">GP clinic</span>. In such a 
        case, the GP would recommend that{" "}
        <span className="text-dark fw-medium">you monitor your blood pressure</span> at home to confirm 
        the diagnosis. If the readings remain consistently{" "}
        <span className="text-dark fw-medium">above 135/85 for a week</span>, it will be confirmed as hypertension.
      </>,
    ],
  },
};

const NoteConent = () => {
  return (
    <div className="d-flex flex-column gap-3">
      {Object.entries(vitalDetails).map(([key, { icon, title, description }]) => (
        <div key={key} className="border-top">
          <div className="py-3 d-flex align-items-center">
            <img width={18} height={18} src={icon} alt="Identity Icon" />
            <span className="font-12px fw-medium d-flex flex-grow-1 ms-2">{title}</span>
          </div>
          {description.map((desc, index) => (
            <p
              key={index}
              className={`font-12px text-grey-901 ${
                index === description.length - 1 ? "mb-0" : "mb-2"
              }`}
            >
              {desc}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NoteConent;

import { useState, useEffect } from "react";
import HealthyColumn from "@components/HealthColumn/healthColumn";
import { ECGType } from "@app/common/enums/ECGType";
import BreadcrumbNavigation from "@app/components/Breadcrumb/BreadcrumbNavigation";
import { VitalType } from "@app/common/enums/VitalTypes";
import SummaryCard from "./widgets/ECG.SummaryCard";
import AnalysisCard from "./widgets/ECG.AnalysisCard";
import HistoryRecordCard from "./widgets/ECG.HistoryRecordCard";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setEcgRequest } from "@app/redux/features/v2/health-service/healthServiceSlice";
import { getEcgAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";
import { PaginateDefault } from "@app/common/enums/PaginateDefault";

const ECG = () => {
  const dispatch = useAppDispatch();
  const { user_profile: profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);
  const { ecgPaginatedResult, ecgRequest } = useAppSelector((state: RootState) => state.healthServiceV2);

  useEffect(() => {
    if (profile?.sid) {
      dispatch(setEcgRequest({...ecgRequest, filter_by: profile?.sid ?? ""}));
      dispatch(getEcgAsync({...ecgRequest, filter_by: profile?.sid ?? ""}));
    }
  }, [dispatch, profile?.sid]);

  const [selectedRecord, setSelectedRecord] = useState(ecgPaginatedResult?.data[0]);

  useEffect(() => {
    if(ecgPaginatedResult) {
      setSelectedRecord(ecgPaginatedResult.data[0]);
    }
  }, [ecgPaginatedResult]);

  const handleRowClick = (record: any) => {
    setSelectedRecord(record);
  };

  return (
    <div className="container health_insight row">
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <BreadcrumbNavigation analysisName={VitalType.getAnalysisName(VitalType.ECG)} />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              summaryData={[
                {
                  label: "Heart Rate",
                  value: selectedRecord?.hhhh.toString() ?? "",
                  type: ECGType.HEART_RATE,
                },
                {
                  label: "Blood Pressure",
                  value: selectedRecord ? `${selectedRecord.maxB}/${selectedRecord.minB}` : "",
                  type: ECGType.BLOOD_PRESSURE,
                },
                {
                  label: "Heart Rate Variability",
                  value: selectedRecord?.hrHz.toString() ?? "",
                  type: ECGType.HEART_RATE_VARIABILITY,
                },
              ]}
              date={selectedRecord?.sample_time}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              analysisData={selectedRecord?.waveform_samples ? JSON.parse(selectedRecord.waveform_samples) : []}
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard data={ecgPaginatedResult ?? PaginateDefault} onRowClick={handleRowClick} />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <div className="health-column">
          <div className="title-health-column">Health Column</div>
          {[1, 2, 3, 4, 5].map((index) => (
            <HealthyColumn key={index.toString()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ECG;

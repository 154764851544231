import { CreateUpdateConditionDto } from "@app/redux/features/v2/medical-service/models/condition-result-dto";
import { Form, InputGroup } from "react-bootstrap";
import deleteIcon from "@assets/icons/delete_icon.svg";
import moment from "moment";

const MedicalHistoryBlock = ({
  isError,
  medicalHistory,
  index,
  update,
  remove,
}: {
  isError: boolean;
  medicalHistory: CreateUpdateConditionDto;
  index: number;
  update: (index: number, updatedContact: CreateUpdateConditionDto) => void;
  remove: (index: number) => void;
}) => {
  return (
    <div
      key={index}
      className={`col-12 row mb-3 gap-3 ${
        index !== 0 ? "border-grey-901 border-top" : ""
      }`}
    >
      {index !== 0 ? (
        <div className="d-flex justify-content-end align-items-end">
          <div
            className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
            style={{ width: "fit-content" }}
            onClick={() => remove(index)}
          >
            <img src={deleteIcon} alt="Delete" />
            Delete
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col">
        <span className="font-10px text-dark">Diagnosis</span>
        <InputGroup className="border rounded-3 border-0 bg-grey-904">
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type="text"
            placeholder="Condition's name"
            value={medicalHistory?.condition ?? ""}
            onChange={(e) => {
              update(index, {
                ...medicalHistory,
                condition: e.target.value,
              });
            }}
          />
        </InputGroup>

        {isError && medicalHistory?.condition === "" ? (
          <Form.Control.Feedback type="invalid">
            Please enter condition
          </Form.Control.Feedback>
        ) : null}
      </div>
      <div className="col">
        <span className="font-10px text-dark">Date of diagnosis</span>
        <InputGroup className="border rounded-3 border-0 bg-grey-904">
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type="date"
            placeholder="dd/mm/yyyy"
            value={
              moment(medicalHistory?.diagnosis_date).format("YYYY-MM-DD") ?? ""
            }
            onChange={(e) => {
              update(index, {
                ...medicalHistory,
                diagnosis_date: e.target.value,
              });
            }}
          />
        </InputGroup>
        {isError && medicalHistory?.diagnosis_date === "" ? (
          <Form.Control.Feedback type="invalid">
            Please enter date
          </Form.Control.Feedback>
        ) : null}
      </div>
    </div>
  );
};

export default MedicalHistoryBlock;

const ONE_HOUR = 60;

export const formatDuration = (
  duration: number,
  isTotalHours: boolean = false
): string => {
  if (isTotalHours) {
    const hours = Math.floor(duration / ONE_HOUR);
    const minutes = Math.floor(duration % ONE_HOUR);
    return `${String(hours).padStart(2, "0")}h${String(minutes).padStart(
      2,
      "0"
    )}m`;
  } else if (duration >= ONE_HOUR) {
    const hours = Math.floor(duration / ONE_HOUR);
    const minutes = Math.floor(duration % ONE_HOUR);
    return `${hours}h ${minutes}m`;
  } else {
    return `${Math.floor(duration)} mins`;
  }
};

export enum ApiStatus {
  IDLE,
  PENDING,
  SUCCESS,
  FAILED,
}

export enum AlertType {
  SOS = "Sos",
  MEDIUM = "Medium",
}

import { HealthSettingsVitalType } from "@app/common/enums/HealthSettingsVitalType";

interface VitalSettingsProps {
  type: HealthSettingsVitalType;
  value: number[]; // Controlled value passed from parent
  onChange: (values: number[]) => void; // Callback to update parent state
}

const vitalSettingAttributes = (type: HealthSettingsVitalType) => {
  switch (type) {
    case HealthSettingsVitalType.GLUCOSE:
      return ["Calibration"];
    case HealthSettingsVitalType.BLOOD_PRESSURE:
      return ["Sys", "Dia"];
    default:
      return [];
  };
};

const VitalSettings: React.FC<VitalSettingsProps> = ({ type, value, onChange }) => {
  const iconSrc = HealthSettingsVitalType.getIcons(type);
  const analysisName = HealthSettingsVitalType.getAnalysisName(type);
  const analysisNameLowerCase = analysisName.toLocaleLowerCase();

  const handleInputChange = (index: number, newValue: string) => {
    const updatedValues = [...value];
    updatedValues[index] = newValue ? parseFloat(newValue) : 0; // Ensure the value is numeric
    onChange(updatedValues); // Notify parent about the change
  };

  return (
    <div className="vital-settings-container bg-white p-3 rounded-3 h-100">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex justify-content-start align-items-center">
            <img width={18} height={18} src={iconSrc} alt="Identity Icon" />
            <span className="font-14px fw-medium ms-2">{analysisName}</span>
          </div>
          <div>
            <p className="m-0 font-12px text-grey-901">{`Set your current ${analysisNameLowerCase} reading`}</p>
          </div>
        </div>
        <div>
          <p className="m-0 font-12px text-dark">{`Enter your current ${analysisNameLowerCase} value to calibrate your readings`}</p>
        </div>
        <div className="d-flex gap-2">
          {vitalSettingAttributes(type).map((attribute, index) => (
            <div className="col" key={attribute}>
              <label className="font-10px d-block">{attribute}</label>
              <div className="position-relative">
                <input
                  className="w-100 font-12px fw-medium p-2 bg-grey-903 border-0 rounded-3"
                  name={attribute}
                  type="number"
                  value={value[index] || ""}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                <span className="me-2 border-start ps-2 font-12px text-grey-901 position-absolute end-0 top-25">
                  {HealthSettingsVitalType.getUnit(type)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VitalSettings;

import { ConfigurationResultDto } from "@redux/features/configuration-service/models/configuration-result-dto";
import { CreateAlarmClockConfigDto, DeviceMainConfiguration, GetConfigurationByUserDto } from "@redux/features/configuration-service/models/create-configuration-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);


const createConfiguration = (config: DeviceMainConfiguration) => {
    return organisationPortalApi.post<string>("/api/v0/config", config);
}

const updateConfigurations = (config: DeviceMainConfiguration) => {
    return organisationPortalApi.put<string>("/api/v0/config/configs", config);
}
const getConfigurationByUser = (config: GetConfigurationByUserDto) => {
    const url = `/api/v0/config/user?user_sid=${config.user_sid}`
        + (config.configuration_name ? `&configuration_name=${config.configuration_name}` : '');
    return organisationPortalApi.get<DeviceMainConfiguration>(url);
}

const createAlarmConfigurations = (alarms: CreateAlarmClockConfigDto) => {
    return organisationPortalApi.post<string>("/api/v0/config/alarm_clock", alarms);
}
const createMedicationConfigurations = (medication: CreateAlarmClockConfigDto) => {
    return organisationPortalApi.post<string>("/api/v0/config/medication_reminder", medication);
}
const ConfigurationServiceApi = {
    createConfiguration,
    updateConfigurations,
    getConfigurationByUser,
    createAlarmConfigurations,
    createMedicationConfigurations
}

export default ConfigurationServiceApi;
import React from "react";
import analysisIcon from "@assets/icons/analysis-icon.svg";
import ProgressContainer from "./ProgressContainer";
import CardContent from "./CardContent";
import { AnalysisStatus } from "@app/common/enums/AnalysisType";
import { VitalType } from "@app/common/enums/VitalTypes";
import { getTimeRange } from "@app/utils/time";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { calculateAverage } from "@app/utils/calculate";

export interface SummaryCardProd {
  analysisType: VitalType;
  analysisStatus: AnalysisStatus;
  date: string;
}

export interface SummaryCardProps extends SummaryCardProd {}

const SummaryCard: React.FC<SummaryCardProps> = ({
  analysisType,
  analysisStatus,
  date,
}) => {
  const vitalsGraphDto = useAppSelector(
    (state: RootState) => state.healthService.vitalsGraphDto[analysisType]
  );

  const { stressDataDto } = useAppSelector(
    (state: RootState) => state.healthServiceV2
  );

  const getSummaryData = (): [number, number, number] => {
    switch (analysisType) {
      case VitalType.STRESS: {
        const series = stressDataDto?.series || [];

        const averageSeries = series.find((item: any) => item.name === "Average")?.data || [];
        const maxSeries = series.find((item: any) => item.name === "Max")?.data || [];
        const minSeries = series.find((item: any) => item.name === "Min")?.data || [];

        const averageValue = averageSeries.length > 0 ? calculateAverage(averageSeries) : 0;
        const highestValue = maxSeries.length > 0 ? calculateAverage(maxSeries) : 0;
        const lowestValue = minSeries.length > 0 ? calculateAverage(minSeries) : 0;

        return [averageValue, lowestValue, highestValue];
      }

      default: {
        const vitalAvg = vitalsGraphDto?.vital_data.VITAL || [];
        const vitalMax = vitalsGraphDto?.vital_data.VITAL_MAX || [];
        const vitalMin = vitalsGraphDto?.vital_data.VITAL_MIN || [];

        return [
          calculateAverage(vitalAvg),
          calculateAverage(vitalMin),
          calculateAverage(vitalMax),
        ];
      }
    }
  };

  const [averageValue, lowestValue, highestValue] = getSummaryData();

  return (
    <div className="bg-white p-3 pe-0 h-100 rounded-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="">
            <img className="img-fluid" src={analysisIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Summary</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(date)}</b>
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 pe-0">
        <CardContent
          analysisType={analysisType}
          averageValue={averageValue}
          lowestValue={lowestValue}
          highestValue={highestValue}
        />
        <div
          className="w-100 mt-2"
          style={{ height: "1px", backgroundColor: "#F7F7F7" }}
        ></div>
        <div className="mt-3 pe-3">
          <ProgressContainer progress={averageValue} analysisType={analysisType} />
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;

import moment from 'moment';

export enum TimePeriod {
  day,
  week,
  month,
  halfYear,
  year,
}

class TimePeriodHelper {
  static timePeriodList = [
    TimePeriod.day,
    TimePeriod.week,
    TimePeriod.month,
    TimePeriod.halfYear,
    TimePeriod.year,
  ];

  static getTimePeriodJson(tp: TimePeriod) {
    switch (tp) {
      case TimePeriod.day:
        return 'DAY';
      case TimePeriod.month:
        return 'MONTH';
      case TimePeriod.week:
        return 'WEEK';
      case TimePeriod.halfYear:
        return '6MONTH';
      case TimePeriod.year:
        return 'YEAR';
      default:
        return 'DAY';
    }
  }


  static getTimePeriod(tp: TimePeriod) {
    switch (tp) {
      case TimePeriod.day:
        return 'DAY';
      case TimePeriod.month:
        return 'MONTH';
      case TimePeriod.week:
        return 'WEEK';
      default:
        return 'DAY';
    }
  }

  static getTimePeriodName(tp: TimePeriod) {
    switch (tp) {
      case TimePeriod.day:
        return 'Daily';
      case TimePeriod.month:
        return 'Month';
      case TimePeriod.week:
        return 'Weekly';
      case TimePeriod.halfYear:
        return '6 Months';
      case TimePeriod.year:
        return 'Year';
      default:
        return 'Daily';
    }
  }

  static getStartTime(tp: TimePeriod) {
    switch (tp) {
      case TimePeriod.day:
        return moment(new Date()).startOf('D').format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.month:
        return moment(new Date())
          .startOf('M')
          .startOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.week:
        return moment(new Date())
          .startOf('W')
          .startOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.halfYear:
        return moment(new Date())
          .subtract(6, 'M')
          .startOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.year:
        return moment(new Date())
          .subtract(1, 'y')
          .startOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      default:
        return moment(new Date()).startOf('D').format('YYYY-MM-DD HH:mm:ss');
    }
  }

  static getEndTime(tp: TimePeriod) {
    switch (tp) {
      case TimePeriod.day:
        return moment(new Date()).endOf('D').format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.month:
        return moment(new Date())
          .endOf('M')
          .endOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.week:
        return moment(new Date())
          .endOf('W')
          .endOf('D')
          .format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.halfYear:
        return moment(new Date()).endOf('D').format('YYYY-MM-DD HH:mm:ss');
      case TimePeriod.year:
        return moment(new Date()).endOf('D').format('YYYY-MM-DD HH:mm:ss');
      default:
        return moment(new Date()).endOf('D').format('YYYY-MM-DD HH:mm:ss');
    }
  }
}

export { TimePeriodHelper };

import { HealthStatus } from "@app/common/enums/HealthStatus";
import { RootState } from "@app/redux/store";
import { useAppSelector } from "@app/redux/store/useStore";
import warningIcon from "@assets/icons/warning_icon.svg";
import lowIcon from "@assets/icons/low_icon.svg";
import avatar from "@assets/icons/complete-profile/default_profile_image.svg";
import Image from "react-bootstrap/Image";

const InformationCard = () => {
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const { userGetProfileImage: avatarImage } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
        <div className="icon-chart-custom py-1 d-flex align-items-center justify-content-center">
          <Image
            height={46}
            width={46}
            src={
              avatarImage?.avatar
                ? `data:image/png;base64,${avatarImage?.avatar}`
                : avatar
            }
            className="rounded-circle"
            alt="Profile"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      {/* Content */}
      <div className="border-grey-901 ">
        <div className="mb-1">
          <div className="d-flex flex-column justify-content-between align-items-start w-100">
            <div className="ps-1 text-dark">
              <div className="d-flex flex-row align-items-center">
                <p className="my-0 font-20px text-black fw-medium whitespace-nowrap overflow-hidden text-truncate">
                  {profile?.first_name ?? ""} {profile?.last_name ?? ""}
                </p>

                {profile?.health_risk === HealthStatus.HIGH ? (
                  <div
                    className="ms-2 px-1 bg-red-150 rounded-2 font-12px text-very-high fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                  >
                    <img width={10} height={10} src={warningIcon} alt="icon" />
                    {profile?.health_risk}
                  </div>
                ) : null}
                {profile?.health_risk === HealthStatus.LOW ? (
                  <div
                    className="ms-2 px-1 bg-green-150 rounded-2 font-12px text-normal fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                  >
                    <img width={10} height={10} src={lowIcon} alt="icon" />
                    {profile?.health_risk}
                  </div>
                ) : null}
              </div>
              <p className="my-0 font-10px text-grey-901 whitespace-nowrap overflow-hidden text-truncate">
                {profile?.email ?? ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationCard;

import DataTable from "react-data-table-component";
import viewMoreIcon from "@assets/icons/view_more_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { useMemo } from "react";
import Table from "@app/components/Table";
import { SOSDetailType, SOSStatus, SOSType } from "@app/common/enums/SOSType";
import { useNavigate } from "react-router-dom";
import { SosResultDto } from "@app/redux/features/v2/sos-service/models/sos-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getSosResultAsync } from "@app/redux/features/v2/sos-service/sosServiceThunk";
import { setSosRequest } from "@app/redux/features/v2/sos-service/sosServiceSlice";

interface ITableProps {
  onEdit: (data: SosResultDto) => void;
  onDelete: (id: string | number) => void;
  data: PaginatedResult<SosResultDto>;
}

const SosTable = ({ onEdit, onDelete, data }: ITableProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row: SosResultDto) => row.name,
      },
      {
        name: "SOS Type",
        selector: (row: SosResultDto) => {
          return (
            <div
              className={`ms-2 px-1 bg-${SOSDetailType.getColor(
                row.sos_type
              )} rounded-1 font-12px text-white fw-medium d-flex flex-row align-items-center gap-1`}
              style={{ height: "17px" }}
            >
              {row.sos_type}
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Date start",
        selector: (row: SosResultDto) => {
          if (row?.created_datetime) {
            const dateObject = new Date(row.created_datetime);
            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(dateObject.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
          }
          return "";
        },
        sortable: true,
      },
      {
        name: "Time",
        selector: (row: SosResultDto) => {
          if (row?.created_datetime) {
            const dateObject = new Date(row.created_datetime);
            const hours = String(dateObject.getHours()).padStart(2, "0");
            const minutes = String(dateObject.getMinutes()).padStart(2, "0");
            const seconds = String(dateObject.getSeconds()).padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
          }
          return "";
        },
        sortable: true,
      },
      {
        name: "Status",
        selector: (row: SosResultDto) => {
          return (
            <div>
              {row.status === SOSStatus.OPEN ? (
                <div
                  className="ms-2 px-1 bg-blue-904 rounded-1 font-12px text-low fw-medium d-flex flex-row align-items-center gap-1"
                  style={{ height: "17px" }}
                >
                  {row.status}
                </div>
              ) : (
                <div
                  className="ms-2 px-1 bg-grey-907 rounded-1 font-12px text-grey-901 fw-medium d-flex flex-row align-items-center gap-1"
                  style={{ height: "17px" }}
                >
                  {row.status}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Actions",
        id: "action",
        style: {
          width: "fit-content",
          flex: "0 !important",
          padding: "12.5px 16px !important",
        },

        cell: (row: SosResultDto) => (
          <div
            className="action-cell"
            onClick={() => navigate(`/portal-new/sos/${row.sid}`)}
          >
            <div className="d-flex flex-row align-items-center text-low fw-medium font-12px text-truncate cursor-pointer">
              View details
              <img
                height={8.25}
                src={viewMoreIcon}
                alt="Edit"
                className="ms-2"
              />
            </div>
          </div>
        ),
      },
    ],
    [onDelete, onEdit]
  );

  const { usersRequest } = useAppSelector(
    (state: RootState) => state.usersServiceV2
  );

  const handlePageChange = (page: number) => {
    dispatch(setSosRequest({ ...usersRequest, page_number: page }));
    dispatch(getSosResultAsync({ ...usersRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(
      setSosRequest({
        ...usersRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
    dispatch(
      getSosResultAsync({
        ...usersRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
  };
  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
export default SosTable;

export enum RespiratoryRateStatus {
  LOW = "Low", // 0 - 12
  NORMAL = "Normal", // 12 - 20
  HIGH = "High", // 20+
}

export namespace RespiratoryRateStatus {
  export function getColor(status: String): string {
    switch (status) {
      case RespiratoryRateStatus.LOW:
        return "low";
      case RespiratoryRateStatus.HIGH:
        return "high";
      default:
        return "normal";
    }
  }
  export function fromValue(value: number): RespiratoryRateStatus {
    if (value < 12) {
      return RespiratoryRateStatus.LOW;
    } else if (value <= 20) {
      return RespiratoryRateStatus.NORMAL;
    } else {
      return RespiratoryRateStatus.HIGH;
    }
  }
  export function getName(status: String): string {
    switch (status) {
      case RespiratoryRateStatus.LOW:
        return "Low";
      case RespiratoryRateStatus.HIGH:
        return "High";
      default:
        return "Normal";
    }
  }
}

export enum OxygenStatus {
  VERY_LOW = "Very Low", // 0 - 90%
  LOW = "Low", // 90 - 95%
  NORMAL = "Normal", // 95 - 100%
}

export namespace OxygenStatus {
  export function getColor(status: String): string {
    switch (status) {
      case OxygenStatus.LOW:
        return "low";
      case OxygenStatus.VERY_LOW:
        return "very-low";
      default:
        return "normal";
    }
  }
  export function fromValue(value: number): OxygenStatus {
    if (value < 90) {
      return OxygenStatus.VERY_LOW;
    } else if (value < 95) {
      return OxygenStatus.LOW;
    } else {
      return OxygenStatus.NORMAL;
    }
  }
  export function getName(status: String): string {
    switch (status) {
      case OxygenStatus.LOW:
        return "Low";
      case OxygenStatus.VERY_LOW:
        return "Very Low";
      default:
        return "Normal";
    }
  }
}

export enum HeartRateStatus {
  LOW = "Low", // 0 - 50
  NORMAL = "Normal", // 50 - 100
  HIGH = "High", // 100+
}

export namespace HeartRateStatus {
  export function getColor(status: String): string {
    switch (status) {
      case HeartRateStatus.LOW:
        return "low";
      case HeartRateStatus.NORMAL:
        return "normal";
      default:
        return "high";
    }
  }
  export function fromValue(value: number): HeartRateStatus {
    if (value < 50) {
      return HeartRateStatus.LOW;
    } else if (value < 90) {
      return HeartRateStatus.NORMAL;
    } else {
      return HeartRateStatus.HIGH;
    }
  }
  export function getName(status: String): string {
    switch (status) {
      case HeartRateStatus.LOW:
        return "Low";
      case HeartRateStatus.HIGH:
        return "High";
      default:
        return "Normal";
    }
  }
}

export enum GlucoseStatus {
  VERY_LOW = "Very Low", // < 3.1 mmol/L
  LOW = "Low", // 3.1 - 3.8 mmol/L
  NORMAL = "Normal", // 3.9 - 10 mmol/L
  HIGH = "High", // 10 - 13.9 mmol/L
  VERY_HIGH = "Very High", // 14+ mmol/L
}

export const GlucoseRangeValues = {
  VERY_LOW: [0, 3],
  LOW: [3, 4],
  TARGET_RANGE: [4, 8],
  HIGH: [8, 11],
  VERY_HIGH: [11, 20],
};

export namespace GlucoseStatus {
  export function getColor(status: String): string {
    switch (status) {
      case GlucoseStatus.VERY_LOW:
        return "very-low";
      case GlucoseStatus.LOW:
        return "low";
      case GlucoseStatus.NORMAL:
        return "normal";
      case GlucoseStatus.HIGH:
        return "high";
      case GlucoseStatus.VERY_HIGH:
        return "very-high";
      default:
        return "normal";
    }
  }

  export function fromValue(value: number): GlucoseStatus {
    if (value < 3.1) {
      return GlucoseStatus.VERY_LOW;
    } else if (
      value >= GlucoseRangeValues.LOW[0] &&
      value <= GlucoseRangeValues.LOW[1]
    ) {
      return GlucoseStatus.LOW;
    } else if (
      value >= GlucoseRangeValues.TARGET_RANGE[0] &&
      value <= GlucoseRangeValues.TARGET_RANGE[1]
    ) {
      return GlucoseStatus.NORMAL;
    } else if (
      value >= GlucoseRangeValues.HIGH[0] &&
      value <= GlucoseRangeValues.HIGH[1]
    ) {
      return GlucoseStatus.HIGH;
    } else {
      return GlucoseStatus.VERY_HIGH;
    }
  }

  export function getName(status: String): string {
    switch (status) {
      case GlucoseStatus.VERY_LOW:
        return "Very Low";
      case GlucoseStatus.LOW:
        return "Low";
      case GlucoseStatus.NORMAL:
        return "Normal";
      case GlucoseStatus.HIGH:
        return "High";
      default:
        return "Very High";
    }
  }
}

export enum BloodPressureStatus {
  LOW = "Low",
  NORMAL = "Normal",
  HIGH = "High",
}

export namespace BloodPressureStatus {
  export function getColor(status: String): string {
    switch (status) {
      case BloodPressureStatus.LOW:
        return "low";
      case BloodPressureStatus.HIGH:
        return "high";
      default:
        return "normal";
    }
  }

  export function fromValue(
    systolic: number,
    diastolic?: number
  ): BloodPressureStatus {
    const map = (systolic + 2 * (diastolic ?? 1)) / 3;

    if (map < 70) {
      return BloodPressureStatus.LOW;
    } else if (map <= 100) {
      return BloodPressureStatus.NORMAL;
    } else {
      return BloodPressureStatus.HIGH;
    }
  }

  export function getName(status: String): string {
    switch (status) {
      case BloodPressureStatus.LOW:
        return "Low";
      case BloodPressureStatus.HIGH:
        return "High";
      default:
        return "Normal";
    }
  }
}

export enum TemperatureStatus {
  LOW = "Low", // < 36
  NORMAL = "Normal", // 36 - 38
  HIGH = "High", // 38+
}

export namespace TemperatureStatus {
  export function getColor(status: String): string {
    switch (status) {
      case TemperatureStatus.LOW:
        return "low";
      case TemperatureStatus.HIGH:
        return "high";
      default:
        return "normal";
    }
  }

  export function fromValue(value: number): TemperatureStatus {
    if (value < 36) {
      return TemperatureStatus.LOW;
    } else if (value <= 38) {
      return TemperatureStatus.NORMAL;
    } else {
      return TemperatureStatus.HIGH;
    }
  }

  export function getName(status: String): string {
    switch (status) {
      case TemperatureStatus.LOW:
        return "Low";
      case TemperatureStatus.HIGH:
        return "High";
      default:
        return "Normal";
    }
  }
}

export enum StressStatus {
  RELAXED = "Relaxed",
  NORMAL = "Normal",
  MEDIUM = "Medium",
  HIGH = "High",
}

export namespace StressStatus {
  export function getColor(status: String): string {
    switch (status) {
      case StressStatus.RELAXED:
        return "low";
      case StressStatus.MEDIUM:
        return "normal";
      case StressStatus.HIGH:
        return "high";
      default:
        return "normal";
    }
  }

  export function fromValue(value: number): StressStatus {
    if (value < 15) {
      return StressStatus.RELAXED;
    } else if (value <= 35) {
      return StressStatus.NORMAL;
    } else if (value <= 70) {
      return StressStatus.MEDIUM;
    } else {
      return StressStatus.HIGH;
    }
  }

  export function getName(status: String): string {
    switch (status) {
      case StressStatus.RELAXED:
        return "Relaxed";
      case StressStatus.MEDIUM:
        return "Medium";
      case StressStatus.HIGH:
        return "High";
      default:
        return "Normal";
    }
  }
}

export type AnalysisStatus =
  | RespiratoryRateStatus
  | OxygenStatus
  | HeartRateStatus
  | GlucoseStatus
  | BloodPressureStatus
  | TemperatureStatus
  | StressStatus;

import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

interface SubNavProps {
  navLinks: {
    label: string;
    path: string;
  }[];
}
const SubNavMobile = ({ navLinks }: SubNavProps) => {
  return (
    <div>
      <nav className="sub-nav h-100 navbar navbar-expand-lg navbar-light bg-white text-dark p-0">
        <div className="container-fluid h-100 justify-content-center">
          <div className="row h-100 w-100 align-items-center">
            <div id="navbarNavAltMarkup">
              <div className="navbar-nav w-100 h-100">
                <div className="d-flex flex-column flex-grow-1 justify-content-center">
                  {navLinks.map((link) => (
                    <NavLink
                      className={({ isActive }) =>
                        ` nav-link px-3 py-1 ${isActive ? "active" : ""}`
                      }
                      aria-current="page"
                      to={link.path}
                      key={link.path}
                    >
                      <p className="font-12px mb-0">
                        {link.label}
                      </p>
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SubNavMobile;

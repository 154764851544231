import DataTable from "react-data-table-component";
import editIcon from "@assets/icons/edit_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { useMemo } from "react";
import moment from "moment";
import Table from "@app/components/Table";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";
import { MedicalAlertDto } from "@app/redux/features/v2/medical-service/models/medical-alert-result-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getMedicalAlertByWearerAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { setMedicalAlertRequest } from "@app/redux/features/v2/medical-service/medicalServiceSlice";

interface ITableProps {
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  data: PaginatedResult<MedicalAlertDto>; 
}

const HealthTable = ({ onEdit, onDelete, data }: ITableProps) => {
  const dispatch = useAppDispatch();
  const columns = useMemo(() => [
    {
      name: "Alert type",
      selector: (row: MedicalAlertDto) => row.alert_type,
    },
    {
      name: "Start date",
      selector: (row: MedicalAlertDto) => moment(row.alert_time).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: MedicalAlertDto) => moment(row.alert_time).format('hh:mm A'),
      sortable: true,
    },
    {
      name: "Alert description",
      selector: (row: MedicalAlertDto) => row.alert_description,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      style: {
        width: "fit-content",
        flex: "0 !important",
        "padding": "12.5px 16px !important"
      },

      cell: (row: MedicalAlertDto) => (
        <div className="action-cell">
          <img height={16} width={16} src={editIcon} alt="Edit" onClick={() => onEdit(row)} className="cursor-pointer" />
          <img height={16} width={16} src={deleteIcon} alt="Delete" onClick={() => onDelete(row)} className="ms-2 cursor-pointer" />
        </div>
      ),

    },
  ], [onDelete, onEdit]);

  const { medicalAlertRequest } = useAppSelector((state: RootState) => state.medicalServiceV2);

  const handlePageChange = (page: number) => {
    dispatch(setMedicalAlertRequest({ ...medicalAlertRequest, page_number: page}));
    dispatch(getMedicalAlertByWearerAsync({ ...medicalAlertRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(setMedicalAlertRequest({ ...medicalAlertRequest, page_number: 1, page_size: rowsPerPage }));
    dispatch(getMedicalAlertByWearerAsync({ ...medicalAlertRequest, page_number: 1, page_size: rowsPerPage }));
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

export default HealthTable;

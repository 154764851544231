import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import { UserHealthCalibrationDto } from "./models/user-health-calibration-dto";
import { GetUserHealthCalibrationDto } from "./models/get-user-health-calibration-dto";
import HealthSettingsService from "@app/services/v2/health-settings-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import { CreateUserHealthCalibrationDto } from "./models/create-user-health-calibration-dto";
import { addErrorNotification } from "../../common/notificationSlice";
import { AxiosError } from "axios";


export const getUserHealthCalibrationAsync = createAsyncThunk<UserHealthCalibrationDto, GetUserHealthCalibrationDto, 
    { rejectValue: ApiError | undefined }
>(
    "healthCalibrationService/getUserHealthCalibration",
    async(credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try{
            const response = await HealthSettingsService.getUserHealthCalibration(credentials);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)

export const setUserHealthCalibrationAsync = createAsyncThunk<null, CreateUserHealthCalibrationDto, 
    { rejectValue: ApiError | undefined }
>(
    "healthCalibrationService/CreateUserHealthCalibration",
    async(credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try{
            const response = await HealthSettingsService.createUserHealthCalibration(credentials);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)
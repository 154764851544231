import React, { useEffect, useState } from "react";
import Table from "./Table";
import { Button, Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import searchIcon from "@assets/icons/search_icon.svg";
import closeIcon from "@assets/icons/close_icon.svg";
import plusIcon from "@assets/icons/plus_icon.svg";
import alertIcon from "@assets/icons/alert_icon.svg";
import DeleteModal from "../../../../../components/Modal/DeleteModal";
import AlertsHealthIncidentsModal from "./AlertsHealthIncidentsModal";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getMedicalAlertByWearerAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { setMedicalAlertRequest } from "@app/redux/features/v2/medical-service/medicalServiceSlice";
import { deleteMedicalAlertAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { PaginateDefault } from "@app/common/enums/PaginateDefault";

interface HistoryRecordCardProps {}

const AlertsHealthIncidentsCard: React.FC<HistoryRecordCardProps> = () => {
  const dispatch = useAppDispatch();

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [dataSelected, setDataSelected] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const { medicalAlertResultPaginate, medicalAlertRequest } = useAppSelector((state: RootState) => state.medicalServiceV2);
  const { user_profile: profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);

  useEffect(() => {
    if (profile?.sid) {
      dispatch(setMedicalAlertRequest({...medicalAlertRequest, wearer_sid: profile?.sid ?? ""}));
      dispatch(getMedicalAlertByWearerAsync({...medicalAlertRequest, wearer_sid: profile?.sid ?? ""}));
    }
  }, [dispatch, profile?.sid]);

  const clearSearch = () => {
    setSearchTerm("");
    dispatch(setMedicalAlertRequest({
      ...medicalAlertRequest,
      page_number: 1,
      page_size: 10,
      filter_by: "",
    }));
    dispatch(getMedicalAlertByWearerAsync({
      ...medicalAlertRequest,
      page_number: 1,
      page_size: 10,
      filter_by: "",
    }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);

    dispatch(setMedicalAlertRequest({
      ...medicalAlertRequest,
      page_number: 1,
      page_size: 10,
      filter_by: e.target.value,
    }));
    dispatch(getMedicalAlertByWearerAsync({
      ...medicalAlertRequest,
      page_number: 1,
      page_size: 10,
      filter_by: e.target.value,
    }));
  };

  const onShowModifyModal = (data?: null) => {
    setShowModifyDialog(true);
    setDataSelected(data);
  }

  const onCloseModifyModal = () => {
    setShowModifyDialog(false);
    setDataSelected(null);
  }

  const onShowDeleteModal = (data: any) => {
    setShowDeleteDialog(true);
    setDataSelected(data);
  }

  const onCloseDeleteModal = () => {
    setShowDeleteDialog(false);
    setDataSelected(null);
  }

  const onSubmitDeleteModal = () => {
    if (!dataSelected.sid) return;

    dispatch(deleteMedicalAlertAsync(dataSelected.sid)).then(res => {
      if (res) {
        dispatch(getMedicalAlertByWearerAsync({...medicalAlertRequest, page_number: 1, page_size: 10}));
      }
    });
    onCloseDeleteModal();
  };

  return (
    <div>
      <div className="d-flex flex-column mt-3" style={{ gap: "18px" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex rounded-3 col-6 title-chart gap-2 align-items-center">
            <div className="rounded-5 icon-chart-custom">
              <img height={32} width={32} src={alertIcon} alt="Icon" />
            </div>
            <div className="text-dark">
              <span className="font-14px fw-bold text-dark">
                Alerts & Health Incidents
              </span>
              <p className="font-10px text-grey-901 m-0">
                Lorem ipsum dolor sit amet, in quo illum vocibus.
              </p>
            </div>
          </div>
          <div className="col-4 d-flex align-items-center gap-2">
            <InputGroup className="rounded-3 bg-white">
              <div className="search-icon-history">
                <img className="h-100" width={16} src={searchIcon} alt="Search" />
              </div>
              <Form.Control
                className="border-0 bg-transparent ms-4 font-12px py-2"
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="close-icon-history cursor-pointer" onClick={clearSearch}>
                <img src={closeIcon} alt="Close" />
              </div>
            </InputGroup>
            <Button
              onClick={() => onShowModifyModal()}
              className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center"
            >
              <img src={plusIcon} alt="Plus" />
              <p className="ms-1 my-0 font-12px progress-bar">Add</p>
            </Button>
          </div>
        </div>
        <Table onEdit={onShowModifyModal} onDelete={onShowDeleteModal} data={medicalAlertResultPaginate ?? PaginateDefault} />
      </div>
      <AlertsHealthIncidentsModal
        type={dataSelected ? "update" : "add"}
        show={showModifyDialog}
        onHide={onCloseModifyModal}
        dataSelected={dataSelected}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteDialog}
        onHide={onCloseDeleteModal}
        onSubmit={onSubmitDeleteModal}
        title="Alert & Health Incident"
        icon={alertIcon}
        content="Are you sure you want to remove this record?"
      />
    </div>
  );
};

export default AlertsHealthIncidentsCard;

import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  ReferenceLine,
} from "recharts";

type Props = {
  summaryData: {
    date: number;
    value1?: number;
    value2?: number;
  }[];
  yAxisDomain?: number[];
  yAxisTicks?: number[];
};

const MonthlyReportBarChart = ({ summaryData, yAxisDomain, yAxisTicks }: Props) => {
  return (
    <div className="container font-12px p-0">
      <div className="chart-container position-relative">
        <ResponsiveContainer width="100%" height={210} style={{marginLeft: "-3rem"}}>
          <BarChart data={summaryData} barSize={8} style={{ stroke: '#fff', strokeWidth: 2 }} >
            <XAxis interval={0} axisLine={false} tickLine={false} dataKey="date" fontSize={12} padding={{ left: 5 }} tickFormatter={(tick) => tick.toString().padStart(2, "0")} />
            <YAxis interval={0} axisLine={false} tickLine={false} padding={{top: 10}} type="number" domain={yAxisDomain} ticks={yAxisTicks} />
            <CartesianGrid stroke="#F7F7F7" strokeDasharray="2 2" />
            <ReferenceLine y={4.5} stroke="#D4D8DC" strokeDasharray="3 3" />
            <Bar dataKey="value2" stackId="a" fill="#3F72F3" radius={[10, 10, 10, 10]} />
            <Bar dataKey="value1" stackId="a" fill="#A281E4" radius={[10, 10, 10, 10]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default MonthlyReportBarChart;

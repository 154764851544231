import DataTable from "react-data-table-component";
import Table from "../Table";

interface TableProps {
  period: string;
  data?: any;
}

const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent",
    },
  },
  rows: {
    style: {
      backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#ECECEC",
      borderRadius: "7px",
      border: "1px solid #cccccc",
    },
  },

  headCells: {
    style: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },

  pagination: {
    style: {
      color: "#212121",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    pageButtonsStyle: {
      // backgroundColor: "black",x`
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Page",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

const customSort = (rows: any, selector: any, direction: any) => {
  return rows.sort((a: any, b: any) => {
    const aField = selector(a).toLowerCase();
    const bField = selector(b).toLowerCase();

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === "desc" ? comparison * -1 : comparison;
  });
};

const StressTable: React.FC<TableProps> = ({ period, data }) => {
  console.log(data)
  const columns = [
    {
      name: "Health vitals",
      selector: (row: any) => row.healthVitals,
    },
    {
      name: "Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: any) => row.time,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row: any) => row.duration,
      sortable: true,
    },
    {
      name: "Stress level",
      selector: (row: any) => row.stressLevel,
      cell: (row: any) => {
        let color;
        switch (row.stressLevel) {
          case "Relaxed":
            color = "low";
            break;
          case "Normal":
            color = "normal";
            break;
          case "Medium":
            color = "high";
            break;
          case "High":
            color = "very-high";
            break;
          default:
            color = "normal";
        }
        return (
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="fw-medium"
          >
            <span className={`fw-medium fs-3 me-2 text-${color}`}>
              &#x2022;
            </span>
            <span className={`fw-medium text-${color}`}>{row.stressLevel}</span>
          </span>
        );
      }
    }
  ];

  return (
    <div className="row">
      <Table columns={columns} data={data} />
    </div>
  );
}

export default StressTable;

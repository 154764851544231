import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "@app/redux/store";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import { CreateOrganisationProfileDto } from "@app/redux/features/v2/user-profile-service/models/complete-organization-profile-dto";

const CompleteOrganizationProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityService
  );

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 100 }));
  }, [dispatch]);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm<CreateOrganisationProfileDto>({
    defaultValues: {
      sid: currentUser?.id,
      email: currentUser?.email,
      organization_name: "",
      location: "",
      jurisdiction: "",
      address: "",
      city: "",
      state: "",
      user_sid: "",
      organisation_sid: currentUser?.org_id ?? "",
    },
  });

  const onSubmit = (data: CreateOrganisationProfileDto) => {};

  return (
    <div style={{ paddingLeft: 103 }}>
      <div style={{ width: 640 }}>
        <div className="mt-3 mb-3 font-20px fw-bold">Organisation Profile</div>
        <div style={{ backgroundColor: "white", padding: 16, borderRadius: 6 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="font-14px fw-bold mt-2">Organization's informations</div>

              <div className="col-12">
                <div className="row gap-1">
                  <div className="col">
                    <span className="font-10px text-dark">Organization’s name</span>
                    <Controller
                      name="organization_name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Organization name is required`,
                        },
                      }}
                      render={({ field }) => (
                        <InputGroup className="border rounded-3 border-0 bg-grey-904">
                          <Form.Control
                            {...field}
                            className="border-0 bg-transparent font-12px py-2"
                            type="text"
                            placeholder="Organization Name"
                          />
                        </InputGroup>
                      )}
                    />
                    {errors?.organization_name && (
                      <Form.Text className="text-danger">
                        {errors.organization_name.message}
                      </Form.Text>
                    )}
                  </div>

                  <div className="col">
                    <span className="font-10px text-dark">Location</span>
                    <Controller
                      name="location"
                      control={control}
                      render={({ field }) => (
                        <InputGroup className="border rounded-3 border-0 bg-grey-904">
                          <Form.Select
                            {...field}
                            className="border-0 bg-transparent font-12px py-2"
                          >
                            <option selected value="VN">Vietnam</option>
                            <option value="US">America</option>
                          </Form.Select>
                        </InputGroup>
                      )}
                    />
                    {errors?.location && (
                      <Form.Text className="text-danger">
                        {errors.location.message}
                      </Form.Text>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12">
                {/* Label */}
                <span className="font-10px text-dark">Data Protection Jurisdiction</span>
                <Controller
                  name="jurisdiction"
                  control={control}
                  render={({ field }) => (
                    <InputGroup className="border rounded-3 border-0 bg-grey-904">
                      <Form.Select
                        {...field}
                        className="border-0 bg-transparent font-12px py-2"
                      >
                        <option selected value="housing-codes">Housing Codes</option>
                        <option value="building-codes">Building Codes</option>
                      </Form.Select>
                    </InputGroup>
                  )}
                />
                {errors?.jurisdiction && (
                  <Form.Text className="text-danger">
                    {errors.jurisdiction.message}
                  </Form.Text>
                )}
              </div>

              <div className="col-12">
                <span className="font-10px text-dark">Address</span>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `Address is required`,
                    },
                  }}
                  render={({ field }) => (
                    <InputGroup className="border rounded-3 border-0 bg-grey-904">
                      <Form.Control
                        {...field}
                        className="border-0 bg-transparent font-12px py-2"
                        type="text"
                        placeholder="Enter address"
                      />
                    </InputGroup>
                  )}
                />
                {errors?.address && (
                  <Form.Text className="text-danger">
                    {errors.address.message}
                  </Form.Text>
                )}
              </div>

              <div className="col-12">
                <div className="row gap-1">
                  <div className="col">
                    <span className="font-10px text-dark">Address</span>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Address is required`,
                        },
                      }}
                      render={({ field }) => (
                        <InputGroup className="border rounded-3 border-0 bg-grey-904">
                          <Form.Control
                            {...field}
                            className="border-0 bg-transparent font-12px py-2"
                            type="text"
                            placeholder="Enter address"
                          />
                        </InputGroup>
                      )}
                    />
                    {errors?.address && (
                      <Form.Text className="text-danger">
                        {errors.address.message}
                      </Form.Text>
                    )}

                  </div>
                  <div className="col">
                    <span className="font-10px text-dark">City</span>
                    <Controller
                      name="city"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `City is required`,
                        },
                      }}
                      render={({ field }) => (
                        <InputGroup className="border rounded-3 border-0 bg-grey-904">
                          <Form.Select
                            {...field}
                            className="border-0 bg-transparent font-12px py-2"
                          >
                            <option selected value="HCMC">HCM City</option>
                            <option value="NYC">Newyork City</option>
                          </Form.Select>
                        </InputGroup>
                      )}
                    />
                    {errors?.city && (
                      <Form.Text className="text-danger">
                        {errors.city.message}
                      </Form.Text>
                    )}

                  </div>
                  <div className="col">
                    <span className="font-10px text-dark">State</span>
                    <Controller
                      name="state"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `State is required`,
                        },
                      }}
                      render={({ field }) => (
                        <InputGroup className="border rounded-3 border-0 bg-grey-904">
                          <Form.Select
                            {...field}
                            className="border-0 bg-transparent font-12px py-2"
                          >
                            <option selected value="washington">Washington State</option>
                            <option value="alabama">Alabama State</option>
                          </Form.Select>
                        </InputGroup>
                      )}
                    />
                    {errors?.address && (
                      <Form.Text className="text-danger">
                        {errors.address.message}
                      </Form.Text>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <Button
                  type="submit"
                  style={{ width: 68, paddingLeft: 12 }}
                  className="rounded-3 text-white px-3 d-flex justify-content-center align-items-center no-hover bg-blue-901 border-blue-901"
                >
                  <p className="ms-1 my-0 font-12px progress-bar">Save</p>
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CompleteOrganizationProfile;

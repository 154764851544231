import MedicalHistoryCard from "@app/views/consumer/medical/widgets/MedicalHistoryCard";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import MedicationCard from "@app/views/consumer/medical/widgets/MedicationCard";
import ClientDetailBreadcrumb from "@app/components/Breadcrumb/ClientDetailBreadcrumb";

const Medical = () => {
  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column gap-3">
          <ClientDetailBreadcrumb viewName="Medical" />
          <div className="row d-flex ">
            <div className="tab-container history-tabs">
              <Tabs className="">
                <Tab
                  eventKey="medicalHistory"
                  title="Medical History"
                  className="text-black"
                >
                  <MedicalHistoryCard />
                </Tab>
                <Tab
                  eventKey="medication"
                  title="Medication"
                  className="text-black"
                >
                  <MedicationCard />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Medical;

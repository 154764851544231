import { ForgotPasswordResponseDto, ResetPasswordResponseDto } from "@app/redux/features/security-service/models/forgot-password-dto";
import { RegisterResponseDto } from "@app/redux/features/security-service/models/register-credentials-dto";
import { ActivateUserDto } from "@app/redux/features/v2/security-service/models/activate-user-dto";
import { ResendVerifyOtpDto } from "@app/redux/features/v2/security-service/models/resend-verify-otp-dto";
import { VerifyOtpDto } from "@app/redux/features/v2/security-service/models/verify-otp";
import {
  ForgotPasswordDto,
  resetForgotPasswordDto,
  ResetPasswordDto,
} from "@redux/features/v2/security-service/models/forgot-password-dto";

import { LoginCredentialsDto, LoginResponseDto } from "@redux/features/v2/security-service/models/login-credentials-dto";
import {
  RegisterClientCredentialsDto,
  RegisterClientResultDto,
  RegisterCredentialsDto,
  RegisterUserCredentialsDto,
} from "@redux/features/v2/security-service/models/register-credentials-dto";
import { ResendConfirmationEmailDto } from "@redux/features/v2/security-service/models/resend-confirmation-email-dto";
import { config } from "@services/config";
import { createOrganisationPortalApi } from "@services/organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const login = (loginCredentials: LoginCredentialsDto) => {
  return organisationPortalApi.post<LoginResponseDto>(
    "api/v2/auth/login",
    loginCredentials,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

const register = (registerCredentials: RegisterCredentialsDto) => {
  return organisationPortalApi.post<RegisterResponseDto>(
    "/api/v2/auth/register",
    registerCredentials
  );
};

const confirmEmail = (token: string) => {
  return organisationPortalApi.post<boolean>(`/v2/auth/confirm-email`, {
    token: token,
  });
};

const forgotPassword = (credential: ForgotPasswordDto) => {
  return organisationPortalApi.post<ForgotPasswordResponseDto>(
    "/api/v2/auth/forgot-password",
    credential
  );
};

const resetForgotPassword = (credential: resetForgotPasswordDto) => {
  return organisationPortalApi.put<ResetPasswordResponseDto>("/api/v2/auth/forgot-password", credential);
};

const resetPassword = (credential: ResetPasswordDto) => {
  return organisationPortalApi.post<any>("/v2/auth/reset-password", credential);
};

const registerClient = (registerCredentials: RegisterClientCredentialsDto) => {
  return organisationPortalApi.post<RegisterClientResultDto>(
    "/v2/auth/register-client",
    registerCredentials
  );
};

const registerUser = (registerCredentials: RegisterUserCredentialsDto) => {
  return organisationPortalApi.post<RegisterClientResultDto>(
    "/v2/auth/register-user",
    registerCredentials
  );
};
const checkUserExists = (email: string) => {
  return organisationPortalApi.get<any>(
    `/api/v2/auth/identity-exists?in_user_email=${email}`
  );
};

const resendConfirmationEmail = (model: ResendConfirmationEmailDto) => {
  return organisationPortalApi.post<any>(
    "/v2/auth/resend-confirmation-email",
    model
  );
};

const verifyOtp = (payload: VerifyOtpDto) => {
  return organisationPortalApi.post<string>("/api/v2/auth/verify-otp", payload);
};

const resendVerifyOtp = (payload: ResendVerifyOtpDto) => {
  return organisationPortalApi.post<string>("/api/v2/auth/resend-otp", payload);
};

const activateUser = (payload: ActivateUserDto) => {
  return organisationPortalApi.put<string>(`/api/v2/auth/activate-user`, payload);
}

const SecurityServiceApi = {
  login,
  register,
  confirmEmail,
  registerClient,
  registerUser,
  forgotPassword,
  resetPassword,
  checkUserExists,
  resendConfirmationEmail,
  verifyOtp,
  resendVerifyOtp,
  activateUser,
  resetForgotPassword
};

export default SecurityServiceApi;

import { Navigate, Route, Routes } from "react-router";
import ConsumerLayout from "@app/views/consumer";
import Login from "@views/auth/login";
import Register from "@views/auth/register";
import ProtectedRoute from "./ProtectedRoute";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { router } from "@routers/router";
import HealthInsights from "@views/consumer/health-insights";
import Glucose from "@app/views/consumer/health-insights/glucose";
import Oxygen from "@app/views/consumer/health-insights/oxygen";
import BloodPressure from "@app/views/consumer/health-insights/blood-pressure";
import HeartRate from "@app/views/consumer/health-insights/heart-rate";
import RespirationRate from "@app/views/consumer/health-insights/respiratory-rate";
import HealthSummary from "@app/views/consumer/health-summary";
import Sleep from "@app/views/consumer/health-insights/sleep";
import Steps from "@app/views/consumer/health-insights/steps";
import Temperature from "@app/views/consumer/health-insights/temperature";
import Stress from "@app/views/consumer/health-insights/stress";
import Medical from "@app/views/consumer/medical";
import AlertsHealthIncidents from "@app/views/consumer/alerts-health-incidents";
import ECG from "@app/views/consumer/health-insights/ecg";
import Profile from "@app/views/consumer/profile";
import MonthlyReports from "@app/views/consumer/monthly-reports";
import HealthSettings from "@app/views/consumer/health-settings";
import AuthLayout from "@app/views/auth";
import ForgetPassword from "@app/views/auth/forget-password";
import ResetPassword from "@app/views/auth/reset-password";
import OtpVerification from "@app/views/auth/otp-verification";
import SignupOtpVerification from "@app/views/auth/signup-otp-verification";
import CompleteIndividualProfile from "@app/views/auth/complete-invidual-profile";
import PortalLayout from "@app/views/portal";
import Home from "@app/views/portal/home";
import Sos from "@app/views/portal/sos";
import Admin from "@app/views/portal/admin";
import Devices from "@app/views/portal/admin/devices";
import CompleteOrganizationProfile from "@app/views/auth/complete-organization-profile";
import ManageUsers from "@app/views/portal/admin/users";

//clients
import Clients from "@app/views/portal/clients";
import ClientDetail from "@app/views/portal/clients/client-detail";
import ClientHealthInsights from "@views/portal/clients/client-detail/health-insights";
import ClientGlucose from "@app/views/portal/clients/client-detail/health-insights/glucose";
import ClientOxygen from "@app/views/portal/clients/client-detail/health-insights/oxygen";
import ClientBloodPressure from "@app/views/portal/clients/client-detail/health-insights/blood-pressure";
import ClientHeartRate from "@app/views/portal/clients/client-detail/health-insights/heart-rate";
import ClientRespirationRate from "@app/views/portal/clients/client-detail/health-insights/respiratory-rate";
import ClientHealthSummary from "@app/views/portal/clients/client-detail/health-summary";
import ClientSleep from "@app/views/portal/clients/client-detail/health-insights/sleep";
import ClientSteps from "@app/views/portal/clients/client-detail/health-insights/steps";
import ClientTemperature from "@app/views/portal/clients/client-detail/health-insights/temperature";
import ClientStress from "@app/views/portal/clients/client-detail/health-insights/stress";
import ClientMedical from "@app/views/portal/clients/client-detail/medical";
import ClientAlertsHealthIncidents from "@app/views/portal/clients/client-detail/alerts-health-incidents";
import ClientECG from "@app/views/portal/clients/client-detail/health-insights/ecg";
import ClientMonthlyReports from "@app/views/portal/clients/client-detail/monthly-reports";
import SosDetail from "@app/views/portal/sos/sos-detail";
const AppRoutes = () => {
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );

  return (
    <Routes>
      {/* <Route path="auth"> */}
      <Route element={<AuthLayout />}>
        <Route path={router.auth.login} element={<Login />} /> {/* </Route> */}
        <Route path={router.auth.register} element={<Register />} />
        <Route path={router.auth.forgotPassword} element={<ForgetPassword />} />
        <Route path={router.auth.resetPassword} element={<ResetPassword />} />
        <Route
          path={router.auth.otpVerification}
          element={<OtpVerification />}
        />
        <Route
          path={router.auth.signUpOtpVerification}
          element={<SignupOtpVerification />}
        />
      </Route>

      {/* Admin Portal Routes */}
      <Route
        element={
          <ProtectedRoute allowedRoles={["org_owner"]}>
            <PortalLayout />
          </ProtectedRoute>
        }
      >
        <Route path="portal-new">
          <Route index element={<Navigate to={router.portal.home} replace />} />
          <Route path={router.portal.clients} element={<Clients />} />
          <Route
            path={router.portal.clientDetail.root}
            element={<ClientDetail />}
          >
            <Route
              index
              element={
                <Navigate
                  to={router.portal.clientDetail.monthlyReports}
                  replace
                />
              }
            />
            <Route
              path={router.portal.clientDetail.healthInsights.root}
              element={<ClientHealthInsights />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={router.portal.clientDetail.healthInsights.heartRate}
                    replace
                  />
                }
              />
              <Route
                path={router.portal.clientDetail.healthInsights.glucose}
                element={<ClientGlucose />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.oxygen}
                element={<ClientOxygen />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.bloodPressure}
                element={<ClientBloodPressure />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.heartRate}
                element={<ClientHeartRate />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.ecg}
                element={<ClientECG />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.respirationRate}
                element={<ClientRespirationRate />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.sleep}
                element={<ClientSleep />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.steps}
                element={<ClientSteps />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.temperature}
                element={<ClientTemperature />}
              />
              <Route
                path={router.portal.clientDetail.healthInsights.stress}
                element={<ClientStress />}
              />
            </Route>
            <Route
              path={router.portal.clientDetail.healthSummary}
              element={<ClientHealthSummary />}
            />
            <Route
              path={router.portal.clientDetail.medical}
              element={<ClientMedical />}
            />
            <Route
              path={router.portal.clientDetail.alertsHealthIncidents}
              element={<ClientAlertsHealthIncidents />}
            />
            <Route
              path={router.portal.clientDetail.monthlyReports}
              element={<ClientMonthlyReports />}
            />
          </Route>
          {/* <Route path={router.portal.sosDetail} element={<SOSDetail />} /> */}
          <Route path={router.portal.home} element={<Home />} />
          <Route path={router.portal.sos} element={<Sos />} />
          <Route path={router.portal.sosDetail} element={<SosDetail />} />
          <Route path={router.portal.admin.root} element={<Admin />}>
            <Route
              index
              element={<Navigate to={router.portal.admin.users} replace />}
            />
            <Route
              index
              path={router.portal.admin.devices}
              element={<Devices />}
            />
            <Route path={router.portal.admin.users} element={<ManageUsers />} />
          </Route>
          <Route
            path={router.portal.completeOrganiztionProfile}
            element={<CompleteOrganizationProfile />}
          />
        </Route>
      </Route>

      {/* Consumer Routes */}
      <Route
        element={
          <ProtectedRoute allowedRoles={["wearer", "org_client"]}>
            <ConsumerLayout />
          </ProtectedRoute>
        }
      >
        <Route path="consumer">
          <Route
            index
            element={<Navigate to={router.consumer.monthlyReports} replace />}
          />
          <Route
            path={router.consumer.healthInsights.root}
            element={<HealthInsights />}
          >
            <Route
              index
              element={
                <Navigate
                  to={router.consumer.healthInsights.heartRate}
                  replace
                />
              }
            />
            <Route
              path={router.consumer.healthInsights.glucose}
              element={<Glucose />}
            />
            <Route
              path={router.consumer.healthInsights.oxygen}
              element={<Oxygen />}
            />
            <Route
              path={router.consumer.healthInsights.bloodPressure}
              element={<BloodPressure />}
            />
            <Route
              path={router.consumer.healthInsights.heartRate}
              element={<HeartRate />}
            />
            <Route
              path={router.consumer.healthInsights.respirationRate}
              element={<RespirationRate />}
            />
            <Route
              path={router.consumer.healthInsights.sleep}
              element={<Sleep />}
            />
            <Route
              path={router.consumer.healthInsights.steps}
              element={<Steps />}
            />
            <Route
              path={router.consumer.healthInsights.temperature}
              element={<Temperature />}
            />
            <Route
              path={router.consumer.healthInsights.stress}
              element={<Stress />}
            />
            <Route
              path={router.consumer.healthInsights.ecg}
              element={<ECG />}
            />
          </Route>

          <Route
            path={router.consumer.healthSummary}
            element={<HealthSummary />}
          />
          <Route path={router.consumer.medical} element={<Medical />} />
          <Route
            path={router.consumer.alertsHealthIncidents}
            element={<AlertsHealthIncidents />}
          />

          <Route
            path={router.consumer.profile}
            element={
              <ProtectedRoute allowedRoles={["wearer", "org_client"]}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={router.consumer.completeIndividualProfile}
            element={<CompleteIndividualProfile />}
          />
          <Route
            path={router.consumer.monthlyReports}
            element={<MonthlyReports />}
          />
          <Route
            path={router.consumer.healthSettings}
            element={<HealthSettings />}
          />
        </Route>
      </Route>

      <Route
        path="*"
        element={
          currentUser?.roles[0].name === "org_owner" ? (
            <ProtectedRoute allowedRoles={[currentUser?.roles[0].name || ""]}>
              <Navigate to={router.portal.home} replace />
            </ProtectedRoute>
          ) : (
            <ProtectedRoute allowedRoles={[currentUser?.roles[0].name || ""]}>
              <Navigate to={router.consumer.monthlyReports} replace />
            </ProtectedRoute>
          )
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import { VitalType } from "@app/common/enums/VitalTypes";
import React from "react";
import { CardContainer, DataHistory } from "./CardContainer";
import { StepDetail, StepDetailType } from "@app/common/enums/StepType";
import runIcon from "assets/icons/home_run_icon.svg";
import moment from "moment";

interface CardContainerProps {
  vitalType: VitalType;
  currentData: number | undefined;
  currentDataSecondValue?: number | undefined;
  unit: string;
  date: string;
  dataHistory: DataHistory[];
  dataDetail: StepDetail[];
}

export const StepsCardContainer = ({
  vitalType,
  currentData,
  unit,
  date,
  dataHistory,
  currentDataSecondValue,
  dataDetail,
}: CardContainerProps) => {
  const valueData = currentData ?? "N/A";
  const secondValueData = currentDataSecondValue ?? "N/A";

  const getTextTime = (): string => {
    if (date === "") {
      return "N/A";
    }
    const dateMoment = moment(date);
    return dateMoment.format("DD MMM YYYY - HH:mm:ss");
  };

  const buildDetailStep = ({
    value,
    unit,
    icon,
  }: {
    value: number;
    unit: string;
    icon: string;
  }) => {
    return (
      <div className="d-flex flex-row align-items-end justify-content-start">
        <span
          className="font-16px fw-medium text-black whitespace-nowrap overflow-hidden text-truncate"
          style={{ marginBottom: "3px" }}
        >
          {value !== 0 ? value : "N/A"}
        </span>

        <img
          src={icon}
          width={14}
          height={14}
          alt="icon"
          style={{ marginBottom: "7px", marginLeft: "2px" }}
        />
        <span
          className="font-12px text-grey-901 "
          style={{ marginBottom: "5px", marginLeft: "2px" }}
        >
          {unit}
        </span>
      </div>
    );
  };

  const customBuildContent = () => {
    return (
      <>
        <div className="d-flex flex-row align-items-end">
          <span className="font-28px fw-medium text-black">
            {currentDataSecondValue
              ? `${valueData}/${secondValueData}`
              : `${valueData}`}
          </span>
          <div className="d-flex flex-column ms-1">
            <img
              src={runIcon} // Adjust the path as needed
              width={14}
              height={14}
              alt="icon"
            />
            <span className="font-12px text-grey-901 mb-2">
              {VitalType.getAnalysisName(vitalType)}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <span className="font-12px text-grey-901">{getTextTime()}</span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            space
          </span>
        </div>
        <div
          className="d-flex flex-row w-100"
          style={{ marginTop: "-10px", marginBottom: "10px" }}
        >
          {dataDetail.map((e) => (
            <div className="d-flex flex-row justify-content-start align-items-center me-2">
              {buildDetailStep({
                value: e.value,
                unit: StepDetailType.getUnit(e.type),
                icon: StepDetailType.getIcon(e.type),
              })}
            </div>
          ))}
        </div>
      </>
    );
  };
  return (
    <CardContainer
      vitalType={vitalType}
      currentData={currentData}
      unit={unit}
      dataHistory={dataHistory}
      date={date}
      customBuildContent={customBuildContent}
    />
  );
};

import { VitalIcons } from '@utils/icons';
import { OxygenStatus, HeartRateStatus, RespiratoryRateStatus, BloodPressureStatus, TemperatureStatus, GlucoseStatus, StressStatus } from './AnalysisType';

export enum VitalType {
  GLUCOSE = 'BLOOD_GLUCOSE',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  STEPS = 'STEPS',
  SLEEP = 'SLEEP',
  RESPIRATORY_RATE = 'RESPIRATORY_RATE',
  ECG = 'ECG',
  STRESS = 'STRESS',
  HEART_RATE = 'HEART_RATE',
  TEMPERATURE = 'BODY_TEMPERATURE',
  OXYGEN = 'BLOOD_OXYGEN',
}

export namespace VitalType {
  export const values: VitalType[] = [
    VitalType.GLUCOSE,
    VitalType.STEPS,
    VitalType.HEART_RATE,
    VitalType.STRESS,
    VitalType.ECG,
    VitalType.SLEEP,
    VitalType.BLOOD_PRESSURE,
    VitalType.RESPIRATORY_RATE,
    VitalType.TEMPERATURE,
    VitalType.OXYGEN,
  ];

  export function getStatusAllType(vitalType: VitalType, data: number , data2?: number) {
    switch (vitalType) {
      case VitalType.GLUCOSE:
        return GlucoseStatus.fromValue(data);
      case VitalType.BLOOD_PRESSURE:
        return BloodPressureStatus.fromValue(data,data2);
      case VitalType.RESPIRATORY_RATE:
        return RespiratoryRateStatus.fromValue(data);
      case VitalType.HEART_RATE:
        return HeartRateStatus.fromValue(data);
      case VitalType.OXYGEN:
        return OxygenStatus.fromValue(data);
      case VitalType.STRESS:
        return StressStatus.fromValue(data);
        // case VitalType.STEPS:
        //   return VitalIcons.STEPS;
        // case VitalType.SLEEP:
        //   return VitalIcons.SLEEP;
      // case VitalType.ECG:
      //   return VitalIcons.ECG;
      // case VitalType.STRESS:
      //   return VitalIcons.STRESS;
      case VitalType.TEMPERATURE:
        return TemperatureStatus.fromValue(data);
      default:
        return '';
    }
  }


  export function getDataColorStatus(vitalType: VitalType, data: number, data2?: number) {

    const statusFromData = getStatusAllType(vitalType, data, data2);

    switch (vitalType) {
      case VitalType.GLUCOSE:
        return GlucoseStatus.getColor(statusFromData);
      case VitalType.BLOOD_PRESSURE:
        return BloodPressureStatus.getColor(statusFromData);
      case VitalType.RESPIRATORY_RATE:
        return RespiratoryRateStatus.getColor(statusFromData);
      case VitalType.HEART_RATE:
        return HeartRateStatus.getColor(statusFromData);
      case VitalType.OXYGEN:
        return OxygenStatus.getColor(statusFromData);
      case VitalType.STRESS:
        return StressStatus.getColor(statusFromData);
        
        // case VitalType.STEPS:
        //   return VitalIcons.STEPS;
        // case VitalType.SLEEP:
        //   return VitalIcons.SLEEP;
      // case VitalType.ECG:
      //   return VitalIcons.ECG;
      // case VitalType.STRESS:
      //   return VitalIcons.STRESS;
      case VitalType.TEMPERATURE:
        return TemperatureStatus.getColor(statusFromData);
      default:
        return '';
    }
  }



  export function getDataTextStatus(vitalType: VitalType, data: number) : string {

    const statusFromData = getStatusAllType(vitalType, data);

    switch (vitalType) {
      case VitalType.GLUCOSE:
        return GlucoseStatus.getName(statusFromData);
      case VitalType.BLOOD_PRESSURE:
        return BloodPressureStatus.getName(statusFromData);
      case VitalType.RESPIRATORY_RATE:
        return RespiratoryRateStatus.getName(statusFromData);
      case VitalType.HEART_RATE:
        return HeartRateStatus.getName(statusFromData);
      case VitalType.OXYGEN:
        return OxygenStatus.getName(statusFromData);
      case VitalType.STRESS:
        return StressStatus.getName(statusFromData);
        // case VitalType.STEPS:
        //   return VitalIcons.STEPS;
        // case VitalType.SLEEP:
        //   return VitalIcons.SLEEP;
      // case VitalType.ECG:
      //   return VitalIcons.ECG;
      // case VitalType.STRESS:
      //   return VitalIcons.STRESS;
      case VitalType.TEMPERATURE:
        return TemperatureStatus.getName(statusFromData);
      default:
        return '';
    }
  }

  export function getImage(vitalType: VitalType) {
    switch (vitalType) {
      case VitalType.OXYGEN:
        return require('@assets/images/oxygen.png');
      case VitalType.HEART_RATE:
        return require('@assets/images/heart-organ.png');
      case VitalType.GLUCOSE:
        return require('@assets/images/flask.png');
      case VitalType.BLOOD_PRESSURE:
        return require('@assets/images/blood-drops.png');
      case VitalType.RESPIRATORY_RATE:
        return require('@assets/images/lungs-organ.png');
      case VitalType.TEMPERATURE:
        return require('@assets/images/thermometer.png');
      case VitalType.STRESS:
        return "";
      default:
        return require('@assets/images/blood-drops.png');

    }
  }

  export function getIcons(vitalType: VitalType) {
    switch (vitalType) {
      case VitalType.GLUCOSE:
        return VitalIcons.GLUCOSE;
      case VitalType.BLOOD_PRESSURE:
        return VitalIcons.BLOOD_PRESSURE;
      case VitalType.STEPS:
        return VitalIcons.STEPS;
      case VitalType.SLEEP:
        return VitalIcons.SLEEP;
      case VitalType.RESPIRATORY_RATE:
        return VitalIcons.RESPIRATORY_RATE;
      case VitalType.ECG:
        return VitalIcons.ECG;
      case VitalType.STRESS:
        return VitalIcons.STRESS;
      case VitalType.HEART_RATE:
        return VitalIcons.HEART_RATE;
      case VitalType.TEMPERATURE:
        return VitalIcons.TEMPERATURE;
      case VitalType.OXYGEN:
        return VitalIcons.OXYGEN;
      default:
        return VitalIcons.GLUCOSE;
    }
  }

  export function getUnit(vitalType: VitalType) {
    switch (vitalType) {
      case VitalType.RESPIRATORY_RATE:
        return 'RPM';
      case VitalType.OXYGEN:
        return '%';
      case VitalType.STRESS:
        return '%';
      case VitalType.HEART_RATE:
        return 'BPM';
      case VitalType.GLUCOSE:
        return 'mmol/L';
      case VitalType.BLOOD_PRESSURE:
        return 'mmHg';
      case VitalType.TEMPERATURE:
        return '°C';
      default:
        return '';
    }
  }

  // export function getName(vitalType: VitalType) {
  //   return `analysis.name${vitalType}`;
  // }

  export function getAnalysisName(vitalType: VitalType) {
    switch (vitalType) {
      case VitalType.GLUCOSE:
        return 'Glucose';
      case VitalType.BLOOD_PRESSURE:
        return 'Blood Pressure';
      case VitalType.HEART_RATE:
        return 'Heart Rate';
      case VitalType.OXYGEN:
        return 'Oxygen Saturation';
      case VitalType.RESPIRATORY_RATE:
        return 'Respiratory Rate';
      case VitalType.STEPS:
        return 'Steps';
      case VitalType.SLEEP:
        return 'Sleep';
      case VitalType.ECG:
        return 'ECG';
      case VitalType.STRESS:
        return 'Stress';
      case VitalType.TEMPERATURE:
        return 'Temperature';
      default:
        return '';
    }
  }

  export function getAnalysisDescription(vitalType: VitalType) {
    switch (vitalType) {
      case VitalType.GLUCOSE:
        return 'glucose rate';
      case VitalType.BLOOD_PRESSURE:
        return 'blood pressure';
      case VitalType.HEART_RATE:
        return 'heart rate';
      case VitalType.OXYGEN:
        return 'blood oxygen';
      case VitalType.RESPIRATORY_RATE:
        return 'breathing rate';
      case VitalType.STEPS:
        return 'Steps';
      case VitalType.SLEEP:
        return 'Sleep';
      case VitalType.ECG:
        return 'ECG';
      case VitalType.STRESS:
        return 'Stress';
      case VitalType.TEMPERATURE:
        return 'temperature';
      default:
        return '';
    }
  }

  // export function getStatusName(vitalType: VitalType) {
  //   return `analysis.statusName${vitalType}`;
  // }
}

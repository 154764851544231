import editIcon from "@assets/icons/edit_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { useMemo, useState } from "react";
import Table from "@app/components/Table";
import RoleBadge from "./RoleBadge";
import StatusBadge from "./StatusBadge";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { UserDto } from "@app/redux/features/v2/users-service/models/user-update-result-dto";
import { UserStatusType } from "@app/common/enums/UserStatusType";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setUsersRequest } from "@app/redux/features/v2/users-service/usersServiceSlice";
import { getUsersAsync } from "@app/redux/features/v2/users-service/usersServiceThunk";

interface ITableProps {
  onEdit: (data: any) => void;
  onDelete: (id: string | number) => void;
  data: PaginatedResult<UserDto>;
}

const UserTable = ({ onEdit, onDelete, data }: ITableProps) => {
  const dispatch = useAppDispatch();

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row: UserDto) => row.name,
      },
      {
        name: "User Role",
        selector: (row: UserDto) => <RoleBadge role={row.roles} />,
      },
      {
        name: "Location",
        selector: (row: UserDto) => row.location,
      },
      {
        name: "Status",
        selector: (row: UserDto) => (
          <StatusBadge
            status={
              row.is_active ? UserStatusType.ACTIVE : UserStatusType.INACTIVE
            }
          />
        ),
      },
      {
        name: "Actions",
        id: "action",
        style: {
          width: "fit-content",
          flex: "0 !important",
          padding: "12.5px 16px !important",
        },

        cell: (row: UserDto) => (
          <div className="action-cell">
            <img
              height={16}
              width={16}
              src={editIcon}
              alt="Edit"
              onClick={() => onEdit(row)}
              className="cursor-pointer"
            />
            <img
              height={16}
              width={16}
              src={deleteIcon}
              alt="Delete"
              className="ms-2 cursor-pointer"
              onClick={() => onDelete(row.sid)}
            />
          </div>
        ),
      },
    ],
    [onDelete, onEdit]
  );
  const { usersRequest } = useAppSelector(
    (state: RootState) => state.usersServiceV2
  );

  const handlePageChange = (page: number) => {
    dispatch(setUsersRequest({ ...usersRequest, page_number: page }));
    dispatch(getUsersAsync({ ...usersRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(
      setUsersRequest({
        ...usersRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
    dispatch(
      getUsersAsync({
        ...usersRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
  };
  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
export default UserTable;

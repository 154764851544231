import mymonXIcon from "@assets/icons/mymonX_icon.svg";
import { VitalType } from "@app/common/enums/VitalTypes";
import ValueBreakDown from "../Monthly.DetailReportCard/ValueBreakdown";
import SummaryDetail from "./SummaryDetail";
import MonthlyReportBarChart from "@app/components/MonthlyReportChart/MonthlyReportBarChart";

interface ValueBreakdown {
  veryLow?: number;
  low?: number;
  normal?: number;
  high?: number;
  veryHigh?: number;
}
interface ValueBreakDownItem {
  minimum: string;
  average: string;
  maximum: string;
}

interface SummaryData {
  date: number;
  value1: number;
  value2: number;
}
interface DetailReportCardProps {
  title?: string;
  icon?: string;
  value?: ValueBreakDownItem;
  unit?: string;
  type: VitalType;
  breakdownValues?: ValueBreakdown;
  wearingMymonX?: number;
  summaryData: SummaryData[];
}

const DetailSleepReportCard: React.FC<DetailReportCardProps> = (
  props: DetailReportCardProps
) => {
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 border-bottom border-grey-904 pb-2">
        <img
          width={18}
          height={18}
          src={VitalType.getIcons(props.type)}
          alt="Identity Icon"
        />
        <span className="font-14px fw-medium d-flex flex-grow-1 ms-2">
          {VitalType.getAnalysisName(props.type)}
        </span>
      </div>
      {/* Content */}

      <div className="d-flex flex-column flex-lg-row col-12 gap-2">
        <div className="col col-lg-5">
          <SummaryDetail
            wearingMymonX={props.wearingMymonX}
            type={props.type}
            breakdownValues={props.breakdownValues}
            value={props.value}
          />
        </div>
        <div className="col col-lg-7">
          <div className="border-start mb-1 mt-3 ps-4">
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              <span className="font-12px fw-medium d-flex flex-grow-1 mb-3">
                Ideal Sleep
              </span>
            </div>
            <MonthlyReportBarChart
              summaryData={props.summaryData}
              yAxisDomain={[0, 9]}
              yAxisTicks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSleepReportCard;

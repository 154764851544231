import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import eyeIcon from "@assets/icons/login/eye_icon.svg";
import eyeCloseIcon from "@assets/icons/login/eye_close_icon.svg";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useEffect, useState } from "react";
import backIcon from "@assets/icons/login/back_icon.svg";
import LocalStorageService from "@app/services/v2/local-storage-service";
import { forgotPasswordAsync } from "@app/redux/features/v2/security-service/securityServiceThunk";
import { ValidationRules } from "@app/utils/types";

const ForgetPassword = () => {
  // Redux state and dispatch
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Local state
  const [isMobile, setIsMobile] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Form handling
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
  });

  // Watch form fields
  const email = watch("email");

  // Check if form is valid
  useEffect(() => {
    setIsFormValid(email !== "" && email.includes("@"));
  }, [email]);

  return (
    <div className="d-flex h-100 w-100 justify-content-end">
      {/* Right Panel */}
      <Row className="h-100 w-50 bg-white m-0">
        <Col className="col-12 w-100" style={{ marginTop: "150px" }}>
          <Row>
            <Col className="col-12 w-100">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column" style={{ width: "502px" }}>
                  <Link
                    to={"/auth/login"}
                    className="d-flex flex-row align-items-center font-12px fw-medium text-decoration-none text-black mb-3"
                  >
                    <img
                      height={14}
                      width={14}
                      className="ms-0 me-2 cursor-pointer"
                      src={backIcon}
                      alt="Note"
                    />
                    <p className="my-0 font-12px fw-medium">Back</p>
                  </Link>

                  <p className="font-24px fw-medium text-start my-0">
                    Reset password
                  </p>
                  <p className="font-12px text-grey-901 pb-0">
                    Please enter your email address
                  </p>
                  <div className="w-100">
                    <Form
                      onSubmit={handleSubmit((data) => {
                        dispatch(forgotPasswordAsync({ username: data.email })).then((res: any) => {
                          if(res && res.payload && !res.payload.status){
                            LocalStorageService.setUsernameResetPassword(data.email);
                            navigate("/auth/otp-verification");
                          }
                        });
                      })}
                      id="loginForm"
                    >
                      <FormInput
                        control={control}
                        name="email"
                        label="Email address"
                        type="email"
                        placeholder="Enter your email address"
                        error={errors.email?.message ?? ''}
                        rules={{
                          required: { value: true, message: "Email address is required" },
                          pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email address",
                          },
                        }}
                      />
                      <Button
                        as="input"
                        type="submit"
                        value="Reset password"
                        className={`font-12px fw-medium rounded-3 ${
                          isFormValid
                            ? "text-white bg-blue-902"
                            : "text-grey-901 bg-grey-904"
                        } border-0 px-3 d-flex align-items-center text-login no-hover`}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

type FormInputProps = {
  control: any;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  rules?: ValidationRules<string>;
  error?: string;
};

const FormInput: React.FC<FormInputProps> = ({
  control,
  name,
  label,
  type,
  placeholder,
  rules = { required: { value: true, message: `${name} is required` } },
  error,
}) => (
  <Form.Group className="mb-4 mt-1" controlId={`loginForm.${name}`}>
    <span className="font-10px text-dark">{label}</span>
    <InputGroup className="border rounded-3 border-0 bg-grey-904">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
        )}
      />
    </InputGroup>
    {error && (
      <Form.Text className="text-danger">
        {error}
      </Form.Text>
    )}
  </Form.Group>
);
export default ForgetPassword;

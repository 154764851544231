import React from "react";
import TrendUp from "@app/assets/icons/trend-up.svg";
import TrendDown from "@app/assets/icons/trend-down.svg";
import { VitalType } from "@app/common/enums/VitalTypes";

interface ContentCardProps {
  analysisType: VitalType;
  averageValue: {
    firstUnit: number;
    secondUnit: number;
  };
  lowestValue: {
    firstUnit: number;
    secondUnit: number;
  };
  highestValue: {
    firstUnit: number;
    secondUnit: number;
  };
}

const CardContent: React.FC<ContentCardProps> = ({
  analysisType,
  averageValue,
  lowestValue,
  highestValue,
}) => {
  const unit = VitalType.getUnit(analysisType);
  const image = VitalType.getImage(analysisType);
  return (
    <div className="d-flex mt-0">
      <div className="col-10 d-flex flex-column">
        <div className="d-flex flex-row align-items-end ">
          <div className="text-grey-901 font-12px mb-0 d-flex flex-row align-items-end flex-responsive">
            <div className="mb-0 fw-bold text-dark font-28px me-1">
              {averageValue.firstUnit === 0 || averageValue.secondUnit === 0
                ? "N/A"
                : `${averageValue.firstUnit}/${averageValue.secondUnit}`}
            </div>
            {averageValue.firstUnit !== 0 && averageValue.firstUnit !== 0 ? (
              <div className="d-flex align-items-end margin-bot-content">
                <div className="d-flex flex-column">
                  <span className="font-12px">
                    <span className="fw-bold text-high">Sys</span> /{" "}
                    <span className="fw-bold text-very-high">Dia</span>
                  </span>
                  <span className="font-12px whitespace-nowrap overflow-hidden text-truncate">
                    {unit} - {" "}
                    {VitalType.getStatusAllType(
                      analysisType,
                      Math.floor(averageValue.firstUnit),
                      Math.floor(averageValue.secondUnit)
                    )}
                  </span>
                </div>
                <div className="text-dark font-12px mb-0 ms-2 whitespace-nowrap overflow-hidden text-truncate">
                  <div
                    className={`bg-${VitalType.getDataColorStatus(
                      analysisType,
                      Math.floor(averageValue.firstUnit),
                      Math.floor(averageValue.secondUnit)
                    )} rounded-circle me-2`}
                    style={{
                      width: "8px",
                      height: "8px",
                      display: "inline-block",
                    }}
                  ></div>
                  Your {VitalType.getAnalysisDescription(analysisType)} is
                </div>
                <div
                  className={`text-${VitalType.getDataColorStatus(
                    analysisType,
                    Math.floor(averageValue.firstUnit),
                    Math.floor(averageValue.secondUnit)
                  )} font-12px mb-0 ms-1 fw-bold whitespace-nowrap overflow-hidden `}
                >
                  {VitalType.getStatusAllType(
                    analysisType,
                    Math.floor(averageValue.firstUnit),
                    Math.floor(averageValue.secondUnit)
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center gap-3 my-2">
          <div className="d-flex flex-column ms-2">
            <p className="text-grey-901 mb-0 font-8px">Lowest</p>
            <div className="d-flex flex-row align-items-center ">
              <span
                className="mb-0 fw-bold font-16px "
                style={{ display: "inline-block", width: "fit-content" }}
              >
                {lowestValue.firstUnit === 0 || lowestValue.secondUnit === 0
                  ? "N/A"
                  : `${lowestValue.firstUnit}/${lowestValue.secondUnit}`}
              </span>
              <div className="h-100">
                <img
                  src={TrendDown}
                  alt="Trend Down"
                  className="h-100 w-75 ms-2"
                />
              </div>
            </div>
          </div>
          <div
            className="h-100"
            style={{
              width: "1px",
              backgroundColor: "#F7F7F7",
            }}
          />

          <div className="d-flex flex-column">
            <p className="text-grey-901 mb-0 font-8px">Highest</p>
            <div className="d-flex flex-row align-items-center ">
              <div className="mb-0 fw-bold font-16px h-fit-content">
                {highestValue.firstUnit === 0 || highestValue.secondUnit === 0
                  ? "N/A"
                  : `${highestValue.firstUnit}/${highestValue.secondUnit}`}
              </div>
              <div className="h-100">
                <img
                  src={TrendUp}
                  alt="Trend Down"
                  className="h-100 w-75 ms-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 position-relative">
        <div className=" h-100 position-relative">
          <div className="position-absolute h-100  end-0 top-25">
            <img src={image} alt="" className="h-75" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContent;

import { VitalIcons } from '@utils/icons';
import { OxygenStatus, HeartRateStatus, RespiratoryRateStatus, BloodPressureStatus, TemperatureStatus, GlucoseStatus, StressStatus } from './AnalysisType';

export enum HealthSettingsVitalType {
    GLUCOSE = 'GLUCOSE',
    BLOOD_PRESSURE = 'BLOOD_PRESSURE',
}

export namespace HealthSettingsVitalType {
    export const values: HealthSettingsVitalType[] = [
      HealthSettingsVitalType.GLUCOSE,
      HealthSettingsVitalType.BLOOD_PRESSURE,
    ];
  
    export function getStatusAllType(healthSettingsVitalType: HealthSettingsVitalType, data: number) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return GlucoseStatus.fromValue(data);
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return BloodPressureStatus.fromValue(data);
        default:
          return '';
      }
    }
  
  
    export function getDataColorStatus(healthSettingsVitalType: HealthSettingsVitalType, data: number) {
  
      const statusFromData = getStatusAllType(healthSettingsVitalType, data);
  
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return GlucoseStatus.getColor(statusFromData);
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return BloodPressureStatus.getColor(statusFromData);
        default:
          return '';
      }
    }
  
  
  
    export function getDataTextStatus(healthSettingsVitalType: HealthSettingsVitalType, data: number) : string {
  
      const statusFromData = getStatusAllType(healthSettingsVitalType, data);
  
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return GlucoseStatus.getName(statusFromData);
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return BloodPressureStatus.getName(statusFromData);
        default:
          return '';
      }
    }
  
    export function getImage(healthSettingsVitalType: HealthSettingsVitalType) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return require('@assets/images/flask.png');
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return require('@assets/images/blood-drops.png');
        default:
          return require('@assets/images/blood-drops.png');
  
      }
    }
  
    export function getIcons(healthSettingsVitalType: HealthSettingsVitalType) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return VitalIcons.GLUCOSE;
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return VitalIcons.BLOOD_PRESSURE;
        default:
          return VitalIcons.GLUCOSE;
      }
    }
  
    export function getUnit(healthSettingsVitalType: HealthSettingsVitalType) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return 'mmol/L';
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return 'mmHg';
        default:
          return '';
      }
    }
  
    // export function getName(HealthSettingsVitalType: HealthSettingsVitalType) {
    //   return `analysis.name${HealthSettingsVitalType}`;
    // }
  
    export function getAnalysisName(healthSettingsVitalType: HealthSettingsVitalType) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return 'Glucose';
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return 'Blood Pressure';
        default:
          return '';
      }
    }
  
    export function getAnalysisDescription(healthSettingsVitalType: HealthSettingsVitalType) {
      switch (healthSettingsVitalType) {
        case HealthSettingsVitalType.GLUCOSE:
          return 'glucose rate';
        case HealthSettingsVitalType.BLOOD_PRESSURE:
          return 'blood pressure';
        default:
          return '';
      }
    }
  
    // export function getStatusName(HealthSettingsVitalType: HealthSettingsVitalType) {
    //   return `analysis.statusName${HealthSettingsVitalType}`;
    // }
  }
import identityIcon from "@assets/icons/identification.svg";
import nationIcon from "@assets/icons/nation_icon.svg";
import skinIcon from "@assets/icons/skin_color_icon.svg";
import genderIcon from "@assets/icons/gender_icon.svg";
import heightIcon from "@assets/icons/height_weight_icon.svg";
import dateIcon from "@assets/icons/date_icon.svg";
import editIcon from "@assets/icons/edit_icon.svg";
import { useState } from "react";
import GeneralActionModal from "./GeneralActionModal";
import { SkinColorItem } from "./SkinColorItem";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";

export interface UserProfile {
  avatar: string;
  email: string;
  name: string;
  date_of_birth: string;
  gender: string;
  height: string;
  weight: string;
  skin_color: string;
  skin_color_name: string;
}

const GeneralInformationCard = () => {
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const [show, setShow] = useState(false);

  const infos = [
    {
      src: nationIcon,
      alt: "Nation Icon",
      title: "Nation",
      value: profile?.nationality ?? "",
    },
    {
      src: dateIcon,
      alt: "Date Icon",
      title: "Date of Birth",
      value: String(profile?.date_of_birth ?? ""),
    },
    {
      src: genderIcon,
      alt: "Gender Icon",
      title: "Gender",
      value: profile?.gender ?? "",
    },
    {
      src: heightIcon,
      alt: "Height Icon",
      title: "Height & Weight",
      value: `${profile?.height_cm ?? 0}cm ${profile?.weight_kg ?? 0}kg`,
    },
    {
      src: skinIcon,
      alt: "Skin Icon",
      title: "Skin Color",
      value: (
        <SkinColorItem
          isSelected={true}
          colorKey={profile?.skin_tone ?? ""}
          onPress={() => {}}
        />
      ),
    },
  ];

  const { contry_data } = useAppSelector(
    (state: RootState) => state.staticDataServiceV2
  );

  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100">
        <img width={18} height={18} src={identityIcon} alt="Identity Icon" />
        <span
          className="font-14px fw-bold d-flex flex-grow-1 ms-2"
          style={{ lineHeight: "17.09px" }}
        >
          General Information
        </span>
        {/* Edit button */}
        <div className="d-flex justify-content-start cursor-pointer">
          <div
            className="d-flex align-items-center"
            onClick={() => setShow(true)}
          >
            <img src={editIcon} alt="Plus" />
            <p
              className="ms-1 my-0 font-12px progress-bar text-blue-902 fw-normal"
              style={{ lineHeight: "14.65px" }}
            >
              Edit
            </p>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="border-grey-901">
        <div className="mt-3 mb-1">
          <div
            className="d-flex flex-column justify-content-between align-items-start w-100"
            style={{ gap: "16px" }}
          >
            {infos.map((icon, index) => (
              <div
                key={index}
                className="d-flex align-items-center rounded-3 col-6 title-chart gap-2"
              >
                <div className="rounded-5 icon-chart-custom d-flex align-items-center justify-content-center">
                  <img
                    height={32}
                    width={32}
                    src={icon.src}
                    alt={icon.alt}
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="text-dark">
                  <div className="d-flex flex-column">
                    <div className="font-12px text-grey-901">{icon.title}</div>
                    <div className="my-0 font-10px text-black whitespace-nowrap overflow-hidden text-truncate">
                      <div className="my-0">{icon.value}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Action Modal */}
      <GeneralActionModal
        nationality={contry_data}
        show={show}
        onHide={() => setShow(false)}
      />
    </div>
  );
};

export default GeneralInformationCard;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import { AssignDeviceOtpPayload, MapDeviceDto } from "./models/MapDeviceDto";
import { MapDeviceResultDto } from "./models/MapDeviceResultDto";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import DeviceServiceApi from "@app/services/v2/device-service";
import { addSuccessNotification } from "@app/redux/features/common/notificationSlice";
import { DeviceApiResponse, DeviceLiveInfo, DeviceResultDto } from "./models/DeviceResultDto";
import { DeviceByUserIdDto, DeviceDto } from "./models/DeviceDto";
import { AnyComponent } from "styled-components/dist/types";


export const assignDeviceAsync = createAsyncThunk<MapDeviceResultDto, MapDeviceDto,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/AssignDevice",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.AssignDevice(request);
            const result = fulfillWithValue(response.data);
            dispatch(addSuccessNotification('Device Map successfully'));
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const unAssignDeviceAsync = createAsyncThunk<MapDeviceResultDto, string,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/UnAssignDevice",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.UnAssignDevice(request);
            const result = fulfillWithValue(response.data);
            dispatch(addSuccessNotification('Device UnAssigned successfully'));
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const GetliveinfoAsync = createAsyncThunk<DeviceLiveInfo, string,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/Getliveinfo",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.Getliveinfo(request);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const CreateDeviceOtpAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/CreateDeviceOtp",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.CreateDeviceOtp(request);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const AssignDeviceOtpAsync = createAsyncThunk<
  string, // Return type (response)
  AssignDeviceOtpPayload, // Argument type (payload)
  { rejectValue: ApiError | undefined }
>(
  "deviceServiceV2/AssignDeviceOtp",
  async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const { otp, data } = request; // Destructure OTP and data from the payload
      const response = await DeviceServiceApi.AssignDeviceOtp(otp, data); // Call API with both OTP and data
      const result = fulfillWithValue(response.data); // Resolve API response
      return result;
    } catch (error) {
      const apiError = getErrorPayload(error); // Extract error
      return rejectWithValue(apiError); // Reject with error payload
    }
  }
)
export const disableDeviceAsync = createAsyncThunk<MapDeviceResultDto, string,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/DisableDevice",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.DisableDevice(request);
            const result = fulfillWithValue(response.data);
            dispatch(addSuccessNotification('Device Disabled successfully'));
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const getDevicesAsync = createAsyncThunk<DeviceApiResponse, DeviceDto,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/GetDevices",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.GetDevices(request);
            return fulfillWithValue(response.data);

        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const getDeviceByUserIdAsync = createAsyncThunk<DeviceResultDto, DeviceByUserIdDto,
    { rejectValue: ApiError | undefined }
>(
    "deviceServiceV2/GetDeviceByUserId",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await DeviceServiceApi.GetDeviceByUserId(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

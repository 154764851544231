import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useEffect, useState } from "react";
import AnalysisCard from "@app/components/AnalysisCard";
import HistoryRecordCard from "@components/HistoryRecordCard";
import SummaryCard from "@app/components/SummaryCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import { GlucoseStatus } from "@app/common/enums/AnalysisType";
import PeriodSelect from "@app/components/PeriodSelect";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useVitalsGraph } from "@app/hooks/useVitalsGraph";
import { VitalsGraphDto } from "@app/redux/features/v2/health-service/models/bp-graph-dto";

const Oxygen = () => {
  const [tableData, setTableData] = useState<
    {
      id: number;
      healthVitals: string;
      date: string;
      time: string;
      low: string | number;
      average: string | number;
      high: string | number;
      status: string;
    }[]
  >([]);
  const dispatch = useAppDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(
    TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
  );
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const { user_profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const { fetchVitalsGraph, fetchCustomRange } = useVitalsGraph(
    user_profile?.sid || "",
    VitalType.OXYGEN
  );
  const vitalsGraphDto = useAppSelector(
    (state: RootState) => state.healthServiceV2.vitalsGraphDto[VitalType.OXYGEN]
  );

  const convertBloodOxygen = (vitalsGraphDto: VitalsGraphDto) => {
    const { vital_datetime, vital_data } = vitalsGraphDto;

    return vital_datetime.map((datetime, index) => {
      const dateObj = new Date(datetime);
      const date = `${(dateObj.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${dateObj
        .getDate()
        .toString()
        .padStart(2, "0")}/${dateObj.getFullYear()}`;
      const time = dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return {
        id: index + 1,
        healthVitals: "Blood Oxygen",
        date,
        time,
        low: vital_data.VITAL_MIN?.[index] ?? "",
        average: vital_data.VITAL?.[index] ?? "",
        high: vital_data.VITAL_MAX?.[index] ?? "",
        status: vital_data.LABEL?.[index] ?? "",
      };
    });
  };

  useEffect(() => {
    fetchVitalsGraph(selectedPeriod);
  }, [selectedPeriod, fetchVitalsGraph]);

  useEffect(() => {
    if (vitalsGraphDto) {
      const defaultData = convertBloodOxygen(vitalsGraphDto);
      setTableData(defaultData);
    }
  }, [vitalsGraphDto, selectedPeriod]);

  useEffect(() => {
    fetchVitalsGraph(selectedPeriod);
  }, [fetchVitalsGraph, selectedPeriod]);

  return (
    <div className="container health_insight row">
      <div className="col-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <PeriodSelect
            analysisName={VitalType.getAnalysisName(VitalType.OXYGEN)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              analysisType={VitalType.OXYGEN}
              analysisStatus={GlucoseStatus.HIGH}
              date={selectedPeriod}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              analysisType={VitalType.OXYGEN}
              period={selectedPeriod}
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard
              period={selectedPeriod}
              type={VitalType.OXYGEN}
              data={tableData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oxygen;

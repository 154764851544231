import { BpGraphDto, EcgResponseDto, GlucoseGraphDto, HrGraphDto, OxygenGraphDto, RespiratoryRateGraphDto, StepsSummaryDto, TempGraphDto, VitalsGraphDto, VitalsSummaryDto } from "@redux/features/v2/health-service/models/bp-graph-dto";
import { GlucoseStatsDto } from "@redux/features/v2/health-service/models/glucose-stats-dto";
import { config } from "../config";
import { createOrganisationPortalApi } from "../organisation-portal-api";
import { PageAndFilterRequestDto } from "@app/redux/common/models/page-and-filter-request-dto";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";
import { StressDataDto } from "@app/redux/features/v2/health-service/models/stress-data-dto";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getGlucoseStats = (userId: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<GlucoseStatsDto>(`/api/v2/health/body_glucose/stats/${userId}`);
}

const getBpGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<BpGraphDto>(`/api/v2/health/blood_pressure_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getOxygenGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<OxygenGraphDto>(`/api/v2/health/oxygen_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getHrGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<HrGraphDto>(`/api/v2/health/heart_rates_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getTempGraphStats = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<TempGraphDto>(`/api/v2/health/temp_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getGlucoseGraph = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<GlucoseGraphDto>(`/api/v2/health/glucose_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}

const getRespiratoryRateGraph = (userId: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<RespiratoryRateGraphDto>(`/api/v2/health/respiratory_rate_graph?user_profile_sid=${userId}&start_date=${startDate}&end_date=${endDate}&graph_type=${graphType}`);
}
const getVitals = (user_profile_sid: string, vital_type: string, graphType: string, startDate: string, endDate: string, timezone: string) => {
    //added timezone
    return organisationPortalApi.get<VitalsGraphDto>(`/api/v2/health/vitals?wearer_sid=${user_profile_sid}&vital_type=${vital_type}&period_type=${graphType}&from_date=${startDate}&to_date=${endDate}&timezone=${localStorage.getItem('timezone') ?? 'UTC'}`);
}

const getsleepVitals = (user_profile_sid: string, startDate: string, endDate: string, graphType: string) => {

    return organisationPortalApi.get<VitalsGraphDto>(`/api/v2/health/sleep/data?wearer_sid=${user_profile_sid}&period_type=${graphType}&from_date=${startDate}&to_date=${endDate}&timezone=${localStorage.getItem('timezone') ?? 'UTC'}`);
}
const getsleepMobileVitals = (user_profile_sid: string, vital_type: string) => {

    return organisationPortalApi.get<string>(`/api/v2/mobile/vital_graph?user_profile_sid=${user_profile_sid}&vital=${vital_type}`);
}
const getEcgVitals = (user_profile_sid: string) => {

    return organisationPortalApi.get<EcgResponseDto>(`/api/v2/health/vitals/ecg?wearer_sid=${user_profile_sid}`);
}
const getEcg = (request: PageAndFilterRequestDto) => {
    const filter = request.filter_by ? `filter_by=${request.filter_by}` : '';
    return organisationPortalApi.get<PaginatedResult<EcgResponseDto>>(`/api/v2/health/ecg?page_number=${request.page_number}&page_size=${request.page_size}&${filter}`);
}

const getVitalsSummary = (userId: string) => {
    // if (userId === '')
    //     return null;
    return organisationPortalApi.get<VitalsSummaryDto[]>(`/api/v2/health/vitals-summary?wearer_sid=${userId}`);
}


const getStepsSummary = (userId: string) => {
    return organisationPortalApi.get<StepsSummaryDto>(`/api/v2/activity/step_summary?wearer_sid=${userId}`);
}

const getStressVitals = (wearer_sid: string, startDate: string, endDate: string, graphType: string) => {
    return organisationPortalApi.get<StressDataDto>(`/api/v2/health/stress_graph?wearer_sid=${wearer_sid}&graph_type=${graphType}&start_date=${startDate}&end_date=${endDate}&timezone=${localStorage.getItem('timezone') ?? 'UTC'}`);
}

const getStepsVitals = (wearer_sid: string, startDate: string, endDate: string, period: string) => {
    return organisationPortalApi.get<StressDataDto>(`/api/v2/activity_graph?wearer_sid=${wearer_sid}&period_type=${period}&from_date=${startDate}&to_date=${endDate}&activity=STEPS`);
}

const HealthServiceApi = {
    getGlucoseStats,
    getBpGraphStats,
    getHrGraphStats,
    getOxygenGraphStats,
    getTempGraphStats,
    getGlucoseGraph,
    getRespiratoryRateGraph,
    getVitals,
    getVitalsSummary,
    getsleepVitals,
    getEcgVitals,
    getsleepMobileVitals,
    getEcg,
    getStressVitals,
    getStepsSummary,
    getStepsVitals
}

export default HealthServiceApi;

import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import ActionModal from "@app/components/Modal/ActionModal";
import moment from "moment";
import userPlusIcon from "@assets/icons/portal/user_plus_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import {
  CreateUpdateClientDto,
  CreateUpdateContactDto,
} from "@app/redux/features/v2/org-service/models/organisation-portal-dto";
import { hasMissingFields } from "@app/utils/helper";
import ContactBlock from "./ContactBlock";
import "./ClientsModal.scss";

const ClientsModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const dispatch = useAppDispatch();
  const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);
  const DEFAULT_CLIENT: CreateUpdateClientDto = {
    first_name: "",
    last_name: "",
    email: "",
    date: "",
    gender: "",
    nationality: "",
    location: "",
    mobile_prefix: "",
    phone_number: "",
    height: "",
    weight: "",
    health_status: "",
    care_team: "",
  };

  const DEFAULT_CONTACT: CreateUpdateContactDto[] = [
    {
      contact_first_name: "",
      contact_last_name: "",
      relationship: "",
      contact_email: "",
      contact_mobile_prefix: "",
      contact_phone_number: "",
      is_sos: false,
    },
  ];

  const [currentStep, setCurrentStep] = useState<"client" | "contact">("client");
  const [client, setClient] = useState(DEFAULT_CLIENT);
  const [contacts, setContacts] = useState(DEFAULT_CONTACT);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 100 }));
  }, [dispatch]);

  useEffect(() => {
    if (dataSelected && type === "update") {
      setClient({
        first_name: dataSelected.first_name ?? "",
        last_name: dataSelected.last_name ?? "",
        email: dataSelected.email ?? "",
        date: dataSelected.date ?? "",
        gender: dataSelected.gender ?? "",
        nationality: dataSelected.nationality ?? "",
        location: dataSelected.location ?? "",
        mobile_prefix: dataSelected.mobile_prefix ?? "",
        phone_number: dataSelected.phone_number ?? "",
        height: dataSelected.height ?? "",
        weight: dataSelected.weight ?? "",
        health_status: dataSelected.health_status ?? "",
        care_team: dataSelected.care_team ?? "",
      });
      setContacts([
        {
          contact_first_name: dataSelected.contact_first_name ?? "",
          contact_last_name: dataSelected.contact_last_name ?? "",
          relationship: dataSelected.relationship ?? "",
          contact_email: dataSelected.contact_email ?? "",
          contact_mobile_prefix: dataSelected.contact_mobile_prefix ?? "",
          contact_phone_number: dataSelected.contact_phone_number ?? "",
          is_sos: dataSelected.is_sos ?? "",
        },
      ]);
    } else {
      setClient(DEFAULT_CLIENT);
      setContacts(DEFAULT_CONTACT);
    }
  }, [dataSelected, type]);

  const fieldsClientToCheck: (keyof CreateUpdateClientDto)[] = [
    "first_name",
    "last_name",
    "email",
    "date",
    "phone_number",
    "gender",
    "height",
    "weight",
    "nationality",
    "health_status",
    "location",
    "care_team",
  ];

  const fieldsContactToCheck: (keyof CreateUpdateContactDto)[] = [
    "contact_first_name",
    "contact_last_name",
    "relationship",
    "contact_email",
    "contact_phone_number",
  ];

  const onAddAnother = () => {
    setContacts([...contacts, DEFAULT_CONTACT[0]]);
  };

  const onDelete = (index: number) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

  const onUpdate = (index: number, updatedContact: CreateUpdateContactDto) => {
    const updatedContacts = [...contacts];
    updatedContacts[index] = updatedContact;
    setContacts(updatedContacts);
  };

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setCurrentStep("client");
    setClient(DEFAULT_CLIENT);
    setContacts(DEFAULT_CONTACT);
  };

  const onSubmitAndContinue = async () => {
    try {
      if (type === "add") {
        if (currentStep === "client") {
          if (hasMissingFields(client, fieldsClientToCheck)) {
            setIsError(true);
            return;
          }
          setCurrentStep("contact");
          setIsError(false);
        }

        if (currentStep === "contact") {
          const emptyContacts = contacts.filter((item) => hasMissingFields(item, fieldsContactToCheck));
          if (emptyContacts.length > 0) {
            setIsError(true);
            return;
          }
          onCloseModal();
        }
      } else {
        if (currentStep === "client") {
          if (hasMissingFields(client, fieldsClientToCheck)) {
            setIsError(true);
            return;
          }
          setCurrentStep("contact");
          setIsError(false);
        }

        if (currentStep === "contact") {
          const value = contacts[0];
          if (hasMissingFields(value, fieldsContactToCheck)) {
            setIsError(true);
            return;
          }
          onCloseModal();
        }
      }
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Input client's information to add them to organization"
      show={show}
      onHide={onCloseModal}
      title="Add client"
      size="lg"
      icon={userPlusIcon}
      onSubmit={() => onSubmitAndContinue()}
      customButton={
        <>
          {currentStep === "contact" && (
            <Button
              className="rounded-3 border-blue-901 d-flex align-items-center ms-2 no-hover"
              onClick={() => setCurrentStep("client")}
              variant="outline"
            >
              <p style={{ color: "#3F72F3" }} className="my-0 font-12px fw-bold">
                Previous
              </p>
            </Button>
          )}
          <Button
            className="rounded-3 bg-blue-901 d-flex align-items-center ms-2"
            onClick={() => onSubmitAndContinue()}
          >
            <p className="my-0 font-12px fw-bold">{currentStep === "client" ? "Next" : "Add"}</p>
          </Button>
        </>
      }
    >
      <div className="row gap-3">
        <div className="font-14px fw-bold mt-2">
          {currentStep === "client" ? "Personal Details" : "Emergency Contacts"}
        </div>
        {currentStep === "client" && (
          <div className="d-flex flex-row gap-3">
            <div className="col">
              <div className="col">
                <span className="font-10px text-dark">Email</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px py-2"
                    type="text"
                    placeholder="Enter email address"
                    value={client?.email ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, email: e.target.value });
                    }}
                  />
                </InputGroup>
                {isError && client?.email === "" ? (
                  <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                ) : null}
              </div>

              <div className="col">
                <span className="font-10px text-dark">Date of birth</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px py-2"
                    type="date"
                    placeholder="Select date of birth"
                    value={moment(client?.date, "DD/MM/YYYY").format("YYYY-MM-DD") ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, date: e.target.value });
                    }}
                  />
                </InputGroup>
                {isError && client?.date === "" ? (
                  <Form.Control.Feedback type="invalid">Please select your date of birth</Form.Control.Feedback>
                ) : null}
              </div>

              <div className="col">
                <span className="font-10px text-dark">Gender</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Select
                    className="border-0 bg-transparent font-12px py-2"
                    value={client?.gender ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, gender: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </InputGroup>
                {isError && client?.gender === "" ? (
                  <Form.Control.Feedback type="invalid">Please select your gender</Form.Control.Feedback>
                ) : null}
              </div>

              <div className="col">
                <span className="font-10px text-dark">Nationality</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Select
                    className="border-0 bg-transparent font-12px py-2"
                    value={client?.nationality ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, nationality: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select nationality
                    </option>
                    {contry_data.map((country: any) => (
                      <option key={country.sid} value={country.sid}>
                        {country.iso_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {isError && client?.nationality === "" ? (
                  <Form.Control.Feedback type="invalid">Please select your nationality</Form.Control.Feedback>
                ) : null}
              </div>

              <div className="col">
                <span className="font-10px text-dark">Location</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Select
                    className="border-0 bg-transparent font-12px py-2"
                    value={client?.location ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, location: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select location
                    </option>
                    {contry_data.map((country: any) => (
                      <option key={country.sid} value={country.sid}>
                        {country.iso_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {isError && client?.location === "" ? (
                  <Form.Control.Feedback type="invalid">Please select your location</Form.Control.Feedback>
                ) : null}
              </div>
            </div>
            <div className="col">
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">First name</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Enter first name"
                      value={client?.first_name ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, first_name: e.target.value });
                      }}
                    />
                  </InputGroup>
                  {isError && client?.first_name === "" ? (
                    <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="col">
                  <span className="font-10px text-dark">Last name</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Enter last name"
                      value={client?.last_name ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, last_name: e.target.value });
                      }}
                    />
                  </InputGroup>
                  {isError && client?.first_name === "" ? (
                    <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">Mobile prefix</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Select
                      className="border-0 bg-transparent font-12px py-2"
                      value={client?.mobile_prefix ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, mobile_prefix: e.target.value });
                      }}
                    >
                      <option value="" hidden>
                        Select prefix
                      </option>
                      {contry_data.map((country: any) => (
                        <option key={country.sid} value={country.sid}>
                          {country.phone_prefix}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                  {isError && client?.mobile_prefix === "" ? (
                    <Form.Control.Feedback type="invalid">Please select your mobile prefix</Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="col-9">
                  <span className="font-10px text-dark">Phone number</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Enter mobile number"
                      value={client?.phone_number ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, phone_number: e.target.value });
                      }}
                    />
                  </InputGroup>
                  {isError && client?.phone_number === "" ? (
                    <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">Height</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904 align-items-center">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Select height"
                      value={client?.height ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, height: e.target.value });
                      }}
                    />
                    <span className="font-12px text-grey-901 pe-2">cm</span>
                  </InputGroup>
                </div>
                <div className="col">
                  <span className="font-10px text-dark">Weight</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904 align-items-center">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Select height"
                      value={client?.weight ?? ""}
                      onChange={(e) => {
                        setClient({ ...client, weight: e.target.value });
                      }}
                    />
                    <span className="font-12px text-grey-901 pe-2">kg</span>
                  </InputGroup>
                </div>
              </div>
              <div className="col">
                <span className="font-10px text-dark">Health Status</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Select
                    className="border-0 bg-transparent font-12px py-2"
                    value={client?.health_status ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, health_status: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select health status
                    </option>
                    <option value="High risk">High risk</option>
                    <option value="Low risk">Low risk</option>
                  </Form.Select>
                </InputGroup>
                {isError && client?.health_status === "" ? (
                  <Form.Control.Feedback type="invalid">Please select health status</Form.Control.Feedback>
                ) : null}
              </div>
              <div className="col">
                <span className="font-10px text-dark">Care team</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Select
                    className="border-0 bg-transparent font-12px py-2"
                    value={client?.care_team ?? ""}
                    onChange={(e) => {
                      setClient({ ...client, care_team: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select care team
                    </option>
                    {contry_data.map((country: any) => (
                      <option key={country.sid} value={country.sid}>
                        {country.iso_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {isError && client?.care_team === "" ? (
                  <Form.Control.Feedback type="invalid">Please select care team</Form.Control.Feedback>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {currentStep === "contact" &&
          (type === "add" ? (
            <div className="row">
              {contacts.map((_, index) => (
                <ContactBlock
                  isError={isError}
                  key={index}
                  contact={contacts[index]}
                  country_data={contry_data}
                  index={index}
                  update={onUpdate}
                  remove={onDelete}
                />
              ))}
              <AddAnotherButton onAddAnother={onAddAnother} />
            </div>
          ) : (
            <ContactBlock
              isError={isError}
              key={0}
              contact={contacts[0]}
              country_data={contry_data}
              index={0}
              update={onUpdate}
              remove={onDelete}
            />
          ))}
      </div>
    </ActionModal>
  );
};

export default ClientsModal;

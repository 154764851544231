import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import moment from "moment-timezone";
import { RootState } from "@redux/store";
import { useAppDispatch, useAppSelector } from "@redux/store/useStore";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { RegisterAccountCredentialsDto } from "@redux/features/v2/security-service/models/register-credentials-dto";
import { RegisterOrganisationDto } from "@redux/features/v2/org-service/models/register-organisation-dto";
import { RegisterOrganisationResultDto } from "@redux/features/v2/org-service/models/organisation-result-dto";
import { FormControl, MenuItem, Select, FormHelperText } from "@mui/material";
import "../Auth.css";
import eyeIcon from "@assets/icons/login/eye_icon.svg";
import eyeCloseIcon from "@assets/icons/login/eye_close_icon.svg";
import arrowBack from "@assets/icons/signup/back.svg";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import TermsPrivacyModal from "./widgets/TermsPrivacyModal";
import { checkUserExistsAsync } from "@app/redux/features/v2/security-service/securityServiceThunk";
import { postRegisterOrganisationAsync } from "@app/redux/features/v2/org-service/orgServiceThunk";
import { ValidationRules } from "@app/utils/types";
import { RoleType } from "@app/common/enums/RoleType";

const Register = () => {
  // Redux state and dispatch
  const { contry_data } = useAppSelector(
    (state: RootState) => state.staticDataService
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Local state
  const [isMobile, setIsMobile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isInfoValid, setIsInfoValid] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [showTermsPrivacyModal, setShowTermsPrivacyModal] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isOrganisationComplete, setIsOrganisationComplete] = useState(false);
  const [organisationType, setOrganisationType] = useState("individual");

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const onShowTermsPrivacyModal = (data?: null) => {
    setShowTermsPrivacyModal(true);
  };

  const onCloseTermsPrivacyModal = () => {
    setShowTermsPrivacyModal(false);
  };

  // Form handling
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    reset,
  } = useForm<RegisterAccountCredentialsDto>({
    defaultValues: {
      username: "",
      password: "",
      confirm_password: "",
      organisation_id: "",
      // Invidual
      country: "",
      // Organization
      first_name: "",
      last_name: "",
    },
    mode: "onChange",
    criteriaMode: "all",
  });

  const {
    control: orgControl,
    formState: { errors: orgErrors },
    handleSubmit: orgHandleSubmit,
    watch: orgWatch,
    register: orgRegister,
    setValue: orgSetValue,
    setError: orgSetError,
    reset: orgReset,
  } = useForm<RegisterOrganisationDto>({
    defaultValues: {
      name: "",
      location: "",
      timezone: "",
      jurisdiction: "",
    },
    mode: "onChange",
    criteriaMode: "all",
  });

  useEffect(() => {
    if (selectedCountry) {
      const countrySelected = contry_data.find(
        (country: any) => country.sid === selectedCountry
      );
      if (countrySelected) {
        const isoCode = countrySelected.iso_a2_code.toUpperCase();
        setSelectedCountryCode(isoCode);
        orgSetValue("jurisdiction", isoCode);
        try {
          const timezones = moment.tz.zonesForCountry(isoCode);
          orgSetValue("timezone", timezones[0]);
        } catch (error) {
          console.error(`Error fetching timezones for ${isoCode}:`, error);
        }
      }
    }
  }, [selectedCountry, contry_data]);

  // Watch form fields
  const username = watch("username");
  const country = watch("country");
  const password = watch("password");
  const confirm_password = watch("confirm_password");
  const organisation_id = watch("organisation_id");
  const first_name = watch("first_name");
  const last_name = watch("last_name");

  const name = orgWatch("name");
  const location = orgWatch("location");
  const timezone = orgWatch("timezone");
  const jurisdiction = orgWatch("jurisdiction");

  // Effects
  useEffect(() => {
    const storedValue = localStorage.getItem("isMobile");
    setIsMobile(storedValue === "true");
  }, []);

  useEffect(() => {
    setIsFormValid(
      username !== "" &&
        password !== "" &&
        confirm_password !== "" &&
        (organisationType === "individual" ? country !== "" : true) &&
        (organisationType === "organisation" ? first_name !== "" : true) &&
        (organisationType === "organisation" ? last_name !== "" : true)
    );
  }, [
    username,
    country,
    password,
    confirm_password,
    first_name,
    last_name,
    organisationType,
  ]);

  useEffect(() => {
    setIsInfoValid(
      name !== "" && location !== "" && timezone !== "" && jurisdiction !== ""
    );
  }, [name, location, timezone, jurisdiction]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOrganisationType(value);

    const resetValues = {
      username: "",
      password: "",
      confirm_password: "",
      country: "",
      first_name: "",
      last_name: "",
    };

    const resetOrgValues = {
      name: "",
      location: "",
      timezone: "",
      jurisdiction: "",
    };

    reset(resetValues);
    orgReset(resetOrgValues);
    setSelectedCountry("");
  };

  // Handlers
  const onSubmit = (registerCredentials: RegisterAccountCredentialsDto) => {
    dispatch(checkUserExistsAsync(registerCredentials.username)).then(
      (action) => {
        if (action.meta.requestStatus === "fulfilled") {
          const result = action.payload as string;
          if (result === "Email exists") {
            setError("username", {
              type: "manual",
              message: "This email already exists, please try another email!",
            });
          } else {
            onShowTermsPrivacyModal();
          }
        }
      }
    );
  };

  const onSubmitOrganisation = (
    registerOrganisation: RegisterOrganisationDto
  ) => {
    dispatch(postRegisterOrganisationAsync(registerOrganisation)).then(
      (action) => {
        if (action.meta.requestStatus === "fulfilled") {
          const result = action.payload as RegisterOrganisationResultDto;
          setValue("organisation_id", result.organisation_id);
          setIsOrganisationComplete(true);
        }
      }
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedCountry(value);
    setValue("country", value);
  };

  // JSX
  return (
    <div className="d-flex h-100 w-100 justify-content-end">
      {/* Right Panel */}
      <Row className="h-100 w-50 bg-white m-0">
        <Col className="col-12 w-100" style={{ marginTop: "200px" }}>
          <Row>
            <Col className="col-12 w-100">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column" style={{ width: "530px" }}>
                  {isOrganisationComplete &&
                    organisationType === "organisation" && (
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => setIsOrganisationComplete(false)}
                      >
                        <img src={arrowBack} alt="back" className="mr-1.5" />
                        <p
                          style={{ color: "#212121" }}
                          className="font-12px fw-medium fw-medium my-0"
                        >
                          Back
                        </p>
                      </div>
                    )}
                  <p
                    style={{ color: "#212121" }}
                    className="font-24px fw-medium text-start my-0"
                  >
                    Sign Up
                  </p>
                  <p className="font-12px text-grey-901 fw-normal">
                    Sign up or login to manage your health better
                  </p>
                  <div className="w-100">
                    {(!isOrganisationComplete ||
                      organisationType === "individual") && (
                      <>
                        <p
                          style={{ color: "#212121" }}
                          className="font-10px fw-medium text-start my-0"
                        >
                          I'm an...
                        </p>
                        <Form.Group
                          className="mb-3 d-flex"
                          controlId="loginForm.isOrganisation"
                          style={{ gap: 20 }}
                        >
                          <label
                            className="d-flex"
                            style={{ gap: 5 }}
                            htmlFor="organisation"
                          >
                            <input
                              className="font-12px fw-medium"
                              style={{ color: "#0D0D0D" }}
                              type="radio"
                              value="organisation"
                              id="organisation"
                              onChange={handleRadioChange}
                              checked={organisationType === "organisation"}
                            />
                            Organization
                          </label>
                          <label
                            className="d-flex"
                            style={{ gap: 5 }}
                            htmlFor="individual"
                          >
                            <input
                              className="font-12px fw-medium"
                              style={{ color: "#0D0D0D" }}
                              type="radio"
                              value="individual"
                              id="individual"
                              onChange={handleRadioChange}
                              checked={organisationType === "individual"}
                            />
                            Individual
                          </label>
                        </Form.Group>
                      </>
                    )}
                    {organisationType === "organisation" &&
                      !isOrganisationComplete && (
                        <Form
                          onSubmit={orgHandleSubmit(onSubmitOrganisation)}
                          id="organisationForm"
                        >
                          <p
                            style={{ color: "#212121" }}
                            className="font-14px fw-medium text-start my-0"
                          >
                            Organization’s information
                          </p>
                          <FormInput
                            type="text"
                            control={orgControl}
                            name="name"
                            label="Organization’s name"
                            placeholder="Enter your organization’s name"
                            rules={{
                              required: "Organization name is required",
                            }}
                            error={orgErrors.name?.message ?? ""}
                          />
                          <Form.Group
                            id="registerCountry"
                            className="mb-3 text-capitalize"
                            controlId="registerForm.location"
                          >
                            <FormControl
                              fullWidth
                              error={Boolean(orgErrors.location)}
                              sx={{ marginBottom: 2 }}
                            >
                              <label className="font-10px text-dark">
                                Location
                              </label>
                              <Controller
                                name="location"
                                control={orgControl}
                                rules={{ required: "Country is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    value={selectedCountry || ""}
                                    onChange={(e: any) => {
                                      handleCountryChange(e);
                                      field.onChange(e.target.value);
                                    }}
                                    labelId="location-select-label"
                                    id="location-select"
                                    label="Location"
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      Select Location
                                    </MenuItem>
                                    {contry_data.map((country: any) => (
                                      <MenuItem
                                        key={country.sid}
                                        value={country.sid}
                                      >
                                        {country.iso_name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              />
                              {orgErrors.location && (
                                <FormHelperText>
                                  {orgErrors.location.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Form.Group>
                          <FormInput
                            type="text"
                            control={orgControl}
                            name="timezone"
                            label="Timezone"
                            disabled
                            placeholder="Select timezone"
                            rules={{ required: "Timezone is required" }}
                            error={orgErrors.timezone?.message ?? ""}
                          />
                          <FormInput
                            type="text"
                            control={orgControl}
                            name="jurisdiction"
                            label="Data Protection Jurisdiction"
                            disabled
                            placeholder="Select Jurisdiction"
                            rules={{ required: "Jurisdiction is required" }}
                            error={orgErrors.jurisdiction?.message ?? ""}
                          />
                          <Button
                            as="input"
                            type="submit"
                            value="Continue"
                            className={`font-12px fw-medium rounded-3 ${
                              isInfoValid
                                ? "text-white bg-blue-902"
                                : "text-grey-901 bg-grey-904"
                            } border-0 px-3 d-flex align-items-center text-login no-hover`}
                          />
                        </Form>
                      )}

                    {(isOrganisationComplete ||
                      organisationType === "individual") && (
                      <Form onSubmit={handleSubmit(onSubmit)} id="loginForm">
                        <>
                          {organisationType === "organisation" && (
                            <>
                              <p
                                style={{ color: "#212121" }}
                                className="font-14px fw-medium text-start my-0"
                              >
                                Organization Owner
                              </p>
                              <div className="d-flex gap-3 align-items-start">
                                <FormInput
                                  control={control}
                                  name="first_name"
                                  label="First name"
                                  type="text"
                                  placeholder="Enter your first name"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "First name is required",
                                    },
                                  }}
                                  error={errors.first_name?.message ?? ""}
                                />
                                <FormInput
                                  control={control}
                                  name="last_name"
                                  label="Last name"
                                  type="text"
                                  placeholder="Enter your last name"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Last name is required",
                                    },
                                  }}
                                  error={errors.last_name?.message ?? ""}
                                />
                              </div>
                            </>
                          )}
                          <FormInput
                            control={control}
                            name="username"
                            label="Email address"
                            type="email"
                            placeholder="Enter your email address"
                            rules={{
                              required: {
                                value: true,
                                message: "Email address is required",
                              },
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email address",
                              },
                            }}
                            error={errors.username?.message ?? ""}
                          />
                          {organisationType === "individual" && (
                            <Form.Group
                              id="registerCountry"
                              className="mb-3 text-capitalize"
                              controlId="registerForm.country"
                            >
                              <FormControl
                                fullWidth
                                error={Boolean(errors.country)}
                                sx={{ marginBottom: 2 }}
                              >
                                <label className="font-10px text-dark">
                                  Country
                                </label>
                                <Controller
                                  name="country"
                                  control={control}
                                  rules={{ required: "Country is required" }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      value={selectedCountry || ""}
                                      onChange={(e: any) => {
                                        handleCountryChange(e);
                                        field.onChange(e.target.value);
                                      }}
                                      labelId="country-select-label"
                                      id="country-select"
                                      label="Country"
                                      displayEmpty
                                    >
                                      <MenuItem value="">
                                        Select Country
                                      </MenuItem>
                                      {contry_data.map((country: any) => (
                                        <MenuItem
                                          key={country.sid}
                                          value={country.sid}
                                        >
                                          {country.iso_name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                />
                                {errors.country && (
                                  <FormHelperText>
                                    {errors.country.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Form.Group>
                          )}
                          <FormInput
                            control={control}
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            togglePasswordVisibility={togglePasswordVisibility}
                            isPassword
                            rules={{
                              required: "Password is required",
                              validate: (value: string) => {
                                const regex =
                                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
                                return (
                                  regex.test(value) ||
                                  "Password must be at least 8 characters long, include a number, an uppercase letter, and a lowercase letter."
                                );
                              },
                            }}
                            error={errors.password?.message ?? ""}
                          />
                          <FormInput
                            control={control}
                            name="confirm_password"
                            label="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm your password"
                            togglePasswordVisibility={
                              toggleConfirmPasswordVisibility
                            }
                            rules={{
                              required: "Confirm Password is required",
                              validate: (value: string) =>
                                value === password || "Passwords do not match",
                            }}
                            isPassword
                            error={errors.confirm_password?.message ?? ""}
                          />
                          <p
                            style={{ color: "#A6A4AB" }}
                            className="font-12px fw-medium"
                          >
                            Your password must be at least 8 characters long,
                            include a number, an uppercase letter and a
                            lowercase letter.
                          </p>
                          <Button
                            as="input"
                            type="submit"
                            value="Continue"
                            className={`font-12px fw-medium rounded-3 ${
                              isFormValid
                                ? "text-white bg-blue-902"
                                : "text-grey-901 bg-grey-904"
                            } border-0 px-3 d-flex align-items-center text-login no-hover`}
                          />
                        </>
                      </Form>
                    )}
                    <p
                      style={{ color: "#212121" }}
                      className="mt-4 text-start font-12px fw-medium"
                    >
                      Already have an account?{" "}
                      <Link
                        to="/auth/login"
                        className="text-start font-12px fw-medium text-decoration-none"
                        style={{ color: "#3F72F3", width: "fit-content" }}
                      >
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Terms Privacy Modal */}
      <TermsPrivacyModal
        show={showTermsPrivacyModal}
        onHide={onCloseTermsPrivacyModal}
        countryCode={selectedCountryCode}
        registerCredentials={{
          username: username,
          password: password,
          organisation_id: organisation_id,
          role_id: "string",
          role:
            organisationType === "individual"
              ? RoleType.WEARER
              : RoleType.ORG_ADMIN,
        }}
      />
    </div>
  );
};

// Helper Components
type FormInputProps = {
  control: any;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  togglePasswordVisibility?: () => void;
  rules?: ValidationRules<string>;
  error?: string;
  isPassword?: boolean;
  disabled?: boolean;
};

const FormInput: React.FC<FormInputProps> = ({
  control,
  name,
  label,
  type,
  placeholder,
  togglePasswordVisibility,
  rules = { required: { value: true, message: `${name} is required` } },
  error,
  isPassword = false,
  disabled = false,
}) => (
  <Form.Group className="mb-3 w-100" controlId={`loginForm.${name}`}>
    <span className="font-10px text-dark">{label}</span>
    <InputGroup className="border rounded-3 border-0 bg-grey-904">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type={type}
            onChange={onChange}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
      />
      {isPassword && togglePasswordVisibility && (
        <div
          className="d-flex justify-content-center align-items-center me-2 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          <img
            width={12}
            height={13}
            src={type === "text" ? eyeCloseIcon : eyeIcon}
            alt="icon"
          />
        </div>
      )}
    </InputGroup>
    {error && <Form.Text className="text-danger">{error}</Form.Text>}
  </Form.Group>
);

export default Register;

import { addWarningNotification } from "@app/redux/features/common/notificationSlice";
import { AssignDeviceOtpAsync, CreateDeviceOtpAsync } from "@app/redux/features/v2/device-service/deviceServiceThunk";
import { MapDeviceDto } from "@app/redux/features/device-service/models/MapDeviceDto";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useEffect, useState } from "react";
import { Button, CloseButton, InputGroup, Form, Modal } from "react-bootstrap";

interface DeviceActionModalProps {
  show: boolean;
  title?: string;
  onHide?(): void;
  contentClassName?: string;
  size: "sm" | "lg" | "xl" | undefined;
  icon?: string;
}

const DeviceActionModal = ({
  show,
  title,
  onHide,
  size,
  contentClassName,
  icon,
}: DeviceActionModalProps) => {
  const dispatch = useAppDispatch();
  const { user_profile: profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);
  const [imei, setImei] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState<'imei' | 'otp'>('imei');
  const [timer, setTimer] = useState(120); // 2 mins timer
  const [timerExpired, setTimerExpired] = useState(false);

  // Timer countdown effect
  useEffect(() => {
    if (step === 'otp' && timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setTimerExpired(true);
      setStep('imei'); // Reset to IMEI step when timer expires
      setImei(''); // Clear the IMEI input field
      setOtp('');  // Clear the OTP input field if needed
    }
  }, [step, timer]);

  const createOtpConnect = async () => {
    try {
      // Dispatch the API call and capture the response
      const response = await dispatch(CreateDeviceOtpAsync(imei)).unwrap(); // Ensure you use .unwrap() to access the payload directly if using Redux toolkit

      // On success
      setStep('otp');
      setTimer(120); // reset timer for OTP
      setTimerExpired(false);
      // Reset the OTP input field
      setImei(''); // Clear the OTP input when transitioning
    } catch (error) {
      dispatch(addWarningNotification("Error registering device please contact Support"));
    }

  }

  const handleVerify = async () => {
    try {
      if (profile && profile?.sid) {
        // Dispatch the API call
        // Construct the MapDeviceDto object
        const mapDeviceData: MapDeviceDto = {
          first_name: profile?.first_name ?? "", // Replace with actual user profile data
          last_name: profile?.last_name ?? "",    // Replace with actual user profile data
          assigned_user_sid: profile?.sid ?? "", // Replace with actual user SID
          setup_date: new Date(),
          sid: "" // Replace this with the actual device SID if available
        };

        // Dispatch the API call with both otp and mapDeviceData
        await dispatch(AssignDeviceOtpAsync({ otp, data: mapDeviceData }));
        setOtp(''); // Clear the OTP input when transitioning
      } else {
        dispatch(addWarningNotification("There is issue with User registration"));
      }

    } catch (error) {
      console.error("Failed to verify OTP", error);
    }
  };

  const resendOtp = () => {
    setTimer(120);
    setTimerExpired(false);
  };

  return (
    <Modal
      show={show}
      onhid
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={contentClassName}
    >
      <Modal.Header className="py-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex align-items-center py-2 rounded-3 col-6 title-chart">
            <div className="rounded-5 icon-chart-custom d-flex align-items-center justify-content-center">
              <img height={32} width={32} src={icon} alt="Icon" />
            </div>
            <div className="ps-1 text-dark">
              <div className="d-flex flex-column">
                <span className="font-14px fw-bold text-dark whitespace-nowrap overflow-hidden text-truncate">
                  {title}
                </span>
              </div>
            </div>
          </div>
        </Modal.Title>
        <CloseButton onClick={onHide} />
      </Modal.Header>

      {step === 'imei' ? <>
        <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <span className="font-14px text-dark">
            Please turn on your watch and enter the IMEI number from the box:
          </span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904 mt-2">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="text"
              placeholder="Enter IMEI number"
              value={imei}
              onChange={(e) => setImei(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer className="bg-grey-904">
          <Button className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center ms-2" onClick={createOtpConnect}>
            <p className="ms-1 my-0 font-12px progress-bar fw-bold">Connect</p>
          </Button>
        </Modal.Footer>
      </> : <>
        <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <span className="font-14px text-dark">
            Enter the OTP from your watch:
          </span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904 mt-2">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="text"
              placeholder="Enter OTP"
              value={imei}
              onChange={(e) => setOtp(e.target.value)}
            />
          </InputGroup>
          <div className="mt-2">
            Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-grey-904">
          <Button className="rounded-3 bg-blue-901 border-blue-901 py-2 px-3 d-flex align-items-center ms-2" onClick={handleVerify}>
            <p className="ms-1 my-0 font-12px progress-bar fw-bold">Verify OTP</p>
          </Button>
        </Modal.Footer>
      </>}

    </Modal>
  );
};

export default DeviceActionModal;

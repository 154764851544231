import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  Legend,
  ReferenceLine,
} from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import "./styles.scss";
import { VitalsGraphDto } from "@app/redux/features/health-service/models/bp-graph-dto";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import React, { useState, useEffect } from "react";

type Props = {
  unit: string;
  period: string;
  data: VitalsGraphDto;
};

interface AggregatedData {
  date: number;
  value: number;
  min: number;
  max: number;
  count: number;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  unit,
  period,
  coordinate,
  position,
  yAxisDomain,
  setClosestValue,
  setActiveState,
}: TooltipProps<ValueType, NameType> & {
  unit: string;
  period: string;
  yAxisDomain: number[];
  coordinate: { x: number; y: number };
  position: { y0: number; y1: number };
  setClosestValue: (value: number) => void;
  setActiveState: (active: boolean) => void;
}) => {
  useEffect(() => {
    setActiveState(active || false);
  }, [active, setActiveState]);

  if (!active || !payload?.length) return null;

  const yAxisMin = yAxisDomain[0];
  const yAxisMax = yAxisDomain[yAxisDomain.length - 1];
  const yAxisHeight = position.y1 - position.y0;

  const dataValue =
    yAxisMin +
    (yAxisMax - yAxisMin) *
      ((yAxisHeight - (coordinate.y - 20) - position.y0) / yAxisHeight);

  const closestPayload = payload.reduce((prev: any, curr: any) => {
    const currDistance = Math.abs(dataValue - curr.value);
    const prevDistance = Math.abs(dataValue - prev.value);
    return prevDistance < currDistance ? prev : curr;
  });

  setClosestValue(Number(closestPayload.value));

  return (
    <div className="bg-black rounded-1 p-1 px-2">
      <p className="mb-0 font-14px text-white fw-medium">{`${closestPayload.value} ${unit}`}</p>
      <p className="mb-0 font-12px text-grey fw-normal">
        {xAxisTickFormatter(label, period)}
      </p>
    </div>
  );
};

const CustomCursor = ({ onChange, ...props }: any) => {
  const { points, height } = props;

  const [lastPoints, setLastPoints] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (
      !lastPoints ||
      points[0].x !== lastPoints.x ||
      points[0].y !== lastPoints.y
    ) {
      onChange(points);
      setLastPoints(points[0]);
    }
  }, [points, lastPoints, onChange]);

  return (
    <g>
      <line
        x1={points[0].x}
        y1={10}
        x2={points[0].x}
        y2={height + 10}
        stroke="#CCC"
        strokeWidth={1}
        strokeDasharray="5 5"
      />
    </g>
  );
};

const CustomLegend = ({ payload }: TooltipProps<ValueType, NameType>) => {
  return (
    <ul className="list-group list-group-horizontal justify-content-center ms-5">
      {payload?.map((entry, index) => (
        <li
          className="list-group-item py-0 px-2 border-0"
          style={{ color: entry.color, backgroundColor: "transparent" }}
          key={`item-${index}`}
        >
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="fw-medium"
          >
            <span className="fs-4 me-2">&#x2022;</span>
            <span className="fw-medium">{entry.value}</span>
          </span>
        </li>
      ))}
    </ul>
  );
};

const formatDataStructure = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  return data.vital_datetime.map((datetime, index) => ({
    date: datetime,
    value: data.vital_data.VITAL![index],
    min: data.vital_data.VITAL_MIN![index],
    max: data.vital_data.VITAL_MAX![index],
  }));
};

const formatDailyDateData = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  const formattedData = formatDataStructure(data);

  return formattedData.map((item) => {
    const dateObj = new Date(item.date);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const totalMinutes = hours * 60 + minutes;
    const scale = Math.floor(totalMinutes / 5);

    return {
      ...item,
      date: scale,
    };
  });
};

// const formatWeeklyData = (data: VitalsGraphDto) => {
//   if (!data || !data.vital_datetime?.length) return [];
//   const formattedData = formatDataStructure(data);
//   const grouped: Record<
//     number,
//     { totalValue: number; count: number; min: number; max: number }
//   > = {};

//   formattedData.forEach(({ date, value, min, max }) => {
//     const dayOfWeek = new Date(date).getDay();
//     if (!grouped[dayOfWeek]) {
//       grouped[dayOfWeek] = {
//         totalValue: 0,
//         count: 0,
//         min: Infinity,
//         max: -Infinity,
//       };
//     }
//     grouped[dayOfWeek].totalValue += value;
//     grouped[dayOfWeek].count += 1;
//     grouped[dayOfWeek].min = Math.min(grouped[dayOfWeek].min, min);
//     grouped[dayOfWeek].max = Math.max(grouped[dayOfWeek].max, max);
//   });

//   return Object.keys(grouped).map((dayOfWeek) => ({
//     date: Number(dayOfWeek),
//     value:
//       grouped[Number(dayOfWeek)].totalValue / grouped[Number(dayOfWeek)].count,
//     min: grouped[Number(dayOfWeek)].min,
//     max: grouped[Number(dayOfWeek)].max,
//   }));
// };

const formatWeeklyData = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  const formattedData = formatDataStructure(data);

  const today = new Date().getDay();
  const grouped: Record<
    number,
    { totalValue: number; count: number; min: number; max: number }
  > = {};

  formattedData.forEach(({ date, value, min, max }) => {
    const dayOffset = (new Date(date).getDay() - today + 7) % 7;
    if (!grouped[dayOffset]) {
      grouped[dayOffset] = {
        totalValue: 0,
        count: 0,
        min: Infinity,
        max: -Infinity,
      };
    }
    grouped[dayOffset].totalValue += value;
    grouped[dayOffset].count += 1;
    grouped[dayOffset].min = Math.min(grouped[dayOffset].min, min);
    grouped[dayOffset].max = Math.max(grouped[dayOffset].max, max);
  });

  return Object.keys(grouped).map((dayOffset) => ({
    date: Number(dayOffset),
    value:
      grouped[Number(dayOffset)].totalValue / grouped[Number(dayOffset)].count,
    min: grouped[Number(dayOffset)].min,
    max: grouped[Number(dayOffset)].max,
  }));
};

const formatMonthlyData = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  const formattedData = formatDataStructure(data);

  return formattedData.map((item) => {
    const dayOfMonth = new Date(item.date).getDate();
    return { ...item, date: dayOfMonth };
  });
};

const formatYearlyData = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  const formattedData = formatDataStructure(data);

  return Object.values(
    formattedData.reduce<Record<string, AggregatedData>>(
      (acc, { date, value, min, max }) => {
        const month = new Date(date).toLocaleString("en-US", {
          month: "numeric",
        });
        if (!acc[month]) {
          acc[month] = {
            date: +month,
            value: 0,
            min: Infinity,
            max: -Infinity,
            count: 0,
          };
        }
        acc[month].value += value;
        acc[month].min = Math.min(acc[month].min, min);
        acc[month].max = Math.max(acc[month].max, max);
        acc[month].count++;
        return acc;
      },
      {}
    )
  ).map(({ date, value, min, max, count }) => ({
    date,
    value: parseFloat((value / count).toFixed(2)),
    min,
    max,
  }));
};

const formatHalfYearData = (data: VitalsGraphDto) => {
  if (!data || !data.vital_datetime?.length) return [];
  const currentMonth = new Date().getMonth();

  const monthIndices = Array.from(
    { length: 6 },
    (_, i) => currentMonth - 5 + i
  );

  const result: { date: number; value: number; min: number; max: number }[] =
    [];

  monthIndices.forEach((monthIndex) => {
    const adjustedMonthIndex = (monthIndex + 12) % 12;

    const monthIndices = data.vital_datetime.reduce((acc, date, index) => {
      const dateMonth = new Date(date).getMonth();
      if (dateMonth === adjustedMonthIndex) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);

    if (monthIndices.length > 0) {
      const monthData = monthIndices.map((index: number) => ({
        value: data.vital_data["VITAL"]?.[index] ?? 0,
        min: data.vital_data["VITAL_MIN"]?.[index] ?? 0,
        max: data.vital_data["VITAL_MAX"]?.[index] ?? 0,
      }));

      const averageValue =
        monthData.reduce((sum, data) => sum + data.value, 0) / monthData.length;
      const minValue = Math.min(...monthData.map((data) => data.min));
      const maxValue = Math.max(...monthData.map((data) => data.max));

      result.push({
        date: monthIndex + 1 - currentMonth,
        value: parseFloat(averageValue.toFixed(2)) || 0,
        min: minValue ?? 0,
        max: maxValue ?? 0,
      });
    } else {
      result.push({
        date: monthIndex + 1 - currentMonth,
        value: 0,
        min: 0,
        max: 0,
      });
    }
  });

  return result;
};

const formatData = (data: VitalsGraphDto, period: string) => {
  if (!data || !data.vital_datetime?.length) return [];
  switch (period) {
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
      return formatDailyDateData(data);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
      return formatWeeklyData(data);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
      return formatMonthlyData(data);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
      return formatHalfYearData(data);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
      return formatYearlyData(data);
    default:
      return formatDailyDateData(data);
  }
};

const formatDailyTick = (tick: any) => {
  const totalMinutes = tick * 5;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

const formatWeeklyTick = (tick: any) => {
  const shortDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return shortDays[tick - 1];
};

const formatYearlyTick = (tick: any) => {
  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return shortMonths[tick - 1];
};

const formatHalfYearTick = (tick: number) => {
  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const adjustedTick = (tick - 1 + 12) % 12;
  return shortMonths[adjustedTick];
};

const xAxisTickFormatter = (tick: any, period: string) => {
  switch (period) {
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
      return formatDailyTick(tick);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.week): {
      const shortDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const today = new Date().getDay();
      const adjustedDay = (tick + today) % 7;
      return shortDays[(adjustedDay + 6) % 7];
    }
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
      return formatHalfYearTick(tick);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
      return formatYearlyTick(tick);
    default:
      return tick;
  }
};

const xAxisDomainByPeriod = (period: string) => {
  switch (period) {
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
      return [0, 288];
    // case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
    //   return [1, 7];

    case TimePeriodHelper.getTimePeriodJson(TimePeriod.week): {
      const today = new Date().getDay();
      return Array.from({ length: 7 }, (_, i) => (today - 6 + i + 7) % 7 || 7);
    }
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
      return [1, 31];
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
      return [new Date().getMonth() - 4, new Date().getMonth() + 1];
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
      return [1, 12];
    default:
      return undefined;
  }
};

const xAxisTicksByPeriod = (period: string) => {
  switch (period) {
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
      return [0, 72, 144, 216, 288];
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
      return Array.from({ length: 7 }, (_, i) => i + 1);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
      return Array.from({ length: 31 }, (_, i) => i + 1);
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
      return [
        new Date().getMonth() - 4,
        new Date().getMonth() - 3,
        new Date().getMonth() - 2,
        new Date().getMonth() - 1,
        new Date().getMonth(),
        new Date().getMonth() + 1,
      ];
    case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
      return Array.from({ length: 12 }, (_, i) => i + 1);
    default:
      return undefined;
  }
};
const yAxisDomainFormat = (
  formattedData: {
    value: number;
    date: number;
    min: number;
    max: number;
  }[]
): [number, number] => {
  const values = formattedData.flatMap(({ min, max }) => [min, max]);
  return [
    Math.floor(Math.min(...values) / 10) * 10,
    Math.ceil(Math.max(...values) / 10) * 10,
  ];
};

const yAxisDomainDayFormat = (
  formattedData: {
    value: number;
    date: number;
    min: number;
    max: number;
  }[]
): [number, number] => {
  const values = formattedData.map(({ value }) => value);
  return [
    Math.floor(Math.min(...values) / 10) * 10,
    Math.ceil(Math.max(...values) / 10) * 10,
  ];
};

const yAxisTicksFormat = (
  formattedData: {
    date: number;
    value: number;
    min: number;
    max: number;
  }[]
): number[] => {
  const [min, max] = yAxisDomainFormat(formattedData);
  const range = max - min;
  const step = range / 4;
  return Array.from({ length: 5 }, (_, i) => min + step * i);
};

const yAxisTicksDayFormat = (
  formattedData: {
    date: number;
    value: number;
    min: number;
    max: number;
  }[]
): number[] => {
  const [min, max] = yAxisDomainDayFormat(formattedData);
  const range = max - min;
  const step = range / 4;
  return Array.from({ length: 5 }, (_, i) => min + step * i);
};

const AnalysisChart = ({ data, unit, period }: Props) => {
  const [cursorPosition, setCursorPosition] = useState({ y0: 0, y1: 0 });
  const [closestValue, setClosestValue] = useState<number | null>(null);
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const formattedData = formatData(data, period);
  console.log(formattedData);

  const isDataEmpty = !formattedData.length
    ? formattedData.every((entry) => entry.value === null)
    : false;
  const xAxisDomain = xAxisDomainByPeriod(period);
  const xAxisTicks = xAxisTicksByPeriod(period);

  const yAxisDomain = isDataEmpty
    ? [0, 100]
    : period === "DAY"
    ? yAxisDomainDayFormat(formattedData)
    : yAxisDomainFormat(formattedData);

  const yAxisTicks = isDataEmpty
    ? [0, 20, 40, 60, 80, 100]
    : period === "DAY"
    ? yAxisTicksDayFormat(formattedData)
    : yAxisTicksFormat(formattedData);

  const handleCursorChange = (points: any) => {
    if (points && points.length > 0) {
      setCursorPosition({ y0: points[0].y, y1: points[1].y });
    }
  };

  return (
    <div className="container font-12px">
      <div className="chart-container position-relative">
        {isDataEmpty && (
          <div
            className="d-flex align-items-center position-absolute rounded-1 bg-blue-50 text-blue-901 p-2"
            style={{ top: "35%", left: "20%", zIndex: "1001" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <rect width="16" height="16" rx="8" fill="#3F72F3" />
              <path
                d="M8 3.9375C7.19652 3.9375 6.41107 4.17576 5.743 4.62215C5.07492 5.06855 4.55422 5.70302 4.24674 6.44535C3.93926 7.18767 3.85881 8.00451 4.01556 8.79255C4.17231 9.5806 4.55923 10.3045 5.12738 10.8726C5.69553 11.4408 6.4194 11.8277 7.20745 11.9844C7.9955 12.1412 8.81233 12.0607 9.55465 11.7533C10.297 11.4458 10.9315 10.9251 11.3778 10.257C11.8242 9.58893 12.0625 8.80349 12.0625 8C12.0614 6.92291 11.633 5.89025 10.8714 5.12863C10.1097 4.36701 9.07709 3.93864 8 3.9375ZM7.6875 6.125C7.6875 6.04212 7.72043 5.96263 7.77903 5.90403C7.83764 5.84542 7.91712 5.8125 8 5.8125C8.08288 5.8125 8.16237 5.84542 8.22097 5.90403C8.27958 5.96263 8.3125 6.04212 8.3125 6.125V8.3125C8.3125 8.39538 8.27958 8.47487 8.22097 8.53347C8.16237 8.59208 8.08288 8.625 8 8.625C7.91712 8.625 7.83764 8.59208 7.77903 8.53347C7.72043 8.47487 7.6875 8.39538 7.6875 8.3125V6.125ZM8 10.1875C7.90729 10.1875 7.81666 10.16 7.73958 10.1085C7.66249 10.057 7.60241 9.98379 7.56693 9.89813C7.53145 9.81248 7.52217 9.71823 7.54026 9.6273C7.55835 9.53637 7.60299 9.45285 7.66855 9.38729C7.7341 9.32174 7.81762 9.27709 7.90855 9.25901C7.99948 9.24092 8.09373 9.2502 8.17938 9.28568C8.26504 9.32116 8.33825 9.38124 8.38975 9.45833C8.44126 9.53541 8.46875 9.62604 8.46875 9.71875C8.46875 9.84307 8.41937 9.9623 8.33146 10.0502C8.24355 10.1381 8.12432 10.1875 8 10.1875Z"
                fill="white"
              />
            </svg>
            <span className="ms-1">
              There is currently no data available to show
            </span>
          </div>
        )}
        <div
          style={{
            width: period === "MONTH" ? "180%" : "100%",
          }}
        >
          <ResponsiveContainer width="110%" height={200}>
            <LineChart
              data={formattedData}
              margin={{ top: 10, left: 30, right: 15 }}
            >
              <XAxis
                interval={0}
                axisLine={false}
                tickLine={false}
                type="number"
                dataKey="date"
                fontSize={12}
                padding={{ left: 20 }}
                domain={xAxisDomain}
                ticks={xAxisTicks}
                tickFormatter={(tick) => xAxisTickFormatter(tick, period)}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                type="number"
                fontSize={12}
                domain={yAxisDomain}
                ticks={yAxisTicks}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <ReferenceLine
                y={closestValue || 0}
                stroke={
                  isTooltipActive && closestValue !== 0 ? "#CCC" : "transparent"
                }
                strokeWidth={1}
                strokeDasharray="5 5"
              />
              <Tooltip
                cursor={<CustomCursor onChange={handleCursorChange} />}
                content={
                  <CustomTooltip
                    yAxisDomain={yAxisTicks}
                    position={cursorPosition}
                    unit={unit}
                    period={period}
                    coordinate={{ x: 0, y: 1 }}
                    setClosestValue={setClosestValue}
                    setActiveState={setIsTooltipActive}
                  />
                }
              />
              {period !==
                TimePeriodHelper.getTimePeriodJson(TimePeriod.day) && (
                <Legend content={<CustomLegend />} />
              )}
              <Line
                type="monotone"
                name="Maximum"
                dataKey="max"
                stroke="#FFAA00"
                dot={false}
              />

              <Line
                type="monotone"
                name="Average"
                dataKey="value"
                stroke="#3B6FF3"
                dot={false}
              />

              <Line
                type="monotone"
                name="Minimum"
                dataKey="min"
                stroke="#00BD91"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AnalysisChart;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { appConstants } from "@app/redux/common/constants/app-constant";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import { BaseState } from "@app/redux/common/models/base-state";

import {
  getClientsAlertCardDataAsync,
  getClientsAlertTableDataAsync,
  getClientsCardDataAsync,
  getHighRiskClientsCardDataAsync,
  getHighRiskClientsTableDatasAsync,
} from "./portalHomeThunk";

import {
  CardDataResultDto,
  ClientsAlertTableDataResult,
  HighRiskClientsTableDataResult,
} from "./models/request-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import {
  ClientsAlertTableDataRequest,
  HighRiskClientsTableDataRequest,
} from "./models/request-dto";

export interface PortalHomeState extends BaseState {
  clientsCardData: CardDataResultDto | null;
  clientsCardDataStatus: ApiStatus;
  clientsAlertCardData: CardDataResultDto | null;
  clientsAlertCardDataStatus: ApiStatus;
  highRiskClientsCardData: CardDataResultDto | null;
  highRiskClientsCardDataStatus: ApiStatus;
  highRiskClientsRequest: any;
  highRiskClientsTableDataResultPaginate?: PaginatedResult<HighRiskClientsTableDataResult>;
  clientsAlertRequest: any;
  clientsAlertTableDataResultPaginate?: PaginatedResult<ClientsAlertTableDataResult>;
}

const initalClientRequest = {
  page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  page_size: appConstants.GRID_DEFAULT_PAGE_SIZE,
  page: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  per_page: appConstants.GRID_DEFAULT_PAGE_SIZE,
  org_id: getOrgFromJwt() || "",
  filter_by: undefined,
  search_query: "",
};

const initialState: PortalHomeState = {
  status: ApiStatus.IDLE,
  apiError: undefined,
  clientsCardData: null,
  clientsCardDataStatus: ApiStatus.IDLE,
  clientsAlertCardData: null,
  clientsAlertCardDataStatus: ApiStatus.IDLE,
  highRiskClientsCardData: null,
  highRiskClientsCardDataStatus: ApiStatus.IDLE,
  highRiskClientsRequest: initalClientRequest,
  clientsAlertRequest: initalClientRequest,
};

export const portalHomeSlice = createSlice({
  name: "portalHomeV2",
  initialState,
  reducers: {
    setHighRiskClientsRequest(
      state,
      action: PayloadAction<HighRiskClientsTableDataRequest>
    ) {
      state.highRiskClientsRequest = action.payload;
    },
    setClientsAlertRequest(
      state,
      action: PayloadAction<ClientsAlertTableDataRequest>
    ) {
      state.clientsAlertRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsCardDataAsync.pending, (state) => {
        state.clientsCardDataStatus = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientsCardDataAsync.fulfilled, (state, action) => {
        state.clientsCardDataStatus = ApiStatus.IDLE;
        state.clientsCardData = action.payload;
      })
      .addCase(getClientsCardDataAsync.rejected, (state, action) => {
        state.clientsCardDataStatus = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getClientsAlertCardDataAsync.pending, (state) => {
        state.clientsAlertCardDataStatus = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientsAlertCardDataAsync.fulfilled, (state, action) => {
        state.clientsAlertCardDataStatus = ApiStatus.IDLE;
        state.clientsAlertCardData = action.payload;
      })
      .addCase(getClientsAlertCardDataAsync.rejected, (state, action) => {
        state.clientsAlertCardDataStatus = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getHighRiskClientsCardDataAsync.pending, (state) => {
        state.highRiskClientsCardDataStatus = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getHighRiskClientsCardDataAsync.fulfilled, (state, action) => {
        state.highRiskClientsCardDataStatus = ApiStatus.IDLE;
        state.highRiskClientsCardData = action.payload;
      })
      .addCase(getHighRiskClientsCardDataAsync.rejected, (state, action) => {
        state.highRiskClientsCardDataStatus = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getHighRiskClientsTableDatasAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getHighRiskClientsTableDatasAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.highRiskClientsTableDataResultPaginate = action.payload;
      })
      .addCase(getHighRiskClientsTableDatasAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getClientsAlertTableDataAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientsAlertTableDataAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.clientsAlertTableDataResultPaginate = action.payload;
      })
      .addCase(getClientsAlertTableDataAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });
  },
});

export const { setHighRiskClientsRequest, setClientsAlertRequest } =
  portalHomeSlice.actions;
export default portalHomeSlice.reducer;

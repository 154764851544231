import { BaseState } from "@app/redux/common/models/base-state";
import { CreateUserHealthCalibrationDto } from "./models/create-user-health-calibration-dto";
import { UserHealthCalibrationDto } from "./models/user-health-calibration-dto";
import { GetUserHealthCalibrationDto } from "./models/get-user-health-calibration-dto";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setUserHealthCalibrationAsync, getUserHealthCalibrationAsync } from "./healthSettingsServiceThunk";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { HealthSettingsVitalType } from "@app/common/enums/HealthSettingsVitalType";

export interface HealthSettingsServiceState extends BaseState {
    createUserHealthCalibrationDto: CreateUserHealthCalibrationDto;
    userHealthCalibrationDto: UserHealthCalibrationDto;
    getUserHealthCalibrationDto: GetUserHealthCalibrationDto;
    glucoseData: UserHealthCalibrationDto;
    bloodPressureData: UserHealthCalibrationDto;
}

const initialState: HealthSettingsServiceState = {
    status: ApiStatus.IDLE,
    createUserHealthCalibrationDto: {
        calibration: '',
        sid: '',
        vital: '',
        calibration_datetime: '',
        user_country_code: '',
        wearer_sid: '',
        timezone: '',
    },
    userHealthCalibrationDto: {
        calibration: '',
        sid: '',
        vital: '',
        calibration_datetime: '',
        user_country_code: '',
        wearer_sid: '',
        timezone: '',
    },
    getUserHealthCalibrationDto: {
        vital: '',
        calibration_date: '',
        wearer_sid: '',
    },
    glucoseData: {
        calibration: '',
        sid: '',
        vital: '',
        calibration_datetime: '',
        user_country_code: '',
        wearer_sid: '',
        timezone: '',
    },
    bloodPressureData: {
        calibration: '',
        sid: '',
        vital: '',
        calibration_datetime: '',
        user_country_code: '',
        wearer_sid: '',
        timezone: '',
    },
}

export const healthSettingsServiceSlice = createSlice({
    name: 'healthSettingsService',
    initialState,
    reducers: {
        setUserHealthCalibration(state, action: PayloadAction<UserHealthCalibrationDto>){
            state.userHealthCalibrationDto = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(setUserHealthCalibrationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(setUserHealthCalibrationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(setUserHealthCalibrationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getUserHealthCalibrationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getUserHealthCalibrationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;

                if (!action.payload) {
                    state.glucoseData = initialState.glucoseData;
                    state.bloodPressureData = initialState.bloodPressureData;
                    return;
                }

                if(action.payload.vital === HealthSettingsVitalType.GLUCOSE){
                    state.glucoseData = action.payload;
                }
                if(action.payload.vital === HealthSettingsVitalType.BLOOD_PRESSURE){
                    state.bloodPressureData = action.payload;
                }
            })
            .addCase(getUserHealthCalibrationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
    }
})

export const { setUserHealthCalibration } = healthSettingsServiceSlice.actions;
export default healthSettingsServiceSlice.reducer;
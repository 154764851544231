import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import {
  CreateContactDto,
  UserContactImageDto,
} from "./models/create-contact-dto";
import {
  addContactsAsync,
  deleteContactsAsync,
  getContactsAsync,
  updateContactsAsync,
} from "./contactServiceThunk";

export interface ContactServiceState extends BaseState {
  contactList: CreateContactDto[];
  selectedContact: CreateContactDto | undefined;
  selectedSosContact: CreateContactDto | undefined;
}

const initialState: ContactServiceState = {
  status: ApiStatus.IDLE,
  contactList: [],
  selectedContact: undefined,
  selectedSosContact: undefined,
};

export const contactServiceSlice = createSlice({
  name: "contactServiceV2",
  initialState,
  reducers: {
    setContactList(state, action: PayloadAction<CreateContactDto[]>) {
      state.contactList = action.payload;
    },
    updateContactAvatar(state, action: PayloadAction<UserContactImageDto>) {
      const contactIndex = state.contactList.findIndex(
        (contact) => contact.sid === action.payload.contact_sid
      );
      if (contactIndex !== -1) {
        state.contactList[contactIndex].avatar = action.payload.avatar;
      }
    },
    setSelectedContact(
      state,
      action: PayloadAction<CreateContactDto | undefined>
    ) {
      state.selectedContact = action.payload;
    },
    setSelectedSosContact(
      state,
      action: PayloadAction<CreateContactDto | undefined>
    ) {
      state.selectedSosContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addContactsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(addContactsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(addContactsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })
      .addCase(updateContactsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(updateContactsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(updateContactsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })
      .addCase(getContactsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getContactsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.contactList = action.payload;
      })
      .addCase(getContactsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })
      .addCase(deleteContactsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(deleteContactsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(deleteContactsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });
  },
});

export const {
  setContactList,
  updateContactAvatar,
  setSelectedContact,
  setSelectedSosContact,
} = contactServiceSlice.actions;
export default contactServiceSlice.reducer;

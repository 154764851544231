/*
export const config = {
    authBaseUrl: "http://localhost:8081",
    userProfileBaseUrl: "http://localhost:8090",
    staticDataBaseUrl: "http://localhost:8084",
    organisationBaseUrl: "http://localhost:8089",
    healthBaseUrl: "http://localhost:8082"
};
*/

//Development Service URIs
export const config = {
    BaseUrl: process.env.REACT_APP_API_URL || '',
    ApiKey: process.env.REACT_APP_API_KEY || '',
    Region: process.env.REACT_APP_REGION || '',
    

};
// userProfileBaseUrl: "https://dev.app.mymonx.co",
    // staticDataBaseUrl: "https://dev.app.mymonx.co",
    // organisationBaseUrl: "https://dev.app.mymonx.co",
    // healthBaseUrl: "https://dev.app.mymonx.co",
    // deviceBaseUrl: "https://dev.app.mymonx.co",
    // contactBaseUrl: "https://dev.app.mymonx.co",
    // sosBaseUrl: "https://dev.app.mymonx.co",
    // configurationBaseUrl: "https://dev.app.mymonx.co",
    // messageBaseUrl: "https://app.mymonx.co",
    // medicalBaseUrl: "https://dev.app.mymonx.co",
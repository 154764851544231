import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "@app/redux/common/models/api-error";
import { CreateContactDto } from "./models/create-contact-dto";
import ContactServiceApi from "@app/services/contact-service";
import { addErrorNotification, addSuccessNotification } from "../common/notificationSlice";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";

export const addContactsAsync = createAsyncThunk<string[], CreateContactDto[],
    { rejectValue: ApiError | undefined }
>(
    "contactService/addContacts",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ContactServiceApi.AddContacts(credentials);
            dispatch(addSuccessNotification('Contact created successfully'));
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const updateContactsAsync = createAsyncThunk<string[], CreateContactDto[],
    { rejectValue: ApiError | undefined }
>(
    "contactService/updateContacts",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ContactServiceApi.updateContacts(credentials);
            dispatch(addSuccessNotification('Contact updated successfully'));
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getContactsAsync = createAsyncThunk<CreateContactDto[], string,
    { rejectValue: ApiError | undefined }
>(
    "contactService/getContacts",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ContactServiceApi.getContacts(credentials);
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const deleteContactsAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "contactService/deleteContacts",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ContactServiceApi.deleteContacts(credentials);
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import ActionModal from "@app/components/Modal/ActionModal";
import alertIcon from "@assets/icons/alert_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { Controller, useForm } from "react-hook-form";
import { FormControl, MenuItem, Select, InputLabel, FormHelperText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { countryDataDto } from "@app/redux/features/staticData-service/models/country-data-dto";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";

const DEFAULT_DATA = [
  { name: "", device_type: "", serial_no: "", status: "", imei: ""},
]

const DeviceModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const dispatch = useAppDispatch();
  const [devices, setDevices] = useState(DEFAULT_DATA);
  const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);

  const onAddAnother = () => {
    setDevices([
      ...devices,
      DEFAULT_DATA[0],
    ]);
  };

  const onDelete = (index: number) => {
    setDevices(devices.filter((_, i) => i !== index));
  };

  const onCloseModal = () => {
    onHide();
    setDevices(DEFAULT_DATA);
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register,
    setValue,
    setError
  } = useForm<{ name: string, device_type: string, serial_no: string, status: string, imei: string }>({
    defaultValues: {
      name: "",
      device_type: "",
      serial_no: "",
      status: "",
      imei: ""
    },
    mode: "onChange", 
    criteriaMode: "all",
  });

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 10 }))
  }, [dispatch])

  return (
    <ActionModal
      subTitle="Customize the contents of your devices"
      show={show}
      onHide={onCloseModal}
      title="User"
      size="lg"
      icon={alertIcon}
    >
      {type === "add" ? (
        <div className="row">
          {devices.map((device, index) => (
            <div
              key={index}
              className={`col-12 row mb-3 ${index !== 0 ? "border-grey-901 border-top" : ""
                }`}
            >
              <div className="d-flex justify-content-end align-items-end">
                <div
                  className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center ${index === 0 ? "d-none" : ""
                    }`}
                  style={{ width: "fit-content" }}
                  onClick={() => onDelete(index)}
                >
                  <img src={deleteIcon} alt="Delete" />
                  Delete
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">Name</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="device-input-color border-0 font-12px py-2"
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={device.name}
                      onChange={(e) => {
                        const updatedDevice = [...devices];
                        updatedDevice[index].name = e.target.value;
                        setDevices(updatedDevice);
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col">
                  <Form.Group className="dropdown-selector text-capitalize">
                    <span className="font-10px text-dark">Status</span>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.status)}
                      sx={{ marginBottom: 2 }}
                    >
                      <Controller
                        name="status"
                        control={control}
                        rules={{ required: "Status is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="status-select-label"
                            id="status-select"
                            label="Status"
                            displayEmpty
                          >
                            <MenuItem value="">
                              Select Status
                            </MenuItem>
                            <MenuItem value="unassign">
                              Unassign
                            </MenuItem>
                            <MenuItem value="assign">
                              Assign
                            </MenuItem>
                          </Select>
                        )}
                      />
                      {errors.status && (
                        <FormHelperText>{errors.status.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <Form.Group className="dropdown-selector text-capitalize">
                    <span className="font-10px text-dark">Device Type</span>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.device_type)}
                      sx={{ marginBottom: 2 }}
                    >
                      <Controller
                        name="device_type"
                        control={control}
                        rules={{ required: "device_type is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="device-type-select-label"
                            id="device-type-select"
                            label="device_type"
                            displayEmpty
                          >
                            <MenuItem value="">
                              Select device_type
                            </MenuItem>
                            {contry_data.map((country: countryDataDto) => (
                              <MenuItem key={country.sid} value={country.sid}>
                                {country.iso_name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.device_type && (
                        <FormHelperText>{errors.device_type.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Form.Group>
                </div>
                <div className="col">
                  <span className="font-10px text-dark">Serial No.</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="device-input-color border-0 font-12px py-2"
                      type="text"
                      name="serial_no"
                      placeholder="Serial No."
                      value={device.serial_no}
                      onChange={(e) => {
                        const updatedDevice = [...devices];
                        updatedDevice[index].serial_no = e.target.value;
                        setDevices(updatedDevice);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">IMEI</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="device-input-color border-0 font-12px py-2"
                      type="text"
                      name="imei"
                      placeholder="IMEI"
                      value={device.imei}
                      onChange={(e) => {
                        const updatedDevice = [...devices];
                        updatedDevice[index].imei = e.target.value;
                        setDevices(updatedDevice);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          ))}
          <AddAnotherButton onAddAnother={onAddAnother} />
        </div>
      ) : (
        <div className="row">
          <div className="col-12 row mb-3 ">
            <div className="d-flex flex-row gap-3">
              <div className="col">
                <span className="font-10px text-dark">Name</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="device-input-color border-0 font-12px py-2"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={dataSelected.name}
                  />
                </InputGroup>
              </div>
              <div className="col">
                <Form.Group className="dropdown-selector text-capitalize">
                  <span className="font-10px text-dark">Status</span>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.status)}
                    sx={{ marginBottom: 2 }}
                  >
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: "Status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={dataSelected.status}
                          labelId="status-select-label"
                          id="status-select"
                          label="Status"
                          displayEmpty
                        >
                          <MenuItem value="">
                            Select Status
                          </MenuItem>
                          <MenuItem value="unassign">
                            Unassign
                          </MenuItem>
                          <MenuItem value="assign">
                            Assign
                          </MenuItem>
                        </Select>
                      )}
                    />
                    {errors.status && (
                      <FormHelperText>{errors.status.message}</FormHelperText>
                    )}
                  </FormControl>
                </Form.Group>
              </div>
            </div>
            <div className="d-flex flex-row gap-3">
              <div className="col">
                <Form.Group className="dropdown-selector text-capitalize">
                  <span className="font-10px text-dark">Device Type</span>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.device_type)}
                    sx={{ marginBottom: 2 }}
                  >
                    <Controller
                      name="device_type"
                      control={control}
                      rules={{ required: "device_type is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={dataSelected.device_type}
                          labelId="device-type-select-label"
                          id="device-type-select"
                          label="device_type"
                          displayEmpty
                        >
                          <MenuItem value="">
                            Select device_type
                          </MenuItem>
                          {contry_data.map((country: countryDataDto) => (
                            <MenuItem key={country.sid} value={country.sid}>
                              {country.iso_name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.device_type && (
                      <FormHelperText>{errors.device_type.message}</FormHelperText>
                    )}
                  </FormControl>
                </Form.Group>
              </div>
              <div className="col">
                <span className="font-10px text-dark">Serial No.</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="device-input-color border-0 font-12px py-2"
                    type="text"
                    name="serial_no"
                    placeholder="Serial No."
                    value={dataSelected.serial_no}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex flex-row gap-3">
              <div className="col">
                <span className="font-10px text-dark">IMEI</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="device-input-color border-0 font-12px py-2"
                    type="text"
                    name="imei"
                    placeholder="IMEI"
                    value={dataSelected.imei}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      )}
    </ActionModal>
  );
};

export default DeviceModal;

import noteIcon from "@assets/icons/pencil_icon.svg";
import { Form, InputGroup } from "react-bootstrap";

interface BasicReportCardProps {
  title?: string;
  icon?: string;
  value?: string;
  unit?: string;
}

const NoteCard: React.FC<BasicReportCardProps> = (
  props: BasicReportCardProps
) => {
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100">
      {/* header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
        <img width={18} height={18} src={noteIcon} alt="icon" />
        <span
          className="font-14px fw-bold d-flex flex-grow-1 ms-2"
          style={{ lineHeight: "17.09px" }}
        >
          Note
        </span>
        {/* Add button */}
        <div className="d-flex justify-content-start cursor-pointer">
          <div className="d-flex align-items-center">
            <p
              className="ms-1 my-0 font-12px progress-bar text-dark fw-normal"
              style={{ lineHeight: "14.65px" }}
            >
              Cancel
            </p>
            <p
              className="ms-2 my-0 font-12px progress-bar text-blue-902 fw-normal"
              style={{ lineHeight: "14.65px" }}
            >
              Save
            </p>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="mt-3 mb-3">
        <div className="border-grey-901 ">
          <div
            className="d-flex flex-column justify-content-between align-items-s w-100"
            style={{ gap: "16px" }}
          >
            <div className="d-flex flex-column">
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 font-12px"
                  as="textarea"
                  placeholder="Enter note"
                  rows={17}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;

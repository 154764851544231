import editIcon from "@assets/icons/edit_icon.svg";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { useMemo } from "react";
import moment from "moment";
import Table from "@app/components/Table";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";
import { ConditionResultDto } from "@app/redux/features/v2/medical-service/models/condition-result-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setConditionRequest } from "@app/redux/features/v2/medical-service/medicalServiceSlice";
import { getConditionByWearerAsync } from "@app/redux/features/v2/medical-service/medicalServiceThunk";

interface ITableProps {
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  data: PaginatedResult<ConditionResultDto>;
}

const MedicalTable = ({ onEdit, onDelete, data }: ITableProps) => {
  const dispatch = useAppDispatch();
  const columns = useMemo(() => [
    {
      name: "Medical Condition",
      selector: (row: any) => row.condition,
    },
    {
      name: "Date of diagnosis",
      selector: (row: any) => moment(row.diagnosis_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      style: {
        width: "fit-content",
        flex: "0 !important",
        "padding": "12.5px 16px !important"
      },

      cell: (row: any) => (
        <div className="action-cell">
          <img height={16} width={16} src={editIcon} alt="Edit" onClick={() => onEdit(row)} className="cursor-pointer" />
          <img height={16} width={16} src={deleteIcon} alt="Delete" className="ms-2 cursor-pointer" onClick={() => onDelete(row)} />
        </div>
      ),
    },
  ], [onEdit, onDelete]);

  const { conditionRequest } = useAppSelector((state: RootState) => state.medicalServiceV2);

  const handlePageChange = (page: number) => {
    dispatch(setConditionRequest({ ...conditionRequest, page_number: page}));
    dispatch(getConditionByWearerAsync({ ...conditionRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(setConditionRequest({ ...conditionRequest, page_number: 1, page_size: rowsPerPage }));
    dispatch(getConditionByWearerAsync({ ...conditionRequest, page_number: 1, page_size: rowsPerPage }));
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

export default MedicalTable;

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ActionModal from "@app/components/Modal/ActionModal";
import moment from "moment";
import alertIcon from "@assets/icons/alert_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import deleteIcon from "@assets/icons/delete_icon.svg";

const DEFAULT_DATA = [
  { incident: "", time: "", date: "", dateEnded: "", note: "" },
]

const HighRiskModal = ({
  show,
  onHide,
  dataSelected,
  type,
}: {
  show: boolean;
  onHide: () => void;
  dataSelected: any;
  type: "add" | "update";
}) => {
  const [incidents, setIncidents] = useState(DEFAULT_DATA);

  const onAddAnother = () => {
    setIncidents([
      ...incidents,
      DEFAULT_DATA[0],
    ]);
  };

  const onDelete = (index: number) => {
    setIncidents(incidents.filter((_, i) => i !== index));
  };

  const onCloseModal = () => {
    onHide();
    setIncidents(DEFAULT_DATA);
  }

  return (
    <ActionModal
      subTitle="Customize the contents of your incidents"
      show={show}
      onHide={onCloseModal}
      title="Alert & Health Incident"
      size="lg"
      icon={alertIcon}
    >
      {type === "add" ? (
        <div className="row">
          {incidents.map((incident, index) => (
            <div
              key={index}
              className={`col-12 row mb-3 ${index !== 0 ? "border-grey-901 border-top" : ""
                }`}
            >
              <div className="d-flex justify-content-end align-items-end">
                <div
                  className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center ${index === 0 ? "d-none" : ""
                    }`}
                  style={{ width: "fit-content" }}
                  onClick={() => onDelete(index)}
                >
                  <img src={deleteIcon} alt="Delete" />
                  Delete
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">Incident</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="text"
                      placeholder="Incident"
                      value={incident.incident}
                      onChange={(e) => {
                        const updatedIncidents = [...incidents];
                        updatedIncidents[index].incident = e.target.value;
                        setIncidents(updatedIncidents);
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="col">
                  <span className="font-10px text-dark">Time</span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <TextField
                      className="border-0 font-12px w-100 h-100"
                      type="time"
                      value={incident.time}
                      onChange={(e) => {
                        const updatedIncidents = [...incidents];
                        updatedIncidents[index].time = e.target.value;
                        setIncidents(updatedIncidents);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <div className="col">
                  <span className="font-10px text-dark">
                    Date of prescription
                  </span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="date"
                      value={
                        moment(incident.date, "DD/MM/YYYY").format(
                          "YYYY-MM-DD"
                        ) || ""
                      }
                      onChange={(e) => {
                        const updatedIncidents = [...incidents];
                        updatedIncidents[index].date = moment(
                          e.target.value
                        ).format("DD/MM/YYYY");
                        setIncidents(updatedIncidents);
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col">
                  <span className="font-10px text-dark">
                    Date of prescription end
                  </span>
                  <InputGroup className="border rounded-3 border-0 bg-grey-904">
                    <Form.Control
                      className="border-0 font-12px py-2"
                      type="date"
                      value={
                        moment(incident.dateEnded, "DD/MM/YYYY").format(
                          "YYYY-MM-DD"
                        ) || ""
                      }
                      onChange={(e) => {
                        const updatedIncidents = [...incidents];
                        updatedIncidents[index].dateEnded = moment(
                          e.target.value
                        ).format("DD/MM/YYYY");
                        setIncidents(updatedIncidents);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="col">
                <span className="font-10px text-dark">Note</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px"
                    as="textarea"
                    placeholder="Enter note"
                    rows={4}
                    value={incident.note}
                    onChange={(e) => {
                      const updatedIncidents = [...incidents];
                      updatedIncidents[index].note = e.target.value;
                      setIncidents(updatedIncidents);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          ))}
          <AddAnotherButton onAddAnother={onAddAnother} />
        </div>
      ) : (
        <div className="row">
          <div className="col-12 row mb-3 ">
            <div className="d-flex flex-row gap-3">
              <div className="col">
                <span className="font-10px text-dark">Incident</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px py-2"
                    type="text"
                    placeholder="Incident"
                    value={dataSelected.incident}
                  />
                </InputGroup>
              </div>

              <div className="col">
                <span className="font-10px text-dark">Time</span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <TextField
                    className="border-0 font-12px w-100 h-100"
                    type="time"
                    value={dataSelected.time}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="d-flex flex-row gap-3">
              <div className="col">
                <span className="font-10px text-dark">
                  Date of prescription
                </span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px py-2"
                    type="date"
                    value={
                      moment(dataSelected.date, "DD/MM/YYYY").format(
                        "YYYY-MM-DD"
                      ) || ""
                    }
                  />
                </InputGroup>
              </div>
              <div className="col">
                <span className="font-10px text-dark">
                  Date of prescription end
                </span>
                <InputGroup className="border rounded-3 border-0 bg-grey-904">
                  <Form.Control
                    className="border-0 font-12px py-2"
                    type="date"
                    value={
                      moment(dataSelected.dateEnded, "DD/MM/YYYY").format(
                        "YYYY-MM-DD"
                      ) || ""
                    }
                  />
                </InputGroup>
              </div>
            </div>

            <div className="col">
              <span className="font-10px text-dark">Note</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 font-12px"
                  as="textarea"
                  placeholder="Enter note"
                  rows={4}
                  value={dataSelected.note}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      )}
    </ActionModal>
  );
};

export default HighRiskModal;

import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "@app/components/Nav/Navbar";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useEffect, useState } from "react";
import { getProfileByEmailAsync, getUserProfileImageAsync } from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";

const navLinks = [
  {
    label: "Monthly Reports",
    path: "/consumer/monthly-reports",
  },
  {
    label: "Health Insights",
    path: "/consumer/health-insights",
  },
  {
    label: "Health Summary",
    path: "/consumer/health-summary",
  },
  {
    label: "Medical",
    path: "/consumer/medical",
  },
  {
    label: "Alerts & Health Incidents",
    path: "/consumer/alerts-health-incidents",
  },
];
const ConsumerLayout = () => {
   
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  
  const [, setIsProfileCompleted] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (profile && profile.sid) {
      dispatch(getUserProfileImageAsync(profile.sid ?? ""));
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(getProfileByEmailAsync(currentUser?.email ?? ""))
        .then((action) => {
          if(action.meta.requestStatus === "fulfilled"){
            setIsProfileCompleted(true);
          } else {
            setIsProfileCompleted(false);
            navigate('/consumer/complete-individual-profile');
          }
        })
        .catch((error) => {});
    }
  }, [dispatch, currentUser]);
  return (
    <>
      <Navbar navLinks={navLinks} />
      <Outlet />
    </>
  );
};
export default ConsumerLayout;

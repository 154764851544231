import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateMessageDto } from "./models/create-message-dto";
import MessageServiceApi from "@app/services/message-service";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";



export const createTextDeviceResultAsync = createAsyncThunk<string, CreateMessageDto,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createTextDevice",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createTextDevice(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createCurrentPositionResultAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createCurrentPosition",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createCurrentPosition(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createFindWatchResultAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createFindWatch",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createFindWatch(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createRestartResultAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createRestart",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createRestart(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createFactoryRestoreResultAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createFactoryRestore",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createFactoryRestore(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const createShutdownResultAsync = createAsyncThunk<string, string,
    { rejectValue: ApiError | undefined }
>(
    "messageService/createShutdown",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MessageServiceApi.createShutdown(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
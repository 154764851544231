interface SignalIndicatorProps {
  strength: number;
}

const SignalIndicator = ({ strength }: SignalIndicatorProps) => {
  const signalBars = [1, 2, 3, 4, 5];
  const barColor = strength === 5 ? "bg-normal" : strength >= 3 ? "bg-high" : "bg-very-high";
  const signalStrengthLabel = strength >= 4 ? "Strong" : strength >= 2 ? "Medium" : "Weak";

  return (
    <div className="d-flex align-items-center gap-1">
      <div className="font-12px fw-normal text-dark">{signalStrengthLabel}</div>
      <div className="d-flex align-items-end" style={{ gap: "2.5px" }}>
        {signalBars.map((bar) => (
          <div
            key={bar}
            className={`rounded-4 ${strength >= bar ? barColor : "bg-grey-250"}`}
            style={{ width: "4px", height: `${bar * 4}px` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default SignalIndicator;

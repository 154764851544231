import { createAsyncThunk } from "@reduxjs/toolkit";
import MedicalServiceApi from "@app/services/v2/medical-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import { ApiError } from "@app/redux/common/models/api-error";
import { MedicationResultDto, CreateUpdateMedicationDto } from "./models/medication-result-dto";
import { ConditionResultDto, CreateUpdateConditionDto } from "./models/condition-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { MedicationRequestDto } from "./models/medication-request-dto";
import { ConditionRequestDto } from "./models/condition-request-dto";
import { addSuccessNotification } from "@app/redux/features/common/notificationSlice";
import { MedicalAlertDto, CreateUpdateAlertDto } from "./models/medical-alert-result-dto";
import { MedicalAlertRequestDto } from "./models/medical-alert-request-dto";

export const getMedicationAsync = createAsyncThunk<MedicationResultDto, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedicationV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getMedicationByWearerAsync = createAsyncThunk<PaginatedResult<MedicationResultDto>, MedicationRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedicationByWearerV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getMedicationByWearer(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getMedicationListAsync = createAsyncThunk<string[], void,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedicationListV2",
    async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getMedicationList();
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const postMedicationAsync = createAsyncThunk<string, CreateUpdateMedicationDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/postMedicationV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.postMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const putMedicationAsync = createAsyncThunk<string, CreateUpdateMedicationDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/putMedicationV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.putMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const deleteMedicationAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/deleteMedicationV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.deleteMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getConditionAsync = createAsyncThunk<ConditionResultDto, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getConditionV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getConditionByWearerAsync = createAsyncThunk<PaginatedResult<ConditionResultDto>, ConditionRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getConditionByWearerV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getConditionByWearer(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const postConditionAsync = createAsyncThunk<string, CreateUpdateConditionDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/postConditionV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.postCondition(credentials);
            dispatch(addSuccessNotification('Medical history created successfully'));

            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const putConditionAsync = createAsyncThunk<string, CreateUpdateConditionDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/putConditionV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.putCondition(credentials);
            dispatch(addSuccessNotification('Medical history updated successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const deleteConditionAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/deleteConditionV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.deleteCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getMedicalAlertAsync = createAsyncThunk<MedicalAlertDto, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedicalAlertV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getAlert(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getMedicalAlertByWearerAsync = createAsyncThunk<PaginatedResult<MedicalAlertDto>, MedicalAlertRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedicalAlertByWearerV2",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getAlertByWearer(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)


export const postMedicalAlertAsync = createAsyncThunk<string, CreateUpdateAlertDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/postMedicalAlert",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.postAlert(credentials);
            dispatch(addSuccessNotification('Medical alert created successfully'));

            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const putMedicalAlertAsync = createAsyncThunk<string, CreateUpdateAlertDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/putMedicalAlert",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.putAlert(credentials);
            dispatch(addSuccessNotification('Medical alert updated successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const deleteMedicalAlertAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/deleteMedicalAlert",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.deleteAlert(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

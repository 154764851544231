import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { ClientDto, ClientsRequest } from "./models/client-dto";
import {
  deleteClientAsync,
  getClientAsync,
  getClientStatsAsync,
  getClientsAsync,
  postClientAsync,
  updateClientAsync,
} from "./clientServiceThunk";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { PageAndFilterRequestDto } from "@app/redux/common/models/page-and-filter-request-dto";
import { appConstants } from "@app/redux/common/constants/app-constant";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";

export interface ClientServiceState extends BaseState {
  clientRequest: ClientsRequest;
  clients?: PaginatedResult<ClientDto>;
  client?: ClientDto;
  clientStats?: number;
  clientsResultPaginate?: PaginatedResult<ClientDto>;
}

const initialClientRequest = {
  page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  page_size: appConstants.GRID_DEFAULT_PAGE_SIZE,
  page: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  per_page: appConstants.GRID_DEFAULT_PAGE_SIZE,
  filter_by: undefined,
  users: {} as ClientDto,
  org_id: getOrgFromJwt(),
};

const initialState: ClientServiceState = {
  status: ApiStatus.IDLE,
  clientRequest: initialClientRequest,
};

export const clientServiceSlice = createSlice({
  name: "clientServiceV2",
  initialState,
  reducers: {
    setClientRequest(state, action: PayloadAction<ClientsRequest>) {
      state.clientRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.clientsResultPaginate = action.payload;
      })
      .addCase(getClientsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });

    builder
      .addCase(getClientStatsAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientStatsAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.clientStats = action.payload.clients as number;
      })
      .addCase(getClientStatsAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });

    builder
      .addCase(getClientAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getClientAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.client = action.payload;
      })
      .addCase(getClientAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });

    builder
      .addCase(postClientAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(postClientAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(postClientAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });

    builder
      .addCase(updateClientAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(updateClientAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(updateClientAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });

    builder
      .addCase(deleteClientAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(deleteClientAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
      })
      .addCase(deleteClientAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      });
  },
});

export const { setClientRequest } = clientServiceSlice.actions;
export default clientServiceSlice.reducer;

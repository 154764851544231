import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";
import { BaseState } from "@app/redux/common/models/base-state";
import { MedicationResultDto } from "./models/medication-result-dto";
import {
    deleteConditionAsync,
    deleteMedicationAsync,
    getMedicalAlertAsync,
    getConditionAsync,
    getConditionByWearerAsync,
    getMedicationAsync,
    getMedicationByWearerAsync,
    postMedicalAlertAsync,
    postConditionAsync,
    putMedicalAlertAsync,
    postMedicationAsync,
    putConditionAsync,
    putMedicationAsync,
    getMedicalAlertByWearerAsync,
    getMedicationListAsync,
} from "./medicalServiceThunk";
import { ConditionResultDto } from "./models/condition-result-dto";
import { MedicalAlertDto } from "./models/medical-alert-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { appConstants } from "@app/redux/common/constants/app-constant";
import { MedicationRequestDto } from "./models/medication-request-dto";
import { ConditionRequestDto } from "./models/condition-request-dto";

export interface MedicalServiceState extends BaseState {
    medicationResultDto: MedicationResultDto;
    conditionResultDto: ConditionResultDto;
    medicalAlertResultDto: MedicalAlertDto;

    medicationRequest: MedicationRequestDto;
    conditionRequest: ConditionRequestDto;
    medicalAlertRequest: ConditionRequestDto;

    medicationResultPaginate?: PaginatedResult<MedicationResultDto>;
    conditionResultPaginate?: PaginatedResult<ConditionResultDto>;
    medicalAlertResultPaginate?: PaginatedResult<MedicalAlertDto>;

    initialClientRequest: { org_id: string };
    isShowMedicationForm: boolean;
    selectedMedication?: MedicationResultDto;

    selectedCondition?: ConditionResultDto;
    isShowConditionForm: boolean;

    medicationList: string[];
}

const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}

const initialPaginateRequest = {
    page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER,
    page_size: appConstants.GRID_DEFAULT_PAGE_SIZE,
    page: appConstants.GRID_DEFAULT_PAGE_NUMBER,
    per_page: appConstants.GRID_DEFAULT_PAGE_SIZE,
    filter_by: undefined,
    wearer_sid: ""
}

const initialState: MedicalServiceState = {
    status: ApiStatus.IDLE,
    initialClientRequest: initialClientRequest,
    apiError: undefined, // Assuming BaseState includes apiError
    medicationRequest: initialPaginateRequest,
    conditionRequest: initialPaginateRequest,
    medicalAlertRequest: initialPaginateRequest,
    medicationResultDto: {} as MedicationResultDto, // Initialize with an empty object casted to MedicationResultDto
    conditionResultDto: {} as ConditionResultDto,
    medicalAlertResultDto: {} as MedicalAlertDto,
    isShowMedicationForm: false,
    isShowConditionForm: false,
    medicationList: [],
}

export const medicalServiceSlice = createSlice({
    name: 'medicalServiceV2',
    initialState,
    reducers: {
        setShowMedicationForm(state, action: PayloadAction<boolean>) {
            state.isShowMedicationForm = action.payload;
        },
        setSelectedMedication(state, action: PayloadAction<MedicationResultDto | undefined>) {
            state.selectedMedication = action.payload;
        },
        setShowConditionForm(state, action: PayloadAction<boolean>) {
            state.isShowConditionForm = action.payload;
        },
        setSelectedCondition(state, action: PayloadAction<ConditionResultDto | undefined>) {
            state.selectedCondition = action.payload;
        },
        setMedicationRequest(state, action: PayloadAction<MedicationRequestDto>){
            state.medicationRequest = action.payload;
        },
        setConditionRequest(state, action: PayloadAction<ConditionRequestDto>){
            state.conditionRequest = action.payload;
        },
        setMedicalAlertRequest(state, action: PayloadAction<ConditionRequestDto>){
            state.medicalAlertRequest = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicationResultDto = action.payload;
            })
            .addCase(getMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getMedicationByWearerAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMedicationByWearerAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicationResultPaginate = action.payload;
            })
            .addCase(getMedicationByWearerAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            .addCase(getMedicationListAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMedicationListAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicationList = action.payload;
            })
            .addCase(getMedicationListAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(postMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(postMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(postMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(putMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(putMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(putMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(deleteMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(deleteMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(deleteMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(getConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.conditionResultDto = action.payload;
            })
            .addCase(getConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getConditionByWearerAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getConditionByWearerAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.conditionResultPaginate = action.payload;
            })
            .addCase(getConditionByWearerAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(postConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(postConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(postConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(putConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(putConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(putConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(getMedicalAlertAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getMedicalAlertAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicalAlertResultDto = action.payload;
            })
            .addCase(getMedicalAlertAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder
            .addCase(getMedicalAlertByWearerAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMedicalAlertByWearerAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicalAlertResultPaginate = action.payload;
            })
            .addCase(getMedicalAlertByWearerAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(postMedicalAlertAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(postMedicalAlertAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(postMedicalAlertAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(putMedicalAlertAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(putMedicalAlertAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(putMedicalAlertAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(deleteConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(deleteConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
    }
});

// Export actions
export const {
    setShowMedicationForm,
    setSelectedMedication,
    setShowConditionForm,
    setSelectedCondition,
    setMedicationRequest,
    setConditionRequest,
    setMedicalAlertRequest,
} = medicalServiceSlice.actions;

// Export the reducer
export default medicalServiceSlice.reducer;

import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "react-bootstrap";
import VitalSettings from "./VitalSettings";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setUserHealthCalibrationAsync, getUserHealthCalibrationAsync } from "@app/redux/features/v2/health-settings-services/healthSettingsServiceThunk";
import { getCurrentDateTime } from "@app/redux/common/helpers/date-helper";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import { countryDataDto } from "@app/redux/features/staticData-service/models/country-data-dto";
import { addSuccessNotification } from "@app/redux/features/common/notificationSlice";
import { HealthSettingsVitalType } from "@app/common/enums/HealthSettingsVitalType";

interface HealthSettingsForm {
  glucoseCalibration: string;
  bloodPressure: { SBP: string; DBP: string };
}

const parseBloodPressureCalibration = (calibration: string) => {
  try {
    const parsed = JSON.parse(calibration.replace(/'/g, '"'));
    return {
      SBP: parsed.SBP?.toString() ?? "0",
      DBP: parsed.DBP?.toString() ?? "0",
    };
  } catch {
    return { SBP: "0", DBP: "0" };
  }
};

const HealthSettings = () => {
  const dispatch = useAppDispatch();
  const { user_profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);
  const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);

  const countryCode = contry_data.find((country: countryDataDto)  => country.iso_name === user_profile?.nationality)?.iso_a2_code ?? '';

  const { glucoseData, bloodPressureData } = useAppSelector(
    (state: RootState) => state.healthSettingsService
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<HealthSettingsForm>({
    defaultValues: {
      glucoseCalibration: "0",
      bloodPressure: { SBP: "0", DBP: "0" },
    },
  });

  useEffect(() => {
    if (user_profile?.sid) {
      dispatch(
        getUserHealthCalibrationAsync({
          vital: HealthSettingsVitalType.BLOOD_PRESSURE,
          wearer_sid: user_profile.sid ?? "",
        })
      );

      dispatch(
        getUserHealthCalibrationAsync({
          vital: HealthSettingsVitalType.GLUCOSE,
          wearer_sid: user_profile.sid ?? "",
        })
      );
    }
  }, [dispatch, user_profile]);

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 100 }));
  }, [dispatch]);

  useEffect(() => {
    reset({
      glucoseCalibration: glucoseData?.calibration ?? "0",
      bloodPressure: parseBloodPressureCalibration(bloodPressureData?.calibration ?? ""),
    });
  }, [glucoseData, bloodPressureData, reset]);

  const handleSaveSettings = async (data: HealthSettingsForm) => {
    const glucosePayload = {
      sid: glucoseData?.sid ?? "",
      calibration: data.glucoseCalibration,
      vital: HealthSettingsVitalType.GLUCOSE,
      calibration_datetime: getCurrentDateTime(),
      wearer_sid: user_profile?.sid ?? "",
      timezone: user_profile?.time_zone ?? "",
      user_country_code: countryCode,
    };

    const bloodPressurePayload = {
      sid: bloodPressureData?.sid ?? "",
      calibration: JSON.stringify({
        SBP: data.bloodPressure.SBP,
        DBP: data.bloodPressure.DBP,
      }),
      vital: HealthSettingsVitalType.BLOOD_PRESSURE,
      calibration_datetime: getCurrentDateTime(),
      wearer_sid: user_profile?.sid ?? "",
      timezone: user_profile?.time_zone ?? "",
      user_country_code: countryCode,
    };

    const glucoseAction = dispatch(setUserHealthCalibrationAsync(glucosePayload));
    const bloodPressureAction = dispatch(setUserHealthCalibrationAsync(bloodPressurePayload));

    const results = await Promise.all([glucoseAction, bloodPressureAction]);

    const allFulfilled = results.every((result) => result.meta.requestStatus === 'fulfilled');

    if (allFulfilled) {
      dispatch(addSuccessNotification('Set Health Settings Successfully!'));
    }
  };

  return (
    <div>
      <main className="health-settings-container container min-vh-100">
        <form
          onSubmit={handleSubmit((data) => {
            handleSaveSettings(data);
          })}
        >
          <div className="d-flex justify-content-between align-items-end my-4">
            <p className="font-20px mb-0 fw-medium">Health Settings</p>
            <Button
              type="submit"
              className="rounded-3 bg-low border-0 px-3 d-flex align-items-center no-hover"
            >
              <span className="font-12px fw-medium progress-bar">Save</span>
            </Button>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-start align-items-center gap-3">
            <div className="col-12 col-md-6 col-lg-3">
              <Controller
                name="glucoseCalibration"
                control={control}
                rules={{
                  required: "Glucose calibration is required",
                  validate: (value) =>
                    !isNaN(parseFloat(value)) || "Must be a valid number",
                }}
                render={({ field }) => (
                  <div>
                    <VitalSettings
                      type={HealthSettingsVitalType.GLUCOSE}
                      value={[parseFloat(field.value || "0")]}  // Ensure it's parsed correctly
                      onChange={(values) => field.onChange(values[0].toString())}
                    />
                    {errors.glucoseCalibration && (
                      <p className="text-danger">
                        {errors.glucoseCalibration.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <Controller
                name="bloodPressure"
                control={control}
                rules={{
                  required: "Blood pressure values are required",
                  validate: (value) => {
                    const SBPValid = !isNaN(parseFloat(value.SBP));
                    const DBPValid = !isNaN(parseFloat(value.DBP));
                    if (!SBPValid || !DBPValid) {
                      return "SBP and DBP must be valid numbers";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <div>
                    <VitalSettings
                      type={HealthSettingsVitalType.BLOOD_PRESSURE}
                      value={[
                        parseFloat(field.value.SBP ?? "0"),
                        parseFloat(field.value.DBP ?? "0"),
                      ]}
                      onChange={(values: any) =>
                        field.onChange({
                          SBP: values[0].toString(),
                          DBP: values[1].toString(),
                        })
                      }
                    />
                    {errors.bloodPressure && (
                      <p className="text-danger">
                        {errors.bloodPressure.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default HealthSettings;

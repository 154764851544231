import { CreateUpdateAlertWithDateTimeDto } from "@app/redux/features/v2/medical-service/models/medical-alert-result-dto";
import { Form, InputGroup } from "react-bootstrap";
import deleteIcon from "@assets/icons/delete_icon.svg";
import moment from "moment";

const AlertBlock = ({
  isError,
  alert,
  index,
  update,
  remove,
}: {
  isError: boolean;
  alert: CreateUpdateAlertWithDateTimeDto;
  index: number;
  update: (index: number, updatedContact: CreateUpdateAlertWithDateTimeDto) => void;
  remove: (index: number) => void;
}) => {
  return (
    <div key={index} className={`col-12 row mb-3 gap-3 ${index !== 0 ? "border-grey-901 border-top" : ""}`}>
      {index !== 0 ? (
        <div className="d-flex justify-content-end align-items-end">
          <div
            className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
            style={{ width: "fit-content" }}
            onClick={() => remove(index)}
          >
            <img src={deleteIcon} alt="Delete" />
            Delete
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col">
        <span className="font-10px text-dark">Alert</span>
        <InputGroup className="border rounded-3 border-0 bg-grey-904">
          <Form.Control
            className="border-0 font-12px py-2"
            type="text"
            placeholder="Enter Type"
            value={alert?.alert_type ?? ""}
            onChange={(e) => {
              update(index, {
                ...alert,
                alert_type: e.target.value,
              });
            }}
          />
        </InputGroup>

        {isError && alert?.alert_type === "" ? (
          <Form.Control.Feedback type="invalid">
            Please enter alert type
          </Form.Control.Feedback>
        ) : null}
      </div>
      <div className="d-flex flex-row gap-3">
        <div className="col">
          <span className="font-10px text-dark">Date of prescription</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 font-12px py-2"
              type="date"
              value={moment(alert?.date).format("YYYY-MM-DD") ?? ""}
              onChange={(e) => {
                update(index, {
                  ...alert,
                  date: e.target.value,
                });
              }}
            />
          </InputGroup>

          {isError && alert?.date === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter date
            </Form.Control.Feedback>
          ) : null}
        </div>

        <div className="col">
          <span className="font-10px text-dark">Time</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 font-12px w-100 h-100"
              type="time"
              value={alert?.time ?? ""}
              onChange={(e) => {
                update(index, {
                  ...alert,
                  time: e.target.value,
                });
              }}
            />
          </InputGroup>

          {isError && alert?.time === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter time
            </Form.Control.Feedback>
          ) : null}
        </div>
      </div>

      <div className="col">
        <span className="font-10px text-dark">Alert Description</span>
        <InputGroup className="border rounded-3 border-0 bg-grey-904">
          <Form.Control
            className="border-0 font-12px"
            as="textarea"
            placeholder="Enter Description"
            rows={4}
            value={alert?.alert_description ?? ""}
            onChange={(e) => {
              update(index, {
                ...alert,
                alert_description: e.target.value,
              });
            }}
          />
        </InputGroup>

        {isError && alert?.alert_description === "" ? (
          <Form.Control.Feedback type="invalid">
            Please enter alert description
          </Form.Control.Feedback>
        ) : null}
      </div>
    </div>
  );
};

export default AlertBlock;

import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useEffect, useState } from "react";
import HistoryRecordCard from "@components/HistoryRecordCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import { BloodPressureStatus } from "@app/common/enums/AnalysisType";
import DoubleUnitSummaryCard from "@app/components/DoubleUnitSummaryCard";
import DoubleUnitAnalysisCard from "@app/components/DoubleUnitAnalysisCard";
import BreadcrumbNavigation from "@app/components/Breadcrumb/BreadcrumbNavigation";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { useAppSelector } from "@app/redux/store/useStore";
import { useVitalsGraph } from "@app/hooks/useVitalsGraph";
import { RootState } from "@app/redux/store";
import { VitalsGraphDto } from "@app/redux/features/v2/health-service/models/bp-graph-dto";

const BloodPressure = () => {
  const [tableData, setTableData] = useState<
    {
      id: number;
      healthVitals: string;
      date: string;
      time: string;
      value1?: string | number;
      value2?: string | number;
      min1?: string | number;
      min2?: string | number;
      max1?: string | number;
      max2?: string | number;
      low: string | number;
      average: string | number;
      high: string | number;
      status: string;
    }[]
  >([]);
  const [summaryData, setSummaryData] = useState<
    {
      date: string;
      value1?: number | null;
      value2?: number | null;
      min1?: number;
      min2?: number;
      max1?: number;
      max2?: number;
    }[]
  >([]);

  const [selectedPeriod, setSelectedPeriod] = useState(
    TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
  );
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const { user_profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );
  const vitalsGraphDto = useAppSelector(
    (state: RootState) =>
      state.healthServiceV2.vitalsGraphDto[VitalType.BLOOD_PRESSURE]
  );

  const { fetchVitalsGraph, fetchCustomRange } = useVitalsGraph(
    user_profile?.sid || "",
    VitalType.BLOOD_PRESSURE
  );

  useEffect(() => {
    fetchVitalsGraph(selectedPeriod);
  }, [fetchVitalsGraph, selectedPeriod]);

  const convertBloodPressureData = (vitalsGraphDto: VitalsGraphDto) => {
    const { vital_datetime, vital_data } = vitalsGraphDto;

    return vital_datetime.map((datetime, index) => {
      const dateObj = new Date(datetime);
      const date = `${(dateObj.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${dateObj
        .getDate()
        .toString()
        .padStart(2, "0")}/${dateObj.getFullYear()}`;
      const time = dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return {
        id: index + 1,
        healthVitals: "Blood Pressure",
        date,
        time,

        low: vital_data.VITAL_BPS_MIN?.[index] ?? "",
        average: vital_data.VITAL_BPS?.[index] ?? "",
        high: vital_data.VITAL_BPS_MAX?.[index] ?? "",
        value1: vital_data.VITAL_BPS?.[index] ?? "",
        value2: vital_data.VITAL_BPD?.[index] ?? "",
        min1: vital_data.VITAL_BPS_MIN?.[index] ?? "",
        min2: vital_data.VITAL_BPD_MIN?.[index] ?? "",
        max1: vital_data.VITAL_BPS_MIN?.[index] ?? "",
        max2: vital_data.VITAL_BPD_MIN?.[index] ?? "",
        status: vital_data.LABEL?.[index] ?? "",
      };
    });
  };

  useEffect(() => {
    if (vitalsGraphDto) {
      const { vital_datetime, vital_data } = vitalsGraphDto;

      const defaultData = convertBloodPressureData(vitalsGraphDto);
      setTableData(defaultData);

      const mappedData = vital_datetime.map((datetime: Date, index: number) => {
        const dateObj = new Date(datetime);

        const date =
          selectedPeriod === TimePeriodHelper.getTimePeriodJson(TimePeriod.day)
            ? `${dateObj.getHours().toString().padStart(2, "0")}:00`
            : selectedPeriod ===
              TimePeriodHelper.getTimePeriodJson(TimePeriod.week)
            ? dateObj.toLocaleDateString("en-US", { weekday: "short" })
            : selectedPeriod ===
              TimePeriodHelper.getTimePeriodJson(TimePeriod.month)
            ? `${dateObj.getDate()}`
            : dateObj.toLocaleDateString("en-US", { month: "short" });

        return {
          date,
          value1: vital_data.VITAL_BPD?.[index] ?? 0,
          value2: vital_data.VITAL_BPS?.[index] ?? 0,
          min1: vital_data.VITAL_BPD_MIN?.[index] ?? 0,
          min2: vital_data.VITAL_BPS_MIN?.[index] ?? 0,
          max1: vital_data.VITAL_BPD_MAX?.[index] ?? 0,
          max2: vital_data.VITAL_BPS_MAX?.[index] ?? 0,
        };
      });

      const filteredData = filterDataByPeriod(mappedData, selectedPeriod);
      setSummaryData(filteredData);
    }
  }, [vitalsGraphDto, selectedPeriod]);

  const filterDataByPeriod = (data: any, period: any) => {
    if (period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day)) {
      return data.slice(0, 24);
    } else if (period === TimePeriodHelper.getTimePeriodJson(TimePeriod.week)) {
      return data.slice(0, 7);
    } else if (
      period === TimePeriodHelper.getTimePeriodJson(TimePeriod.month)
    ) {
      return data.slice(0, 31);
    } else if (
      period === TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear)
    ) {
      return data.slice(0, 6);
    } else if (period === TimePeriodHelper.getTimePeriodJson(TimePeriod.year)) {
      return data.slice(0, 12);
    }
    return data;
  };

  return (
    <div className="container health_insight row">
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <BreadcrumbNavigation
            analysisName={VitalType.getAnalysisName(VitalType.BLOOD_PRESSURE)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <DoubleUnitSummaryCard
              period={selectedPeriod}
              analysisType={VitalType.BLOOD_PRESSURE}
              date={selectedPeriod}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <DoubleUnitAnalysisCard
              period={selectedPeriod}
              summaryType={VitalType.BLOOD_PRESSURE}
              summaryData={summaryData}
              unit1="sys"
              unit2="dia"
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard
              type={VitalType.BLOOD_PRESSURE}
              period={selectedPeriod}
              data={tableData}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <div className="health-column">
          <div className="title-health-column">Health Column</div>
          {[1, 2, 3, 4, 5].map((index) => (
            <HealthyColumn key={index.toString()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BloodPressure;

import React, { useEffect, useState } from "react";
import analysisIcon from "@assets/icons/analysis-icon.svg";
import { PieDataItem } from "./Sleep.DailyChart";
import { getTimeRange } from "@app/utils/time";
import AnalysisDailyChart from "./Sleep.SummaryDailyChart";
import { TimePeriod } from "@app/common/enums/TimePeriod";
import { TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { formatDuration } from "@app/utils/timeUtils";
export interface SummaryCardProd {
  dailyAnalysisData: {
    stages: {
      name: string;
      value: number;
    }[];
    startTime: string;
    endTime: string;
  };
  period: string;
}

export interface SummaryCardProps extends SummaryCardProd {}

const SummaryCard: React.FC<SummaryCardProps> = ({
  dailyAnalysisData,
  period,
}) => {
  const [mappedSleepData, setMappedSleepData] = useState<PieDataItem[]>([]);

  const colorMapping: Record<string, string> = {
    Light: "#A281E4",
    Deep: "#3F72F3",
    REM: "#00BD91",
    Awake: "#FFAA00",
  };
  
  const statusMapping: Record<string, string> = {
    Light: "very-low",
    Deep: "low",
    REM: "normal",
    Awake: "high",
  };
  
  const mapSleepData = (stages: { name: string; value: number }[]): PieDataItem[] => {
    const categoryTotals: Record<string, number> = {
      Light: 0,
      Deep: 0,
      REM: 0,
      Awake: 0,
    };
  
    stages.forEach(({ name, value }) => {
      if (categoryTotals[name] !== undefined) {
        categoryTotals[name] += value;
      }
    });
  
    return Object.keys(categoryTotals).map((category) => {
      const value = categoryTotals[category];
      const color = colorMapping[category] ?? "#F9FAFB";
      const status = statusMapping[category] ?? "normal";
  
      return {
        name: category,
        value,
        status,
        color,
      };
    });
  };

  useEffect(() => {
    if (dailyAnalysisData) {
      const mappedData = mapSleepData(dailyAnalysisData.stages);
      setMappedSleepData(mappedData);
    }
  }, [dailyAnalysisData]);


  const renderComponent = () => (
    <div className="d-flex flex-column mt-0 h-100">
      {mappedSleepData.map((data, index) => (
        <InfoBlock key={index} data={data} />
      ))}
    </div>
  );

  const renderDailyComponent = () => {
    return (
      <AnalysisDailyChart
        stages={dailyAnalysisData.stages}
        startTime={dailyAnalysisData.startTime}
        endTime={dailyAnalysisData.endTime}
      />
    )
  };

  const InfoBlock = ({ data }: { data: PieDataItem }) => {
    const formattedDuration = formatDuration(data.value);

    return (
      <div className="w-100 mb-2">
        <div
          className="rounded-2 bg-grey-903 px-2 d-flex justify-content-between align-items-center w-100"
          style={{ height: "40px" }}
        >
          <div className={`text-${data.status} font-12px fw-bold`}>
            <div
              className={`bg-${data.status} rounded-circle ms-2 me-2`}
              style={{
                width: "8px",
                height: "8px",
                display: "inline-block",
              }}
            ></div>
            {data.name}
          </div>
          <div className="d-flex flex-row">
            <p className="ms-1 my-0 font-12px fw-bold text-black py-1">
              {formattedDuration}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white p-3 h-100 rounded-3 ">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="">
            <img className="img-fluid" src={analysisIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Summary</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      <div className="col-12 mt-2">
        {period === TimePeriodHelper.getTimePeriodJson(TimePeriod.day) ? renderDailyComponent() : renderComponent()}
      </div>
    </div>
  );
};

export default SummaryCard;

import React from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import { VitalType } from "@app/common/enums/VitalTypes";
import Table from "@app/components/Table";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { EcgResponseDto } from "@app/redux/features/v2/health-service/models/bp-graph-dto";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { setEcgRequest } from "@app/redux/features/v2/health-service/healthServiceSlice";
import { getEcgAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";

interface HistoryTableProps {
  data: PaginatedResult<EcgResponseDto>;
  onRowClick: (row: EcgResponseDto) => void;
}

const HistoryTable: React.FC<HistoryTableProps> = ({ data, onRowClick }) => {
  const dispatch = useAppDispatch();
  const columns = [
    {
      name: "Health vitals",
      selector: () => {
        return VitalType.getAnalysisName(VitalType.ECG);
      },
    },
    {
      name: "Date",
      selector: (row: EcgResponseDto) => moment(row.sample_time).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: EcgResponseDto) => moment(row.sample_time).format("hh:mm A"),
      sortable: true,
    },
    {
      name: "Heart Rate",
      selector: (row: EcgResponseDto) => row.hhhh,
      sortable: true,
      cell: (row: EcgResponseDto) => {
        let color =
          row.hhhh < 70
            ? "very-low"
            : row.hhhh >= 70 && row.hhhh <= 100
            ? "low"
            : row.hhhh > 100 && row.hhhh <= 140
            ? "normal"
            : "high";
        return (
          <span>
            <span className={`fw-medium text-${color}`}>{row.hhhh}</span>{" "}
            <span>{VitalType.getUnit(VitalType.HEART_RATE)}</span>
          </span>
        );
      },
    },
    {
      name: "Blood Pressure",
      selector: (row: EcgResponseDto) => `${row.maxB} / ${row.minB}`,
      sortable: true,
      cell: (row: EcgResponseDto) => {
        const value = row.maxB / row.minB;
        let color =
          value < 0.5 ? "very-low" : value >= 0.5 && value < 1 ? "low" : value >= 1 && value < 1.5 ? "normal" : "high";
        return (
          <span>
            <span className={`fw-medium text-${color}`}>{`${row.maxB} / ${row.minB}`}</span>{" "}
            <span>{VitalType.getUnit(VitalType.BLOOD_PRESSURE)}</span>
          </span>
        );
      },
    },
    {
      name: "Heart Rate Variability",
      selector: (row: EcgResponseDto) => row.hrHz,
      sortable: true,
      cell: (row: EcgResponseDto) => {
        let color =
          row.hrHz < 70
            ? "very-low"
            : row.hrHz >= 70 && row.hrHz <= 100
            ? "low"
            : row.hrHz > 100 && row.hrHz <= 140
            ? "normal"
            : "high";
        return (
          <span>
            <span className={`fw-medium text-${color}`}>{row.hrHz}</span> <span>ms</span>
          </span>
        );
      },
    },
  ];

  const { ecgRequest } = useAppSelector((state: RootState) => state.healthServiceV2);

  const handlePageChange = (page: number) => {
    dispatch(setEcgRequest({ ...ecgRequest, page_number: page }));
    dispatch(getEcgAsync({ ...ecgRequest, page_number: page }));
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(setEcgRequest({ ...ecgRequest, page_number: 1, page_size: rowsPerPage }));
    dispatch(getEcgAsync({ ...ecgRequest, page_number: 1, page_size: rowsPerPage }));
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        onRowClick={onRowClick}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

interface HistoryRecordCardProps {
  data: PaginatedResult<EcgResponseDto>;
  onRowClick: (row: EcgResponseDto) => void;
}

const HistoryRecordCard: React.FC<HistoryRecordCardProps> = ({ data, onRowClick }) => {
  return (
    <div className="d-flex flex-column mt-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex py-2 ps-0 rounded-3 col-6 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1 text-dark">
            <span className="font-14px fw-bold text-dark">History Record</span>
            <p className="font-10px text-grey-901">Lorem ipsum dolor sit amet, in quo illum vocibus.</p>
          </div>
        </div>
      </div>

      <HistoryTable data={data} onRowClick={onRowClick} />
    </div>
  );
};

export default HistoryRecordCard;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { GlucoseStatsDto } from "./models/glucose-stats-dto";
import { VitalsGraphRequestDto } from "./models/graph-request-dto";
import {
  getCurrentDateStartOfDay,
  getCurrentDateTime,
} from "@app/redux/common/helpers/date-helper";
import { GraphFilterDto } from "./models/graph-filter-dto";
import { ApiError } from "@app/redux/common/models/api-error";
import {
  EcgResponseDto,
  SleepRecord,
  StepsSummaryDto,
  VitalsGraphDto,
  VitalsSummaryDto,
} from "./models/bp-graph-dto";
import {
  getEcgAsync,
  getecgVitalsGraphAsync,
  getsleepMobileVitalsGraphAsync,
  getsleepVitalsGraphAsync,
  getVitalsGraphAsync,
  getVitalsSummaryAsync,
  getStepsVitalsGraphAsync,
  getStressVitalsGraphAsync,
  getStepsSummaryAsync,
} from "./healthServiceThunk";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { PageAndFilterRequestDto } from "@app/redux/common/models/page-and-filter-request-dto";
import { appConstants } from "@app/redux/common/constants/app-constant";
import { StressDataDto } from "./models/stress-data-dto";
import { StepsDataDto } from "./models/steps-data-dto";

export interface HealthServiceState extends BaseState {
  vitalsGraphDto: Record<string, VitalsGraphDto>;
  vitalsGraphRequest: Record<string, VitalsGraphRequestDto>;
  graphFilterDto: GraphFilterDto[];
  vitalsSummaryDto: VitalsSummaryDto[] | null;
  stepsSummaryDto?: StepsSummaryDto | null;
  glucoseStats?: GlucoseStatsDto;
  sleepDataDto?: SleepRecord[];
  sleepMobileDto?: string;
  ecgDataDto?: EcgResponseDto;
  ecgRequest: PageAndFilterRequestDto;
  ecgPaginatedResult?: PaginatedResult<EcgResponseDto>;
  stressDataDto?: StressDataDto;
  stepsDataDto?: StepsDataDto;
}

const inititalEcgRequest: PageAndFilterRequestDto = {
  page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER,
  page_size: appConstants.GRID_DEFAULT_PAGE_SIZE,
  filter_by: undefined,
};

const initialState: HealthServiceState = {
  status: ApiStatus.IDLE,
  vitalsSummaryDto: null,
  stepsSummaryDto: null,
  sleepDataDto: undefined,
  sleepMobileDto: undefined,
  ecgDataDto: undefined,
  ecgRequest: inititalEcgRequest,
  ecgPaginatedResult: undefined,
  stressDataDto: undefined,
  vitalsGraphDto: {}, // Initialize as an empty object
  stepsDataDto: undefined,
  vitalsGraphRequest: {
    BLOOD_PRESSURE: {
      userId: "",
      vital_type: "BLOOD_PRESSURE",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    BLOOD_GLUCOSE: {
      userId: "",
      vital_type: "BLOOD_GLUCOSE",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    BLOOD_OXYGEN: {
      userId: "",
      vital_type: "BLOOD_OXYGEN",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    RESPIRATORY_RATE: {
      userId: "",
      vital_type: "RESPIRATORY_RATE",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    BODY_TEMPERATURE: {
      userId: "",
      vital_type: "BODY_TEMPERATURE",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    HEART_RATE: {
      userId: "",
      vital_type: "HEART_RATE",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    ECG: {
      userId: "",
      vital_type: "ECG",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    STEPS: {
      userId: "",
      vital_type: "STEPS",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
    SLEEP: {
      userId: "",
      vital_type: "SLEEP",
      graphType: "DAY",
      startDate: getCurrentDateStartOfDay(),
      endDate: getCurrentDateTime(),
    },
  },

  graphFilterDto: [
    {
      key: "DAY",
      value: "DAY",
      label: "Daily",
    },
    {
      key: "WEEK",
      value: "WEEK",
      label: "Week",
    },
    {
      key: "MONTH",
      value: "MONTH",
      label: "Month",
    },
    {
      key: "QUARTER",
      value: "QUARTER",
      label: "6 Months",
    },
    {
      key: "YEAR",
      value: "YEAR",
      label: "Year",
    },
  ],
};

export const HealthServiceSlice = createSlice({
  name: "healthService",
  initialState,
  reducers: {
    setVitalsGraphResults(
      state,
      action: PayloadAction<{ vital_type: string; result: VitalsGraphDto }>
    ) {
      state.vitalsGraphDto[action.payload.vital_type] = action.payload.result;
    },
    setVitalsGraphRequest(
      state,
      action: PayloadAction<{
        vital_type: string;
        request: VitalsGraphRequestDto;
      }>
    ) {
      // action.payload.request.timezone=localStorage.getItem('timezone') ||'';
      state.vitalsGraphRequest[action.payload.vital_type] =
        action.payload.request;
    },
    resetVitalsGraph(state, action: PayloadAction<string>) {
      delete state.vitalsGraphDto[action.payload];
    },
    resetSleepVitalsGraph(state, action: PayloadAction<string>) {
      delete state.sleepDataDto;
    },
    resetSleepMobileVitalsGraph(state, action: PayloadAction<string>) {
      delete state.sleepMobileDto;
    },
    resetEcgVitalsGraph(state, action: PayloadAction<string>) {
      delete state.ecgDataDto;
    },
    setVitalsSummary(state, action: PayloadAction<VitalsSummaryDto[]>) {
      state.vitalsSummaryDto = action.payload;
    },
    setStepsSummary(state, action: PayloadAction<StepsSummaryDto>) {
      state.stepsSummaryDto = action.payload;
    },
    setEcgRequest(state, action: PayloadAction<PageAndFilterRequestDto>) {
      state.ecgRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(
        getVitalsGraphAsync.fulfilled,
        (
          state,
          action: PayloadAction<{ vital_type: string; data: VitalsGraphDto }>
        ) => {
          state.status = ApiStatus.IDLE;
          state.vitalsGraphDto[action.payload.vital_type] = action.payload.data;
        }
      )
      .addCase(getVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      })

      .addCase(getsleepVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getsleepVitalsGraphAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.sleepDataDto = action.payload;
      })
      .addCase(getsleepVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      })

      .addCase(getsleepMobileVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getsleepMobileVitalsGraphAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.sleepMobileDto = action.payload;
      })
      .addCase(getsleepMobileVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      })

      .addCase(getecgVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getecgVitalsGraphAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.ecgDataDto = action.payload;
      })
      .addCase(getecgVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      })

      .addCase(getVitalsSummaryAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getVitalsSummaryAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.vitalsSummaryDto = action.payload;
      })
      .addCase(getVitalsSummaryAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })


      .addCase(getStepsSummaryAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getStepsSummaryAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.stepsSummaryDto = action.payload;
      })
      .addCase(getStepsSummaryAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })

      .addCase(getEcgAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getEcgAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.ecgPaginatedResult = action.payload;
      })
      .addCase(getEcgAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload;
      })
      .addCase(getStressVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getStressVitalsGraphAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.stressDataDto = action.payload;
      })
      .addCase(getStressVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      })

      .addCase(getStepsVitalsGraphAsync.pending, (state) => {
        state.status = ApiStatus.PENDING;
        state.apiError = undefined;
      })
      .addCase(getStepsVitalsGraphAsync.fulfilled, (state, action) => {
        state.status = ApiStatus.IDLE;
        state.stepsDataDto = action.payload;
      })
      .addCase(getStepsVitalsGraphAsync.rejected, (state, action) => {
        state.status = ApiStatus.FAILED;
        state.apiError = action.payload as ApiError;
      });
  },
});

export const {
  setVitalsSummary,
  setVitalsGraphRequest,
  setStepsSummary,
  resetVitalsGraph,
  resetSleepVitalsGraph,
  resetEcgVitalsGraph,
  resetSleepMobileVitalsGraph,
  setEcgRequest,
} = HealthServiceSlice.actions;
export default HealthServiceSlice.reducer;

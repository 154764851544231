import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";

export const PaginateDefault: PaginatedResult<any> = {
  data: [],
  total_count: 0,
  total_pages: 0,
  has_next: false,
  has_previous: false,
  page: 1,
  per_page: 10,
};

import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import eyeIcon from "@assets/icons/login/eye_icon.svg";
import eyeCloseIcon from "@assets/icons/login/eye_close_icon.svg";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { useEffect, useState } from "react";
import backIcon from "@assets/icons/login/back_icon.svg";
import LocalStorageService from "@app/services/v2/local-storage-service";
import { resetForgotPasswordAsync } from "@app/redux/features/v2/security-service/securityServiceThunk";
import { ResetPasswordResponseDto } from "@app/redux/features/security-service/models/forgot-password-dto";
import { ValidationRules } from "@app/utils/types";

const ResetPassword = () => {
  // Redux state and dispatch
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Local state
  const [isMobile, setIsMobile] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const emailForgot = LocalStorageService.getUsernameResetPassword();

  // Form handling
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<{ password: string; confirm_password: string }>({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  // Watch form fields
  const password = watch("password");
  const confirmPassword = watch("confirm_password");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  // Check if form is valid
  useEffect(() => {
    setIsFormValid(password !== "" && confirmPassword !== "");
  }, [password, confirmPassword]);

  return (
    <div className="d-flex h-100 w-100 justify-content-end">
      {/* Right Panel */}
      <Row className="h-100 w-50 bg-white m-0">
        <Col className="col-12 w-100" style={{ marginTop: "150px" }}>
          <Row>
            <Col className="col-12 w-100">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column" style={{ width: "502px" }}>
                  <Link
                    to={"/auth/forgot-password"}
                    className="d-flex flex-row align-items-center font-12px fw-medium text-decoration-none text-black mb-3"
                  >
                    <img
                      height={14}
                      width={14}
                      className="ms-0 me-2 cursor-pointer"
                      src={backIcon}
                      alt="Note"
                    />
                    <p className="my-0 font-12px fw-medium">Back</p>
                  </Link>

                  <p className="font-24px fw-medium text-start my-0">
                    Reset password
                  </p>

                  <div className="w-100 pt-3">
                    <Form
                      onSubmit={handleSubmit((data) => {
                        const payload = {
                          username: emailForgot ?? '',
                          new_password: data.password,
                        }
                        dispatch(resetForgotPasswordAsync(payload)).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            const result = action.payload as ResetPasswordResponseDto;
                            if (result.user_sid) {
                              navigate('/auth/login');
                              LocalStorageService.removeUsernameResetPassword();
                            }
                          }
                        })
                      })}
                      id="loginForm"
                    >
                      <FormInput
                        control={control}
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        togglePasswordVisibility={togglePasswordVisibility}
                        rules={{
                          required: "Password is required",
                          validate: (value: string) => {
                            const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
                            return (
                              regex.test(value) ||
                              "Password must be at least 8 characters long, include a number, an uppercase letter, and a lowercase letter."
                            );
                          },
                        }}
                        error={errors.password?.message ?? ''}
                      />
                      <FormInput
                        control={control}
                        name="confirm_password"
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm your password"
                        togglePasswordVisibility={
                          toggleConfirmPasswordVisibility
                        }
                        rules={{
                          required: "Confirm Password is required",
                          validate: (value: string) =>
                            value === password || "Passwords do not match",
                        }}
                        error={errors.confirm_password?.message ?? ''}
                      />
                      <p className="font-12px text-grey-906">
                        Your password must be at least 8 characters long,
                        include a number, an uppercase letter and a lowercase
                        letter.
                      </p>
                      <Button
                        as="input"
                        type="submit"
                        value="Reset password"
                        className={`font-12px fw-medium rounded-3 ${
                          isFormValid
                            ? "text-white bg-blue-902"
                            : "text-grey-901 bg-grey-904"
                        } border-0 px-3 d-flex align-items-center text-login no-hover`}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

type FormInputProps = {
  control: any;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  togglePasswordVisibility?: () => void;
  rules?: ValidationRules<string>;
  error?: string;
};

const FormInput: React.FC<FormInputProps> = ({
  control,
  name,
  label,
  type,
  placeholder,
  togglePasswordVisibility,
  rules = { required: { value: true, message: `${name} is required` } },
  error,
}) => (
  <Form.Group className="mb-3" controlId={`loginForm.${name}`}>
    <span className="font-10px text-dark">{label}</span>
    <InputGroup className="border rounded-3 border-0 bg-grey-904">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            className="border-0 bg-transparent font-12px py-2"
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
        )}
      />
      {togglePasswordVisibility &&
        (type === "text" ? (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeCloseIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center me-2 cursor-pointer">
            <img
              width={12}
              height={13}
              src={eyeIcon}
              onClick={togglePasswordVisibility}
              alt="icon"
            />
          </div>
        ))}
    </InputGroup>
    {error && (
      <Form.Text className="text-danger">
        {error}
      </Form.Text>
    )}
  </Form.Group>
);
export default ResetPassword;

import {
  ClientsAlertTableDataRequest,
  HighRiskClientsTableDataRequest,
} from "@app/redux/features/v2/portal-service/home/models/request-dto";
import { config } from "../config";

import { createOrganisationPortalApi } from "../organisation-portal-api";

import {
  CardDataResultDto,
  ClientsAlertTableDataResult,
  HighRiskClientsTableDataResult,
} from "@app/redux/features/v2/portal-service/home/models/request-result-dto";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const getClientsCardData = (org_id: string) => {
  return organisationPortalApi.get<CardDataResultDto>(
    `/api/v2/wearer/organisation/clients_count?org_id=${org_id}`
  );
};

const getClientsAlertCardData = (org_id: string) => {
  return organisationPortalApi.get<CardDataResultDto>(
    `/api/v2/medical/alert/clients_alert_count/by-organization?org_id=${org_id}`
  );
};

const getHighRiskClientsCardData = (org_id: string) => {
  return organisationPortalApi.get<CardDataResultDto>(
    `/api/v2/wearer/organisation/health_risk_count?organisation_sid=${org_id}&health_risk=High`
  );
};

const getHighRiskClientsTableData = ({
  organisation_sid,
  page_number,
  page_size,
  search_query,
}: HighRiskClientsTableDataRequest) => {
  const queryParams = new URLSearchParams({
    page: page_number.toString(),
    per_page: page_size.toString(),
    health_risk: "High",
    ...(organisation_sid && { organisation_sid }),
    ...(search_query && { search_query }),
  });
  return organisationPortalApi.get<
    PaginatedResult<HighRiskClientsTableDataResult>
  >(`/api/v2/wearer/organisation/by-health-risk?${queryParams.toString()}`);
};

const getClientsAlertTableData = ({
  org_sid,
  page_number,
  page_size,
  search_query,
}: ClientsAlertTableDataRequest) => {
  const queryParams = new URLSearchParams({
    page: page_number.toString(),
    per_page: page_size.toString(),
    ...(org_sid && { org_sid }),
    ...(search_query && { search_query }),
  });
  return organisationPortalApi.get<
    PaginatedResult<ClientsAlertTableDataResult>
  >(`/api/v2/medical/alert/organization_clients?${queryParams.toString()}`);
};

const PortalHomeApi = {
  getClientsCardData,
  getClientsAlertCardData,
  getHighRiskClientsCardData,
  getHighRiskClientsTableData,
  getClientsAlertTableData,
};

export default PortalHomeApi;

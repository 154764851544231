import { useMemo } from "react";
import Table from "@app/components/Table";
import { PaginatedResult } from "@app/redux/common/models/paginated-result-dto";
import { HighRiskClientsTableDataResult } from "@app/redux/features/v2/portal-service/home/models/request-result-dto";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { setHighRiskClientsRequest } from "@app/redux/features/v2/portal-service/home/portalHomeSlice";
import { RootState } from "@app/redux/store";
import { getHighRiskClientsTableDatasAsync } from "@app/redux/features/v2/portal-service/home/portalHomeThunk";
import moment from "moment";

interface ITableProps {
  data: PaginatedResult<HighRiskClientsTableDataResult>;
}

const HighRiskTable = ({ data }: ITableProps) => {
  const dispatch = useAppDispatch();
  const { highRiskClientsRequest } = useAppSelector(
    (state: RootState) => state.portalHomeV2
  );
  const getCurrentAge = (date: string | Date) => {
    const dob = moment(date);
    const now = moment();
    return now.diff(dob, "years");
  };

  const columns = useMemo(
    () => [
      {
        name: "No.",
        selector: (row: HighRiskClientsTableDataResult, index: number) =>
          index + 1,
      },

      {
        name: "Name",
        selector: (row: HighRiskClientsTableDataResult) =>
          `${row.first_name} ${row.last_name}`,
      },

      {
        name: "Age",
        selector: (row: HighRiskClientsTableDataResult) =>
          getCurrentAge(row.date_of_birth),
        sortable: true,
      },
      {
        name: "Gender",
        selector: (row: HighRiskClientsTableDataResult) => row.gender,
        sortable: true,
      },
      {
        name: "Location",
        id: "action",
        selector: (row: HighRiskClientsTableDataResult) => row.location,
        sortable: true,
        style: {
          width: "fit-content",
          flex: "0 !important",
          padding: "12.5px 16px !important",
        },
      },
    ],
    []
  );

  const handlePageChange = (page: number) => {
    dispatch(
      setHighRiskClientsRequest({
        ...highRiskClientsRequest,
        page_number: page,
      })
    );
    dispatch(
      getHighRiskClientsTableDatasAsync({
        ...highRiskClientsRequest,
        page_number: page,
      })
    );
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(
      setHighRiskClientsRequest({
        ...highRiskClientsRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
    dispatch(
      getHighRiskClientsTableDatasAsync({
        ...highRiskClientsRequest,
        page_number: 1,
        page_size: rowsPerPage,
      })
    );
  };

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data.data}
        totalCount={data.total_count}
        currentPage={data.page}
        rowsPerPage={data.per_page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};
export default HighRiskTable;

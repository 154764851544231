import mymonXIcon from "@assets/icons/mymonX_icon.svg";

interface BasicReportCardProps {
  title?: string;
  icon?: string;
  value?: number;
  unit?: string;
}

const BasicReportCard: React.FC<BasicReportCardProps> = (
  props: BasicReportCardProps
) => {
  return (
    <div className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 pb-1 w-100">
      {/* Header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100">
        <img
          width={18}
          height={18}
          src={props.icon ? props.icon : mymonXIcon}
          alt="Identity Icon"
        />
        <span className="font-14px fw-medium d-flex flex-grow-1 ms-2">
          {props.title ? props.title : "Used mymonX for"}
        </span>
      </div>
      {/* Content */}
      <div className="border-grey-901">
        <div className="mt-2 mb-1">
          <div className="w-100">
            <p className="font-28px fw-medium mb-0">
              {props.value}
              <span className="ms-1 font-10px text-grey-901">{props.unit}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicReportCard;

import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";

type Props = {
  period: string;
  summaryData: {
    date: string | number;
    startTime: number | undefined | null;
    stages: { stage: string; value: number; duration: number }[];
  }[];
};

type AdjustedProps = {
  date: string | number;
  startTime: number | undefined | null;
  stages: { category: string; duration: number }[];
}[];

const adjustStages = (data: Props["summaryData"]): AdjustedProps => {
  return data.map((entry) => {
    const adjustedStages: { category: string; duration: number }[] = [];

    entry.stages.forEach(({ stage, duration }) => {
      const category = stage;
      const lastStage = adjustedStages[adjustedStages.length - 1];
      
      if (lastStage && lastStage.category === category) {
        lastStage.duration += duration;
      } else {
        adjustedStages.push({ category, duration });
      }
    });

    return {
      date: entry.date,
      startTime: entry.startTime,
      stages: adjustedStages,
    };
  });
};

const formatYAxis = (value: number) => {
  const totalMinutes = value + 1320;
  const hours24 = Math.floor(totalMinutes / 60) % 24;
  const period = hours24 >= 12 ? "pm" : "am";
  const hours12 = hours24 % 12 === 0 ? 0 : hours24 % 12;
  return `${hours12}${period}`;
}

const getRadius = (currentKey: string, nextKey: string | undefined): [number, number, number, number] => {
  if (!nextKey) return [2, 2, 0, 0];
  if (currentKey.split('-')[2] === "0") return [0, 0, 2, 2];
  if (currentKey.split('-')[1] !== nextKey.split('-')[1]) return [2, 2, 0, 0];

  return [0, 0, 0, 0];
}

const getStageColor = (stage: string) => {
  switch (stage) {
    case 'Relaxed':
      return "#3F72F3";
    case 'Normal':
      return "#00BD91";
    case 'Medium':
      return "#FFAA00";
    case 'High':
      return "#FF2D1A";
    default:
      return "transparent";
  }
}

const NoDataMessage = () => (
  <div
    className="d-flex align-items-center position-absolute rounded-1 bg-blue-50 text-blue-901 p-2"
    style={{ top: "35%", left: "20%", zIndex: "1001" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#3F72F3" />
      <path
        d="M8 3.9375C7.19652 3.9375 6.41107 4.17576 5.743 4.62215C5.07492 5.06855 4.55422 5.70302 4.24674 6.44535C3.93926 7.18767 3.85881 8.00451 4.01556 8.79255C4.17231 9.5806 4.55923 10.3045 5.12738 10.8726C5.69553 11.4408 6.4194 11.8277 7.20745 11.9844C7.9955 12.1412 8.81233 12.0607 9.55465 11.7533C10.297 11.4458 10.9315 10.9251 11.3778 10.257C11.8242 9.58893 12.0625 8.80349 12.0625 8C12.0614 6.92291 11.633 5.89025 10.8714 5.12863C10.1097 4.36701 9.07709 3.93864 8 3.9375ZM7.6875 6.125C7.6875 6.04212 7.72043 5.96263 7.77903 5.90403C7.83764 5.84542 7.91712 5.8125 8 5.8125C8.08288 5.8125 8.16237 5.84542 8.22097 5.90403C8.27958 5.96263 8.3125 6.04212 8.3125 6.125V8.3125C8.3125 8.39538 8.27958 8.47487 8.22097 8.53347C8.16237 8.59208 8.08288 8.625 8 8.625C7.91712 8.625 7.83764 8.59208 7.77903 8.53347C7.72043 8.47487 7.6875 8.39538 7.6875 8.3125V6.125ZM8 10.1875C7.90729 10.1875 7.81666 10.16 7.73958 10.1085C7.66249 10.057 7.60241 9.98379 7.56693 9.89813C7.53145 9.81248 7.52217 9.71823 7.54026 9.6273C7.55835 9.53637 7.60299 9.45285 7.66855 9.38729C7.7341 9.32174 7.81762 9.27709 7.90855 9.25901C7.99948 9.24092 8.09373 9.2502 8.17938 9.28568C8.26504 9.32116 8.33825 9.38124 8.38975 9.45833C8.44126 9.53541 8.46875 9.62604 8.46875 9.71875C8.46875 9.84307 8.41937 9.9623 8.33146 10.0502C8.24355 10.1381 8.12432 10.1875 8 10.1875Z"
        fill="white"
      />
    </svg>
    <span className="ms-1">There is currently no data available to show</span>
  </div>
);

const SummaryChart = ({ summaryData, period }: Props) => {
  const areAllStagesNull = (summaryData: {
    date: string | number;
    startTime: number | undefined | null;
    stages: { stage: string; value: number; duration: number }[];
  }[]): boolean => {
    return summaryData.every(entry => 
      entry.stages.length === 0 || entry.stages.every(stage => stage === null)
    );
  };

  const isEmpty = areAllStagesNull(summaryData);

  const transformedData = adjustStages(summaryData).map((item, summaryIndex) => {
    const stagesObject = item.stages.reduce(
      (acc, stage, stageIndex) => ({ ...acc, [`${stage.category}-${summaryIndex}-${stageIndex}`]: stage.duration }),
      {}
    );
    return { ...item, ...stagesObject };
  });

  const keyPattern = /-(\d+)-(\d+)/;
  let keys = transformedData.flatMap(item => 
    Object.keys(item).filter(key => keyPattern.test(key))
  );
  
  keys = keys.sort((a, b) => {
    const [, aFirst, aSecond] = a.match(keyPattern)!.map(Number);
    const [, bFirst, bSecond] = b.match(keyPattern)!.map(Number);

    return aFirst - bFirst || aSecond - bSecond;
  });

  return (
    <div className="container font-12px">
      <div className="chart-container position-relative">
        <div style={{ width: period === TimePeriodHelper.getTimePeriodJson(TimePeriod.month) ? '150%' : '100%', overflowX: 'auto' }}>
          {isEmpty && <NoDataMessage />}
          <ResponsiveContainer width="110%" height={200}>
            <BarChart data={transformedData} barSize={20} barGap={-1} margin={{ left: 25 }}>
              <XAxis 
                interval={period === TimePeriodHelper.getTimePeriodJson(TimePeriod.month) ? 0 : undefined} 
                axisLine={false} 
                tickLine={false} 
                dataKey="date" 
                fontSize={12} 
              />
              <YAxis 
                axisLine={false} 
                tickLine={false} 
                padding={{top: 10}} 
                interval={0} 
                type="number" 
                domain={[0, 1380]}
                ticks={[0, 240, 480, 720, 960, 1200, 1440]} // Range from 10 PM to 10 PM of next day (24 hours)
                tickFormatter={formatYAxis} 
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="startTime" stackId="a" fill="transparent" />
              {keys.map((name, index) => (
                <Bar 
                  key={name}
                  dataKey={name} 
                  stackId="a" 
                  fill={getStageColor(name.split('-')[0])} 
                  radius={getRadius(name, keys[index + 1])}
                  clipPath="url(#clip)"
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default SummaryChart;

import { useMemo } from "react";
import settingIcon from "@assets/icons/portal/settings_icon.svg";
import deleteIcon from "@assets/icons/portal/prohibit_icon.svg";
import watch from "@assets/icons/portal/watch_icon.svg";
import redWatch from "@assets/icons/portal/watch_red_icon.svg";
import Table from "@app/components/Table";
import { MenuItem, Select } from "@mui/material";
import "./index.scss";

interface DeviceDataType {
  id: number;
  name: string;
  device_type: string;
  serial_no: string;
  imei: string;
  status: string;
}

const data = [
  {
    id: 1,
    name: "Anna Johnson",
    device_type: "Mobile",
    type: "watch",
    serial_no: "123468agdjdywbm1098_28dnbk",
    imei: "129465728045169",
    status: "Unassign",
  },
  {
    id: 2,
    name: "Tim Arknold",
    device_type: "Watch",
    type: "ring",
    serial_no: "123468agdjdywbm1sjs098_28dnbk",
    imei: "129465728045168",
    status: "Assign",
  },
];

interface ITableProps {
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onAssign: (data: any) => void;
  onUnassign: (data: any) => void;
  onConfigure: (data: any) => void;
}

const DeviceTable = ({ onEdit, onAssign, onDelete, onUnassign, onConfigure }: ITableProps) => {
  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row: DeviceDataType) => row.name,
      },
      {
        name: "Device Type",
        selector: (row: DeviceDataType) => (
          <div
            style={{
              backgroundColor: "#E3E3E3",
              padding: "1px 3px",
              borderRadius: "2px",
            }}
          >
            {row.device_type}
          </div>
        ),
      },
      {
        name: "Serial No",
        selector: (row: DeviceDataType) => row.serial_no,
        sortable: true,
      },
      {
        name: "IMEI",
        selector: (row: DeviceDataType) => row.imei,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row: DeviceDataType) => (
          <div
            id={`${row.status === "Assign" ? "device-status-selector" : ""}`}
            style={{
              minWidth: 90,
              maxWidth: 100,
            }}
          >
            <Select
              value={row.status}
              size="small"
              renderValue={(value) => (value)}
              className="device-selector-bg-transparent"
              style={{
                color: row.status === "Assign" ? "#3F72F3" : "inherit",
                width: "100%",
                background: "transparent",
              }}
            >
              <MenuItem className="d-flex align-items-center gap-1 p-3" onClick={() => onConfigure(row)}>
                <img height={12} width={12} src={settingIcon} alt="setting-icon" className="mr-1" />
                <span style={{ color: "#3F72F3" }} className="font-12px fw-normal">
                  Configure
                </span>
              </MenuItem>
              <MenuItem
                value="Unassign"
                className={`${row.status === "Unassign" ? "d-none" : "d-flex"} align-items-center gap-1 p-3`}
                onClick={() => onUnassign(row)}
              >
                <img height={12} width={12} src={redWatch} alt="unassign-icon" className="mr-1" />
                <span style={{ color: "#FF2D1A" }} className="font-12px fw-normal">
                  Unassign Device
                </span>
              </MenuItem>
              <MenuItem
                value="Assign"
                className={`${row.status === "Assign" ? "d-none" : "d-flex"} align-items-center gap-1 p-3`}
                onClick={() => onAssign(row)}
              >
                <img height={12} width={12} src={watch} alt="assign-icon" className="mr-1" />
                <span style={{ color: "#3F72F3" }} className="font-12px fw-normal">
                  Assign Device
                </span>
              </MenuItem>
            </Select>
          </div>
        ),
      },  
      {
        name: "Actions",
        id: "action",
        style: {
          width: "fit-content",
          flex: "0 !important",
          "padding": "12.5px 16px !important",
        },
        cell: (row: DeviceDataType) => (
          <div className="action-cell">
            <img
              height={16}
              width={16}
              src={settingIcon}
              alt="Edit"
              onClick={() => onEdit(row)}
              className="cursor-pointer"
            />
            <img
              height={16}
              width={16}
              src={deleteIcon}
              alt="Delete"
              className="ms-2 cursor-pointer"
              onClick={() => onDelete(row)}
            />
          </div>
        ),
      },
    ],
    [onDelete, onEdit]
  );

  return (
    <div className="row">
      <Table columns={columns} data={data} />
    </div>
  );
};

export default DeviceTable;

import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import InformationCard from "../widgets/SOSDetail.InformationCard";
import { RootState } from "@app/redux/store";
import { useEffect } from "react";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import {
  getProfileByEmailAsync,
  getUserProfileImageAsync,
} from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";
import { getContactsAsync } from "@app/redux/features/v2/contact-service/contactServiceThunk";
import { getDeviceByUserIdAsync } from "@app/redux/features/v2/device-service/deviceServiceThunk";
import MedicalInfoCard from "../widgets/SOSDetail.MedicalInfoCard";
import ContactCard from "../widgets/SOSDetail.ContactCard";
import BasicInfoCard from "../widgets/SOSDetail.BasicInfoCard";
import NoteCard from "../widgets/SOSDetail.NoteCard";
import { VitalType } from "@app/common/enums/VitalTypes";
import MapCard from "@app/views/portal/sos/widgets/SOSDetail.MapCard";

const SosDetail = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 10 }));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(getProfileByEmailAsync(currentUser?.email ?? ""));
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (profile && profile.sid) {
      dispatch(getUserProfileImageAsync(profile.sid ?? ""));
      dispatch(getContactsAsync(profile.sid));
      dispatch(getDeviceByUserIdAsync({ user_id: profile.sid }));
    }
  }, [dispatch, profile]);
  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column">
          <div className="mt-5">
            <p className="font-20px mb-0 fw-bold">SOS</p>
          </div>
          <div className="row d-flex ">
            <div className="tab-container history-tabs">
              <div className="row d-flex ">
                <div className="d-flex flex-column flex-md-row col-12 gap-2">
                  <div className="col">
                    <div className="d-flex flex-column gap-2">
                      <InformationCard />
                      <ContactCard />
                      <MedicalInfoCard />
                    </div>
                  </div>

                  <div className="col col-md-6 col-lg-6 d-flex flex-column gap-2">
                    <div className="d-flex flex-wrap flex-md-nowrap flex-row gap-2">
                      <BasicInfoCard
                        type={VitalType.HEART_RATE}
                        value="240"
                        unit="hours"
                      />
                      <BasicInfoCard
                        type={VitalType.TEMPERATURE}
                        title="Burned"
                        value="5502"
                        unit="kcal"
                      />
                      <BasicInfoCard
                        type={VitalType.OXYGEN}
                        title="Taken"
                        value="125374"
                        unit="steps"
                      />
                    </div>
                    <div className="d-flex flex-wrap flex-md-nowrap flex-row gap-2">
                      <BasicInfoCard
                        type={VitalType.BLOOD_PRESSURE}
                        value="240"
                        unit="hours"
                      />
                      <BasicInfoCard
                        type={VitalType.RESPIRATORY_RATE}
                        title="Burned"
                        value="5502"
                        unit="kcal"
                      />
                      <BasicInfoCard
                        type={VitalType.STRESS}
                        title="Taken"
                        value="125374"
                        unit="steps"
                      />
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <MapCard />
                    </div>
                  </div>

                  <div className="col">
                    <div className="d-flex flex-column gap-2">
                      <NoteCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SosDetail;

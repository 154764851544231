import { useState } from "react";
import { Marker } from "react-map-gl/maplibre";
import PinIcon from "./PinIcon";
import { LockerPopup } from "./LockerPopup";

const LockerMarker = ({ locker, selectedLocker, onLockerSelected }: any) => (
  <Marker
    latitude={locker.latitude}
    longitude={locker.longitude}
    onClick={(e) => {
      e.originalEvent.stopPropagation();
      onLockerSelected(locker);
    }}
  >
    <PinIcon size={35} isSelected={locker === selectedLocker} />
  </Marker>
);

export const LockerMarkers = ({ lockers }: any) => {
  const [selectedLocker, setSelectedLocker] = useState();

  return (
    <>
      {lockers.map((locker: any, index: number) => (
        <LockerMarker
          key={index}
          locker={locker}
          selectedLocker={selectedLocker}
          onLockerSelected={setSelectedLocker}
        />
      ))}
      {selectedLocker && (
        <LockerPopup
          locker={selectedLocker}
          onClose={() => setSelectedLocker(undefined)}
        />
      )}
    </>
  );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "@app/redux/common/enums/api-status";
import { BaseState } from "@app/redux/common/models/base-state";
import { getOrgFromJwt } from "@app/redux/common/helpers/jwt-helper";

import { getMonthlyReportsAsync, getMonthlyReportsByVitalAsync } from "./reportServiceThunk";

// Define the interface for ConfigurationServiceState
export interface ReportServiceState extends BaseState {
    monthlyReportsResult: string;
    monthlyReportsResultsUpdated: Record<string, string>;  // Use a dictionary keyed by vital_type
    initialClientRequest: { org_id: string };
}

// Initial client request object with org_id
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}

// Initial state for ConfigurationService
const initialState: ReportServiceState = {
    status: ApiStatus.IDLE,
    monthlyReportsResult: '', // Initialize as null or appropriate initial value
    monthlyReportsResultsUpdated: {},  // Initialize as an empty object
    initialClientRequest: initialClientRequest,
    apiError: undefined // Assuming BaseState includes apiError
}

// Create the slice
export const reportServiceSlice = createSlice({
    name: 'reportService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setMonthlyReportResult(state, action: PayloadAction<string>) {
            state.monthlyReportsResult = action.payload;
        },
        setmonthlyReportsResultsUpdated(state, action: PayloadAction<{vital_type: string, result: string}>) {
            state.monthlyReportsResultsUpdated[action.payload.vital_type] = action.payload.result;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks


            .addCase(getMonthlyReportsAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMonthlyReportsAsync.fulfilled, (state, action) => {

                state.monthlyReportsResult = action.payload;
                state.status = ApiStatus.IDLE;
            })
            .addCase(getMonthlyReportsAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(getMonthlyReportsByVitalAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMonthlyReportsByVitalAsync.fulfilled, (state, action) => {
                const { vital_type, result } = action.payload;
                state.monthlyReportsResultsUpdated[vital_type] = result;
                state.status = ApiStatus.IDLE;
            })
            .addCase(getMonthlyReportsByVitalAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            });
    }
});

// Export actions
export const { setMonthlyReportResult } = reportServiceSlice.actions;

// Export
// Export the reducer
export default reportServiceSlice.reducer;

import React from "react";
import { VitalType } from "@app/common/enums/VitalTypes";

interface ProgressContainerProps {
  progress: number;
  analysisType: VitalType;
}

const splitAndWrapUnit = (unit: string, type: string) => {
  const parts = unit.match(/(\D*)(\d+)(\D*)/);
  return (
    <div className="d-flex align-items-center">
      {parts && parts[1] && (
        <span style={{ marginBottom: "2px", marginRight: "2px" }}>
          {parts[1]}
        </span>
      )}
      {parts && parts[2] && <span>{type === VitalType.GLUCOSE ? Number(parts[2]).toFixed(1) : parts[2]}</span>}
      {parts && parts[3] && (
        <span style={{ marginLeft: "1px" }}>{parts[3]}</span>
      )}
    </div>
  );
};

export const typeRanges = {
  [VitalType.OXYGEN]: [
    {
      label: "Low",
      color: "low",
      range: [0, 90],
      flex: 4,
      unit: ["< 85%", "90%"],
    },
    {
      label: "Below Average",
      color: "below-average",
      range: [90, 94],
      flex: 4,
      unit: ["90%", "94%"],
    },
    {
      label: "Normal",
      color: "normal",
      range: [94, 100],
      flex: 6,
      unit: ["94%", "100%"],
    },
  ],
  [VitalType.HEART_RATE]: [
    {
      label: "Low",
      color: "low",
      range: [0, 50],
      flex: 5,
      unit: ["0", ""],
    },
    {
      label: "Normal",
      color: "normal",
      range: [50, 90],
      flex: 8,
      unit: ["50", ""],
    },
    {
      label: "High",
      color: "high",
      range: [90, 200],
      flex: 8,
      unit: ["90", ""],
    },
  ],
  [VitalType.RESPIRATORY_RATE]: [
    {
      label: "Low",
      color: "low",
      range: [0, 12],
      flex: 5,
      unit: ["0", ""],
    },
    {
      label: "Normal",
      color: "normal",
      range: [12, 20],
      flex: 8,
      unit: ["12", ""],
    },
    {
      label: "High",
      color: "high",
      range: [20, 50],
      flex: 8,
      unit: ["20 +", ""],
    },
  ],
  [VitalType.TEMPERATURE]: [
    {
      label: "Low",
      color: "low",
      range: [0, 36],
      flex: 5,
      unit: ["", ""],
    },
    {
      label: "Normal",
      color: "normal",
      range: [36, 38],
      flex: 8,
      unit: ["", ""],
    },
    {
      label: "High",
      color: "high",
      range: [38, 50],
      flex: 8,
      unit: ["", ""],
    },
  ],
  [VitalType.GLUCOSE]: [
    {
      label: "Very low",
      color: "very-low",
      range: [0, 3],
      flex: 5,
      unit: ["0", ""],
    },
    { label: "Low", color: "low", range: [3, 4], flex: 5, unit: ["3", ""] },
    {
      label: "Normal",
      color: "normal",
      range: [4, 8],
      flex: 8,
      unit: ["4", ""],
    },
    {
      label: "High",
      color: "high",
      range: [8, 11],
      flex: 8,
      unit: ["8", ""],
    },
    {
      label: "Very high",
      color: "very-high",
      range: [11, 20],
      flex: 6,
      unit: ["11", ""],
    },
  ],
  [VitalType.STRESS]: [
    {
      label: "Relaxed",
      color: "low",
      range: [0, 25],
      flex: 5,
      unit: ["", ""],
    },
    {
      label: "Normal",
      color: "normal",
      range: [26, 50],
      flex: 8,
      unit: ["", ""],
    },
    {
      label: "High",
      color: "high",
      range: [51, 75],
      flex: 8,
      unit: ["", ""],
    },
    {
      label: "Very High",
      color: "very-high",
      range: [76, 100],
      flex: 6,
      unit: ["", ""],
    },
  ],
};

const ProgressContainer: React.FC<ProgressContainerProps> = ({
  progress,
  analysisType,
}) => {
  const ranges = typeRanges[analysisType as keyof typeof typeRanges];

  const getMarkerPosition = () => {
    const totalFlex = ranges.reduce((sum, range) => sum + range.flex, 0);
    let cumulativeFlex = 0;

    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      const [min, max] = range.range;

      if (progress <= max) {
        const rangeFlex = range.flex;
        const rangeWidth = (rangeFlex / totalFlex) * 100;
        const rangeProgress = ((progress - min) / (max - min)) * rangeWidth;
        return (cumulativeFlex / totalFlex) * 100 + rangeProgress;
      }

      cumulativeFlex += range.flex;
    }

    return 100;
  };

  const labelPositions = ranges.reduce(
    (acc, range, index) => {
      const cumulativeFlex = acc.cumulativeFlex + range.flex;
      const position = (acc.cumulativeFlex / acc.totalFlex) * 100;
      acc.positions.push(position);
      acc.cumulativeFlex = cumulativeFlex;
      return acc;
    },
    {
      cumulativeFlex: 0,
      totalFlex: ranges.reduce((sum, r) => sum + r.flex, 0),
      positions: [] as number[],
    }
  ).positions;

  const markerPosition = getMarkerPosition();
  const shouldHideNow = labelPositions.some(
    (labelPosition) => Math.abs(markerPosition - labelPosition) < 5
  ) && ranges.every(range => range.unit[0] !== "" );

  return (
    <div className="position-relative pe-2">
      <div className="d-flex justify-content-between mb-4 position-relative">
        {
          ranges.reduce(
            (acc, range, index) => {
              const cumulativeFlex = acc.cumulativeFlex + range.flex;
              const position = (acc.cumulativeFlex / acc.totalFlex) * 100;
              const labelWidth = 30;
              const adjustedPosition = Math.min(
                Math.max(position, labelWidth / 2),
                100 - labelWidth / 2
              );

              acc.elements.push(
                <div
                  key={index}
                  className="fw-bold d-flex align-items-center"
                  style={{
                    position: "absolute",
                    left: range.range[0] === 0 ? "0%" : `${adjustedPosition}%`,
                    fontSize: "12px",
                    transform: "translateX(-50%)",
                  }}
                >
                  {splitAndWrapUnit(range.unit[0], analysisType)}
                </div>
              );

              if (index === ranges.length - 1) {
                acc.elements.push(
                  <div
                    key={`${index}-end`}
                    className="fw-bold"
                    style={{
                      position: "absolute",
                      left: "100%",
                      fontSize: "12px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {splitAndWrapUnit(range.unit[1], analysisType)}
                  </div>
                );
              }

              acc.cumulativeFlex = cumulativeFlex;
              return acc;
            },
            {
              cumulativeFlex: 0,
              totalFlex: ranges.reduce((sum, r) => sum + r.flex, 0),
              elements: [] as React.ReactNode[],
            }
          ).elements
        }
      </div>

      <div
        style={{
          position: "absolute",
          left: `${Math.min(Math.max(markerPosition, 0), 100)}%`,
          top: "0px",
          marginBottom: "0",
          transform: "translateX(-50%)",
        }}
      >
        <div className="d-flex flex-column align-items-center">
          <div
            className="font-12px fw-bold"
            style={{
              marginRight: "4px",
              visibility: shouldHideNow ? "hidden" : "visible",
            }}
          >
            Now
          </div>

          <div
            style={{
              width: "9.6px",
              height: "5.2px",
              backgroundColor: "white",
              transform: "rotate(180deg)",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderBottom: "5px solid #777E8F",
            }}
          />
        </div>
      </div>

      <div
        className="d-flex align-items-center"
        style={{ height: "24px", position: "relative", marginBottom: "4px" }}
      >
        {ranges.map((range, index) => (
          <React.Fragment key={index}>
            {index === 0 && (
              <div
                style={{
                  width: "1px",
                  height: "24px",
                  backgroundColor: "#ECECEC",
                  position: "absolute",
                  left: "0%",
                }}
              />
            )}
            <div
              className={`bg-${range.color}`}
              style={{
                flex: range.flex,
                height: "14px",
                borderRadius:
                  index === 0
                    ? "14px 0 0 14px"
                    : index === ranges.length - 1
                      ? "0 14px 14px 0"
                      : "0",
              }}
            />
            <div
              style={{
                width: "1px",
                height: "24px",
                backgroundColor: "#ECECEC",
              }}
            />
          </React.Fragment>
        ))}
      </div>

      <div className="d-flex justify-content-between mb-2">
        {ranges.map((range, index) => (
          <div key={index} style={{ flex: range.flex, textAlign: "center" }}>
            <span className="text-grey-901" style={{ fontSize: "12px" }}>
              {range.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressContainer;

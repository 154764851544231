import React from 'react';
import summaryIcon from "@assets/icons/summary-icon.svg";
import SummaryChart from './AnalysisChart';
import { VitalType } from '@app/common/enums/VitalTypes';
import { getTimeRange } from '@app/utils/time';


interface ChartCardProps {
  period: string;
  summaryType: string;
  summaryData: { 
    date: string;
    value1?: number | null;
    value2?: number | null;
    max1?: number | null;
    max2?: number | null;
    min1?: number | null;
    min2?: number | null;
  }[];
  unit1: string;
  unit2: string;
}

const DoubleUnitAnalysisCard: React.FC<ChartCardProps> = ({ period , summaryType, summaryData, unit1, unit2 }) => {
  const RenderChart = (summaryType: string) => {
    switch (summaryType) {
      case VitalType.BLOOD_PRESSURE:
        return <SummaryChart summaryData={summaryData} unit1={unit1} unit2={unit2} period={period} />
      default:
        return <></>
    }
  }

  return (
    <div className="bg-white p-3 rounded-3 h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Analysis</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      {RenderChart(summaryType)}
    </div>
  );
};

export default DoubleUnitAnalysisCard; 
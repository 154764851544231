import DeviceCard from "./widgets/Profile.DeviceCard";
import ContactCard from "./widgets/Profile.ContactCard";
import GeneralInformationCard from "./widgets/Profile.GeneralInformationCard";
import InformationCard from "./widgets/Profile.InformationCard";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import { RootState } from "@app/redux/store";
import {
  getProfileByEmailAsync,
  getUserProfileImageAsync,
} from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";
import { getContactsAsync } from "@app/redux/features/v2/contact-service/contactServiceThunk";
import { getDeviceByUserIdAsync } from "@app/redux/features/v2/device-service/deviceServiceThunk";

const Profile = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityServiceV2
  );
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 10 }));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(getProfileByEmailAsync(currentUser?.email ?? ""));
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (profile && profile.sid) {
      dispatch(getUserProfileImageAsync(profile.sid ?? ""));
      dispatch(getContactsAsync(profile.sid));
      dispatch(getDeviceByUserIdAsync({ user_id: profile.sid }));
    }
  }, [dispatch, profile]);

  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column gap-2">
          <div className="mt-5">
            <div className="font-20px mb-0 fw-bold">Profile</div>
          </div>
          <div className="row d-flex ">
            <div className="d-flex flex-column flex-md-row col-12 gap-2">
              <div className="col">
                <div className="d-flex flex-column gap-2">
                  <InformationCard />
                  <GeneralInformationCard />
                </div>
              </div>
              <div className="col col-md-6 col-lg-5">
                <div className="d-flex flex-column gap-2">
                  <ContactCard />
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column gap-2">
                  <DeviceCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;

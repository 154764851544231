import { Button, CloseButton, Modal } from "react-bootstrap";
import '../styles.scss';

interface ActionModalProps {
  show: boolean;
  children: JSX.Element;
  title?: string;
  subTitle?: string;
  onHide?(): void;
  contentClassName?: string;
  size: "sm" | "lg" | "xl" | undefined;
  icon?: string;
  customButton?: JSX.Element;
  onSubmit?(): void;
}

const ActionModal = ({
  show,
  children,
  title,
  subTitle,
  onHide,
  size,
  contentClassName,
  icon,
  customButton,
  onSubmit,
}: ActionModalProps) => {
  return (
    <Modal
      show={show}
      onhid
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={contentClassName}
    >
      <Modal.Header className="py-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex align-items-center rounded-3 col-6 title-chart gap-2">
            {icon && (
              <div className="rounded-5 icon-chart-custom d-flex align-items-center justify-content-center">
                <img height={32} width={32} src={icon} alt="Icon" />
              </div>
            )}
            <div className="text-dark">
              <div className="d-flex flex-column">
                <span className="font-14px fw-bold text-dark whitespace-nowrap overflow-hidden text-truncate">{title}</span>
                <p className="my-0 font-10px text-dark whitespace-nowrap overflow-hidden text-truncate">
                  {subTitle}
                </p>
              </div>
            </div>
          </div>
        </Modal.Title>
        <CloseButton onClick={onHide} />
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }} className="new-model-body">
        {children}
      </Modal.Body>
      <Modal.Footer className="bg-grey-904">
        {customButton ? customButton :
          (<Button className="rounded-3 bg-blue-901 border-blue-901 d-flex align-items-center" onClick={onSubmit}>
            <p className="ms-1 my-0 font-12px progress-bar fw-bold">Save</p>
          </Button>)}

      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;

import { useEffect, useState } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import moment from "moment";
import medicalIcon from "@assets/icons/medical_icon.svg";
import AddAnotherButton from "./AddAnotherButton";
import {
  getConditionByWearerAsync,
  postConditionAsync,
  putConditionAsync,
} from "@app/redux/features/v2/medical-service/medicalServiceThunk";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { CreateUpdateConditionDto } from "@app/redux/features/v2/medical-service/models/condition-result-dto";
import { hasMissingFields } from "@app/utils/helper";
import MedicalHistoryBlock from "./MedicalHistoryBlock";

const MedicalHistoryModal = ({
  show,
  onHide,
  type,
  dataSelected,
}: {
  show: boolean;
  onHide: () => void;
  type: "add" | "update";
  dataSelected: any;
}) => {
  const dispatch = useAppDispatch();
  const { conditionRequest } = useAppSelector(
    (state: RootState) => state.medicalServiceV2
  );
  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const DEFAULT_DATA: CreateUpdateConditionDto[] = [
    {
      condition: "",
      diagnosis_date: "",
      wearer_sid: profile?.sid ?? "",
      sid: "",
      is_active: true,
    },
  ];

  const [medicalHistories, setMedicalHistories] = useState(DEFAULT_DATA);

  const fieldsToCheck: (keyof CreateUpdateConditionDto)[] = [
    "condition",
    "diagnosis_date",
  ];

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (dataSelected && type === "update") {
      setMedicalHistories([
        {
          condition: dataSelected.condition ?? "",
          diagnosis_date: dataSelected.diagnosis_date ?? "",
          wearer_sid: profile?.sid ?? "",
          sid: dataSelected.sid ?? "",
          is_active: dataSelected.is_active ?? true,
        },
      ]);
    } else {
      setMedicalHistories(DEFAULT_DATA);
    }
  }, [dataSelected, type, profile?.sid]);

  const onAddAnother = () => {
    setMedicalHistories([...medicalHistories, DEFAULT_DATA[0]]);
  };

  const onDelete = (index: number) => {
    setMedicalHistories(medicalHistories.filter((_, i) => i !== index));
  };

  const onUpdate = (
    index: number,
    updatedMedicalHistory: CreateUpdateConditionDto
  ) => {
    const updatedMedicalHistories = [...medicalHistories];
    updatedMedicalHistories[index] = updatedMedicalHistory;
    setMedicalHistories(updatedMedicalHistories);
  };

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setMedicalHistories(DEFAULT_DATA);
  };

  const onSubmit = async () => {
    try {
      if (type === "add") {
        const emptyContacts = medicalHistories.filter((item) =>
          hasMissingFields(item, fieldsToCheck)
        );

        if (emptyContacts.length > 0) {
          setIsError(true);
          return;
        }

        const requests = medicalHistories.map((history) =>
          dispatch(
            postConditionAsync({
              ...history,
              diagnosis_date: moment(history.diagnosis_date).format('YYYY-MM-DD'),
            })
          )
        );

        const responses = await Promise.all(requests);
        const success = responses.every((res) => res.payload);

        if (success) {
          onCloseModal();
          dispatch(getConditionByWearerAsync(conditionRequest));
        }
      } else {
        const value = medicalHistories[0];
        if (hasMissingFields(value, fieldsToCheck)) {
          setIsError(true);
          return;
        }

        const res = await dispatch(
          putConditionAsync({
            ...value,
            diagnosis_date: moment(value.diagnosis_date).format('YYYY-MM-DD'),
          })
        );

        if (res) {
          onCloseModal();
          dispatch(getConditionByWearerAsync(conditionRequest));
        }
      }
    } catch (error) {
      console.error("Error submitting condition:", error);
    }
  };

  return (
    <ActionModal
      subTitle="Customize the contents of your medical history"
      show={show}
      onHide={onCloseModal}
      title="Medical History"
      size="lg"
      icon={medicalIcon}
      onSubmit={onSubmit}
    >
      {type === "add" ? (
        <div className="row">
          {medicalHistories.map((_, index) => (
            <MedicalHistoryBlock
              isError={isError}
              key={index}
              medicalHistory={medicalHistories[index]}
              index={index}
              update={onUpdate}
              remove={onDelete}
            />
          ))}
          <AddAnotherButton onAddAnother={onAddAnother} />
        </div>
      ) : (
        <MedicalHistoryBlock
          isError={isError}
          key={0}
          medicalHistory={medicalHistories[0]}
          index={0}
          update={onUpdate}
          remove={onDelete}
        />
      )}
    </ActionModal>
  );
};

export default MedicalHistoryModal;

import iconBloodPressure from '@assets/icons/vital/ic_blood_pressure.svg';
import iconEcg from '@assets/icons/vital/ic_egc.svg';
import iconGlucose from '@assets/icons/vital/ic_glucose.svg';
import iconHeartRate from '@assets/icons/vital/ic_heart_rate.svg';
import iconOxygen from '@assets/icons/vital/ic_oxygen_saturation.svg';
import iconRespiratoryRate from '@assets/icons/vital/ic_respiratory_rate.svg';
import iconSleep from '@assets/icons/vital/ic_sleep.svg';
import iconStep from '@assets/icons/vital/ic_step.svg';
import iconStress from '@assets/icons/vital/ic_stress.svg';
import iconTemperature from '@assets/icons/vital/ic_temperature.svg';

export const VitalIcons = {
  BLOOD_PRESSURE: iconBloodPressure,
  ECG: iconEcg,
  GLUCOSE: iconGlucose,
  HEART_RATE: iconHeartRate,
  OXYGEN: iconOxygen,
  RESPIRATORY_RATE: iconRespiratoryRate,
  SLEEP: iconSleep,
  STEPS: iconStep,
  STRESS: iconStress,
  TEMPERATURE: iconTemperature,
};

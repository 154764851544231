import { createAsyncThunk } from "@reduxjs/toolkit";
import { GlucoseStatsDto } from "./models/glucose-stats-dto";
import { ApiError } from "@app/redux/common/models/api-error";
import HealthServiceApi from "@app/services/v2/health-service";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import {
  GlucoseGraphRequestDto,
  GraphRequestDto,
  OxygenGraphRequestDto,
  RespiratoryRateGraphRequestDto,
  TempGraphRequestDto,
  VitalsGraphRequestDto,
} from "./models/graph-request-dto";
import {
  BpGraphDto,
  EcgResponseDto,
  GlucoseGraphDto,
  HrGraphDto,
  OxygenGraphDto,
  RespiratoryRateGraphDto,
  SleepData,
  StepsSummaryDto,
  TempGraphDto,
  VitalsGraphDto,
  VitalsSummaryDto,
} from "./models/bp-graph-dto";
import { PageAndFilterRequestDto } from "@app/redux/common/models/page-and-filter-request-dto";
import { PaginatedResult } from "@redux/common/models/paginated-result-dto";

export const getGlucoseStatsAsync = createAsyncThunk<
  GlucoseStatsDto,
  string,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getGlucose",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getGlucoseStats(credentials);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getBpGraphAsync = createAsyncThunk<
  BpGraphDto,
  GraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getBpGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getBpGraphStats(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getOxygenGraphAsync = createAsyncThunk<
  OxygenGraphDto,
  OxygenGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getOxygenGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getOxygenGraphStats(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getHrGraphAsync = createAsyncThunk<
  HrGraphDto,
  GraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getHrGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getHrGraphStats(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getTempGraphAsync = createAsyncThunk<
  TempGraphDto,
  TempGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getTempGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getTempGraphStats(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getGlucoseGraphAsync = createAsyncThunk<
  GlucoseGraphDto,
  GlucoseGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getGlucoseGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getGlucoseGraph(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getRespiratoryRateGraphAsync = createAsyncThunk<
  RespiratoryRateGraphDto,
  RespiratoryRateGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getRespiratoryRateGraph",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getRespiratoryRateGraph(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getVitalsSummaryAsync = createAsyncThunk<
  VitalsSummaryDto[],
  string,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getVitalsSummary",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getVitalsSummary(credentials);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getStepsSummaryAsync = createAsyncThunk<
  StepsSummaryDto,
  string,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getStepsSummary",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getStepsSummary(credentials);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getVitalsGraphAsync = createAsyncThunk<
  { vital_type: string; data: VitalsGraphDto },
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getVitals",
  async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getVitals(
        credentials.userId,
        credentials.vital_type,
        credentials.graphType,
        credentials.startDate,
        credentials.endDate,
        credentials.timezone
      );
      return fulfillWithValue({
        vital_type: credentials.vital_type,
        data: response.data,
      });
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getsleepVitalsGraphAsync = createAsyncThunk<
  any,
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getsleepVitals",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getsleepVitals(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getsleepMobileVitalsGraphAsync = createAsyncThunk<
  string,
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getsleepMobileVitals",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getsleepMobileVitals(
        credentials.userId,
        credentials.vital_type
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getecgVitalsGraphAsync = createAsyncThunk<
  EcgResponseDto,
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getEcgVitals",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getEcgVitals(credentials.userId);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getEcgAsync = createAsyncThunk<
  PaginatedResult<EcgResponseDto>,
  PageAndFilterRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getEcg",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getEcg(credentials);
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getStressVitalsGraphAsync = createAsyncThunk<
  any,
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getStressVitals",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getStressVitals(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

export const getStepsVitalsGraphAsync = createAsyncThunk<
  any,
  VitalsGraphRequestDto,
  { rejectValue: ApiError | undefined }
>(
  "healthService/getStepsVitals",
  async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await HealthServiceApi.getStepsVitals(
        credentials.userId,
        credentials.startDate,
        credentials.endDate,
        credentials.graphType
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      const apiError = getErrorPayload(error);
      return rejectWithValue(apiError);
    }
  }
);

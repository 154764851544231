import olockIcon from "@assets/icons/olock-icon.svg";

const HealthyColumn = () => {
  return (
    <div className="overflow-hidden border-bottom">
      <div className="d-flex mt-3">
        <div className="article-image w-25">
          <img
            src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
            className="img-fluid"
            alt="article image"
          />
        </div>
        <div className="article-content w-75 font-12px ms-2">
          <div className="warp-text-1-line fw-medium">
            Copewith abnormal blood sugar and...
          </div>
          <div className="d-flex">
            <img
              src={olockIcon}
              className="img-fluid mt-1 me-1"
              alt="icon"
              style={{ width: "9px", height: "9px" }}
            />
            <p className="text-grey-901">5 minutes read</p>
          </div>
        </div>
      </div>
      <div>
        <div className="article-tags fw-normal">
          <span className="tag rounded-pill bg-grey-250">Glucose</span>
          <span className="tag rounded-pill font-12px">Article tag</span>
        </div>
        <p className="warp-text-2-line font-12px text-grey-901">
          Learn how to dealwith blood sugar abnormalities, including
          hypeglyceinia, hypoglycemia, and fluctuating...
        </p>
      </div>
    </div>
  );
};

export default HealthyColumn;

interface StatusBadgeType {
    status: string;
}

export default function StatusBadge({
    status
}: StatusBadgeType){
    return (
        <div>
            {status === "High" ? (
                <div
                    className="text-capitalize rounded-2 font-12px text-high fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                >
                    <span style={{ width: 8, height: 8, background: '#FFAA00', borderRadius: '1000px' }}></span>
                    <span className="fw-medium">{status}</span>
                </div>
            ) : null}
            {status === "Normal" ? (
                <div
                    className="text-capitalize rounded-2 font-12px text-normal fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                >
                    <span style={{ width: 8, height: 8, background: '#00bd91', borderRadius: '1000px' }}></span>
                    <span className="fw-medium">{status}</span>
                </div>
            ) : null}
            {status === "Low" ? (
                <div
                    className="text-capitalize rounded-2 font-12px text-low fw-medium d-flex flex-row align-items-center gap-1"
                    style={{ height: "17px" }}
                >
                    <span style={{ width: 8, height: 8, background: '#3f72f3', borderRadius: '1000px' }}></span>
                    <span className="fw-medium">{status}</span>
                </div>
            ) : null}
        </div>
    )
}
import React from "react";
import summaryIcon from "@assets/icons/summary-icon.svg";
import AnalysisChart from "./AnalysisChart";
import { VitalType } from "@app/common/enums/VitalTypes";
import { getTimeRange } from "@app/utils/time";
import { RootState } from "@app/redux/store";
import { useAppSelector } from "@app/redux/store/useStore";

interface ChartCardProps {
  analysisType: VitalType;
  period: string;
}

const AnalysisCard: React.FC<ChartCardProps> = ({
  period,
  analysisType,
}) => {
  const vitalsGraphDto = useAppSelector(
    (state: RootState) => state.healthService.vitalsGraphDto[analysisType]
  );

  return (
    <div className="bg-white p-3 rounded-3 h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="">
            <img className="img-fluid" src={summaryIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Analysis</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>
      <AnalysisChart
        data={vitalsGraphDto}
        unit={VitalType.getUnit(analysisType)}
        period={period}
      />
    </div>
  );
};

export default AnalysisCard;

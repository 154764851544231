interface BatteryIndicatorProps {
  level: number;
}

const BatteryIndicator = ({ level }: BatteryIndicatorProps) => {
  const batteryBars = [20, 40, 60, 80, 100];
  const barColor = level >= 80 ? "bg-normal" : level >= 40 ? "bg-high" : "bg-very-high";

  return (
    <div className="d-flex align-items-center gap-1">
      <div className="font-12px fw-normal text-dark">{level}%</div>
      <div className="d-flex align-items-center" style={{ gap: "2.5px" }}>
        {batteryBars.map((bar, index) => (
          <div
            key={index}
            className={`rounded-4 ${level >= bar ? barColor : "bg-grey-250"}`}
            style={{ height: "12px", width: "3.6px" }}
          />
        ))}
        <div
          className={`rounded-4 ${level === 100 ? barColor : "bg-grey-250"}`}
          style={{ height: "4.8px", width: "2.4px" }}
        />
      </div>
    </div>
  );
};

export default BatteryIndicator;

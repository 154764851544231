export const defaultData = [
  {
    "id": 1,
    "healthVitals": "Glucose",
    "date": "02/01/2024",
    "time": "10:00 AM",
    "low": "3.0",
    "average": "10.0",
    "high": "15.0",
    "status": "Low"
  },
  {
    "id": 2,
    "healthVitals": "Blood Pressure",
    "date": "05/03/2024",
    "time": "03:00 PM",
    "low": "70",
    "average": "120",
    "high": "140",
    "status": "Normal"
  },
  {
    "id": 3,
    "healthVitals": "Heart Rate",
    "date": "12/06/2024",
    "time": "08:00 AM",
    "low": "60",
    "average": "80",
    "high": "100",
    "status": "High"
  },
  {
    "id": 4,
    "healthVitals": "Weight",
    "date": "28/02/2024",
    "time": "11:00 AM",
    "low": "50",
    "average": "70",
    "high": "90",
    "status": "Normal"
  },
  {
    "id": 5,
    "healthVitals": "Cholesterol",
    "date": "15/09/2024",
    "time": "04:00 PM",
    "low": "100",
    "average": "150",
    "high": "200",
    "status": "High"
  },
  {
    "id": 6,
    "healthVitals": "Body Temperature",
    "date": "10/05/2024",
    "time": "09:00 AM",
    "low": "36.5",
    "average": "37.0",
    "high": "37.5",
    "status": "Normal"
  },
  {
    "id": 7,
    "healthVitals": "Blood Oxygen",
    "date": "22/08/2024",
    "time": "02:00 PM",
    "low": "95",
    "average": "98",
    "high": "100",
    "status": "Normal"
  },
  {
    "id": 8,
    "healthVitals": "Sleep Hours",
    "date": "05/11/2024",
    "time": "07:00 AM",
    "low": "7",
    "average": "8",
    "high": "9",
    "status": "Normal"
  },
  {
    "id": 9,
    "healthVitals": "Steps",
    "date": "18/04/2024",
    "time": "10:00 AM",
    "low": "5000",
    "average": "8000",
    "high": "10000",
    "status": "Low"
  },
  {
    "id": 10,
    "healthVitals": "Water Intake",
    "date": "01/07/2024",
    "time": "03:00 PM",
    "low": "1.5",
    "average": "2.0",
    "high": "2.5",
    "status": "Normal"
  },
  {
    "id": 11,
    "healthVitals": "Mood",
    "date": "15/02/2024",
    "time": "08:00 AM",
    "low": "1",
    "average": "5",
    "high": "10",
    "status": "Normal"
  },
  {
    "id": 12,
    "healthVitals": "Exercise Minutes",
    "date": "08/05/2024",
    "time": "01:00 PM",
    "low": "30",
    "average": "60",
    "high": "90",
    "status": "High"
  },
  {
    "id": 13,
    "healthVitals": "Calories Burned",
    "date": "21/08/2024",
    "time": "06:00 AM",
    "low": "200",
    "average": "500",
    "high": "800",
    "status": "Normal"
  },
  {
    "id": 14,
    "healthVitals": "Stress Level",
    "date": "04/04/2024",
    "time": "09:00 AM",
    "low": "1",
    "average": "5",
    "high": "10",
    "status": "High"
  },
  {
    "id": 15,
    "healthVitals": "Meditation Minutes",
    "date": "17/07/2024",
    "time": "02:00 PM",
    "low": "10",
    "average": "20",
    "high": "30",
    "status": "High"
  },
  {
    "id": 16,
    "healthVitals": "Glucose",
    "date": "20/01/2024",
    "time": "07:00 AM",
    "low": "4.5",
    "average": "9.5",
    "high": "14.5",
    "status": "Normal"
  },
  {
    "id": 17,
    "healthVitals": "Blood Pressure",
    "date": "13/03/2024",
    "time": "12:00 PM",
    "low": "85",
    "average": "130",
    "high": "150",
    "status": "High"
  },
  {
    "id": 18,
    "healthVitals": "Heart Rate",
    "date": "26/06/2024",
    "time": "05:00 AM",
    "low": "75",
    "average": "90",
    "high": "110",
    "status": "Normal"
  },
  {
    "id": 19,
    "healthVitals": "Weight",
    "date": "09/02/2024",
    "time": "08:00 AM",
    "low": "65",
    "average": "80",
    "high": "100",
    "status": "High"
  },
  {
    "id": 20,
    "healthVitals": "Cholesterol",
    "date": "22/05/2024",
    "time": "01:00 PM",
    "low": "130",
    "average": "160",
    "high": "220",
    "status": "High"
  },
  {
    "id": 21,
    "healthVitals": "Body Temperature",
    "date": "25/04/2024",
    "time": "07:00 AM",
    "low": "36.0",
    "average": "36.8",
    "high": "37.2",
    "status": "Normal"
  },
  {
    "id": 22,
    "healthVitals": "Blood Oxygen",
    "date": "18/07/2024",
    "time": "12:00 PM",
    "low": "92",
    "average": "97",
    "high": "99",
    "status": "Normal"
  },
  {
    "id": 23,
    "healthVitals": "Sleep Hours",
    "date": "31/10/2024",
    "time": "04:00 AM",
    "low": "6",
    "average": "7.5",
    "high": "8.5",
    "status": "Low"
  },
  {
    "id": 24,
    "healthVitals": "Steps",
    "date": "14/03/2024",
    "time": "06:00 AM",
    "low": "6000",
    "average": "9000",
    "high": "12000",
    "status": "Normal"
  },
  {
    "id": 25,
    "healthVitals": "Water Intake",
    "date": "27/06/2024",
    "time": "11:00 AM",
    "low": "1.8",
    "average": "2.2",
    "high": "2.8",
    "status": "Normal"
  },
  {
    "id": 26,
    "healthVitals": "Mood",
    "date": "03/01/2024",
    "time": "06:00 AM",
    "low": "3",
    "average": "7",
    "high": "9",
    "status": "Normal"
  },
  {
    "id": 27,
    "healthVitals": "Exercise Minutes",
    "date": "26/03/2024",
    "time": "11:00 AM",
    "low": "45",
    "average": "75",
    "high": "105",
    "status": "High"
  },
  {
    "id": 28,
    "healthVitals": "Calories Burned",
    "date": "08/07/2024",
    "time": "03:00 AM",
    "low": "300",
    "average": "600",
    "high": "900",
    "status": "Normal"
  },
  {
    "id": 29,
    "healthVitals": "Stress Level",
    "date": "21/02/2024",
    "time": "05:00 AM",
    "low": "2",
    "average": "4",
    "high": "8",
    "status": "Normal"
  },
  {
    "id": 30,
    "healthVitals": "Meditation Minutes",
    "date": "04/05/2024",
    "time": "10:00 AM",
    "low": "15",
    "average": "25",
    "high": "35",
    "status": "High"
  }
]

import { Link } from "react-router-dom";
import backIcon from "@assets/icons/login/back_icon.svg";
import { VitalType } from "@app/common/enums/VitalTypes";

const ClientDetailBreadcrumb = ({ viewName }: { viewName: string }) => {
  return (
    <div className="mt-5 mb-2">
      <nav aria-label="breadcrumb">
        <Link
          to={"/portal-new/clients"}
          className="d-flex flex-row align-items-center font-12px fw-medium text-decoration-none text-black mb-2"
        >
          <img
            height={14}
            width={14}
            className="ms-0 me-2 cursor-pointer"
            src={backIcon}
            alt="Note"
          />
          <p className="my-0 font-12px fw-medium">Back</p>
        </Link>
        <p className="font-20px mb-0 fw-bold"> {viewName}</p>
        {/* <ol className="breadcrumb m-0">
            <li
              className="breadcrumb-item active font-14px"
              aria-current="page"
            >
              Client
            </li>
            <li className="breadcrumb-item font-14px ">{viewName}</li>
          </ol> */}
      </nav>
    </div>
  );
};

export default ClientDetailBreadcrumb;

import React, { useEffect, useState } from "react";
import ActionModal from "@app/components/Modal/ActionModal";
import callIcon from "@assets/icons/call_icon.svg";
import { EmergencyProps } from ".";
import ContactBlock from "./ContactBlock";
import AddContactButton from "./AddContactButton";
import { setContactList } from "@app/redux/features/v2/contact-service/contactServiceSlice";
import { RootState } from "@app/redux/store";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { hasMissingFields } from "@app/utils/helper";
import { Button } from "react-bootstrap";

export interface ActionModalProp {
  type: "add" | "update";
  show: boolean;
}

const ContactActionModal = ({
  show,
  onHide,
  type,
  contact,
}: ActionModalProp & { contact?: EmergencyProps; onHide: () => void }) => {
  const dispatch = useAppDispatch();
  const fieldsToCheck: (keyof EmergencyProps)[] = [
    "first_name",
    "mobile_number",
    "relationship",
    "email_address",
    "mobile_prefix",
    "last_name",
  ];

  const { user_profile: profile } = useAppSelector(
    (state: RootState) => state.userProfileServiceV2
  );

  const DEFAULT_CONTACT: EmergencyProps = {
    wearer_sid: profile?.sid ?? "",
    sid: "",
    user_sid: profile?.sid ?? "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    mobile_prefix: "",
    is_sos: false,
    relationship: "",
    avatar: "",
    email_address: "",
  };
  const [contacts, setContacts] = useState<EmergencyProps[]>(
    contact ? [] : [DEFAULT_CONTACT]
  );
  const [isError, setIsError] = useState(false);
  const { contactList } = useAppSelector(
    (state: RootState) => state.contactServiceV2
  );

  useEffect(() => {
    if (contact) {
      setContacts([contact]);
    }
  }, [contact]);

  const addContact = () => setContacts([...contacts, DEFAULT_CONTACT]);
  const removeContact = (index: number) =>
    setContacts(contacts.filter((_, i) => i !== index));

  const updateContact = (index: number, updatedContact: EmergencyProps) => {
    const updatedContacts = [...contacts];
    updatedContacts[index] = updatedContact;
    setContacts(updatedContacts);
  };

  const onCloseModal = () => {
    onHide();
    setIsError(false);
    setContacts([contact || DEFAULT_CONTACT]);
  };

  const onSubmit = () => {
    let updatedContactList = [...contactList];
    if (type === "add") {
      // The design hard to using library for this case so need to manually check
      // Check for empty fields and invalid email
      const emptyContacts = contacts.filter(
        (contact) =>
          hasMissingFields(contact, fieldsToCheck) ||
          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact?.email_address)
      );
      if (emptyContacts.length > 0) {
        setIsError(true);
        return;
      }

      // Check for duplicate contacts with the same mobile number
      updatedContactList = contacts.reduce(
        (accumulator, contact) => {
          const existingIndex = accumulator.findIndex(
            (existingContact) =>
              existingContact.mobile_number === contact.mobile_number &&
              existingContact.mobile_prefix === contact.mobile_prefix
          );

          if (existingIndex !== -1) {
            const existingContact = accumulator[existingIndex];
            accumulator[existingIndex] = {
              ...contact,
              sid: existingContact.sid,
            };
          } else {
            accumulator.push(contact);
          }

          return accumulator;
        },
        [...contactList]
      );
    } else if (type === "update" && contacts.length > 0) {
      //Find the index of the contact to be updated
      const contactIndex = updatedContactList.findIndex(
        (c) => c.sid === contacts[0].sid
      );
      if (contactIndex !== -1) {
        updatedContactList[contactIndex] = contacts[0];
      }
      dispatch(setContactList(updatedContactList));

      // Check for empty fields and invalid email
      if (
        hasMissingFields(updatedContactList[contactIndex], fieldsToCheck) ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
          updatedContactList[contactIndex]?.email_address
        )
      ) {
        setIsError(true);
        return;
      }
    }

    // Save the updated contact list
    setIsError(false);
    onCloseModal();
    dispatch(setContactList(updatedContactList));
  };

  return (
    <ActionModal
      show={show}
      onHide={onCloseModal}
      subTitle="Edit your emergency contact"
      title="Emergency Contact"
      size="lg"
      icon={callIcon}
      onSubmit={onSubmit}
      customButton={
        <>
          <Button
            className="rounded-3 bg-blue-901 border-blue-901 d-flex align-items-center"
            onClick={onSubmit}
          >
            <p className="ms-1 my-0 font-12px progress-bar fw-bold">
              {type === "add" ? "Add" : "Update"}
            </p>
          </Button>
        </>
      }
    >
      {type === "add" ? (
        <div className="row">
          {contacts.map((contact, index) => (
            <ContactBlock
              isError={isError}
              key={index}
              type={type}
              contact={contact}
              index={index}
              updateContact={updateContact}
              removeContact={removeContact}
            />
          ))}
          <AddContactButton addContact={addContact} />
        </div>
      ) : (
        <ContactBlock
          isError={isError}
          key={0}
          type={type}
          contact={contacts[0]}
          index={0}
          updateContact={updateContact}
          removeContact={removeContact}
        />
      )}
    </ActionModal>
  );
};

export default ContactActionModal;

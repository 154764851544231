export enum SOSType {
  SOS = "SOS",
  MEDIUM = "Medium",
  HIGH = "High",
}

export enum SOSStatus {
  OPEN = "Open",
  CLOSED = "Closed",
}

export namespace SOSDetailType {
  export function getColor(sosType: string) {
    switch (sosType) {
      case SOSType.SOS:
        return "very-high";
      case SOSType.MEDIUM:
        return "high";
      default:
        return "default-status";
    }
  }

  export function getStatusColor(sosStatus: SOSStatus) {
    switch (sosStatus) {
      case SOSStatus.CLOSED:
        return "grey-907";
      case SOSStatus.OPEN:
        return "blue-904";
      default:
        return "";
    }
  }
}

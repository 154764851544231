import HealthyColumn from "@components/HealthColumn/healthColumn";
import { useCallback, useEffect, useState } from "react";
import HistoryRecordCard from "@components/HistoryRecordCard";
import { VitalType } from "@app/common/enums/VitalTypes"; 
import SummaryCard from "./widgets/Sleep.SummaryCard";
import AnalysisCard from "./widgets/Sleep.AnalysisCard";
import BreadcrumbNavigation from "@app/components/Breadcrumb/BreadcrumbNavigation";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { getsleepVitalsGraphAsync } from "@app/redux/features/v2/health-service/healthServiceThunk";
import moment from "moment";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

type SleepDataDto = {
  summary: {
    data: {
      timestamp: string
      duration: number; 
      category: string;
    }[];
  };
};

type SleepTableRow = {
  id: number;
  date: string;
  time: string;
  healthVitals: string;
  duration: number;
  stage: string;
};

type DailyAnalysisData = {
  stages: {
    name: string;
    value: number;
    timestamp: string;
  }[];
  startTime: string;
  endTime: string;
};

type Stage = {
  name: string;
  value: number;
};

type SummaryDataType = {
  date: string | number;
  startTime: number | null;
  stages: Stage[];
};

const Sleep = () => {
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<SleepTableRow[]>([]);

  const [selectedPeriod, setSelectedPeriod] = useState(TimePeriodHelper.getTimePeriodJson(TimePeriod.day));
  const handleFilterClick = (value: string) => {
    setSelectedPeriod(value);
  };

  const { user_profile } = useAppSelector((state: RootState) => state.userProfileServiceV2);  
  const sleepDataDto = useAppSelector((state: RootState) => state.healthServiceV2.sleepDataDto);

  const calculateDateRange = useCallback((period: string): DateRange => {
    const current_moment = moment().endOf('day');
    let start: Date;
    let end: Date
  
    switch (period) {
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        start = moment().startOf('day').toDate();
        end = current_moment.clone().toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
        start = current_moment.clone().startOf('isoWeek').startOf('day').toDate();
        end = current_moment.clone().endOf('isoWeek').startOf('day').toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
        start = current_moment.clone().startOf('month').startOf('day').toDate();
        end = current_moment.clone().endOf('month').endOf('day').toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
        start = current_moment.clone().startOf('month').subtract(5, 'months').startOf('day').toDate();
        end = current_moment.clone().endOf('month').endOf('day').toDate();
        break;
      case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
        start = current_moment.clone().startOf('year').startOf('day').toDate();
        end = current_moment.clone().endOf('year').endOf('day').toDate();
        break;
      default:
        start = current_moment.clone().startOf('day').toDate();
        end = current_moment.toDate();
    }
  
    return { startDate: start, endDate: end };
  }, []);

  useEffect(()=>{
    if(user_profile && selectedPeriod){
      const dateRange = calculateDateRange(selectedPeriod);
      const defaultRequest = {
        userId: user_profile?.sid ?? "",
        vital_type: 'SLEEPS',
        graphType: selectedPeriod,
        startDate: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch(getsleepVitalsGraphAsync(defaultRequest));
    }
  }, [dispatch, user_profile, selectedPeriod])


  const[summaryData, setSummaryData] = useState<SummaryDataType[]>([])

  const [analysisData, setAnalysisData] = useState<{
    stages: { name: string, value: number}[];
    startTime: string;
    endTime: string;
  }>({
    endTime: "",
    startTime: "",
    stages: [
      { name: "", value: 0 },
    ]
  });
  
  const convertSleepDataToTable = (sleepDataDto: SleepDataDto[]): SleepTableRow[] => {
    if (!Array.isArray(sleepDataDto) || sleepDataDto.length === 0) {
      return [];
    }
  
    return sleepDataDto.flatMap((item, index) => {
      if (!item.summary || !item.summary.data || item.summary.data.length === 0) {
        return [];
      }
  
      const { summary } = item;
  
      return summary.data.map((dataItem, dataIndex) => {
        const dateTime = moment(dataItem.timestamp);
        return {
          id: index * 100 + dataIndex + 1,
          date: dateTime.format("YYYY-MM-DD") ?? "",
          time: dateTime.format("hh:mm A") ?? "",
          healthVitals: "Sleep",
          duration: dataItem.duration,
          stage: dataItem.category,
        };
      });
    });
  };
  
  const convertToDailyAnalysisData = (sleepDataDto: SleepDataDto[]): DailyAnalysisData => {
    if (!Array.isArray(sleepDataDto) || sleepDataDto.length === 0) {
      return {
        stages: [],
        startTime: "",
        endTime: "",
      };
    }
  
    let startTime: string | null = null;
    let endTime: string | null = null;
  
    const stages: { name: string; value: number; timestamp: string }[] = [];
  
    sleepDataDto.forEach((item) => {
      if (!item.summary || !item.summary.data || item.summary.data.length === 0) {
        return;
      }
  
      const { summary } = item;
  
      if (!startTime || new Date(summary.data[0].timestamp).getUTCHours() < new Date(startTime).getUTCHours()) {
        startTime = summary.data[0].timestamp;
      }
  
      const lastItem = summary.data[summary.data.length - 1];
      if (!endTime || new Date(lastItem.timestamp).getUTCHours() > new Date(endTime).getUTCHours()) {
        endTime = lastItem.timestamp;
      }
  
      summary.data.forEach((phase) => {
        stages.push({
          name: phase.category,
          value: phase.duration,
          timestamp: phase.timestamp,
        });
      });
    });
  
    const formattedStartTime = startTime
      ? new Date(startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "";
  
    const formattedEndTime = endTime
      ? new Date(endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "";
  
    return {
      stages,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    };
  };
  
  const convertDataToChartFormat = (sleepDataDto: SleepDataDto[], period: string) => {
    const result: SummaryDataType[] = [];
    const currentDate = new Date();
  
    const getAllLabelsForPeriod = () => {
      switch (period) {
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
          return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
          return Array.from({ length: 31 }, (_, i) => i + 1);
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
          return Array.from({ length: 6 }, (_, i) => {
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i);
            return date.toLocaleString("en-US", { month: "short" });
          }).reverse();
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
          return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        default:
          return [];
      }
    };
  
    const getLabelForPeriod = (timestamp: string) => {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return;
      }
      switch (period) {
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.day):
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.week):
          return date.toLocaleString("en-US", { weekday: "short" });
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.month):
          return date.getDate();
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.halfYear):
          return date.toLocaleString("en-US", { month: "short" });
        case TimePeriodHelper.getTimePeriodJson(TimePeriod.year):
          return date.toLocaleString("en-US", { month: "short" });
        default:
          return "";
      }
    };
  
    const allLabels = getAllLabelsForPeriod();
    allLabels.forEach((label, index) => {
      result.push({
        date: label,
        startTime: null,
        stages: []
      });
    });

    const convertStartTimeToNumber = (timestamp: string) => {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
          return null;
      }
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const totalMinutesFromMidnight = hours * 60 + minutes;
      let adjustedMinutes = totalMinutesFromMidnight - 1320;
      if (adjustedMinutes < 0) {
          adjustedMinutes += 1440;
      }
      return adjustedMinutes > 720 ? 720 : adjustedMinutes;
    };
  
    sleepDataDto.forEach((item) => {
      if (!item.summary || !item.summary.data || item.summary.data.length === 0) return;
      const { summary } = item;
  
      const label = getLabelForPeriod(summary.data[0].timestamp);
      let entry = result.find((r) => r.date === label);
  
      if (entry) {
        entry.startTime = convertStartTimeToNumber(summary.data[0].timestamp);
        entry.stages = summary.data.map((phase: { category: string, duration: number }) => ({
          name: phase.category,
          value: phase.duration
        }));
      }
    });
  
    return result;
  };
  
  useEffect(() => {
    if (sleepDataDto && selectedPeriod) {
      const mappedSummaryData = convertDataToChartFormat(sleepDataDto, selectedPeriod);
      const historyRecord = convertSleepDataToTable(sleepDataDto);
      const dailyAnalysisData = convertToDailyAnalysisData(sleepDataDto);
  
      setTableData(historyRecord);
      setSummaryData(mappedSummaryData);
      setAnalysisData(dailyAnalysisData);
    }
  }, [sleepDataDto, selectedPeriod]);

  return (
    <div className="container health_insight row">
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-3 mt-3">
          <BreadcrumbNavigation
            analysisName={VitalType.getAnalysisName(VitalType.SLEEP)}
            handleFilterClick={handleFilterClick}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="row g-3">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <SummaryCard
              dailyAnalysisData={analysisData}
              period={selectedPeriod}
            />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <AnalysisCard
              summaryData={summaryData}
              period={selectedPeriod}
            />
          </div>
          <div className="col-12">
            <HistoryRecordCard 
              type={VitalType.SLEEP} 
              period={selectedPeriod}  
              data={tableData ?? []}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <div className="health-column">
          <div className="title-health-column">Health Column</div>
          {[1, 2, 3, 4, 5].map((index) => (
            <HealthyColumn key={index.toString()} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sleep;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginCredentialsDto } from "./models/login-credentials-dto";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import SecurityServiceApi from "@app/services/security-service-api";
import { RegisterClientCredentialsDto, RegisterClientResultDto, RegisterCredentialsDto, RegisterUserCredentialsDto } from "./models/register-credentials-dto";
import LocalStorageService from "@app/services/local-storage-service";
import { addErrorNotification, addSuccessNotification } from "../common/notificationSlice";
import { ForgotPasswordDto, ResetPasswordDto } from "./models/forgot-password-dto";
import { InviteClientDto } from "./models/invite-client-dto";
import { ResendConfirmationEmailDto } from "./models/resend-confirmation-email-dto";

export const loginAsync = createAsyncThunk<any, LoginCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/login",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.login(loginCredentials);
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const registerAsync = createAsyncThunk<string, RegisterCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/register",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.register(registerCredentials);
            dispatch(addSuccessNotification('register successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)

export const confirmEmailAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "securityService/confirmEmail",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.confirmEmail(registerCredentials);
            dispatch(addSuccessNotification('email confirmation successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)


export const registerClientAsync = createAsyncThunk<RegisterClientResultDto, RegisterClientCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/registerClient",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.registerClient(registerCredentials);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)

export const registerUserAsync = createAsyncThunk<RegisterClientResultDto, RegisterUserCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/registerUser",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.registerUser(registerCredentials);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)


export const forgotPasswordAsync = createAsyncThunk<any, ForgotPasswordDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/forgotPassword",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.forgotPassword(loginCredentials);
            dispatch(addSuccessNotification('Email send successfully'))
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const resetPasswordAsync = createAsyncThunk<any, ResetPasswordDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resetPassword",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resetPassword(loginCredentials);
            dispatch(addSuccessNotification('Password reset successfully'))
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const checkUserExistsAsync = createAsyncThunk<any, string,
    { rejectValue: ApiError | undefined }
>(
    "securityService/checkUserExists",
    async (email, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.checkUserExists(email);
            // dispatch(addSuccessNotification('Email send successfully'))
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const resendConfirmationEmailAsync = createAsyncThunk<any, ResendConfirmationEmailDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resendConfirmationEmail",
    async (inviteClientDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resendConfirmationEmail(inviteClientDto);
            dispatch(addSuccessNotification('register successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)
const AUTH_TOKEN = 'AUTH_TOKEN';
const USER_ROLE = 'USER_ROLE';
const ORG_REGISTER = 'ORG_REGISTER';
const USERNAME_VERIFIED = 'USERNAME_VERIFIED';
const USERNAME_RESETPASSWORD = 'USERNAME_RESETPASSWORD';


const setAccessToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
}
const setUserRole = (role: string) => {
    localStorage.setItem(USER_ROLE, role);
}
const setOrg = (org_id: string) => {
    localStorage.setItem(ORG_REGISTER, org_id);
}

const setUsernameVerified = (username: string) => {
    localStorage.setItem(USERNAME_VERIFIED, username);
}

const setUsernameResetPassword = (username: string) => {
    localStorage.setItem(USERNAME_RESETPASSWORD, username);
}

const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}
const getUserRole = () => {
    return localStorage.getItem(USER_ROLE);
}
const getOrg = () => {
    return localStorage.getItem(ORG_REGISTER)
}

const getUsernameVerified = () => {
    return localStorage.getItem(USERNAME_VERIFIED)
}

const getUsernameResetPassword = () => {
    return localStorage.getItem(USERNAME_RESETPASSWORD)
}

const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

const removeOrg = () => {
    localStorage.removeItem(ORG_REGISTER);
}

const removeUsernameVerified = () => {
    localStorage.removeItem(USERNAME_VERIFIED);
}

const removeUsernameResetPassword = () => {
    localStorage.removeItem(USERNAME_RESETPASSWORD);
}

const LocalStorageService = {
    setAccessToken,
    setOrg,
    getAccessToken,
    getOrg,
    removeAccessToken,
    removeOrg,
    setUserRole,
    getUserRole,
    setUsernameVerified,
    getUsernameVerified,
    removeUsernameVerified,
    setUsernameResetPassword,
    getUsernameResetPassword,
    removeUsernameResetPassword
}

export default LocalStorageService;
import Table from "../Table";
import { formatDuration } from "@app/utils/timeUtils";

interface TableProps {
  period: string;
  data?: any;
}

const SleepTable: React.FC<TableProps> = ({ period, data }) => {
  const columns = [
    {
      name: "Health vitals",
      selector: (row: any) => row.healthVitals,
    },
    {
      name: "Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row: any) => row.time,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row: any) => (
        <span>{formatDuration(row.duration)}</span>
      ),
      sortable: true,
    },
    {
      name: "Stage",
      selector: (row: any) => row.stage,
      cell: (row: any) => {
        let color;
        switch (row.stage) {
          case "Light":
            color = "very-low";
            break;
          case "REM":
            color = "normal";
            break;
          case "Deep":
            color = "low";
            break;
          case "Awake":
            color = "high";
            break;
          default:
            color = "normal";
        }
        return (
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="fw-medium"
          >
            <span className={`fw-medium fs-3 me-2 text-${color}`}>
              &#x2022;
            </span>
            <span className={`fw-medium text-${color}`}>{row.stage}</span>
          </span>
        );
      }
    }
  ];

  return (
    <div className="row">
      <Table columns={columns} data={data} />
    </div>
  );
}

export default SleepTable;

import {
  CreateContactDto,
  UserContactImageDto,
  UserProfileImageResultDto,
  UserUploadImageResultDto,
} from "@redux/features/v2/contact-service/models/create-contact-dto";
import { config } from "@services/config";
import { createOrganisationPortalApi } from "@services/organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);

const AddContacts = (contacts: CreateContactDto[]) => {
  return organisationPortalApi.post<string[]>("/api/v2/contact", contacts);
};

const getContacts = async (user_sid: string) => {
  return await organisationPortalApi
    .get<CreateContactDto[]>(`/api/v2/contact/by-wearer?wearer_sid=${user_sid}`)
    .then((response) => {
      const contacts = response.data.map(async (contact) => {
        const avatarUrl = await organisationPortalApi
          .get<UserProfileImageResultDto>(
            `/api/v2/contact/avatar?contact_sid=${contact.sid}`
          )
          .then((response) => response.data?.avatar)
          .catch(() => "");

        return {
          ...contact,
          avatar: avatarUrl,
        };
      });
      return Promise.all(contacts);
    })
    .catch(() => []);
};

const updateContacts = (contacts: CreateContactDto[]) => {
  return organisationPortalApi.put<string[]>("/api/v2/contact", contacts);
};
const deleteContacts = (contact_id: string) => {
  return organisationPortalApi.delete<string>(
    `/api/v2/contact?contact_id=${contact_id}`
  );
};

const uploadContactImage = (userContactImage: UserContactImageDto) => {
  return organisationPortalApi.patch<UserUploadImageResultDto>(
    "/api/v2/contact/avatar",
    userContactImage
  );
};

const getUserContactImage = (contact_sid: string) => {
  return organisationPortalApi.get<UserProfileImageResultDto>(
    `/api/v2/contact/avatar?contact_sid=${contact_sid}`
  );
};
const ContactServiceApi = {
  AddContacts,
  getContacts,
  updateContacts,
  deleteContacts,
  uploadContactImage,
  getUserContactImage,
};

export default ContactServiceApi;

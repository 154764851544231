import React, { ReactNode } from "react";
import moment from "moment";
import { VitalType } from "@app/common/enums/VitalTypes";
import { TimePeriod, TimePeriodHelper } from "@app/common/enums/TimePeriod";
import { FluctuationTypes } from "@app/common/enums/FluctuationTypes";
interface CardContainerProps {
  vitalType: VitalType;
  currentData: number | undefined;
  currentDataSecondValue?: number | undefined;
  unit: string;
  date: string;
  dataHistory: DataHistory[];
  customBuildContent?: (() => ReactNode) | undefined;
  customBuildElementType?: (() => ReactNode) | undefined;
}

export interface DataHistory {
  type: TimePeriod;
  value?: number;
  secondValue?: number;
  increase: FluctuationTypes;
}

export const CardContainer = ({
  vitalType,
  currentData,
  unit,
  date,
  dataHistory,
  currentDataSecondValue,
  customBuildContent,
  customBuildElementType,
}: CardContainerProps) => {
  const buildDot = ({ datValue }: { datValue: number }) => {
    if (datValue === 0) {
      return null;
    }
    const statusText = VitalType.getDataTextStatus(vitalType, datValue);
    const statusColor = VitalType.getDataColorStatus(vitalType, datValue);

    return (
      <div className="d-flex flex-row align-items-center">
        <div
          className={`bg-${statusColor} rounded-circle me-2`}
          style={{
            width: "8px",
            height: "8px",
            display: "inline-block",
          }}
        ></div>
        <span
          className={`font-12px fw-medium text-${statusColor} whitespace-nowrap overflow-hidden text-truncate`}
        >
          {statusText}
        </span>
      </div>
    );
  };

  const buildContent = () => {
    return (
      <>
        <div className="d-flex flex-row align-items-end">
          <span className="font-28px fw-medium text-black">
            {currentDataSecondValue
              ? currentDataSecondValue !== undefined &&
                currentData !== undefined
                ? `${valueData}/${secondValueData}`
                : "N/A"
              : `${valueData}`}
          </span>
          <span className="font-12px text-grey-901 pb-2 ms-1">{unit}</span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <span className="font-12px text-grey-901">{getTextTime()}</span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            space
          </span>
          {currentData && currentData !== 0
            ? buildDot({ datValue: currentData })
            : ""}
        </div>
        <div
          className="d-flex flex-row align-items-center justify-content-between"
          style={{ marginTop: "-10px", marginBottom: "10px" }}
        >
          <span
            className="font-12px text-grey-901"
            style={{ color: "transparent" }}
          >
            space
          </span>
          <span
            className="font-16px fw-medium"
            style={{ marginBottom: "3px", color: "transparent" }}
          >
            space
          </span>
        </div>
      </>
    );
  };

  const getTextTime = (): string => {
    if (date === "") {
      return "N/A";
    }
    const dateMoment = moment(date);

    return dateMoment.format("DD MMM YYYY - HH:mm:ss");
  };

  const buildElementType = ({
    item,
    index,
  }: {
    item: DataHistory;
    index: number;
  }) => {
    return (
      <div
        className={`col-4 d-flex flex-column justify-content-end ${
          index === 1
            ? "border-grey-905 border border-top-0 border-bottom-0"
            : ""
        }`}
      >
        <div className="d-flex flex-column">
          <span className="font-12px text-grey-901">
            {TimePeriodHelper.getTimePeriodName(item.type)}
          </span>
          <div className="d-flex flex-row  align-items-center">
            <span className="font-14px fw-medium me-2 text-black whitespace-nowrap overflow-hidden text-truncate">
              {item?.value ?? "N/A"}
              {item?.secondValue ? `/${item.secondValue}` : ""}
            </span>
            <img
              src={FluctuationTypes.getIcon(item.increase)}
              height={14}
              width={14}
              style={{ fill: "#55C1F6" }}
              alt="icon"
            />
          </div>

          {item?.value ? buildDot({ datValue: item.value }) : ""}
        </div>
      </div>
    );
  };

  const valueData = currentData ?? "N/A";
  const secondValueData = currentDataSecondValue ?? "N/A";
  return (
    <div
      className="d-flex flex-column justify-content-between bg-white rounded-4 p-3 w-100"
      style={{ height: "247px" }}
    >
      {/* header */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
        <img
          width={18}
          height={18}
          src={VitalType.getIcons(vitalType)}
          alt="icon"
        />
        <span className="font-14px fw-medium d-flex flex-grow-1 ms-2">
          {VitalType.getAnalysisName(vitalType)}
        </span>
      </div>
      {/* Content */}
      <div className="border-grey-905 border border-top-0 border-start-0 border-end-0">
        <div className="mt-2 mb-1">
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <span className="font-12px text-grey-901">Current</span>
          </div>
          {customBuildContent ? customBuildContent() : buildContent()}
        </div>
      </div>
      {/* footer */}
      {customBuildElementType ? (
        customBuildElementType()
      ) : (
        <div className="row flex-fill">
          {dataHistory.map((e: DataHistory, index: number) =>
            buildElementType({
              item: e,
              index: index,
            })
          )}
        </div>
      )}
    </div>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginCredentialsDto } from "./models/login-credentials-dto";
import { ApiError } from "@app/redux/common/models/api-error";
import { getErrorPayload } from "@app/redux/common/helpers/error-helper";
import SecurityServiceApi from "@app/services/v2/security-service-api";
import { RegisterClientCredentialsDto, RegisterClientResultDto, RegisterCredentialsDto, RegisterUserCredentialsDto } from "./models/register-credentials-dto";
import { addErrorNotification, addSuccessNotification } from "@app/redux/features/common/notificationSlice";
import { ForgotPasswordDto, resetForgotPasswordDto, ResetPasswordDto } from "./models/forgot-password-dto";
import { ResendConfirmationEmailDto } from "./models/resend-confirmation-email-dto";
import { VerifyOtpDto } from "./models/verify-otp";
import { ResendVerifyOtpDto } from "./models/resend-verify-otp-dto";
import { ActivateUserDto } from "./models/activate-user-dto";
import { AxiosError } from "axios";
import { RegisterResponseDto } from "../../security-service/models/register-credentials-dto";
import { ResetPasswordResponseDto } from "../../security-service/models/forgot-password-dto";

export const loginAsync = createAsyncThunk<any, LoginCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/login",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.login(loginCredentials);
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const registerAsync = createAsyncThunk<RegisterResponseDto, RegisterCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/register",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.register(registerCredentials);
            dispatch(addSuccessNotification('Register successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)

export const confirmEmailAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "securityService/confirmEmail",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.confirmEmail(registerCredentials);
            dispatch(addSuccessNotification('email confirmation successfully'));
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)


export const registerClientAsync = createAsyncThunk<RegisterClientResultDto, RegisterClientCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/registerClient",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.registerClient(registerCredentials);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)

export const registerUserAsync = createAsyncThunk<RegisterClientResultDto, RegisterUserCredentialsDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/registerUser",
    async (registerCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.registerUser(registerCredentials);
            const result = fulfillWithValue(response.data);
            return result;
        } catch (error) {
            const apiError = getErrorPayload(error);
            dispatch(addErrorNotification(apiError.detail));
            return rejectWithValue(apiError);
        }
    }
)


export const forgotPasswordAsync = createAsyncThunk<any, ForgotPasswordDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/forgotPassword",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.forgotPassword(loginCredentials);
            dispatch(addSuccessNotification('Email send successfully'))
            return fulfillWithValue(response.data);
        } catch (error: any) {
            dispatch(addErrorNotification(error.response.data))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const resetForgotPasswordAsync = createAsyncThunk<ResetPasswordResponseDto, resetForgotPasswordDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resetForgotPassword",
    async (resetForgotPasswordDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resetForgotPassword(resetForgotPasswordDto);
            dispatch(addSuccessNotification('Password reset successfully!'))
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)


export const resetPasswordAsync = createAsyncThunk<any, ResetPasswordDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resetPassword",
    async (loginCredentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resetPassword(loginCredentials);
            dispatch(addSuccessNotification('Password reset successfully'))
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)
export const checkUserExistsAsync = createAsyncThunk<any, string,
    { rejectValue: ApiError | undefined }
>(
    "securityService/checkUserExists",
    async (email, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.checkUserExists(email);
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            if (axiosErr.response?.status === 404) {
                return fulfillWithValue(undefined);
            }
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const resendConfirmationEmailAsync = createAsyncThunk<any, ResendConfirmationEmailDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resendConfirmationEmail",
    async (inviteClientDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resendConfirmationEmail(inviteClientDto);
            dispatch(addSuccessNotification('register successfully'));
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)

export const verifyOtpAsync = createAsyncThunk<any, VerifyOtpDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/verifyOtp",
    async (verifyOtpDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.verifyOtp(verifyOtpDto);
            dispatch(addSuccessNotification('OTP verified successfully'));
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)

export const resendVerifyOtpAsync = createAsyncThunk<any, ResendVerifyOtpDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/resendVerifyOtp",
    async (resendVerifyOtpDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.resendVerifyOtp(resendVerifyOtpDto);
            dispatch(addSuccessNotification('Resend OTP successfully'));
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)

export const activateUserAsync = createAsyncThunk<any, ActivateUserDto,
    { rejectValue: ApiError | undefined }
>(
    "securityService/activateUser",
    async (activateUserDto, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SecurityServiceApi.activateUser(activateUserDto);
            dispatch(addSuccessNotification('Activate user successfully'));
            return fulfillWithValue(response.data);
        } catch (error: unknown) {
            const axiosErr = error as AxiosError;
            const errorMessage = (axiosErr.response?.data as string) ?? "An unexpected error occurred";
            dispatch(addErrorNotification(errorMessage))
            const apiError = getErrorPayload(axiosErr);
            return rejectWithValue(apiError);
        }
    }
)

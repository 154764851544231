import React from "react";
import TrendUp from "@app/assets/icons/trend-up.svg";
import TrendDown from "@app/assets/icons/trend-down.svg";
import { VitalType } from "@app/common/enums/VitalTypes";

interface ContentCardProps {
  analysisType: VitalType;
  averageValue: number;
  lowestValue: number;
  highestValue: number;
}

const CardContent: React.FC<ContentCardProps> = ({
  analysisType,
  averageValue,
  lowestValue,
  highestValue,
}) => {
  const unit = VitalType.getUnit(analysisType);
  const image = VitalType.getImage(analysisType);
  return (
    <div className="d-flex mt-0">
      <div className="col-10 d-flex flex-column">
        <div className="d-flex flex-row align-items-end ">
          <div className="text-grey-901 font-12px mb-0 d-flex flex-row align-items-end flex-responsive">
            <div className="mb-0 fw-bold text-dark font-28px me-1">
              {averageValue === 0 ? "N/A" : averageValue}
            </div>

            {averageValue !== 0 ? (
              <div className="d-flex align-items-end margin-bot-content whitespace-nowrap overflow-hidden">
                <div className="d-flex flex-column">
                  <span className="font-12px whitespace-nowrap overflow-hidden text-truncate">
                    {unit ? `${unit} - ` : ""}{" "}
                    {VitalType.getStatusAllType(analysisType, averageValue)}
                  </span>
                </div>
                <div className="text-dark font-12px mb-0 ms-2 whitespace-nowrap overflow-hidden text-truncate">
                  <div
                    className={`bg-${VitalType.getDataColorStatus(
                      analysisType,
                      averageValue
                    )} rounded-circle ms-2 me-2`}
                    style={{
                      width: "8px",
                      height: "8px",
                      display: "inline-block",
                    }}
                  ></div>
                  Your {VitalType.getAnalysisDescription(analysisType)} is
                </div>
                <div
                  className={`text-${VitalType.getDataColorStatus(
                    analysisType,
                    averageValue
                  )} font-12px mb-0 ms-1 fw-bold whitespace-nowrap overflow-hidden text-truncate`}
                >
                  {VitalType.getStatusAllType(analysisType, averageValue)}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {lowestValue !== null && highestValue !== null ? (
          <div className="d-flex flex-row align-items-center gap-3 my-2 ">
            <div className="d-flex flex-column ms-2">
              <p className="text-grey-901 mb-0 font-8px">Lowest</p>
              <div className="d-flex flex-row align-items-center ">
                <span
                  className="mb-0 fw-bold font-16px "
                  style={{ display: "inline-block", width: "fit-content" }}
                >
                  {lowestValue === 0 ? "N/A" : lowestValue}
                  {analysisType === VitalType.TEMPERATURE ? (
                    <span>
                      <span className="text-grey-901"> °</span>C
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                <div className="h-100">
                  <img
                    src={TrendDown}
                    alt="Trend Down"
                    className="h-100 w-75 ms-2"
                  />
                </div>
              </div>
            </div>
            <div
              className="h-100"
              style={{
                width: "1px",
                backgroundColor: "#F7F7F7",
              }}
            />

            <div className="d-flex flex-column">
              <p className="text-grey-901 mb-0 font-8px">Highest</p>
              <div className="d-flex flex-row align-items-center ">
                <div className="mb-0 fw-bold font-16px h-fit-content">
                  {highestValue === 0 ? "N/A" : highestValue}
                  {analysisType === VitalType.TEMPERATURE ? (
                    <span>
                      <span className="text-grey-901"> °</span>C
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="h-100">
                  <img
                    src={TrendUp}
                    alt="Trend Down"
                    className="h-100 w-75 ms-2"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="my-4"></div>
        )}
      </div>

      <div className="col-2 position-relative">
        <div className=" h-100 position-relative">
          <div className="position-absolute h-100  end-0 top-20 ">
            <img src={image} alt="" className="h-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContent;

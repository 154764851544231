import { Button } from "react-bootstrap";
import ActionModal from "../ActionModal";

interface DeleteModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  title: string;
  icon?: string;
  content: string;
  deleteText?: string;
  contentClassName?: string;
}
const DeleteModal = ({
  show,
  onHide,
  title,
  icon,
  content,
  onSubmit,
  deleteText,
  contentClassName,
}: DeleteModalProps) => {
  return (
    <ActionModal
      show={show}
      onHide={onHide}
      title={title}
      size="lg"
      icon={icon}
      contentClassName={contentClassName}
      customButton={
        <>
          <Button
            className="rounded-3 border-very-high py-2 px-3 d-flex align-items-center ms-2 no-hover"
            variant="outline-danger"
            onClick={onHide}
          >
            <p className="my-0 font-12px progress-bar fw-bold">Cancel</p>
          </Button>
          <Button
            className="rounded-3 border-very-high bg-very-high py-2 px-3 d-flex align-items-center ms-2"
            onClick={onSubmit}
            variant="danger"
          >
            <p className="my-0 font-12px progress-bar fw-bold">{`${deleteText ? deleteText : "Yes, remove"}`}</p>
          </Button>
        </>
      }
    >
      <div className="row">
        <div className="col-12 row">
          <div className="col">
            {/* Label */}
            <span className="font-14px text-dark">{content}</span>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default DeleteModal;

import React from "react";
import analysisIcon from "@assets/icons/analysis-icon.svg";
import ProgressContainer from "./ProgressContainer";
import CardContent from "./CardContent";
import { AnalysisStatus } from "@app/common/enums/AnalysisType";
import { VitalType } from "@app/common/enums/VitalTypes";
import { getTimeRange } from "@app/utils/time";
import { useAppSelector } from "@app/redux/store/useStore";
import { RootState } from "@app/redux/store";
import { calculateAverage } from "@app/utils/calculate";

export interface DoubleUnitSummaryCardProd {
  analysisType: VitalType;
  date: string;
  period: string;
}

const DoubleUnitSummaryCard: React.FC<DoubleUnitSummaryCardProd> = ({
  analysisType,
  period,
}) => {
  const typeRanges = {
    [VitalType.BLOOD_PRESSURE]: {
      // sys using for progress bar info  
      firstUnit: [
        { label: "Low", color: "low", range: [0, 90], flex: 5 },
        { label: "Normal", color: "normal", range: [90, 120], flex: 8 },
        { label: "High", color: "high", range: [120, 140], flex: 8 },
      ],
      // dia using for progress bar info
      secondUnit: [
        { label: "Low", color: "low", range: [0, 60], flex: 5 },
        { label: "Normal", color: "normal", range: [60, 80], flex: 8 },
        { label: "High", color: "high", range: [80, 90], flex: 8 },
      ],
    },
    [VitalType.GLUCOSE]: {
      firstUnit: [],
      secondUnit: [],
    },
  };

  const ranges = typeRanges[analysisType as keyof typeof typeRanges];
  const vitalsGraphDto = useAppSelector(
    (state: RootState) => state.healthServiceV2.vitalsGraphDto[analysisType]
  );
  const getSummaryData = (): [
    { firstUnit: number; secondUnit: number },
    { firstUnit: number; secondUnit: number },
    { firstUnit: number; secondUnit: number },
  ] => {
    const vitalBPSAvg = vitalsGraphDto?.vital_data.VITAL_BPS || [];
    const vitalBPSMax = vitalsGraphDto?.vital_data.VITAL_BPS_MAX || [];
    const vitalBPSMin = vitalsGraphDto?.vital_data.VITAL_BPS_MIN || [];
    const vitalBPDAvg = vitalsGraphDto?.vital_data.VITAL_BPD || [];
    const vitalBPDMax = vitalsGraphDto?.vital_data.VITAL_BPD_MAX || [];
    const vitalBPDMin = vitalsGraphDto?.vital_data.VITAL_BPD_MIN || [];

    return [
      {
        firstUnit: calculateAverage(vitalBPSAvg),
        secondUnit: calculateAverage(vitalBPDAvg),
      },
      {
        firstUnit: calculateAverage(vitalBPSMin),
        secondUnit: calculateAverage(vitalBPDMin),
      },
      {
        firstUnit: calculateAverage(vitalBPSMax),
        secondUnit: calculateAverage(vitalBPDMax),
      },
    ];
  };
  const [averageValue, lowestValue, highestValue] = getSummaryData();

  return (
    <div className="bg-white p-3 h-100 rounded-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex bg-white p-2 ps-0 rounded-3 col-7 title-chart">
          <div className="bg-white-100 rounded-circle">
            <img className="img-fluid" src={analysisIcon} alt="Icon" />
          </div>
          <div className="ps-1" style={{ marginTop: "-5px" }}>
            <span className="font-14px fw-medium">Summary</span>
            <p className="text-grey-901 font-10px fw-normal">
              Display data on
              <b className="fw-normal text-dark"> {getTimeRange(period)}</b>
            </p>
          </div>
        </div>
      </div>

      <div className="p-2">
        <CardContent
          analysisType={analysisType}
          averageValue={averageValue}
          lowestValue={lowestValue}
          highestValue={highestValue}
        />
        <div
          className="w-100 mt-2"
          style={{ height: "1px", backgroundColor: "#F7F7F7" }}
        ></div>
        <div className="d-flex flex-row ">
          <div className="col-6 pe-2">
            <ProgressContainer
              progress={averageValue.firstUnit}
              ranges={ranges.firstUnit}
              name="Sys"
              colorName="high"
            />
          </div>
          <div className="col-6 ps-2">
            <ProgressContainer
              progress={averageValue.secondUnit}
              ranges={ranges.secondUnit}
              name="Dia"
              colorName="very-high"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleUnitSummaryCard;

import ClientDetailBreadcrumb from "@app/components/Breadcrumb/ClientDetailBreadcrumb";
import AlertsHealthIncidentsCard from "./widgets/AlertsHealthIncidentsCard";

const AlertsHealthIncidents = () => {
  return (
    <div>
      <main className="container min-vh-100">
        <div className="d-flex flex-column">
          <ClientDetailBreadcrumb viewName="Alerts & Health Incidents" />
          <div className="row d-flex ">
            <div className="tab-container history-tabs">
              <AlertsHealthIncidentsCard />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AlertsHealthIncidents;

import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import uploadIcon from "@assets/icons/upload_icon.svg";
import removeIcon from "@assets/icons/remove_icon.svg";
import avatar from "@assets/icons/complete-profile/default_profile_image.svg";
import { EmergencyProps } from ".";
import deleteIcon from "@assets/icons/delete_icon.svg";
import { CountryCodes } from "./data";
import { FormControl, MenuItem, Select } from "@mui/material";
import { debounce } from "lodash";

const ContactBlock = ({
  isError,
  contact,
  index,
  updateContact,
  removeContact,
  type,
}: {
  isError: boolean;
  contact: EmergencyProps;
  type: "add" | "update";
  index: number;
  updateContact: (index: number, updatedContact: EmergencyProps) => void;
  removeContact: (index: number) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string | null;
        if (result) {
          const base64Image = result.split(",")[1];
          updateContact(index, { ...contact, avatar: base64Image });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };

  const handleRemovePhoto = () => {
    updateContact(index, { ...contact, avatar: "" });
  };

  //Multi filter select custom

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        left: 0,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 125,
      },
    },
  };

  const debouncedShowDropdown = debounce(() => {
    setSelectOpen(true);
  }, 1000);

  const [filteredCountryCodes, setFilteredCountryCodes] =
    useState(CountryCodes);

  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    return () => {
      debouncedShowDropdown.cancel();
    };
  }, []);

  useEffect(() => {
    const isPrefixValid = CountryCodes.some(
      (prefix) => prefix.value === contact?.mobile_prefix
    );

    if (!isPrefixValid && !selectOpen) {
      updateContact(index, {
        ...contact,
        mobile_prefix: "",
      });
      setFilteredCountryCodes(
        CountryCodes.filter((item) => item.value.includes(""))
      );
    }
  }, [selectOpen]);
  return (
    <div
      className={`contact-block pb-3 mb-2 ${
        index !== 0 ? "border-grey-901 border-top" : ""
      }`}
    >
      <div className="font-14px fw-bold mt-2 d-flex justify-content-between align-items-center">
        {type === "add" ? <span>Person {index + 1} </span> : ""}
        {index !== 0 ? (
          <div className="d-flex justify-content-end align-items-end">
            <div
              className={`mt-2 text-very-high font-12px cursor-pointer d-flex align-items-center`}
              style={{ width: "fit-content" }}
              onClick={() => removeContact(index)}
            >
              <img src={deleteIcon} alt="Delete" />
              Delete
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-12">
        <div className="d-flex flex-row justify-content-start align-items-center w-100 ">
          <div className="icon-chart-custom py-1 d-flex align-items-center justify-content-center">
            <img
              height={78}
              width={78}
              src={
                contact?.avatar
                  ? `data:image/png;base64,${contact.avatar}`
                  : avatar
              }
              alt="Icon"
              className="rounded-circle"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div>
            <Button
              className="ms-2 rounded-3 text-blue-901 border-green-900 px-3 d-flex align-items-center no-hover bg-blue-903"
              onClick={handleButtonClick}
            >
              <img src={uploadIcon} alt="upload icon" />
              <p
                className="ms-1 my-0 font-12px progress-bar"
                style={{ textTransform: "none" }}
              >
                Upload photo
              </p>
            </Button>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <Button className="rounded-3 text-very-high border-green-900 px-3 d-flex align-items-center no-hover bg-transparent">
            <img src={removeIcon} alt="remove" />
            <p
              className="ms-1 my-0 font-12px progress-bar"
              style={{ textTransform: "none" }}
              onClick={handleRemovePhoto}
            >
              Remove photo
            </p>
          </Button>
        </div>
      </div>

      <div className="font-14px fw-bold mt-2">Contact information</div>
      <div className="d-flex justify-content-end">
        <div className="form-check form-switch ">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            style={{ width: "2.5em" }}
            id={`flexSwitchCheckChecked-${index}`}
            checked={contact.is_sos ?? false}
            onChange={() =>
              updateContact(index, { ...contact, is_sos: !contact.is_sos })
            }
          />
          <label className="form-check-label font-12px fw-bold ms-2">
            SOS Contact
          </label>
        </div>
      </div>

      <div className="col-12 row gap-3">
        <div className="col">
          <div className="row gap-3">
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">First Name</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="First Name"
                  value={contact?.first_name ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      first_name: e.target.value,
                    })
                  }
                />
              </InputGroup>
              {isError && contact?.first_name === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter first name
                </Form.Control.Feedback>
              ) : null}
            </div>

            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Last Name</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="Last Name"
                  value={contact?.last_name ?? ""}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      last_name: e.target.value,
                    })
                  }
                />
              </InputGroup>
              {isError && contact?.last_name === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter last name
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col">
          {/* Label */}
          <span className="font-10px text-dark">Relationship</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="text"
              placeholder="Relationship"
              value={contact.relationship || ""}
              onChange={(e) =>
                updateContact(index, {
                  ...contact,
                  relationship: e.target.value,
                })
              }
            />
          </InputGroup>
          {isError && contact?.relationship === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter relationship
            </Form.Control.Feedback>
          ) : null}
        </div>
      </div>

      <div className="col-12 row gap-3">
        <div className="col">
          {/* Label */}
          <span className="font-10px text-dark">Email</span>
          <InputGroup className="border rounded-3 border-0 bg-grey-904">
            <Form.Control
              className="border-0 bg-transparent font-12px py-2"
              type="email"
              placeholder="Email"
              value={contact?.email_address ?? ""}
              onChange={(e) =>
                updateContact(index, {
                  ...contact,
                  email_address: e.target.value,
                })
              }
            />
          </InputGroup>
          {isError && contact?.email_address === "" ? (
            <Form.Control.Feedback type="invalid">
              Please enter email
            </Form.Control.Feedback>
          ) : isError &&
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact?.email_address) ? (
            <Form.Control.Feedback type="invalid">
              Please enter valid email
            </Form.Control.Feedback>
          ) : null}
        </div>

        <div className="col">
          <div className="row gap-3">
            <div className="col-4">
              {/* Label */}
              <span className="font-10px text-dark">Mobile Prefix</span>

              <Form.Group
                id="registerCountry"
                className="text-capitalize position-relative mb-0"
                controlId="registerForm.location"
              >
                <InputGroup
                  className="position-absolute w-50 border rounded-start-3 border-0 bg-grey-904"
                  style={{ zIndex: 1 }}
                >
                  <Form.Control
                    className="border-0 bg-transparent font-12px py-2"
                    type="text"
                    placeholder="Mobile Number"
                    value={contact?.mobile_prefix ?? ""}
                    maxLength={5}
                    onChange={(e) => {
                      const filteredValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      updateContact(index, {
                        ...contact,
                        mobile_prefix: filteredValue,
                      });

                      setFilteredCountryCodes(
                        CountryCodes.filter((item) =>
                          item.value.includes(filteredValue)
                        )
                      );

                      debouncedShowDropdown();
                    }}
                  />
                </InputGroup>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <Select
                    className="custom-select d-flex flex-column"
                    MenuProps={MenuProps}
                    value={contact?.mobile_prefix ?? ""}
                    onChange={(e) => {
                      updateContact(index, {
                        ...contact,
                        mobile_prefix: e.target.value,
                      });
                      setSelectOpen(false);
                    }}
                    open={selectOpen}
                    onClose={() => {
                      setSelectOpen(false);
                    }}
                    onOpen={() => setSelectOpen(true)}
                    labelId="country-select-label"
                    id="country-select"
                    label="Country"
                    displayEmpty={true}
                  >
                    {filteredCountryCodes.map(
                      (item: { value: string }, index) => (
                        <MenuItem className="d-flex justify-content-start ms-2" key={index} value={item.value}>
                          {item.value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Form.Group>

              {isError && contact?.mobile_prefix === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter prefix
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="col">
              {/* Label */}
              <span className="font-10px text-dark">Mobile Number</span>
              <InputGroup className="border rounded-3 border-0 bg-grey-904">
                <Form.Control
                  className="border-0 bg-transparent font-12px py-2"
                  type="text"
                  placeholder="Mobile Number"
                  value={contact?.mobile_number ?? ""}
                  maxLength={10}
                  onChange={(e) =>
                    updateContact(index, {
                      ...contact,
                      mobile_number: e.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                />
              </InputGroup>
              {isError && contact?.mobile_number === "" ? (
                <Form.Control.Feedback type="invalid">
                  Please enter mobile number
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;

import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@app/redux/store/useStore";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "@app/redux/store";
import { getCountriesAsync } from "@app/redux/features/staticData-service/saticDataServiceThunk";
import uploadIcon from "@assets/icons/upload_icon.svg";
import moment from "moment";
import { SkinColorSection } from "@app/views/consumer/profile/widgets/Profile.GeneralInformationCard/SkinColorSection";
import removeIcon from "@assets/icons/remove_icon.svg";
import defaultProfileImage from "@assets/icons/complete-profile/default_profile_image.svg";
import { postProfileAsync } from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";
import { CreateProfileDto } from "@app/redux/features/v2/user-profile-service/models/complete-invidual-profile-dto";
import { countryDataDto } from "@app/redux/features/staticData-service/models/country-data-dto";
import { uploadProfileImageAsync } from "@app/redux/features/v2/user-profile-service/userProfileServiceThunk";
import { CreateProfileResultDto } from "@app/redux/features/v2/user-profile-service/models/user-profile-result-dto";

const CompleteInvidualProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { contry_data } = useAppSelector(
    (state: RootState) => state.staticDataService
  );
  const [avatar, setAvatar] = useState("");
  const { currentUser } = useAppSelector(
    (state: RootState) => state.securityService
  );

  useEffect(() => {
    dispatch(getCountriesAsync({ page: 1, per_page: 100 }));
  }, [dispatch]);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm<CreateProfileDto>({
    defaultValues: {
      sid: currentUser?.id,
      email: currentUser?.email,
      first_name: "",
      last_name: "",
      date_of_birth: "",
      age: 0,
      gender: "male",
      mobile_prefix: "",
      mobile: "",
      location: "",
      health_risk: "",
      nationality: "",
      weight_kg: 0,
      weight_lb: 0,
      height_cm: 0,
      height_in: 0,
      time_zone: clientTimezone,
      user_sid: "",
      skin_tone: "Dairy Cream",
      organisation_sid: currentUser?.org_id ?? "",
      is_complete: false,
      mobile_app_status: false,
    },
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleChangeSkinToneValue = (value: string) => {
    setValue("skin_tone", value);
  };

  const dob = watch("date_of_birth");

  useEffect(() => {
    if (dob) {
      const age = moment().diff(moment(dob, "YYYY-MM-DD"), "years");
      setValue("age", age);
    }
  }, [dob, setValue]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string | null;
        if (result) {
          const base64Image = result.split(",")[1];
          setAvatar(base64Image ?? "");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = () => {
    setAvatar("");
  };

  const onSubmit = (data: CreateProfileDto) => {
    dispatch(postProfileAsync(data)).then((action) => {
      if(action.meta.requestStatus === "fulfilled"){
        const result = action.payload as CreateProfileResultDto;
        if(result.wearer_sid){
          if(avatar){
            dispatch(uploadProfileImageAsync({ 
              wearer_sid: result.wearer_sid,
              avatar: avatar
            })).then((action) => {
              if(action.meta.requestStatus === "fulfilled"){
                navigate('/consumer/monthly-reports');
              }
            })
          }else{
            navigate('/consumer/monthly-reports');
          }
        }
      }
    });
  };

  return (
    <div style={{ paddingLeft: 103 }}>
      <div style={{ width: 640 }}>
        <div className="mt-3 mb-3 font-20px fw-bold">My Profile</div>
        <div style={{ backgroundColor: "white", padding: 16, borderRadius: 6 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="font-14px fw-bold mt-2">Profile image</div>
              <div className="col-12 pb-3 mb-2">
                <div className="d-flex flex-row justify-content-start align-items-end w-100 ">
                  <div className="icon-chart-custom py-1 d-flex align-items-center justify-content-center">
                    <Image
                      height={78}
                      width={78}
                      src={
                        avatar
                          ? `data:image/png;base64,${avatar}`
                          : defaultProfileImage
                      }
                      className="rounded-circle"
                      alt="Profile"
                    />
                  </div>
                  <div>
                    <Button
                      style={{ border: "none" }}
                      className="ms-2 rounded-3 text-blue-901 px-3 d-flex align-items-center no-hover bg-blue-903"
                      onClick={handleButtonClick}
                    >
                      <img src={uploadIcon} alt="upload icon" />
                      <p
                        className="ms-1 my-0 font-12px progress-bar"
                        style={{ textTransform: "none" }}
                      >
                        Upload photo
                      </p>
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <Button
                    style={{ border: "none" }}
                    className="text-very-high px-3 d-flex align-items-center no-hover bg-transparent"
                    onClick={handleRemovePhoto}
                  >
                    <img src={removeIcon} alt="remove" />
                    <p
                      className="ms-1 my-0 font-12px progress-bar"
                      style={{ textTransform: "none" }}
                    >
                      Remove photo
                    </p>
                  </Button>
                </div>
              </div>

              <div className="font-14px fw-bold mt-2">Edit Profile</div>

              <div className="col-12 row gap-3">
                <div className="col">
                  <div className="row gap-1">
                    <div style={{ paddingRight: 0 }} className="col">
                      <span className="font-10px text-dark">First Name</span>
                      <Controller
                        name="first_name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: `First name is required`,
                          },
                        }}
                        render={({ field }) => (
                          <InputGroup className="border rounded-3 border-0 bg-grey-904">
                            <Form.Control
                              {...field}
                              className="border-0 bg-transparent font-12px py-2"
                              type="text"
                              placeholder="First Name"
                            />
                          </InputGroup>
                        )}
                      />
                      {errors?.first_name && (
                        <Form.Text className="text-danger">
                          {errors.first_name.message}
                        </Form.Text>
                      )}
                    </div>
                    <div style={{ paddingLeft: 5 }} className="col">
                      <span className="font-10px text-dark">Last Name</span>
                      <Controller
                        name="last_name"
                        rules={{
                          required: {
                            value: true,
                            message: `Last name is required`,
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputGroup className="border rounded-3 border-0 bg-grey-904">
                            <Form.Control
                              {...field}
                              className="border-0 bg-transparent font-12px py-2"
                              type="text"
                              placeholder="Last Name"
                            />
                          </InputGroup>
                        )}
                      />
                      {errors?.last_name && (
                        <Form.Text className="text-danger">
                          {errors.last_name.message}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <span className="font-10px text-dark">Email</span>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: { value: true, message: `Email is required` },
                    }}
                    render={({ field }) => (
                      <InputGroup className="border rounded-3 border-0 bg-grey-904">
                        <Form.Control
                          {...field}
                          className="border-0 bg-transparent font-12px py-2"
                          type="email"
                          placeholder="Email"
                        />
                      </InputGroup>
                    )}
                  />
                  {errors?.email && (
                    <Form.Text className="text-danger">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </div>
              </div>

              <div className="col-12 row gap-3">
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Nationality</span>
                  <Controller
                    name="nationality"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: `Nationality is required`,
                      },
                    }}
                    render={({ field }) => (
                      <InputGroup className="border rounded-3 border-0 bg-grey-904">
                        <Form.Select
                          className="border-0 bg-transparent font-12px py-2"
                          {...field}
                        >
                          <option>Select Nationality</option>
                          {contry_data.map((item: countryDataDto) => (
                            <option key={item.sid} value={item.iso_name}>
                              {item.iso_name}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    )}
                  />
                  {errors?.nationality && (
                    <Form.Text className="text-danger">
                      {errors.nationality.message}
                    </Form.Text>
                  )}
                </div>
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Date of birth</span>
                  <Controller
                    name="date_of_birth"
                    rules={{
                      required: { value: true, message: `D.O.B is required` },
                    }}
                    control={control}
                    render={({ field }) => (
                      <InputGroup className="border rounded-3 border-0 bg-grey-904">
                        <Form.Control
                          {...field}
                          className="border-0 bg-transparent font-12px py-2 "
                          type="date"
                          placeholder="dd/mm/yyyy"
                        />
                      </InputGroup>
                    )}
                  />
                  {errors?.date_of_birth && (
                    <Form.Text className="text-danger">
                      {errors.date_of_birth.message}
                    </Form.Text>
                  )}
                </div>
              </div>

              <div className="col-12 row gap-3">
                <div className="col">
                  {/* Label */}
                  <span className="font-10px text-dark">Gender</span>
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <InputGroup className="border rounded-3 border-0 bg-grey-904">
                        <Form.Select
                          {...field}
                          className="border-0 bg-transparent font-12px py-2"
                        >
                          <option selected value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Select>
                      </InputGroup>
                    )}
                  />
                  {errors?.gender && (
                    <Form.Text className="text-danger">
                      {errors.gender.message}
                    </Form.Text>
                  )}
                </div>
                <div className="col">
                  <div className="row gap-1">
                    <div style={{ paddingRight: 0 }} className="col">
                      {/* Label */}
                      <span className="font-10px text-dark">Height</span>
                      <Controller
                        name="height_cm"
                        rules={{
                          required: {
                            value: true,
                            message: "Height is required",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Height must be a valid number",
                          },
                          validate: {
                            notZero: (value) =>
                              value > 0 || "Height must be greater than 0",
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <InputGroup className="border rounded-3 border-0 bg-grey-904 d-flex flex-row align-items-center">
                            <Form.Control
                              {...field}
                              className="border-0 bg-transparent font-12px py-2"
                              type="text"
                              placeholder=""
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                            />
                            <span className="font-12px text-grey-901 pe-2">
                              cm
                            </span>
                          </InputGroup>
                        )}
                      />
                      {errors?.height_cm && (
                        <Form.Text className="text-danger">
                          {errors.height_cm.message}
                        </Form.Text>
                      )}
                    </div>
                    <div style={{ paddingLeft: 5 }} className="col">
                      <span className="font-10px text-dark">Weight</span>
                      <Controller
                        name="weight_kg"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Weight is required",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Weight must be a valid number",
                          },
                          validate: {
                            notZero: (value) =>
                              value > 0 || "Weight must be greater than 0",
                          },
                        }}
                        render={({ field }) => (
                          <InputGroup className="border rounded-3 border-0 bg-grey-904 d-flex flex-row align-items-center">
                            <Form.Control
                              {...field}
                              className="border-0 bg-transparent font-12px py-2 text-black"
                              type="text"
                              placeholder=""
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                            />
                            <span className="font-12px text-grey-901 pe-2">
                              kg
                            </span>
                          </InputGroup>
                        )}
                      />
                      {errors?.weight_kg && (
                        <Form.Text className="text-danger">
                          {errors.weight_kg.message}
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <SkinColorSection
                  onSkinColorChange={handleChangeSkinToneValue}
                  initValue="Dairy Cream"
                />
              </div>

              <div className="col-12 mt-2">
                <Button
                  type="submit"
                  style={{ width: 68, paddingLeft: 12 }}
                  className="rounded-3 text-white px-3 d-flex justify-content-center align-items-center no-hover bg-blue-901 border-blue-901"
                >
                  <p className="ms-1 my-0 font-12px progress-bar">Save</p>
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CompleteInvidualProfile;
